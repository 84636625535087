import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from 'state/store';
import { ProjectProgressState } from 'types/store/projectProgress';
import { fetchProjectProgress as fetchProjectProgressApi } from 'api/projectProgress';

const initialState: ProjectProgressState = {
  totalTasks: 0,
  completedTasks: 0,
  estimateHours: 0,
  actualHours: 0,
  loading: false,
};

interface FetchProjectProgressParams {
  projectId: string;
  userId?: string;
}

export const fetchProjectProgress = createAsyncThunk(
  'project/progress/fetchProjectProgress',
  async (params: FetchProjectProgressParams, { rejectWithValue }) => {
    try {
      const response = await fetchProjectProgressApi(params);
      return response.progress;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error);
      }
      return rejectWithValue(
        'An error occurred when fetching project progress'
      );
    }
  }
);

const progressSlice = createSlice({
  name: 'project/progress',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProjectProgress.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchProjectProgress.fulfilled, (state, action) => {
      state.totalTasks = parseInt(action.payload.totalTasks);
      state.completedTasks = parseInt(action.payload.completedTasks);
      state.estimateHours = parseFloat(action.payload.estimateHours);
      state.actualHours = parseFloat(action.payload.actualHours);
      state.loading = false;
    });
    builder.addCase(fetchProjectProgress.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const selectIsProjectProgressLoading = createSelector(
  [(state: RootState) => state.projectProgress],
  (progress: ProjectProgressState) => progress.loading
);

export const selectAreAllTasksCompleted = createSelector(
  [(state: RootState) => state.projectProgress],
  (progress: ProjectProgressState) =>
    progress.totalTasks === progress.completedTasks
);

export const selectProjectProgress = createSelector(
  [(state: RootState) => state.projectProgress],
  (progress: ProjectProgressState) => ({
    totalTasks: progress.totalTasks,
    completedTasks: progress.completedTasks,
    estimateHours: progress.estimateHours,
    actualHours: progress.actualHours,
  })
);

export default progressSlice.reducer;
