import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Dropdown, FormItem } from '@getsynapse/design-system';
import classnames from 'classnames';
import { Option } from 'utils/customTypes';
import {
  fetchTeamTasks,
  selectAreAllTasksClosed,
} from 'state/ProjectTasks/projectTaskSlice';
import {
  fetchProjectProgress,
  selectAreAllTasksCompleted,
} from 'state/Project/progress/progressSlice';

import { NEW_PROJECT_FORM_FIELDS, TASK_STATUS } from 'utils/constants';
import { ProjectStatusOption } from './ProjectStatusOption';

type ProjectStatusPickerProps = {
  hasError: boolean;
  fromSidePanel: boolean;
  isReadOnly: boolean;
  handleFormFieldChange: (status: string, value: string) => void;
  values: Option[];
  options: Option[];
  projectId?: string;
};

function ProjectStatusPicker(props: ProjectStatusPickerProps) {
  const {
    hasError,
    fromSidePanel,
    isReadOnly,
    handleFormFieldChange,
    values,
    options,
    projectId,
  } = props;
  const { newTaskListViews = false } = useFlags();
  const dispatch = useDispatch();
  useEffect(() => {
    if (projectId) {
      if (newTaskListViews) {
        dispatch(fetchProjectProgress({ projectId }));
      } else {
        dispatch(fetchTeamTasks(projectId));
      }
    }
  }, [projectId, dispatch, newTaskListViews]);

  const areAllV1TasksClosed = useSelector(selectAreAllTasksClosed);
  const areAllV2TaskClosed = useSelector(selectAreAllTasksCompleted);
  const areAllTasksCompleted = newTaskListViews
    ? areAllV2TaskClosed
    : areAllV1TasksClosed;

  function renderOption(
    option: Option,
    selected: boolean,
    selectOption: () => void,
    { className, ...otherProps }: Record<string, any>
  ) {
    const isCompletedOption = option.value === TASK_STATUS.COMPLETED;
    const disabled = !areAllTasksCompleted && isCompletedOption;

    return (
      <ProjectStatusOption
        selected={selected}
        disabled={disabled}
        otherProps={otherProps}
        option={option}
        selectOption={selectOption}
      />
    );
  }

  return (
    <FormItem
      label={intl.get('PROJECT_DETAIL.STATUS').defaultMessage('Status')}
      helpText={
        hasError &&
        intl
          .get('NEW_PROJECT_PAGE.MISSING_INFO_ERROR')
          .defaultMessage('This field is required')
      }
      helpTextProps={{
        state: hasError ? 'error' : 'default',
      }}
      labelProps={{
        required: true,
        state: hasError ? 'error' : 'default',
      }}
      className={classnames({ 'mb-4': fromSidePanel })}
    >
      <Dropdown
        disabled={isReadOnly}
        placeholder={intl
          .get('PROJECT_DETAIL.STATUS_PLACEHOLDER')
          .defaultMessage('Select a status')}
        state={hasError ? 'error' : 'default'}
        onChange={(option: Option) =>
          handleFormFieldChange(NEW_PROJECT_FORM_FIELDS.STATUS, option.value)
        }
        values={values}
        options={options}
        triggerProps={{ 'data-cy': 'project-status-input' }}
        renderOption={renderOption}
      />
    </FormItem>
  );
}

export default ProjectStatusPicker;
