import { useState, useCallback, FC } from 'react';
import intl from 'react-intl-universal';
import { TASKS_MORE_ACTIONS } from 'utils/constants';
import MoreActions from 'Organisms/MoreActions/MoreActions';
import DeleteTaskConfirmation from '../DeleteTaskConfirmation/DeleteTaskConfirmation';

interface TaskActionsPopupProps {
  disabled?: boolean;
  deleteTaskCallback: () => void;
}

const TaskActionsPopup: FC<TaskActionsPopupProps> = ({
  disabled = false,
  deleteTaskCallback,
}) => {
  const [displayDeleteConfirmationModal, setDisplayDeleteConfirmationModal] =
    useState<boolean>(false);

  const toggleDeleteConfirmationModal = useCallback(() => {
    setDisplayDeleteConfirmationModal((prev) => !prev);
  }, []);

  const handleConfirmation = useCallback(() => {
    toggleDeleteConfirmationModal();
    deleteTaskCallback();
  }, [toggleDeleteConfirmationModal, deleteTaskCallback]);

  return (
    <>
      <DeleteTaskConfirmation
        shouldDisplayModal={displayDeleteConfirmationModal}
        toogleModal={toggleDeleteConfirmationModal}
        confirmDeleteTask={handleConfirmation}
      />
      <MoreActions
        options={[
          {
            value: TASKS_MORE_ACTIONS.DELETE,
            label: intl.get(
              'TASKS.TASK_DETAIL_PAGE.MORE_ACTIONS_DROPDOWN.DELETE'
            ),
            iconName: 'trash',
            iconClassName: 'text-error-darker group-hover:text-error-dark',
            dataCy: 'delete-task-button',
            disabled,
          },
        ]}
        onSelectOption={toggleDeleteConfirmationModal}
      />
    </>
  );
};

export default TaskActionsPopup;
