import { useState, useCallback, useMemo } from 'react';
import intl from 'react-intl-universal';
import { useDispatch } from 'react-redux';
import {
  addActualHours,
  deleteActualHours,
} from 'state/ActualHours/actualHoursSlice';
import { Hours, NewActualHoursEntry } from 'types/store/actualHours';

export interface UpdateActualHoursParams {
  taskId: string;
  showNotification: (params: any) => void;
  onSuccessCallback?: () => void;
}

interface UpdateActualHoursReturn {
  canSave: boolean;
  addNewActualHoursEntry: (
    newActualHoursEntry: NewActualHoursEntry | null
  ) => void;
  handleDeletedHours: (hours: Hours) => void;
  handleSaveActualHours: () => void;
  reset: () => void;
}

const useUpdateActualHours: (
  params: UpdateActualHoursParams
) => UpdateActualHoursReturn = ({
  taskId,
  showNotification,
  onSuccessCallback = () => {},
}) => {
  const dispatch = useDispatch();
  const [newActualHoursEntries, setNewActualHoursEntries] = useState<
    NewActualHoursEntry[]
  >([]);
  const [hoursEntriesToRemove, setHoursEntriesToRemove] = useState<Hours[]>([]);
  const canSave = useMemo(
    () => newActualHoursEntries.length > 0 || hoursEntriesToRemove.length > 0,
    [newActualHoursEntries, hoursEntriesToRemove]
  );

  const addNewActualHoursEntry = useCallback(
    (newActualHoursEntry: NewActualHoursEntry | null) => {
      if (newActualHoursEntry) {
        setNewActualHoursEntries([newActualHoursEntry]);
      } else {
        setNewActualHoursEntries([]);
      }
    },
    []
  );

  const handleDeletedHours = useCallback((hours: Hours) => {
    setHoursEntriesToRemove((prev) => [...prev, hours]);
  }, []);

  const reset = useCallback(() => {
    setNewActualHoursEntries([]);
    setHoursEntriesToRemove([]);
  }, []);

  const handleSaveActualHours = useCallback(async () => {
    let updatedActualHoursEntries = false;
    try {
      if (newActualHoursEntries.length > 0) {
        await dispatch(
          addActualHours({
            taskId,
            actualHours: newActualHoursEntries,
          })
        );
        updatedActualHoursEntries = true;
      }
      if (hoursEntriesToRemove.length > 0) {
        await dispatch(
          deleteActualHours({
            taskId,
            hours: hoursEntriesToRemove,
          })
        );
        updatedActualHoursEntries = true;
      }
    } catch (error) {
      showNotification({
        notificationTitle: intl.get(
          'TASK_ACTUAL_HOURS.UPDATE_ACTUAL_HOURS_ERROR.TITLE'
        ),
        notificationMessage: intl.get(
          'TASK_ACTUAL_HOURS.UPDATE_ACTUAL_HOURS_ERROR.MESSAGE'
        ),
        notificationVariant: 'error',
      });
    } finally {
      if (updatedActualHoursEntries) {
        showNotification({
          notificationTitle: '',
          notificationMessage: intl.get(
            'TASK_ACTUAL_HOURS.UPDATE_ACTUAL_HOURS_SUCCESS_MESSAGE'
          ),
        });
      }
      reset();
      onSuccessCallback();
    }
  }, [
    dispatch,
    taskId,
    newActualHoursEntries,
    hoursEntriesToRemove,
    onSuccessCallback,
    showNotification,
    reset,
  ]);

  return useMemo(
    () => ({
      canSave,
      addNewActualHoursEntry,
      handleDeletedHours,
      handleSaveActualHours,
      reset,
    }),
    [
      addNewActualHoursEntry,
      canSave,
      handleSaveActualHours,
      handleDeletedHours,
      reset,
    ]
  );
};

export default useUpdateActualHours;
