import { FormItem, Modal, Typography } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';
import {
  getAvailabeProjectsForLinking,
  setLinkedProjects,
} from 'state/Program/programSlice';
import { useState, useMemo } from 'react';
import linkProjectIcon from 'assets/icons/link-project.svg';
import isEmpty from 'lodash/isEmpty';
import MultiSelectDropdown from 'Organisms/MultiSelectDropdow/MultiSelectDropdown';
import { FormOption, ProgramLinkedProject } from 'utils/customTypes';
import UnsavedChangesModal from 'Organisms/UnsavedChangesModal/UnsavedChangesModal';
import { formatProjectNumber } from 'utils/formatters';

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const LinkProjectsModal = ({ isOpen, setIsOpen }: Props) => {
  const dispatch = useDispatch();

  const projects = useSelector(getAvailabeProjectsForLinking);

  const [selectedProjects, setSelectedProjects] = useState<
    ProgramLinkedProject[]
  >([]);

  const projectsOptions = useMemo(
    () =>
      projects
        .map((project) => ({
          title: project.title,
          label:
            formatProjectNumber(project.projectNumber) + ' - ' + project.title,
          value: project.id,
        }))
        .sort((a, b) =>
          a.title.localeCompare(b.title, undefined, {
            numeric: true,
            sensitivity: 'base',
          })
        ),
    [projects]
  );

  const [hasChanges, setHasChanges] = useState(false);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);

  const handleSelectProject = (options: FormOption[]) => {
    const values = options.map((item) => {
      return item.value;
    });
    const addedProjects = projects.filter((project) =>
      values.some((value) => value === project.id)
    );
    setSelectedProjects(addedProjects);
    setHasChanges(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setHasChanges(false);
    setSelectedProjects([]);
  };

  const linkProjects = () => {
    dispatch(setLinkedProjects({ projects: selectedProjects }));
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      title={intl.get('PROGRAM_PAGE.LINK_PROJECTS_MODAL.TITLE')}
      data-cy='link-projects-modal'
      titleIcon={{ src: linkProjectIcon }}
      aria-label={intl.get('PROGRAM_PAGE.LINK_PROJECTS_MODAL.TITLE')}
      closeModal={() => {
        if (hasChanges) {
          setShowUnsavedChangesModal(true);
        } else {
          setShowUnsavedChangesModal(false);
          closeModal();
        }
      }}
      size='large'
      actionButtons={[
        {
          children: intl.get('PROGRAM_PAGE.LINK_PROJECTS_MODAL.LINK_BUTTON'),
          variant: 'primary',
          onClick: linkProjects,
          'data-cy': 'modal-confirm-button',
          disabled: isEmpty(selectedProjects),
        },
        {
          children: intl.get('CANCEL'),
          variant: 'secondary',
          onClick: closeModal,
          'data-cy': 'modal-cancel-button',
        },
      ]}
    >
      <div>
        <Typography className='mb-6' data-cy='test-clickable'>
          {intl.get('PROGRAM_PAGE.LINK_PROJECTS_MODAL.CAPTION')}
        </Typography>

        <FormItem
          label={intl.get('PROGRAM_PAGE.LINK_PROJECTS_MODAL.PROJECTS_LABEL')}
        >
          <MultiSelectDropdown
            options={projectsOptions}
            values={[]}
            onChange={handleSelectProject}
            multiple
            filterable
            noOptionsText={intl.get(
              'PROGRAM_PAGE.LINK_PROJECTS_MODAL.NO_PROJECTS_AVAILABLE'
            )}
            placeholder={intl.get(
              'PROGRAM_PAGE.LINK_PROJECTS_MODAL.PROJECTS_DROPDOWN_PLACEHOLDER'
            )}
            triggerProps={{
              'data-cy': 'link-projects-dropdown',
              className: 'py-0 items-center',
            }}
          />
        </FormItem>
      </div>
      <UnsavedChangesModal
        isOpen={showUnsavedChangesModal}
        setIsOpen={setShowUnsavedChangesModal}
        onConfirm={() => closeModal()}
      />
    </Modal>
  );
};

export default LinkProjectsModal;
