import React, { useCallback, useEffect, useMemo, useState } from 'react';
import intl from 'react-intl-universal';
import { useDispatch } from 'react-redux';
import { tailwindOverride } from '@getsynapse/design-system';
import { NewParticipant } from 'utils/types/tasksAssignmentsResourcesCapacity';
import {
  addParticipant,
  setParticipantToUpdate,
  updateParticipant,
} from 'state/TasksAssignmentsResourcesCapacity/tasksAssignmentsResourcesCapacitySlice';
import { showNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import useModal from 'Hooks/useModal';
import ParticipantPicker from './components/ParticipantPicker/ParticipantPicker';
import ProjectRolePicker from './components/ProjectRolePicker/ProjectRolePicker';
import UnsavedChangesModal from 'Organisms/UnsavedChangesModal/UnsavedChangesModal';

const defaultState: NewParticipant = {
  user_id: '',
  job_roles: [],
};

const AddParticipantModal: React.FC<{
  projectId: string;
  isModalOpen: boolean;
  onCloseModal: () => void;
  participant?: NewParticipant;
}> = ({ projectId, isModalOpen = false, onCloseModal, participant }) => {
  const dispatch = useDispatch();
  const isUpdating = !!participant;
  const [currentParticipant, setCurrentParticipant] =
    useState<NewParticipant>(defaultState);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { Modal, modalProps, openModal, closeModal } = useModal();
  const canSave = useMemo(() => {
    if (isUpdating) {
      return (
        !isSaving &&
        currentParticipant?.job_roles?.length !== participant?.job_roles?.length
      );
    } else {
      return !isSaving && !!currentParticipant?.user_id;
    }
  }, [currentParticipant, participant, isSaving, isUpdating]);

  const [hasChanges, setHasChanges] = useState(false);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);

  const handleCloseModal = useCallback(() => {
    if (isUpdating) {
      dispatch(setParticipantToUpdate(null));
    }
    setCurrentParticipant(defaultState);
    setIsSaving(false);
    setHasChanges(false);
    onCloseModal();
    closeModal();
  }, [onCloseModal, closeModal, isUpdating, dispatch]);

  const handleAddNewParticipant = useCallback(async () => {
    await dispatch(
      addParticipant({
        project_id: projectId,
        user_id: currentParticipant.user_id,
        job_roles: currentParticipant.job_roles,
      })
    );
    dispatch(
      showNotification({
        notificationVariant: 'success',
        notificationTitle: intl.get(
          'PEOPLE.RESOURCE_ALLOCATION.ADD_PARTICIPANT_MODAL.PARTICIPANT_ADDED_SUCCESS_SNACKBAR_MESSAGE'
        ),
      })
    );
  }, [projectId, currentParticipant, dispatch]);

  const handleUpdateParticipant = useCallback(async () => {
    await dispatch(
      updateParticipant({
        project_id: projectId,
        user_id: currentParticipant.user_id,
        updateFields: {
          job_roles: currentParticipant.job_roles,
        },
      })
    );
    dispatch(
      showNotification({
        notificationVariant: 'success',
        notificationTitle: intl.get(
          'PEOPLE.RESOURCE_ALLOCATION.ADD_PARTICIPANT_MODAL.PARTICIPANT_UPDATED_SUCCESS_SNACKBAR_MESSAGE'
        ),
      })
    );
  }, [projectId, currentParticipant, dispatch]);

  const handleSave = useCallback(async () => {
    setIsSaving(true);
    if (isUpdating) {
      await handleUpdateParticipant();
    } else {
      await handleAddNewParticipant();
    }
    handleCloseModal();
  }, [
    handleAddNewParticipant,
    handleUpdateParticipant,
    handleCloseModal,
    isUpdating,
  ]);

  const actionButons = useMemo(
    () => [
      {
        children: intl.get('TEAMS.SAVE'),
        variant: 'primary',
        'data-cy': 'confirm-button',
        className: tailwindOverride({ 'cursor-not-allowed': !canSave }),
        disabled: !canSave,
        loading: isSaving,
        onClick: handleSave,
      },
      {
        children: intl.get('PROJECT_DETAIL.DELETE_PROJECT.CANCEL'),
        variant: 'tertiary',
        onClick: handleCloseModal,
        'data-cy': 'cancel-button',
      },
    ],
    [handleCloseModal, canSave, handleSave, isSaving]
  );

  useEffect(() => {
    if (isModalOpen && !modalProps.isOpen) {
      openModal(modalProps);
    }
  }, [openModal, modalProps, isModalOpen]);

  useEffect(() => {
    if (participant && participant?.user_id !== currentParticipant?.user_id) {
      setCurrentParticipant(participant);
    }
  }, [participant, currentParticipant]);

  const handleChange = (
    key: keyof NewParticipant,
    value: string | string[]
  ) => {
    setHasChanges(true);
    setCurrentParticipant((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const modalTitle = isUpdating
    ? intl.get('PEOPLE.RESOURCE_ALLOCATION.ADD_PARTICIPANT_MODAL.UPDATE_ROLE')
    : intl.get('PEOPLE.RESOURCE_ALLOCATION.ADD_PARTICIPANT_MODAL.TITLE');

  return (
    <Modal
      {...modalProps}
      closeModal={() => {
        if (hasChanges) {
          setShowUnsavedChangesModal(true);
        } else {
          setShowUnsavedChangesModal(false);
          handleCloseModal();
        }
      }}
      title={modalTitle}
      titleIcon={{
        name: isUpdating ? 'person' : 'person-add',
        className: 'text-purple-dark text-xl',
      }}
      aria-label={modalTitle}
      size='large'
      className='h-full'
      actionButtons={actionButons}
      data-cy={`${isUpdating ? 'update' : 'add'}-participant-modal`}
    >
      <div className='grid grid-cols-2 space-x-10%'>
        <ParticipantPicker
          isUpdating={isUpdating}
          currentParticipant={currentParticipant}
          handleChange={handleChange}
        />
        <ProjectRolePicker
          currentParticipant={currentParticipant}
          handleChange={handleChange}
        />
      </div>
      <UnsavedChangesModal
        isOpen={showUnsavedChangesModal}
        setIsOpen={setShowUnsavedChangesModal}
        onConfirm={() => handleCloseModal()}
      />
    </Modal>
  );
};

export default AddParticipantModal;
