import { Status } from 'utils/customTypes';
import { TaskAssignee, TaskProject } from './tasks';

export interface TaskDetailProject extends TaskProject {
  isArchived: boolean;
}

export interface TaskDetailToUpdate {
  id: string;
  name?: string;
  description?: string;
  status?: string;
  type?: string;
  startDate?: string;
  dueDate?: string;
  estimateHours?: string;
  disabled?: boolean;
  completionDate?: string;
  assignees?: {
    toAdd: string[];
    toRemove: string[];
  };
}

export interface TaskState {
  id: string;
  name: string;
  organizationId: string;
  displayId: string;
  project: TaskDetailProject;
  description: string;
  disabled: boolean;
  status: string;
  type: string;
  startDate: string;
  endDate: string;
  estimateHours: string;
  actualHours: string;
  assignees: TaskAssignee[];
  completedDate?: string;
}

export interface TaskActualHourState {
  [id: string]: {
    id: string;
    hours: string;
    date: string;
    userId: string;
    userName: string;
  };
}
export interface TaskDetailState {
  currentTask?: TaskState;
  permissions: { canEdit: boolean; canDelete: boolean };
  status?: Status;
  availableAssigneesStatus: Status;
  availableAssigneeIds: string[];
  availableAssignees: TaskAssigneeMap;
  taskActualHoursLoading: boolean;
  taskActualHourIds?: string[];
  taskActualHours?: TaskActualHourState;
}

export interface TaskAssigneeMap {
  [assigneeId: string]: TaskAssignee;
}

export class TaskNotFoundError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'TaskNotFoundError';
  }
}
