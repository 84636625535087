import { useEffect } from 'react';
import {
  Table,
  Typography,
  Icon,
  TableOperationHeader,
  Tag,
  tailwindOverride,
} from '@getsynapse/design-system';
import importantIcon from 'assets/icons/important.svg';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import FilterFacilitatorsPopup from './FilterFacilitatorsPopup/FilterFacilitatorsPopup';
import { Option } from 'utils/customTypes';
import classNames from 'classnames';
import {
  fetchFacilitators,
  selectFacilitatorsForDropdown,
} from 'state/Schedule/ScheduleSlice';
import { Facilitator } from 'utils/types/learningSchedule';
import debounce from 'lodash/debounce';
import { USER_STATUS } from 'utils/constants';

export const FacilitatorsSection = ({
  existingFacilitators,
  updateFacilitatorsIds,
}: {
  updateFacilitatorsIds: (rowsIds: string[]) => void;
  existingFacilitators: { id: string; name: string }[];
}) => {
  const dispatch = useDispatch();

  let facilitators = useSelector(selectFacilitatorsForDropdown);
  const [orderedFacilitators, setOrderedFacilitators] = useState<Facilitator[]>(
    facilitators || []
  );
  const [appliedFilters, setAppliedFilters] = useState<{
    skills: Option[];
    languages: Option[];
    countries: Option[];
  }>({ skills: [], languages: [], countries: [] });
  const [selectedFacilitators, setSelectedFacilitators] = useState<string[]>(
    []
  );
  const [searchValue, setSearchValue] = useState<string>('');

  const existingFacilitatorsMap = useMemo(() => {
    return new Map(
      existingFacilitators.map((facilitator) => [facilitator.id, facilitator])
    );
  }, [existingFacilitators]);

  const eventSavedFacilitators = useMemo(() => {
    return existingFacilitators.map((facilitator) => facilitator.id);
  }, [existingFacilitators]);

  useEffect(() => {
    const buildFacilitatorMap = () => {
      const facilitatorMap = new Map<string, Facilitator>();

      facilitators.forEach((f) => {
        // Do not add the facilitator to the list of selectable facilitators if it is disabled
        // and not ALREADY assigned to the event
        if (
          (f.status === USER_STATUS.REGISTERED_DISABLED ||
            f.status === USER_STATUS.INVITED_DISABLED) &&
          !existingFacilitatorsMap.has(f.id)
        ) {
          return;
        }

        const facilitator = { ...f };

        // The user was already previously assigned so show them as disabled
        if (
          f.status === USER_STATUS.REGISTERED_DISABLED ||
          f.status === USER_STATUS.INVITED_DISABLED
        ) {
          facilitator.name = `${f.name} (${intl.get(
            'USERS_PAGE.TABLE.USER_STATUS.DEACTIVATED'
          )})`;
        }

        facilitatorMap.set(f.id, facilitator);
      });

      return facilitatorMap;
    };

    const processFacilitators = (facilitatorMap: Map<string, Facilitator>) => {
      const ordered: Facilitator[] = [];

      existingFacilitators.forEach((existingFac) => {
        const facilitator = facilitatorMap.get(existingFac.id);
        if (facilitator) {
          ordered.push(facilitator);
          facilitatorMap.delete(existingFac.id);
        }
      });

      const remaining = Array.from(facilitatorMap.values());
      setOrderedFacilitators([...ordered, ...remaining]);
    };

    if (eventSavedFacilitators.length > 0 && facilitators.length > 0) {
      const facilitatorMap = buildFacilitatorMap();
      processFacilitators(facilitatorMap);
      updateFacilitatorsIds(eventSavedFacilitators);
      setSelectedFacilitators(eventSavedFacilitators);
    } else {
      const facilitatorMap = buildFacilitatorMap();
      setOrderedFacilitators([...Array.from(facilitatorMap.values())]);
    }
  }, [
    eventSavedFacilitators,
    facilitators,
    existingFacilitators,
    updateFacilitatorsIds,
    existingFacilitatorsMap,
  ]);

  const updateAppliedFilters = useCallback(
    (
      selectedOptions: Option[] | null,
      type?: 'skills' | 'languages' | 'countries'
    ) => {
      const oldFilters = { ...appliedFilters };
      if (selectedOptions === null && type === undefined) {
        oldFilters.skills = [];
        oldFilters.languages = [];
        oldFilters.countries = [];
      } else if (type) {
        oldFilters[type] = selectedOptions!;
      }
      setAppliedFilters(oldFilters);
      const filters = {
        skills: oldFilters.skills.map((skill) => skill.value),
        languages: oldFilters.languages.map((language) => language.value),
        countries: oldFilters.countries.map((country) => country.value),
      };
      dispatch(fetchFacilitators({ search: searchValue, filters }));
    },
    [appliedFilters, dispatch, searchValue]
  );

  const debounceSearch = useMemo(
    () =>
      debounce((value: string) => {
        const filters = {
          skills: appliedFilters.skills.map((skill) => skill.value),
          languages: appliedFilters.languages.map((language) => language.value),
          countries: appliedFilters.countries.map((country) => country.value),
        };
        dispatch(fetchFacilitators({ search: value, filters }));
      }, 300),
    [appliedFilters, dispatch]
  );

  const handleSearch = useCallback(
    (value: string) => {
      setSearchValue(value);
      debounceSearch(value);
    },
    [debounceSearch]
  );

  return (
    <div id='fac-table' data-testid='fac-table'>
      <TableOperationHeader
        className='border-b-0'
        actionButtons={
          <FilterFacilitatorsPopup
            updateAppliedFilters={updateAppliedFilters}
            appliedFilters={appliedFilters}
            disabled={facilitators.length === 0}
          />
        }
        searchInputContainerClassName='w-48-percent'
        inputValue={searchValue}
        setInputValue={handleSearch}
      />
      <div
        className={classNames('w-full rounded-b', {
          'overflow-x-auto border border-neutral-lighter-two h-60':
            facilitators.length > 0,
        })}
      >
        <Table
          data-cy='facilitator-section'
          className={classNames('w-full', {
            'border-0': facilitators.length > 0,
          })}
          emptyComponent={
            <div className='flex justify-center items-center h-60 w-full'>
              <Icon src={importantIcon} className='h-6 w-6 mr-2' />
              <Typography>
                {intl.get(
                  'SCHEDULE.EVENTS.FACILITATORS_SECTION.NO_FACILITATORS'
                )}
              </Typography>
            </div>
          }
          selectedRowsIds={selectedFacilitators}
          isSelectRowCellSticky={true}
          numberOfHeadRows={0}
          data={{
            headData: [],
            rows: orderedFacilitators.map((data, index) => {
              let facilitatorName = data.name;
              const facilitatorStatus = data.status;
              const isOdd = index % 2;
              const isDisabled =
                facilitatorStatus === USER_STATUS.REGISTERED_DISABLED ||
                facilitatorStatus === USER_STATUS.INVITED_DISABLED;
              const isSelected = selectedFacilitators.includes(data.id!);
              const isSelectable = !isDisabled || isSelected;

              return {
                id: data.id,
                cells: [
                  ...(!isSelectable
                    ? [
                        {
                          content: <div />,
                          className:
                            'border-b border-t border-r-0 border-transparent group-hover:shadow-first-table-cell-hover group-hover:border-secondary-darker shadow-table-column group-hover:shadow-none px-4 py-3 w-6 sticky z-1 left-0 bg-neutral-white',
                        },
                      ]
                    : []),
                  {
                    content: (
                      <div className='flex flex-col'>
                        <Typography variant='body2' className='truncate'>
                          {facilitatorName}
                        </Typography>
                        <Typography
                          variant='body2'
                          className='text-neutral-light truncate'
                        >
                          {data.jobTitle}
                        </Typography>
                      </div>
                    ),
                    className: tailwindOverride(
                      'w-1/2 px-2 py-2 sticky left-14',
                      {
                        'bg-neutral-white': !isOdd && !isSelected,
                        'bg-neutral-lightest-two': isOdd && !isSelected,
                        'bg-secondary-lightest border-secondary-darker':
                          isSelected,
                        'opacity-50 cursor-not-allowed': !isSelectable,
                      }
                    ),
                  },
                  {
                    content: (
                      <div className='flex flex-col'>
                        <Typography
                          variant='body2'
                          className='text-neutral-black truncate'
                        >
                          {data.province || ''}
                        </Typography>
                        <Typography
                          variant='body2'
                          className='text-neutral-light truncate'
                        >
                          {intl.get(`COUNTRIES.${data.country}`)}
                        </Typography>
                      </div>
                    ),
                    className: 'px-2 py-2 w-1/2',
                  },
                  ...(appliedFilters.skills.length > 0
                    ? [
                        {
                          content: (
                            <Tag
                              className='text-xs border border-transparent py-0.5 bg-purple-lightest group-hover:border-purple-dark'
                              textClassName='text-purple-darker overflow-auto max-w-full font-semibold'
                              label={data.matchedSkills
                                .map((skill: string) =>
                                  intl.get(`TEAMS.SKILLS.${skill}`)
                                )
                                .join(' - ')}
                            />
                          ),
                        },
                      ]
                    : []),
                  ...(appliedFilters.languages.length > 0
                    ? [
                        {
                          content: (
                            <Tag
                              className='text-xs border border-transparent py-0.5 bg-purple-lightest group-hover:border-purple-dark'
                              textClassName='text-purple-darker overflow-auto max-w-full font-semibold'
                              label={data.matchedLanguages
                                .map((language: string) =>
                                  intl.get(
                                    `LANGUAGES.${language.toLocaleLowerCase()}`
                                  )
                                )
                                .join(' - ')}
                            />
                          ),
                        },
                      ]
                    : []),
                ],
                canSelectRows: isSelectable,
              };
            }),
          }}
          onSelectRows={(rowsIds: string[]) => {
            updateFacilitatorsIds(rowsIds);
            setSelectedFacilitators(rowsIds);
          }}
        />
      </div>
    </div>
  );
};

export default FacilitatorsSection;
