import { useState } from 'react';
import intl from 'react-intl-universal';
import { getEvaluationRedirectionLink } from 'state/Evaluations/evaluationsSlice';
import { showNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import { useAppThunkDispatch } from 'state/store';

const usePerformitivRedirection = (
  evaluationId?: string
): { redirect: () => Promise<void>; loading: boolean } => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppThunkDispatch();

  const redirect = async () => {
    if (loading) return;

    try {
      setLoading(true);
      const redirectUrl = await dispatch(
        getEvaluationRedirectionLink(evaluationId)
      ).unwrap();
      setLoading(false);

      window.open(redirectUrl, '_blank');
    } catch (error) {
      dispatch(
        showNotification({
          autoHide: false,
          notificationVariant: 'error',
          notificationTitle: intl.get('ERROR_SNACKBAR_TITLE'),
          notificationMessage: intl.get('ERROR_SNACKBAR_MESSAGE'),
        })
      );
    }
  };

  return {
    redirect,
    loading,
  };
};

export default usePerformitivRedirection;
