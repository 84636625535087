import { FC, useState, useEffect, useRef } from 'react';
import { TableOperationHeader } from '@getsynapse/design-system';
import debounce from 'lodash/debounce';

interface SearchFilterProps {
  initialSearchFilterValue: string;
  onSearchFilterChange: (value: string) => void;
  tableName?: string;
}

const SearchFilter: FC<SearchFilterProps> = ({
  children,
  initialSearchFilterValue,
  onSearchFilterChange,
  tableName,
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedSearchValueChangeHandler = useRef(
    debounce((value: string) => onSearchFilterChange(value), 500)
  );

  const handleSearchValueChange = (value: string) => {
    setSearchValue(value);
    debouncedSearchValueChangeHandler.current(value);
  };

  useEffect(() => {
    setSearchValue(initialSearchFilterValue);
  }, [initialSearchFilterValue]);

  return (
    <TableOperationHeader
      className='border-b-0'
      inputValue={searchValue}
      setInputValue={handleSearchValueChange}
      actionButtons={children}
      searchProps={{
        'aria-label': `${tableName}__search-filter`,
      }}
    />
  );
};

export default SearchFilter;
