import { FC } from 'react';
import { Link } from 'react-router-dom';
import intl from 'react-intl-universal';
import { Typography } from '@getsynapse/design-system';
import { TaskProject } from 'api/taskTypes';
import { PATHS } from 'utils/constants';

interface ProjectColumnProps {
  taskProject?: TaskProject;
}

const ProjectColumn: FC<ProjectColumnProps> = ({ taskProject }) => {
  if (!taskProject?.id) {
    return (
      <Typography varint='caption' className='text-neutral-dark'>
        {intl.get('TASKS.TABLE.NO_DATA')}
      </Typography>
    );
  }

  return (
    <Link
      to={{
        pathname: `${PATHS.PROJECT_PAGE}/${taskProject.id}`,
        state: {
          from: PATHS.TASKS_LIST_PAGE,
        },
      }}
      className='focus:underline hover:underline'
      onClick={(e) => e.stopPropagation()}
    >
      {taskProject.title}
    </Link>
  );
};

export default ProjectColumn;
