import {
  configureStore,
  combineReducers,
  ThunkDispatch,
  Action,
} from '@reduxjs/toolkit';
import activeRequest from './ActiveRequest/activeRequestSlice';
import forms from './Forms/formSlice';
import inlineNotification from './InlineNotification/inlineNotificationSlice';
import requestsState from './Requests/requestSlice';
import projectsState from './Projects/projectsSlice';
import project from './Project/projectSlice';
import projectComments from './ProjectComments/projectCommentsSlice';
import currentUser from './User/userSlice';
import userSettings from './UserSetting/userSettingsSlice';
import requestComments from './RequestComments/requestCommentsSlice';
import users from './UsersManagement/usersManagementSlice';
import teams from './Teams/teamsSlice';
import organization from './Organization/organizationSlice';
import processes from './Processes/processesSlice';
import notifications from './Notifications/notificationsSlice';
import notificationSettings from './NotificationSettings/notificationSettingsSlice';
import timeOff from './TimeOff/TimeOffSlice';
import singleTask from './SingleTask/singleTaskSlice';
import projectTasks from './ProjectTasks/projectTaskSlice';
import tasks from './Tasks/taskSlice';
import vendors from './Vendors/vendorsSlice';
import businessTeams from './BusinessTeams/businessTeamsSlice';
import learningTeams from './LearningTeams/learningTeamsSlice';
import budgets from './Budget/budgetSlice';
import capacity from './Capacity/capacitySlice';
import capacities from './Capacities';
import resourceAllocation from './ResourceAllocation/resourceAllocation';
import designs from './Designs/designsSlice';
import myWorkspace from './MyWorkspace/myWorkspaceSlice';
import sidePanel from './SidePanel/sidePanelSlice';
import sidekick from './Sidekick/sidekickSlice';
import projectTemplates from './ProjectTemplates/projectTemplatesSlice';
import projectTemplate from './ProjectTemplate/ProjectTemplateSlice';
import programs from './Programs/programsSlice';
import program from './Program/programSlice';
import programStrategy from './ProgramStrategy/programStrategySlice';
import snackbarNotification from './SnackbarNotification/SnackbarNotificationSlice';
import filtersSettings from './Settings/Filters/FiltersSlice';
import tasksAssignmentsResourcesCapacity from './TasksAssignmentsResourcesCapacity/tasksAssignmentsResourcesCapacitySlice';
import projectsInsights from './Insights/ProjectsSlice';
import intakeInsights from './Insights/InsightsSlice';
import customFields from './CustomFields/customFieldsSlice';
import taskBundles from 'state/TasksBundle/tasksBundleSlice';
import activeTaskBundle from './ActiveTaskBundle/activeTaskBundleSlice';
import teamsCapacity from './TeamsCapacity/teamsCapacitySlice';
import capacityInsights from './Insights/capacitySlice';
import programCategory from './StrategyCategory/strategyCategorySlice';
import dailyTeamsCapacity from './DailyTeamsCapacity';
import weeklyTeamsCapacity from './WeeklyTeamCapacity';
import objectiveMeasurement from './ObjectiveMeasurementSlice/objectiveMeasurementSlice';
import monetaryBenefits from './MonetaryBenefits/monetaryBenefitsSlice';
import measurementScales from './MeasurementScales/measurementScalesSlice';
import viewSettings from './ViewSettings/viewSettingsSlice';
import additionalBenefit from './AdditionalBenefit/additionalBenefitSlice';
import manageTimeOff from './ManageTimeOff';
import programInput from './ProgramInputCategoy/ProgramInputCategoySlice';
import roiCategory from './ROICategory/ROICategoySlice';
import roiCosts from './ROICosts/ROICostsSlice';
import tasksList from './TasksList';
import projectTasksList from './ProjectTasksList';
import { useDispatch } from 'react-redux';
import taskDetail from './TaskDetail/taskSlice';
import schedule from './Schedule/ScheduleSlice';
import actualHours from './ActualHours/actualHoursSlice';
import linkedTaskContent from './LinkedTaskContent/linkedTaskContentSlice';
import projectProgress from './Project/progress/progressSlice';
import evaluations from './Evaluations/evaluationsSlice';
import evaluationInsights from './Evaluations/evaluationInsightsSlice';
import instructors from './Evaluations/instructorsSlice';

export const rootReducer = combineReducers({
  activeRequest,
  activeTaskBundle,
  actualHours,
  budgets,
  businessTeams,
  capacity,
  capacities,
  capacityInsights,
  currentUser,
  customFields,
  dailyTeamsCapacity,
  designs,
  evaluations,
  evaluationInsights,
  filtersSettings,
  forms,
  inlineNotification,
  instructors,
  intakeInsights,
  learningTeams,
  measurementScales,
  myWorkspace,
  notifications,
  notificationSettings,
  objectiveMeasurement,
  monetaryBenefits,
  organization,
  processes,
  program,
  programCategory,
  programs,
  programStrategy,
  project,
  projectComments,
  projectsInsights,
  projectsState,
  projectTasks,
  projectTemplate,
  projectTemplates,
  requestComments,
  requestsState,
  resourceAllocation,
  sidekick,
  sidePanel,
  singleTask,
  snackbarNotification,
  taskBundles,
  tasks,
  tasksAssignmentsResourcesCapacity,
  teams,
  teamsCapacity,
  timeOff,
  users,
  userSettings,
  vendors,
  viewSettings,
  weeklyTeamsCapacity,
  additionalBenefit,
  manageTimeOff,
  programInput,
  roiCategory,
  roiCosts,
  tasksList,
  taskDetail,
  schedule,
  linkedTaskContent,
  projectTasksList,
  projectProgress,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;

export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;

export const useAppThunkDispatch = () => useDispatch<ThunkAppDispatch>();
