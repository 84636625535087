import { FC } from 'react';
import intl from 'react-intl-universal';
import { Typography } from '@getsynapse/design-system';

const TimeEntriesSection: FC = ({ children }) => {
  return (
    <div className='flex flex-col gap-y-4 mt-6'>
      <Typography variant='body' weight='medium'>
        {intl.get('TASK_ACTUAL_HOURS_MODAL.TIME_ENTRIES')}
      </Typography>
      {children}
    </div>
  );
};

export default TimeEntriesSection;
