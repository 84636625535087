import {
  createAsyncThunk,
  createSlice,
  createAction,
  createSelector,
} from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';
import { SLICE_STATUS, USER_STATUS, USER_TYPES } from 'utils/constants';
import {
  LDUser,
  AllUsersType,
  SortingType,
  Status,
  UserAvatars,
  BusinessUser,
  FacilitatorUser,
} from 'utils/customTypes';
import { selectOrganizationLicense } from 'state/Organization/organizationSlice';
import { selectCurrentLearningTeam } from 'state/LearningTeams/learningTeamsSlice';
import { SelectComapnyName } from 'state/Organization/organizationSlice';
import { selectUserId, selectUser, setCurrentUser } from 'state/User/userSlice';
import usersManagementAPI from './usersManagementAPI';
import exportAPI from 'Services/exportAPI';
import type {
  LearningTeam,
  LearningTeamWithMembers,
} from 'utils/types/learningTeam';
import { UserFilters } from 'utils/types/filters';
import { filterUsers, orderUsersBy } from './helpers';
import { isUserStatusDisabled } from 'utils/functions';

type UPGRADE_RESULT = 'success' | 'failed';

interface Users {
  ldUsers: LDUser[];
  facilitatorUsers: FacilitatorUser[];
  allUsers: AllUsersType[];
  usersSorting: {
    orderBy: string[];
    order: SortingType;
  };
  allUsersPagination: {
    limit: number;
    offset: number;
  };
  selectedUser: AllUsersType;
  searchParam: string;
  filters: UserFilters;
  selectedUserStatus: Status;
  businessReviewers: BusinessUser[];
  LDReviewers: LDUser[];
  exportStatus: Status;
  upgradeStatus: Status;
  upgradeResult?: UPGRADE_RESULT;
}

/* ============================= INITIAL STATE ============================== */
const initialState: Users = {
  ldUsers: [],
  facilitatorUsers: [],
  allUsers: [],
  usersSorting: {
    order: 'asc',
    orderBy: ['data.firstName', 'data.lastName'],
  },
  allUsersPagination: {
    limit: 15,
    offset: 0,
  },
  selectedUser: {
    data: {
      firstName: '',
      lastName: '',
      email: '',
    },
    registeredLearningTeams: [],
    id: '',
    country_iso_3166_1_alpha_2_code: '',
    type: 'ld',
    default_capacity: 0,
    status: '',
    role: 'user',
    disabled_at: '',
    avatar_url: '',
  },
  searchParam: '',
  filters: {},
  selectedUserStatus: SLICE_STATUS.IDLE,
  businessReviewers: [],
  LDReviewers: [],
  exportStatus: SLICE_STATUS.IDLE,
  upgradeStatus: SLICE_STATUS.IDLE,
};

/* ============================== REDUX THUNK =============================== */
export const getLDUsers = createAsyncThunk(
  'usersManagement/GET_LD_USERS',
  async () => {
    const response = await usersManagementAPI.fetchUsers({ type: 'ld' });
    const data = response.data.map((user: LDUser) => {
      return {
        businessTeam_id: get(user, 'businessTeam_id'),
        avatar_url: get(user, 'avatar_url'),
        data: {
          email: get(user, 'data.email'),
          lastName: get(user, 'data.lastName'),
          firstName: get(user, 'data.firstName'),
          avatar_url: get(user, 'avatar_url'),
        },
        id: get(user, 'id'),
        teamsManaged: get(user, 'teamsManaged') || [],
        status: get(user, 'status'),
      };
    });
    return data;
  }
);

export const getFacilitatorUsers = createAsyncThunk(
  'usersManagement/GET_FACILITATOR_USERS',
  async () => {
    const response = await usersManagementAPI.fetchUsers({
      type: 'facilitator',
    });
    const data = response.data.map((user: LDUser) => {
      return {
        businessTeam_id: get(user, 'businessTeam_id'),
        avatar_url: get(user, 'avatar_url'),
        data: {
          email: get(user, 'data.email'),
          lastName: get(user, 'data.lastName'),
          firstName: get(user, 'data.firstName'),
          avatar_url: get(user, 'avatar_url'),
        },
        id: get(user, 'id'),
        status: get(user, 'status'),
      };
    });
    return data;
  }
);

export const getAllUsers = createAsyncThunk(
  'usersManagement/GET_ALL_USERS',
  async () => {
    const { data } = await usersManagementAPI.fetchUsers();
    return data;
  }
);

export const getSelectedUser = createAsyncThunk(
  'usersManagement/GET_USER',
  async (userId: string) => {
    const { data } = await usersManagementAPI.fetchUser(userId);
    return data.user;
  }
);

export const updateUser = createAsyncThunk(
  'usersManagement/UPDATE_USER',
  async (
    {
      userId,
      updateFields,
    }: {
      userId: string;
      updateFields: Partial<AllUsersType>;
    },
    { getState, dispatch }
  ) => {
    const state = getState() as RootState;
    const { data } = await usersManagementAPI.updateUser(userId, updateFields);
    const currentUserId = selectUserId(state);

    if (get(data, 'user.id') === currentUserId) {
      const currentUser = selectUser(state);
      dispatch(
        setCurrentUser({
          ...currentUser,
          full_name: `${get(data, 'user.data.firstName')} ${get(
            data,
            'user.data.lastName'
          )}`,
          avatar_url: get(data, 'user.avatar_url'),
          firstName: get(data, 'user.data.firstName'),
          lastName: get(data, 'user.data.lastName'),
        })
      );
    }
    return data.user;
  }
);

export const assignUserToLearningTeam = createAsyncThunk(
  'usersManagement/ASSIGN_USER_TO_LEARNING_TEAM',
  async ({ userId, teamsIds }: { userId: string; teamsIds: string[] }) => {
    const { code } = await usersManagementAPI.assignUserToLearningTeam(
      userId,
      teamsIds
    );
    if (code === 200) {
      return teamsIds;
    }
  }
);

export const addUser = createAsyncThunk(
  'usersManagement/ADD_USER',
  async (userData: Partial<AllUsersType>) => {
    const { data } = await usersManagementAPI.createUser(userData);
    return data.user;
  }
);

export const addBusinessUser = createAsyncThunk(
  'usersManagement/ADD_USER',
  async (userData: Partial<AllUsersType>) => {
    const response = await usersManagementAPI.createBusinessUser(userData);
    if (response.success) {
      return response.data.user;
    }
    return response;
  }
);

export const addUserAndOrganization = createAsyncThunk(
  'usersManagement/ADD_USER_AND_ORGANIZATION',
  async (userData: Partial<AllUsersType> & { organization: object }) => {
    const response = await usersManagementAPI.createOrganizationWithFirstUser(
      userData
    );
    if (response.success) {
      return response.data.user;
    }
    return response;
  }
);

export const inviteUser = createAsyncThunk(
  'usersManagement/INVITE_USER',
  async (
    {
      userId = '',
      email,
      firstName,
      selfRegistration = false,
    }: {
      userId?: string;
      email: string;
      firstName: string;
      selfRegistration?: boolean;
    },
    { getState }
  ) => {
    const state = getState() as RootState;
    const companyName = SelectComapnyName(state);
    const { data } = await usersManagementAPI.inviteUser(
      userId,
      email,
      firstName,
      companyName,
      selfRegistration
    );
    return data.user;
  }
);

export const bulkCreateUsers = createAsyncThunk(
  'usersManagement/BULK_CREATE_USERS',
  async (newUsers: Partial<LDUser>[]) => {
    const { data } = await usersManagementAPI.bulkCreateUsers(newUsers);
    return data;
  }
);

export const exportUsers = createAsyncThunk(
  'usersManagement/EXPORT_CSV',
  async (userIds: string[], { rejectWithValue }) => {
    try {
      const response = await exportAPI.exportUsers(userIds);
      return response.data.fileUrl;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBusinessReviewers = createAsyncThunk(
  'usersManagement/GET_BUSINESS_REVIEWERS',
  async (requestId: string) => {
    const { data } = await usersManagementAPI.fetchBusinessReviewers(requestId);
    return data;
  }
);

export const getLDReviewers = createAsyncThunk(
  'usersManagement/GET_LD_REVIEWERS',
  async (requestId: string) => {
    const { data } = await usersManagementAPI.fetchLDReviewers(requestId);
    return data;
  }
);

export const upgradeUser = createAsyncThunk(
  'user/UPGRADE',
  async ({
    userId,
    organizationId,
    employmentType,
    defaultCapacity,
  }: {
    userId: string;
    organizationId: string;
    employmentType: string;
    defaultCapacity: number;
  }) => {
    const { data } = await usersManagementAPI.upgradeUser(
      userId,
      organizationId,
      employmentType,
      defaultCapacity
    );
    return data;
  }
);

/* ================================ ACTIONS ================================= */
export const updateUsersPagination = createAction<{
  limit: number;
  offset: number;
}>('usersManagement/UPDATE_USERS_PAGINATION');

export const setUsersOrder = createAction<{
  order: SortingType;
  orderBy: string[];
}>('usersManagement/SET_USERS_ORDER');

export const resetSelectedUser = createAction(
  'usersManagement/RESET_SELECTED_USER'
);

export const setSearchParam = createAction<string>(
  'usersManagement/SET_SEARCH_PARAM'
);

export const setFilters = createAction<UserFilters>(
  'usersManagement/SET_FILTERS'
);

export const resetUpgradeResult = createAction('usersManagement/RESET_UPGRADE');

/* ================================= REDUCER ================================ */
const usersManagementSlice = createSlice({
  name: 'usersManagement',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLDUsers.fulfilled, (state, action) => {
        state.ldUsers = action.payload;
      })
      .addCase(getFacilitatorUsers.fulfilled, (state, action) => {
        state.facilitatorUsers = action.payload;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.allUsers = action.payload;
      })
      .addCase(getAllUsers.rejected, (state) => {
        state.allUsers = [];
      })
      .addCase(updateUsersPagination, (state, action) => {
        state.allUsersPagination.limit = action.payload.limit;
        state.allUsersPagination.offset = action.payload.offset;
      })
      .addCase(setUsersOrder, (state, action) => {
        state.usersSorting.order = action.payload.order;
        state.usersSorting.orderBy = action.payload.orderBy;
      })
      .addCase(getSelectedUser.fulfilled, (state, action) => {
        state.selectedUserStatus = SLICE_STATUS.IDLE;
        state.selectedUser = action.payload;
      })
      .addCase(getSelectedUser.pending, (state) => {
        state.selectedUserStatus = SLICE_STATUS.LOADING;
      })
      .addCase(getSelectedUser.rejected, (state) => {
        state.selectedUserStatus = SLICE_STATUS.FAILED;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload;
      })
      .addCase(inviteUser.fulfilled, (state, action) => {
        state.allUsers = state.allUsers
          .filter((user) => user.id !== action.payload.id)
          .concat([action.payload]);
      })
      .addCase(resetSelectedUser, (state) => {
        state.selectedUser = initialState.selectedUser;
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.allUsers = [action.payload].concat(state.allUsers);
      })
      .addCase(addUserAndOrganization.fulfilled, (state, action) => {
        state.allUsers = [action.payload].concat(state.allUsers);
      })
      .addCase(bulkCreateUsers.fulfilled, (state, action) => {
        state.allUsers = [...state.allUsers, ...action.payload];
      })
      .addCase(setSearchParam, (state, action) => {
        state.searchParam = action.payload.toLocaleLowerCase().trim();
      })
      .addCase(setFilters, (state, action) => {
        state.filters = action.payload;
      })
      .addCase(exportUsers.pending, (state) => {
        state.exportStatus = SLICE_STATUS.LOADING;
      })
      .addCase(exportUsers.fulfilled, (state, action) => {
        state.exportStatus = SLICE_STATUS.IDLE;
        window.location.href = action.payload;
      })
      .addCase(exportUsers.rejected, (state) => {
        state.exportStatus = SLICE_STATUS.FAILED;
      })
      .addCase(getBusinessReviewers.fulfilled, (state, action) => {
        state.businessReviewers = action.payload;
      })
      .addCase(getLDReviewers.fulfilled, (state, action) => {
        state.LDReviewers = action.payload;
      })
      .addCase(upgradeUser.pending, (state) => {
        state.upgradeStatus = SLICE_STATUS.LOADING;
      })
      .addCase(upgradeUser.fulfilled, (state) => {
        state.upgradeStatus = SLICE_STATUS.IDLE;
        state.upgradeResult = 'success';
      })
      .addCase(upgradeUser.rejected, (state) => {
        state.upgradeStatus = SLICE_STATUS.FAILED;
        state.upgradeResult = 'failed';
      })
      .addCase(resetUpgradeResult, (state) => {
        state.upgradeResult = undefined;
      })
      .addCase(assignUserToLearningTeam.fulfilled, (state, action) => {
        set(state, 'selectedUser.registeredLearningTeams', action.payload);
      });
  },
});

/* =============================== SELECTORS ================================ */
const selectSelf = (state: RootState) => state;
export const selectLDUsers = (state: RootState) => state.users.ldUsers;

export const allUsers = (state: RootState) => state.users.allUsers;

export const allActiveUsers = createSelector(allUsers, (users) => {
  const activeUsers = users.filter(
    (user) =>
      user.status === USER_STATUS.INVITED ||
      user.status === USER_STATUS.REGISTERED
  );
  return orderBy(
    activeUsers,
    (user) => {
      const label = `${get(user, 'data.firstName')} ${get(
        user,
        'data.lastName'
      )}`.trim();
      return label ? label.toLocaleLowerCase() : '';
    },
    ['asc']
  ) as AllUsersType[];
});

export const allUsersLabelled = createSelector(allUsers, (users) => {
  return orderBy(
    users,
    (user) => {
      const label = `${get(user, 'data.firstName')} ${get(
        user,
        'data.lastName'
      )}`.trim();
      return label ? label.toLocaleLowerCase() : '';
    },
    ['asc']
  ) as AllUsersType[];
});

export const selectBusinessReviewers = (state: RootState) =>
  state.users.businessReviewers;

export const selectLDReviewers = (state: RootState) => state.users.LDReviewers;

const usersSorting = (state: RootState) => state.users.usersSorting;

const allUsersPagination = (state: RootState) => state.users.allUsersPagination;

const selectSearchParam = (state: RootState) => state.users.searchParam;

export const selectFilters = (state: RootState) => state.users.filters;
export const exportUsersStatus = (state: RootState) => state.users.exportStatus;

const selectFilteredSortedUsers = createSelector(
  [allUsers, selectSearchParam, usersSorting, selectFilters],
  (users, searchParam, sorting, filters): AllUsersType[] => {
    const formattedUsers: AllUsersType[] = users.map((user: AllUsersType) => {
      if (user.type === USER_TYPES.BUSINESS) {
        return { ...user, team: get(user, 'businessTeams.title', '-') };
      } else if (
        user.type === USER_TYPES.L_D ||
        user.type === USER_TYPES.FACILITATOR
      ) {
        const registeredTeams = get(user, 'registeredLearningTeams') || [];
        const teamsArray = registeredTeams.map(
          (team: LearningTeamWithMembers) => team.name
        );
        return {
          ...user,
          team: teamsArray.length ? teamsArray.join(', ') : '-',
        };
      } else {
        return { ...user, team: '-' };
      }
    });
    const filteredUsers: AllUsersType[] = filterUsers(
      formattedUsers,
      searchParam,
      filters
    );
    const sortedUsers: AllUsersType[] = orderUsersBy(
      filteredUsers,
      sorting.orderBy,
      sorting.order
    );

    return sortedUsers;
  }
);

export const selectFilteredSortedUserIds = createSelector(
  [selectFilteredSortedUsers],
  (users): string[] => users.map((user) => user.id)
);

export const selectPaginatedUsers = createSelector(
  [selectFilteredSortedUsers, allUsersPagination],
  (users, pagination): AllUsersType[] => {
    return users.slice(pagination.offset, pagination.limit);
  }
);

export const selectUsersPaginationData = createSelector(
  [allUsersPagination, selectFilteredSortedUsers],
  (pagination, users) => {
    const count = users.length;
    return {
      ...pagination,
      count,
    };
  }
);

export const selectSelectedUser = (state: RootState) => {
  const learningTeams = get(
    state.users.selectedUser,
    'registeredLearningTeams'
  );
  const registeredLearningTeams = learningTeams
    ? learningTeams.map((team: LearningTeam) => team.id)
    : [];
  return {
    ...state.users.selectedUser,
    registeredLearningTeams,
  };
};

export const selectUserStatus = (state: RootState) =>
  state.users.selectedUserStatus;

export const selectActiveLDUsers = createSelector(selectSelf, (state) => {
  const users = state.users.ldUsers.filter(
    (user) =>
      user.status === USER_STATUS.INVITED ||
      user.status === USER_STATUS.REGISTERED
  );
  return orderBy(
    users,
    (user) => {
      const label = `${get(user, 'data.firstName')} ${get(
        user,
        'data.lastName'
      )}`.trim();
      return label ? label.toLocaleLowerCase() : '';
    },
    ['asc']
  ) as LDUser[];
});

export const selectAllLDUsers = createSelector(selectSelf, (state) => {
  return orderBy(
    state.users.ldUsers,
    (user) => {
      const label = `${get(user, 'data.firstName')} ${get(
        user,
        'data.lastName'
      )}`.trim();
      return label ? label.toLocaleLowerCase() : '';
    },
    ['asc']
  ) as LDUser[];
});

export const selectLDUsersForDropdown = createSelector(
  [selectAllLDUsers, selectCurrentLearningTeam],
  (users, team) => {
    if (!isEmpty(team)) {
      return users.reduce<UserAvatars[]>((ldUsers, user) => {
        if (!team?.ldTeamMembers?.find((member) => member.id === user.id)) {
          const fullName = `${get(user, 'data.firstName') || ''} ${
            get(user, 'data.lastName') || ''
          }`;
          if (fullName.trim()) {
            return ldUsers.concat({
              label: `${user.data.firstName} ${user.data.lastName}`,
              avatar: {
                imageSrc: user.avatar_url,
                initial: `${user.data?.firstName?.charAt(
                  0
                )}${user.data?.lastName?.charAt(0)}`,
                name: `${user.data.firstName} ${user.data.lastName}`,
              },
              disabled: isUserStatusDisabled(user.status),
              value: user.id,
            });
          }
        }
        return ldUsers;
      }, []);
    } else {
      return users
        .filter((user) => {
          const fullName = `${get(user, 'data.firstName') || ''} ${
            get(user, 'data.lastName') || ''
          }`;
          return fullName.trim() ? true : false;
        })
        .map((user) => {
          return {
            label: `${user.data.firstName} ${user.data.lastName}`,
            avatar: {
              imageSrc: user.avatar_url,
              initial: `${user.data.firstName.charAt(
                0
              )}${user.data?.lastName?.charAt(0)}`,
              name: `${user.data.firstName} ${user.data.lastName}`,
            },
            value: user.id,
            disabled: isUserStatusDisabled(user.status),
          };
        }) as UserAvatars[];
    }
  }
);

export const selectAllUsersForDropdown = createSelector([allUsers], (users) => {
  return users
    .filter((user) => {
      const fullName = `${get(user, 'data.firstName') || ''} ${
        get(user, 'data.lastName') || ''
      }`;
      return fullName.trim() ? true : false;
    })
    .map((user) => {
      return {
        label: `${user.data.firstName} ${user.data.lastName}`,
        avatar: {
          imageSrc: user.avatar_url,
          initial: `${user.data.firstName.charAt(
            0
          )}${user.data?.lastName?.charAt(0)}`,
          name: `${user.data.firstName} ${user.data.lastName}`,
        },
        disabled: isUserStatusDisabled(user.status),
        value: user.id,
      };
    }) as UserAvatars[];
});

export const selectBusinessReviewersForDropdown = createSelector(
  [selectBusinessReviewers],
  (users) => {
    const orderedUsers = orderBy(
      users,
      (user) => {
        const label = `${get(user, 'data.firstName') || ''} ${
          get(user, 'data.lastName') || ''
        }`.trim();
        return label ? label.toLocaleLowerCase() : '';
      },
      'asc'
    );

    return orderedUsers
      .filter((user) => {
        const fullName = `${get(user, 'data.firstName') || ''} ${
          get(user, 'data.lastName') || ''
        }`;
        return fullName.trim() ? true : false;
      })
      .map((user) => {
        return {
          label: `${user.data.firstName} ${user.data.lastName}`,
          avatar: {
            imageSrc: user.avatar_url,
            initial: `${user.data?.firstName?.charAt(
              0
            )}${user.data?.lastName?.charAt(0)}`,
            name: `${user.data.firstName} ${user.data.lastName}`,
          },
          disabled: isUserStatusDisabled(user.status),
          value: user.id,
        };
      }) as UserAvatars[];
  }
);

export const selectLDReviewersForDropdown = createSelector(
  [selectLDReviewers],
  (users) => {
    const orderedUsers = orderBy(
      users,
      (user) => {
        const label = `${get(user, 'data.firstName') || ''} ${
          get(user, 'data.lastName') || ''
        }`.trim();
        return label ? label.toLocaleLowerCase() : '';
      },
      'asc'
    );
    return orderedUsers
      .filter((user) => {
        const fullName = `${get(user, 'data.firstName') || ''} ${
          get(user, 'data.lastName') || ''
        }`;
        return fullName.trim() ? true : false;
      })
      .map((user) => {
        return {
          label: `${user.data.firstName} ${user.data.lastName}`,
          avatar: {
            imageSrc: user.avatar_url,
            initial: `${user.data?.firstName?.charAt(
              0
            )}${user.data?.lastName?.charAt(0)}`,
            name: `${user.data.firstName} ${user.data.lastName}`,
          },
          disabled: isUserStatusDisabled(user.status),
          value: user.id,
        };
      }) as UserAvatars[];
  }
);

export const selectDisabledDate = (state: RootState) =>
  state.users?.selectedUser?.disabled_at;

export const selectAvailableLicenses = createSelector(
  [allUsers, selectOrganizationLicense],
  (users, licenses) => {
    const activeLdAndFacilitatorUsers = users
      .filter(
        (user) =>
          user.status === USER_STATUS.INVITED ||
          user.status === USER_STATUS.REGISTERED
      )
      .filter(
        (user) =>
          user.type === USER_TYPES.L_D || user.type === USER_TYPES.FACILITATOR
      );
    const licencesLeft =
      licenses.license_number - activeLdAndFacilitatorUsers.length;
    return licencesLeft > 0 ? licencesLeft : 0;
  }
);

export const selectUserById = createSelector(
  [allUsers, selectUserId],
  (users: AllUsersType[], userId: string | undefined) => {
    if (userId) {
      return users.find((user: AllUsersType) => user.id === userId);
    }
    return undefined;
  }
);

export const selectUpgradeResult = (state: RootState) =>
  state.users.upgradeResult;
export const selectUpgradeStatus = (state: RootState) =>
  state.users.upgradeStatus;

export default usersManagementSlice.reducer;
