import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  getNotificationSettings,
  resetStatus,
  selectNotificationSettings,
  selectNotificationSettingsStatus,
  updateNotificationSetting,
} from 'state/NotificationSettings/notificationSettingsSlice';
import { showNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import intl from 'react-intl-universal';
import { useAppThunkDispatch } from 'state/store';
import { Status } from 'utils/customTypes';
import { selectUser } from 'state/User/userSlice';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  DeactivatedNotificationsFlag,
  NotificationSetting,
} from 'utils/types/notification-settings';

const useNotificationSettings = () => {
  const dispatch = useAppThunkDispatch();
  const notificationSettings = useSelector(selectNotificationSettings);
  const status: Status = useSelector(selectNotificationSettingsStatus);
  const user = useSelector(selectUser);
  const {
    deactivatedNotifications = {
      categories: [],
      groups: [],
    },
  } = useFlags<{ deactivatedNotifications: DeactivatedNotificationsFlag }>();

  useEffect(() => {
    dispatch(getNotificationSettings());
  }, [dispatch]);

  const handleSettingChange = async (
    category: string,
    trigger: string,
    groupId: string,
    value: boolean
  ) => {
    await dispatch(
      updateNotificationSetting({
        userId: user.id!,
        category,
        trigger,
        groupId,
        value,
      })
    );
  };

  useEffect(() => {
    if (status === 'failed') {
      dispatch(
        showNotification({
          notificationVariant: 'error',
          notificationTitle: intl.get('ERROR_SNACKBAR_TITLE'),
          notificationMessage: intl.get('NOTIFICATIONS_SETTINGS.UPDATE.ERROR'),
        })
      );
      dispatch(resetStatus());
    } else if (status === 'success') {
      dispatch(
        showNotification({
          notificationVariant: 'success',
          notificationTitle: intl.get('SUCCESS_SNACKBAR_TITLE'),
          notificationMessage: intl.get(
            'NOTIFICATIONS_SETTINGS.UPDATE.SUCCESS'
          ),
        })
      );
      dispatch(resetStatus());
    }
  }, [dispatch, status]);

  const availableNotificationSettings = useMemo<NotificationSetting[]>(
    () =>
      notificationSettings.reduce((acc, setting) => {
        if (deactivatedNotifications.categories.includes(setting.category)) {
          return acc;
        }

        const filteredGroups = setting.groupSettings.filter((group) => {
          return !deactivatedNotifications.groups.includes(group.name);
        });

        if (filteredGroups.length === 0) {
          return acc;
        } else {
          return acc.concat({
            ...setting,
            groupSettings: filteredGroups,
          });
        }
      }, [] as NotificationSetting[]),
    [
      deactivatedNotifications.categories,
      deactivatedNotifications.groups,
      notificationSettings,
    ]
  );

  return {
    notificationSettings: availableNotificationSettings,
    handleSettingChange,
  };
};

export default useNotificationSettings;
