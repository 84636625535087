import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import isEqual from 'lodash/isEqual';
import { getProjectFiles } from 'state/Project/projectSlice';
import {
  getLinkedTaskContentFilesIds,
  updateLinkedContent,
} from 'state/SingleTask/singleTaskSlice';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import { ProjectContent } from 'utils/customTypes';
import useModal from 'Hooks/useModal';
import ProjectContentPicker from './ProjectContentPicker';

const LinkContentModal: React.FC<{
  isModalOpen: boolean;
  onModalClose: () => void;
  taskId: string;
}> = ({ isModalOpen, onModalClose, taskId }) => {
  const dispatch = useDispatch();
  const { Modal, modalProps, openModal, closeModal } = useModal();
  const projectContentFiles = useSelector(getProjectFiles);
  const originalLinkedTaskContentFilesIds = useSelector(
    getLinkedTaskContentFilesIds
  );
  const [linkedContentFiles, setLinkedContentFiles] = useState<
    ProjectContent[]
  >([]);

  const initCurrentLinkedFilesArray = useCallback(() => {
    const currentLinkedFiles = [];
    for (const contentFile of projectContentFiles) {
      if (originalLinkedTaskContentFilesIds?.includes(contentFile.id!)) {
        currentLinkedFiles.push(contentFile);
      }
    }
    setLinkedContentFiles(currentLinkedFiles);
  }, [projectContentFiles, originalLinkedTaskContentFilesIds]);

  useEffect(() => {
    if (isModalOpen && !modalProps.isOpen) {
      openModal(modalProps);
    }
  }, [isModalOpen, modalProps, openModal]);

  useEffect(() => initCurrentLinkedFilesArray(), [initCurrentLinkedFilesArray]);

  const canSubmit = useMemo(() => {
    const currentLinkedContentFilesIds = linkedContentFiles.map(
      (contentFile: ProjectContent) => contentFile.id
    );
    return !isEqual(
      originalLinkedTaskContentFilesIds?.sort(),
      currentLinkedContentFilesIds.sort()
    );
  }, [originalLinkedTaskContentFilesIds, linkedContentFiles]);

  const handleCloseModal = () => {
    onModalClose();
    closeModal();
  };

  const onConfirm = async () => {
    await dispatch(updateLinkedContent({ taskId, linkedContentFiles }));
    dispatch(
      showNotificationBanner({
        notificationVariant: 'success',
        notificationText: intl.get(
          'TASKS.TASK_DETAIL_PAGE.CONTENT.CONTENT_LINKED_MESSAGE'
        ),
      })
    );
    handleCloseModal();
  };

  const actionButtons = [
    {
      children: intl.get('SAVE'),
      variant: 'primary',
      onClick: onConfirm,
      'data-cy': 'confirm-button',
      disabled: !canSubmit,
    },
    {
      children: intl.get('CANCEL'),
      variant: 'tertiary',
      onClick: () => {
        initCurrentLinkedFilesArray();
        handleCloseModal();
      },
    },
  ];

  return (
    <Modal
      {...modalProps}
      title={intl.get(
        'TASKS.TASK_DETAIL_PAGE.CONTENT.LINK_CONTENT_MODAL_TITLE'
      )}
      size='large'
      actionButtons={actionButtons}
      closeModal={handleCloseModal}
      data-cy='link-content-modal'
      aria-label={intl.get(
        'TASKS.TASK_DETAIL_PAGE.CONTENT.LINK_CONTENT_MODAL_TITLE'
      )}
    >
      <ProjectContentPicker
        linkedContentFiles={linkedContentFiles}
        projectContentFiles={projectContentFiles}
        setLinkedContentFiles={setLinkedContentFiles}
      />
    </Modal>
  );
};

export default LinkContentModal;
