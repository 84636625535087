import { FC } from 'react';
import { useSelector } from 'react-redux';
import { selectSubmittersIds } from 'state/ActualHours/actualHoursSlice';
import { Hours } from 'types/store/actualHours';
import TimeEntriesSection from './TimeEntriesSection';
import SubmitterAccordion from '../SubmitterAccordion/SubmitterAccordion';
import EmptyList from './EmptyList';

interface TimeEntriesListProps {
  isViewOnly?: boolean;
  onDeleteHours: (hours: Hours) => void;
}

const TimeEntriesList: FC<TimeEntriesListProps> = ({
  isViewOnly = false,
  onDeleteHours,
}) => {
  const submittersIds = useSelector(selectSubmittersIds);

  if (submittersIds.length === 0) {
    if (isViewOnly) {
      return <EmptyList />;
    }

    return null;
  }

  return (
    <TimeEntriesSection>
      {submittersIds.map((submitterId) => {
        return (
          <SubmitterAccordion
            key={submitterId}
            submitterId={submitterId}
            onDeleteHours={onDeleteHours}
            canDeleteHours={!isViewOnly}
          />
        );
      })}
    </TimeEntriesSection>
  );
};

export default TimeEntriesList;
