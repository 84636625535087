import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  evaluationsSelectors,
  fetchEvaluations,
} from 'state/Evaluations/evaluationsSlice';
import { useAppThunkDispatch } from 'state/store';
import { SLICE_STATUS } from 'utils/constants';
import { DEFAULT_OFFSET, DEFAULT_PAGE_SIZE } from 'utils/constants/ui';
import { Pagination } from 'utils/customTypes';

interface EvaluationsPageData {
  onPageChange: (params: Pagination) => void;
  isLoading: boolean;
  page: number;
  total: number;
}

const useEvaluationsPage = (): EvaluationsPageData => {
  const dispatch = useAppThunkDispatch();

  useEffect(() => {
    dispatch(
      fetchEvaluations({ limit: DEFAULT_PAGE_SIZE, offset: DEFAULT_OFFSET })
    );
  }, [dispatch]);

  const onPageChange = ({ limit }: Pagination) => {
    dispatch(fetchEvaluations({ limit, offset: page }));
  };

  const page = useSelector(evaluationsSelectors.selectPage);
  const total = useSelector(evaluationsSelectors.selectTotalCount);
  const currentPageEvaluationIds = useSelector(evaluationsSelectors.selectIds);
  const status = useSelector(evaluationsSelectors.selectStatus);
  const isLoading = useMemo<boolean>(() => {
    return (
      status === SLICE_STATUS.LOADING && currentPageEvaluationIds.length === 0
    );
  }, [currentPageEvaluationIds.length, status]);

  return {
    onPageChange,
    isLoading,
    page,
    total,
  };
};

export default useEvaluationsPage;
