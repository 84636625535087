const TimeEntrySkeletonLoader = () => {
  return (
    <div
      className='w-full flex justify-between animate-pulse'
      aria-label='time-entry-skeleton-loader'
    >
      <div className='w-2/5 flex gap-x-2 animate-pulse'>
        <div className='w-4 h-4 rounded bg-neutral-lighter-two self-center' />
        <div className='w-6 h-6 rounded-full bg-neutral-lighter-two' />
        <div className='flex-1 rounded bg-neutral-lighter-two' />
      </div>
      <div className='w-1/6 rounded bg-neutral-lighter-two' />
    </div>
  );
};

export default TimeEntrySkeletonLoader;
