import { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Icon } from '@getsynapse/design-system';
import { fetchProjects, fetchUserProjects } from 'state/Projects/projectsSlice';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import { selectIsUserLd, selectUserType } from 'state/User/userSlice';
import {
  createUpdateUserViewSetting,
  getUserSettingValues,
  getViewSettingValues,
  selectCurrentViewSettingValue,
  selectViewSettingValues,
} from 'state/ViewSettings/viewSettingsSlice';
import {
  getLearningTeams,
  selectLearningTeamStatus,
  selectLearningTeamIsFetched,
} from 'state/LearningTeams/learningTeamsSlice';
import { getAllUsers } from 'state/UsersManagement/usersManagementSlice';
import {
  fetchProjectTemplates,
  selectAllTemplates,
} from 'state/ProjectTemplates/projectTemplatesSlice';
import {
  PROJECTS_LIST_VIEW_MODE,
  PATHS,
  SLICE_STATUS,
  USER_TYPES,
} from 'utils/constants';
import CreateProjectModal from './components/CreateProject/CreateProjectModal';
import ToggleButtonGroup from 'Molecules/ToggleButtonGroup/ToggleButtonGroup';
import TableView from './views/TableView';
import BoardView from './views/BoardView';
import boardView from 'assets/icons/board-view.svg';
import tableView from 'assets/icons/table-view.svg';
import { UserViewSettings } from 'utils/customTypes';

const ViewModeToggleButton = ({ viewType }: { viewType: UserViewSettings }) => {
  const dispatch = useDispatch();
  const projectViewValues = useSelector(
    selectViewSettingValues('PROJECT_VIEW_TYPE')
  );

  const viewModeOptions = [
    {
      children: (isSelected: boolean) => (
        <Icon src={tableView} className='h-4' />
      ),
      key: PROJECTS_LIST_VIEW_MODE.TABLE,
      'data-cy': 'list-view-switch-button',
    },
    {
      children: (isSelected: boolean) => <Icon src={boardView} />,
      key: PROJECTS_LIST_VIEW_MODE.BOARD,
      'data-cy': 'board-view-switch-button',
    },
  ];

  const handleToggleViewMode = (key: string) => {
    const view_settings_values_id = projectViewValues.find(
      (val) => val.value === key
    )?.view_settings_values_id;
    if (view_settings_values_id) {
      dispatch(
        createUpdateUserViewSetting({
          view_settings_id: viewType.view_settings_id,
          view_settings_values_id,
        })
      );
      window.history.replaceState({}, '', window.location.pathname);
    }
  };

  return (
    <>
      {viewType && (
        <ToggleButtonGroup
          buttonsList={viewModeOptions}
          className='absolute top-0 right-0 mr-6 z-5'
          onChange={handleToggleViewMode}
          selected={viewType?.value}
        />
      )}
    </>
  );
};

const ProjectsListPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLDUser = useSelector(selectIsUserLd);
  const userType = useSelector(selectUserType);
  const isFacilitatorUser = userType === USER_TYPES.FACILITATOR;
  const projectTemplates = useSelector(selectAllTemplates);
  const viewType = useSelector(
    selectCurrentViewSettingValue('PROJECT_VIEW_TYPE')
  );

  const [displayAddProjectModal, setDisplayAddProjectModal] =
    useState<boolean>(false);

  useEffect(() => {
    const init = async () => {
      if (isFacilitatorUser) {
        return await Promise.all([
          dispatch(fetchUserProjects()),
          dispatch(getAllUsers()),
          dispatch(fetchProjectTemplates({ status: 'published' })),
          dispatch(getUserSettingValues()),
          dispatch(getViewSettingValues(['PROJECT_VIEW_TYPE'])),
        ]);
      }

      return await Promise.all([
        dispatch(fetchProjects()),
        dispatch(fetchUserProjects()),
        dispatch(getAllUsers()),
        dispatch(fetchProjectTemplates({ status: 'published' })),
        dispatch(getUserSettingValues()),
        dispatch(getViewSettingValues(['PROJECT_VIEW_TYPE'])),
      ]);
    };
    init();
  }, [dispatch, isFacilitatorUser]);

  const ldTeamStatus = useSelector(selectLearningTeamStatus);
  const ldTeamsFetched = useSelector(selectLearningTeamIsFetched);

  useEffect(() => {
    if (!ldTeamsFetched && ldTeamStatus !== SLICE_STATUS.LOADING) {
      dispatch(getLearningTeams());
    }
  }, [ldTeamStatus, ldTeamsFetched, dispatch]);

  const handleAddProject = () => {
    if (projectTemplates.length === 1) {
      return history.push(
        `${PATHS.NEW_PROJECT_PAGE}/${projectTemplates[0].id}`
      );
    }
    setDisplayAddProjectModal(true);
  };

  return (
    <div className='flex flex-col h-full'>
      {!isFacilitatorUser && (
        <CreateProjectModal
          showModal={displayAddProjectModal}
          onCloseModal={() => setDisplayAddProjectModal(false)}
        />
      )}

      <PageTitle
        titleComponent={intl.get('PROJECTS_LIST_PAGE.TITLE')}
        headerChildren={
          isLDUser && (
            <Button onClick={handleAddProject} data-cy='add-project-button'>
              {intl.get('PROJECTS_LIST_PAGE.ADD_PROJECT_BUTTON')}
            </Button>
          )
        }
      />
      <div className='relative w-full h-full'>
        <ViewModeToggleButton viewType={viewType} />

        {viewType?.value === PROJECTS_LIST_VIEW_MODE.TABLE && (
          <TableView handleAddProject={() => setDisplayAddProjectModal(true)} />
        )}
        {viewType?.value === PROJECTS_LIST_VIEW_MODE.BOARD && (
          <BoardView handleAddProject={() => setDisplayAddProjectModal(true)} />
        )}
      </div>
    </div>
  );
};

export default ProjectsListPage;
