export const SCHEDULE_REQUIRED_FIELDS = [
  'scheduleProjectIds',
  'startDateTime',
  'endDateTime',
  'recurrencePattern',
];

export const RECURRENCE_PATTERN_REQUIRED_FIELDS = [
  'name',
  'interval',
  'dayOfWeek',
  'dayOfMonth',
  'monthOfYear',
  'startDateTime',
  'endDateTime',
  'recurrenceRule',
];

export const ADD_FACILITATORS_FILTERS = {
  SKILLS: 'skills',
  LANGUAGES: 'languages',
  COUNTRIES: 'countries',
} as const;

export const EVENTS_TABLE_FILTERS = {
  FACILITATORS: 'facilitators',
  EVENT_TYPE: 'eventTypes',
  PROJECTS: 'projects',
  PROGRAMS: 'programs',
  PROJECT_STATUS: 'projectStatus',
};

export const FACILITATORS_TABLE_FILTERS = {
  FACILITATORS: 'facilitators',
  INCLUDE_PENDING: 'include_pending',
  TEAMS: 'teams',
  SKILLS: 'skills',
  LANGUAGES: 'languages',
  COUNTRIES: 'countries',
  STATES: 'states',
};

export const UNASSIGNED_FACILITATOR = 'unassigned';

export const SCHEDULE_TABS = {
  MY_SCHEDULE: 'mySchedule',
  EVENTS: 'events',
  FACILITATORS: 'facilitators',
};
