import { FC } from 'react';
import intl from 'react-intl-universal';
import {
  tailwindOverride,
  TableRow,
  IconButton,
  Tooltip,
} from '@getsynapse/design-system';
import { getStatusColumn } from 'Pages/ProjectsListPage/helpers/tableColumnsValues';
import { TaskWithAssigneesAndProject } from 'types/store/tasksList';
import { TASK_STATUS, PROJECT_STATUS } from 'utils/constants';
import AssigneesColumn from './AssigneesColumn/AssigneesColumn';
import ProjectColumn from './ProjectColumn/ProjectColumn';

interface TaskRowProps {
  task: TaskWithAssigneesAndProject;
  bgColor: string;
  openTakInSidePanel: (taskId: string) => void;
  handleClickTaskRow: (taskId: string, projectId: string) => void;
}

const TaskRow: FC<TaskRowProps> = ({
  task,
  bgColor,
  openTakInSidePanel,
  handleClickTaskRow,
}) => {
  const columnStyles = tailwindOverride(
    'border-b border-t border-r-0 border-transparent',
    'px-4 py-3'
  );

  const stickyColumnStyles = tailwindOverride(
    columnStyles,
    bgColor,
    'sticky z-1'
  );

  const dateFormatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  const formatDate = (dateString: string): string => {
    return dateFormatter.format(new Date(dateString));
  };

  const isViewOnlyMode =
    task.status === TASK_STATUS.ON_HOLD ||
    task.disabled ||
    !task.permissions.canEdit ||
    [PROJECT_STATUS.CLOSED, PROJECT_STATUS.CANCELED].includes(
      task.project.status
    );

  return (
    <TableRow
      id={task.id}
      aria-label={`task-row-${task.id}`}
      className={tailwindOverride('group cursor-pointer relative', bgColor)}
      onClick={() => handleClickTaskRow(task.id, task.project.id)}
      cells={[
        {
          content: <div className='w-32'>{task.displayId}</div>,
          className: tailwindOverride(stickyColumnStyles, 'w-32 left-0'),
        },
        {
          content: <div className='truncate w-84'>{task.name}</div>,
          className: tailwindOverride(stickyColumnStyles, 'w-84 left-40'),
        },
        {
          content: <div className='w-32'>{getStatusColumn(task.status)}</div>,
          className: tailwindOverride(stickyColumnStyles, 'w-32 left-132'),
        },
        {
          content: (
            <div className='truncate w-72'>
              <ProjectColumn taskProject={task.project} />
            </div>
          ),
          className: tailwindOverride(columnStyles, 'w-72 left-54'),
        },
        {
          content: (
            <div className='truncate w-44'>
              <AssigneesColumn assignees={task.assignees} />
            </div>
          ),
          className: tailwindOverride(columnStyles, 'w-72 left-54'),
        },
        {
          content: (
            <div className='truncate w-32'>
              {task.startDate
                ? formatDate(task.startDate)
                : intl.get('TASKS.TABLE.NO_DATE')}
            </div>
          ),
          className: tailwindOverride(columnStyles, 'w-32'),
        },
        {
          content: (
            <div className='truncate w-32'>
              {task.endDate
                ? formatDate(task.endDate)
                : intl.get('TASKS.TABLE.NO_DATE')}
            </div>
          ),
          className: tailwindOverride(columnStyles, 'w-32'),
        },
        {
          content: (
            <div className='truncate w-32'>
              {task.estimateHours ? `${parseFloat(task.estimateHours)}h` : '0h'}
            </div>
          ),
          className: tailwindOverride(columnStyles, 'w-32'),
        },
        {
          content: (
            <div className='truncate w-32'>
              {task.actualHours ? `${parseFloat(task.actualHours)}h` : '0h'}
            </div>
          ),
          className: tailwindOverride(columnStyles, 'w-32'),
        },
        {
          content: (
            <div
              className='w-6 h-full text-center relative'
              onClick={(e) => e.stopPropagation()}
            >
              <div className='hidden group-hover:flex'>
                <Tooltip
                  openMode='hover1'
                  timeout={0}
                  showPopper
                  position='topCenter'
                  contentProps={{
                    className: 'px-3 py-2 text-sm z-50 absolute',
                  }}
                  usePortal
                  trigger={
                    <IconButton
                      name={!isViewOnlyMode ? 'pencil-outline' : 'eye'}
                      aria-label={`task-row-${task.id}-cta-button`}
                      iconClassname='pointer-events-none'
                      className='text-base text-neutral-dark p-1 hover:bg-neutral-lightest hover:shadow-allocation-table rounded'
                      onClick={() => openTakInSidePanel(task.id)}
                    />
                  }
                >
                  {!isViewOnlyMode
                    ? intl.get('SIDE_PANEL.QUICK_EDIT')
                    : intl.get('SIDE_PANEL.QUICK_VIEW')}
                </Tooltip>
              </div>
            </div>
          ),
          className: tailwindOverride(stickyColumnStyles, 'w-6 right-0'),
        },
      ]}
    />
  );
};

export default TaskRow;
