import { forwardRef } from 'react';
import intl from 'react-intl-universal';
import { useSelector } from 'react-redux';
import { selectScheduleEvents } from 'state/Schedule/ScheduleSlice';
import { GlobalBanner, Typography } from '@getsynapse/design-system';
import EventsHeader from './components/EventsHeader/EventsHeader';
import ScheduleCalendar from '../ScheduleCalendar/ScheduleCalendar';

const Events = forwardRef(({ pageToLoad }: { pageToLoad: string }, ref) => {
  const events = useSelector(selectScheduleEvents);
  return (
    <div className='overflow-hidden'>
      {events.length === 0 && (
        <GlobalBanner
          variant='warning'
          className='h-12 mb-4 bg-teal-lightest'
          iconProps={{
            className: 'text-teal',
          }}
        >
          {() => <Typography>{intl.get('SCHEDULE.EVENTS.BANNER')}</Typography>}
        </GlobalBanner>
      )}
      <EventsHeader />
      <ScheduleCalendar ref={ref} pageToLoad={pageToLoad} />
    </div>
  );
});

export default Events;
