import {
  FormItem,
  tailwindOverride,
  UsersPicker,
} from '@getsynapse/design-system';
import debounce from 'lodash/debounce';
import { UserOption } from '@getsynapse/design-system/dist/Molecules/UsersPicker/UsersPicker';
import { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAvailableAssignees,
  selectAssignees,
  selectAvailableAssignees,
} from 'state/TaskDetail/taskSlice';
import { TaskAssignee } from 'types/store/tasks';

function toUserOption(user: TaskAssignee): UserOption {
  const names = user.name.split(' ');

  let initial;
  if (names.length >= 2) {
    initial = `
        ${names[0].charAt(0).toUpperCase()}${names[1].charAt(0).toUpperCase()}
      `.trim();
  }

  return {
    avatar: {
      imageSrc: user.avatarUrl,
      initial,
    },
    label: user.name,
    value: user.id,
    disabled: false,
  };
}

interface TaskAssigneesFieldProp {
  taskId: string;
  onAssigneesChange: (assignees: UserOption[]) => void;
  isViewOnly?: boolean;
  isReadOnly?: boolean;
  isOnSidepanel?: boolean;
}

const TaskAssigneesField = ({
  taskId,
  onAssigneesChange,
  isReadOnly = false,
  isViewOnly = false,
  isOnSidepanel = false,
}: TaskAssigneesFieldProp) => {
  const selectedUsers: UserOption[] =
    useSelector(selectAssignees).map(toUserOption);

  const availableAssignees: UserOption[] = useSelector(
    selectAvailableAssignees
  ).map(toUserOption);

  const [assigneeName, handleFilterChange] = useState<string | undefined>();
  const onFilterChange = debounce((value) => {
    handleFilterChange(value);
  }, 500);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAvailableAssignees({ taskId, assigneeName }));
  }, [taskId, assigneeName, dispatch]);

  const userList = selectedUsers.concat(availableAssignees);

  const updateAssignees = (assignees: UserOption[]) => {
    onAssigneesChange(assignees);
  };

  return (
    <FormItem
      label={intl.get('TASKS.TASK_DETAIL_PAGE.TASK_ASSIGNEE')}
      disabled={isViewOnly || isReadOnly}
      className={tailwindOverride({ 'mt-4': isOnSidepanel })}
    >
      <UsersPicker
        disabled={isViewOnly || isReadOnly}
        triggerText={intl.get('TASKS.ADD_TASK_MODAL.TASK_ASSIGNEE_PLACEHOLDER')}
        deactivatedUserText={intl.get('DEACTIVATED')}
        usersList={userList}
        selectedUsersList={selectedUsers}
        onChange={updateAssignees}
        onFilterChange={onFilterChange}
        triggerProps={{
          'aria-label': 'task-assignees-picker-field',
        }}
      />
    </FormItem>
  );
};

export default TaskAssigneesField;
