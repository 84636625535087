import { Typography } from '@getsynapse/design-system';
import { useSelector } from 'react-redux';
import { evaluationInsightsSelectors } from 'state/Evaluations/evaluationInsightsSlice';
import { SLICE_STATUS } from 'utils/constants';
import intl from 'react-intl-universal';
import {
  LearningDeliveryChart,
  LearningDeliveryChartData,
} from './LearningDeliveryChart';
import { useMemo } from 'react';
import ErrorScreen from 'Pages/InsightsPage/Components/ErrorScreen';
import BasicInfoLoader from 'Pages/InsightsPage/Components/SkeletonLoaders/BasicInfoLoader';
import { PerformitivLink } from 'Pages/InsightsPage/Components/PerformitivLink';

const LearningDelivery = () => {
  const chartData = useSelector(
    evaluationInsightsSelectors.getLearningDeliveryData
  );
  const status = useSelector(evaluationInsightsSelectors.selectStatus);
  const formattedChartData: LearningDeliveryChartData[] = useMemo(
    () =>
      chartData?.map((data) => ({
        ...data,
        distributionHigh: data.distributionHigh * 100,
        distributionLow: data.distributionLow * 100,
        benchmarking: {
          score: data.score,
          scorePrecision: data.scorePrecision,
          benchmark: data.benchmark,
        },
      })),
    [chartData]
  );

  return (
    <>
      {status === SLICE_STATUS.LOADING && <BasicInfoLoader />}
      {status === SLICE_STATUS.FAILED && <ErrorScreen />}
      {status === SLICE_STATUS.SUCCESS && (
        <div className='flex flex-col w-full h-full gap-8'>
          <div className='flex flex-row w-full justify-between align-middle'>
            <Typography
              variant='label'
              className='text-primary font-semibold leading-1'
            >
              {intl.get(
                'INSIGHTS_PAGE_TABS.INSIGHTS_EVALUATIONS.CHARTS.LEARNING_DELIVERY.TITLE'
              )}
            </Typography>
            <PerformitivLink />
          </div>

          <LearningDeliveryChart data={formattedChartData} />
        </div>
      )}
    </>
  );
};

export default LearningDelivery;
