import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import {
  addContentToTask,
  removeContentFromTask,
} from 'state/LinkedTaskContent/linkedTaskContentSlice';
import projectAPI from 'state/Project/projectAPI';
import { addProjectFilesFromTask } from 'state/Project/projectSlice';
import { ProjectContent } from 'utils/customTypes';

export interface UseLinkedTaskContentParams {
  taskId: string;
  projectId: string;
  showNotification: (props: any) => void;
}

interface UseLinkedTaskContentResponse {
  addTaskContent: (projectFiles: ProjectContent[]) => void;
  removeTaskContent: (contentIds: string[]) => void;
  linkContentFiles: (contentIds: string[]) => void;
}

const useLinkedTaskContent: (
  params: UseLinkedTaskContentParams
) => UseLinkedTaskContentResponse = ({
  taskId,
  projectId,
  showNotification,
}) => {
  const dispatch = useDispatch();

  const addTaskContent = useCallback(
    async (projectFiles: ProjectContent[]) => {
      try {
        const response = await projectAPI.addProjectFiles(projectId, {
          projectFiles,
        });
        dispatch(addProjectFilesFromTask(response));
        await dispatch(
          addContentToTask({
            taskId,
            contentIds: response.map((file: ProjectContent) => file.id!),
          })
        );
        showNotification({
          notificationTitle: '',
          notificationMessage: intl.get(
            'TASKS.TASK_DETAIL_PAGE.CONTENT.FILES_ADDED_MESSAGE'
          ),
        });
      } catch (error) {
        showNotification({
          notificationVariant: 'error',
          notificationTitle: intl.get(
            'TASKS.TASK_DETAIL_PAGE.CONTENT.UPDATE_CONTENT_ERROR,TITLE'
          ),
          notificationMessage: intl.get(
            'TASKS.TASK_DETAIL_PAGE.CONTENT.UPDATE_CONTENT_ERROR,MESSAGE'
          ),
        });
      }
    },
    [dispatch, taskId, showNotification, projectId]
  );

  const removeTaskContent = useCallback(
    async (contentIds: string[]) => {
      try {
        await dispatch(
          removeContentFromTask({
            taskId,
            contentIds,
          })
        );
        showNotification({
          notificationTitle: '',
          notificationMessage: intl.get(
            'TASKS.TASK_DETAIL_PAGE.CONTENT.CONTENT_UNLINK_MESSAGE'
          ),
        });
      } catch (error) {
        showNotification({
          notificationVariant: 'error',
          notificationTitle: intl.get(
            'TASKS.TASK_DETAIL_PAGE.CONTENT.UPDATE_CONTENT_ERROR,TITLE'
          ),
          notificationMessage: intl.get(
            'TASKS.TASK_DETAIL_PAGE.CONTENT.UPDATE_CONTENT_ERROR,MESSAGE'
          ),
        });
      }
    },
    [dispatch, taskId, showNotification]
  );

  const linkContentFiles = useCallback(
    async (contentIds: string[]) => {
      try {
        await dispatch(
          addContentToTask({
            taskId,
            contentIds,
          })
        );
        showNotification({
          notificationTitle: '',
          notificationMessage: intl.get(
            'TASKS.TASK_DETAIL_PAGE.CONTENT.CONTENT_LINKED_MESSAGE'
          ),
        });
      } catch (error) {
        showNotification({
          notificationVariant: 'error',
          notificationTitle: intl.get(
            'TASKS.TASK_DETAIL_PAGE.CONTENT.UPDATE_CONTENT_ERROR.TITLE'
          ),
          notificationMessage: intl.get(
            'TASKS.TASK_DETAIL_PAGE.CONTENT.UPDATE_CONTENT_ERROR.MESSAGE'
          ),
        });
      }
    },
    [dispatch, taskId, showNotification]
  );

  return useMemo(
    () => ({
      addTaskContent,
      removeTaskContent,
      linkContentFiles,
    }),
    [addTaskContent, removeTaskContent, linkContentFiles]
  );
};

export default useLinkedTaskContent;
