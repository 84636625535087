import config from 'config/Config';
import { TaskFilters } from 'utils/types/filters';
import { RangeFilter } from 'utils/types/filters';
import { TaskListFilters, TasksListTableSorting } from 'types/store/tasksList';
import { FetchTasksListApiParams } from 'api/tasksList';

export const getAfterQueryParamFromNextLink: (
  nextLink: string | null
) => string = (nextLink) => {
  if (nextLink) {
    const fullUrl = new URL(`${config.get('backendURL')}/${nextLink}`);
    const searchParams = new URLSearchParams(new URL(fullUrl).search);
    if (searchParams.has('after')) {
      return searchParams.get('after')!;
    }
  }
  return '';
};

interface FetchCentralizedTasksListParams {
  userId?: string;
  nextLink: string | null;
  includeTotalCount?: boolean;
}

export const getFetchCentralizedTasksListQueryParams: (
  params: FetchCentralizedTasksListParams,
  filters: TaskListFilters,
  sorting: TasksListTableSorting
) => FetchTasksListApiParams = (params, filters, sorting) => {
  let queryParams: FetchTasksListApiParams = {};

  if (params.userId) {
    queryParams['user'] = [params.userId];
  }

  if (params.nextLink) {
    queryParams['after'] = getAfterQueryParamFromNextLink(params.nextLink);
  }

  if (Object.keys(filters).length > 0) {
    queryParams = { ...queryParams, ...filters };
  }

  if (sorting.sortBy && sorting.order) {
    queryParams['sortBy'] = sorting.sortBy;
    queryParams['order'] = sorting.order;
  }

  queryParams['includeTotalCount'] = params.includeTotalCount;
  return queryParams;
};

export const normalizeFiltersSettingsVersions: (
  oldFilters: TaskFilters
) => TaskListFilters = (oldFilters) => {
  const filters: TaskListFilters = {};
  const oldFiltersCopy = { ...oldFilters };
  if (oldFiltersCopy.additionalFlags) {
    filters.disabled = true;
    delete oldFiltersCopy.additionalFlags;
  }

  if (
    oldFiltersCopy.assignedUsers &&
    Array.isArray(oldFiltersCopy.assignedUsers)
  ) {
    filters.user = oldFiltersCopy.assignedUsers;
    delete oldFiltersCopy.assignedUsers;
  }

  if (oldFiltersCopy.start_date) {
    const startDate = { ...oldFiltersCopy.start_date } as RangeFilter;
    delete oldFiltersCopy.start_date;
    if (startDate.from) {
      filters.startDateFrom = new Date(startDate.from).toLocaleDateString(
        'en-US'
      );
    }

    if (startDate.to) {
      filters.startDateTo = new Date(startDate.to).toLocaleDateString('en-US');
    }
  }

  if (oldFiltersCopy.due_date) {
    const dueDate = { ...oldFiltersCopy.due_date } as RangeFilter;
    delete oldFiltersCopy.due_date;
    if (dueDate.from) {
      filters.dueDateFrom = new Date(dueDate.from).toLocaleDateString('en-US');
    }

    if (dueDate.to) {
      filters.dueDateTo = new Date(dueDate.to).toLocaleDateString('en-US');
    }
  }

  if (oldFiltersCopy.completion_date) {
    const completionDate = { ...oldFiltersCopy.completion_date } as RangeFilter;
    delete oldFiltersCopy.completion_date;
    if (completionDate.from) {
      filters.completionDateFrom = new Date(
        completionDate.from
      ).toLocaleDateString('en-US');
    }

    if (completionDate.to) {
      filters.completionDateTo = new Date(completionDate.to).toLocaleDateString(
        'en-US'
      );
    }
  }

  if (oldFiltersCopy.estimate_hours) {
    const estimateHours = { ...oldFiltersCopy.estimate_hours } as RangeFilter;
    delete oldFiltersCopy.estimate_hours;
    if (estimateHours.from) {
      filters.estimateHoursFrom = estimateHours.from;
    }

    if (estimateHours.to) {
      filters.estimateHoursTo = estimateHours.to;
    }
  }

  if (oldFiltersCopy.actual_hours) {
    const actualHours = { ...oldFiltersCopy.actual_hours } as RangeFilter;
    delete oldFiltersCopy.actual_hours;
    if (actualHours.from) {
      filters.actualHoursFrom = actualHours.from;
    }

    if (actualHours.to) {
      filters.actualHoursTo = actualHours.to;
    }
  }

  return { ...filters, ...oldFiltersCopy } as TaskListFilters;
};
