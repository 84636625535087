import intl from 'react-intl-universal';
import { Tooltip, Button } from '@getsynapse/design-system';

const ButtonWithTooltip = () => {
  return (
    <Tooltip
      openMode='hover1'
      position='topLeft'
      timeout={0}
      showPopper
      contentProps={{
        className: 'rounded text-sm w-60 whitespace-normal z-50',
      }}
      trigger={
        <Button
          variant='primary'
          size='large'
          disabled
          className='px-4 py-2 cursor-not-allowed'
        >
          {intl.get('TASKS.ADD_TASK_BUTTON_TITLE')}
        </Button>
      }
    >
      {intl.get('TASKS.ADD_TASK_BUTTON_TOOLTIP_FOR_FACILITATORS')}
    </Tooltip>
  );
};

export default ButtonWithTooltip;
