import { FC } from 'react';
import intl from 'react-intl-universal';
import {
  Dropdown,
  FormItem,
  Tag,
  Checkbox,
  Typography,
  tailwindOverride,
} from '@getsynapse/design-system';
import { PROJECT_CONTENT_TYPE } from 'utils/constants';
import { ProjectContent } from 'utils/customTypes';
import { getContentTypeLabel } from 'Pages/ProjectPage/tabs/Content/components/ContentTable/utils';
import designIcon from 'assets/icons/design-icon.svg';

interface ProjectContentPickerProps {
  linkedContentFiles: ProjectContent[];
  projectContentFiles: ProjectContent[];
  setLinkedContentFiles: (options: ProjectContent[]) => void;
}

const ProjectContentPicker: FC<ProjectContentPickerProps> = ({
  projectContentFiles,
  linkedContentFiles,
  setLinkedContentFiles,
}) => {
  return (
    <FormItem
      label={intl.get('TASKS.TASK_DETAIL_PAGE.CONTENT.LINK_CONTENT_MODAL_BODY')}
    >
      <Dropdown
        multiple
        onChange={(options: ProjectContent[]) => setLinkedContentFiles(options)}
        options={projectContentFiles}
        values={linkedContentFiles}
        getOptionLabel={(option: ProjectContent) => option.name!}
        triggerProps={{
          'data-cy': 'link-content-dropdown',
          'aria-label': 'link-content-dropdown',
          className: 'pb-1',
        }}
        renderOption={(
          option: ProjectContent,
          isSelected,
          selectOption: () => void,
          { className, ...restProps }
        ) => {
          return (
            <li
              key={option.id}
              {...restProps}
              className={tailwindOverride('group', className, {
                'hover:bg-primary focus-visible:bg-primary': isSelected,
              })}
              data-cy={`content-file-option--${option.id}`}
              aria-label={`content-file-option--${option.id}`}
            >
              <Checkbox
                checked={isSelected}
                onChange={selectOption}
                aria-label={`content-file-option--${option.id}`}
                label={
                  <div className='flex'>
                    <Typography
                      className={tailwindOverride({
                        'group-hover:text-neutral-white': isSelected,
                      })}
                    >
                      {option.name}
                    </Typography>
                    <Tag
                      label={getContentTypeLabel(option.content_type)}
                      className='bg-neutral-lighter-two ml-2 group-hover:bg-neutral-dark'
                      textClassName='text-neutral-darker font-semibold group-hover:text-neutral-white'
                    />
                  </div>
                }
                inputProps={{
                  className: tailwindOverride({
                    'group-hover:border-neutral-white': isSelected,
                    'group-focus-visible:border-neutral-white': isSelected,
                  }),
                }}
                className={tailwindOverride({
                  'group-hover:text-neutral-white': isSelected,
                  'group-focus-visible:text-neutral-white': isSelected,
                })}
              />
            </li>
          );
        }}
        renderSelectedOptionTag={(
          { textClassName, label, ...restOfProps },
          selectedOption: any
        ) => {
          const projectContentFile = selectedOption as ProjectContent;
          const leadingIconProps: { name?: string; src?: string } = {};
          switch (projectContentFile.content_type) {
            case PROJECT_CONTENT_TYPE.FILE:
            case PROJECT_CONTENT_TYPE.DESIGN_FILE:
              leadingIconProps['name'] = 'document-outline';
              break;
            case PROJECT_CONTENT_TYPE.WEB_LINK:
              leadingIconProps['name'] = 'link-outline';
              break;
            case PROJECT_CONTENT_TYPE.DESIGN:
              leadingIconProps['src'] = designIcon;
              break;
            default:
              leadingIconProps['name'] = 'checkmark-circle-outline';
              break;
          }

          return (
            <Tag
              {...restOfProps}
              textClassName={tailwindOverride(
                'text-xs text-secondary-darker font-semibold',
                'group-hover:text-secondary-lighter',
                textClassName
              )}
              leadingIcon={{
                ...leadingIconProps,
                className: 'text-base',
              }}
              className={tailwindOverride(
                'group bg-secondary-lightest hover:bg-secondary-darker',
                'mr-1 mb-1 last:mr-0',
                'max-w-xs truncate'
              )}
              label={projectContentFile.name}
              tailingIcon={{ className: 'text-base' }}
              aria-label={`selected-content-file-tag--${projectContentFile.id}`}
            />
          );
        }}
      />
    </FormItem>
  );
};

export default ProjectContentPicker;
