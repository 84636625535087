import { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import {
  fetchTaskDetail,
  selectTaskDetail,
  resetState,
} from 'state/TaskDetail/taskSlice';
import {
  selectTaskIdToDuplicate,
  createProjectTask,
  setTaskIdToDuplicate,
  setShouldRefreshTasks,
} from 'state/ProjectTasksList/actions/actionsSlice';
import { NewTask } from 'types/store/newTask';
import { serializeTaskDetailToDuplicate } from './serializer';
import useSnackbarNotification from 'Hooks/useSnackbarNotification';
import TaskModalForm from 'Pages/TasksListPage/components/v2/TaskModalForm/TaskModalForm';

const DuplicateTaskModal = () => {
  const dispatch = useDispatch();
  const taskIdToDuplicate = useSelector(selectTaskIdToDuplicate);
  const taskDetail = useSelector(selectTaskDetail);
  const [shouldOpenModal, setShouldOpenModal] = useState<boolean>(false);
  const [orginalTaskData, setOrginalTaskData] = useState<NewTask>(
    {} as NewTask
  );

  const closeModal = useCallback(() => {
    dispatch(setTaskIdToDuplicate(undefined));
    dispatch(setShouldRefreshTasks(true));
    dispatch(resetState());
    setShouldOpenModal(false);
  }, [dispatch]);

  const {
    SnackbarNotification,
    snackbarProps,
    showNotification,
    isNotificationVisible,
  } = useSnackbarNotification();

  const handleDuplicateTask = useCallback(
    async (newTask: NewTask) => {
      try {
        await dispatch(
          createProjectTask({
            newTasks: [newTask],
            userId: '',
          })
        );
        showNotification({
          notificationTitle: '',
          notificationMessage: intl.get(
            'TASKS.NOTIFICATIONS.TASK_DUPLICATION_SUCCESS'
          ),
        });
      } catch (error) {
        showNotification({
          notificationVariant: 'error',
          notificationTitle: intl.get(
            'TASKS.NOTIFICATIONS.TASK_CREATION_ERROR_TITLE'
          ),
          notificationMessage: intl.get(
            'TASKS.NOTIFICATIONS.TASK_CREATION_ERROR_MESSAGE'
          ),
        });
      } finally {
        closeModal();
      }
    },
    [dispatch, showNotification, closeModal]
  );

  useEffect(() => {
    const init = async (taskId: string) => {
      await dispatch(fetchTaskDetail({ taskId }));
      setShouldOpenModal(true);
    };
    if (taskIdToDuplicate) {
      init(taskIdToDuplicate);
    }
  }, [dispatch, taskIdToDuplicate]);

  useEffect(() => {
    if (taskDetail && shouldOpenModal) {
      const taskData = serializeTaskDetailToDuplicate(taskDetail);
      setOrginalTaskData(taskData);
    }
  }, [taskDetail, shouldOpenModal]);

  return (
    <>
      <TaskModalForm
        title={intl.get('TASK_MODAL.TITLE', {
          action: intl.get('ADD'),
        })}
        shouldOpenModal={shouldOpenModal}
        onCloseModal={closeModal}
        saveButtonLabel={intl.get('ADD')}
        formPrompt={intl.get('TASK_MODAL.ADD_PROMPT', {
          container: intl.get('ENTITIES.PROJECT', { num: 1 }).toLowerCase(),
        })}
        displayProjectPicker={false}
        onSaveTask={handleDuplicateTask}
        orginalTaskData={orginalTaskData}
        isDuplicate
      />
      {isNotificationVisible && snackbarProps && (
        <SnackbarNotification {...snackbarProps} />
      )}
    </>
  );
};

export default DuplicateTaskModal;
