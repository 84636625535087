import { FC, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'state/store';
import {
  selectSubmitterTimeEntries,
  removeSubmitterHours,
} from 'state/ActualHours/actualHoursSlice';
import { selectUserId } from 'state/User/userSlice';
import { Hours } from 'types/store/actualHours';
import AccordionTrigger from './AccordionTrigger';
import TimeEntry from '../TimeEntries/TimeEntry';

interface SubmitterAccordionProps {
  submitterId: string;
  onDeleteHours: (hours: Hours) => void;
  canDeleteHours?: boolean;
}

const SubmitterAccordion: FC<SubmitterAccordionProps> = ({
  submitterId,
  onDeleteHours,
  canDeleteHours = true,
}) => {
  const dispatch = useDispatch();
  const submitterTimeEntries = useSelector((state: RootState) =>
    selectSubmitterTimeEntries(state, submitterId)
  );
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const currentUserId = useSelector(selectUserId);
  const isCurrentUserTheSubmitter = currentUserId === submitterId;

  const handleDeletedHours = useCallback(
    (hours: Hours) => {
      dispatch(
        removeSubmitterHours({
          submitterId,
          hoursId: hours.id,
        })
      );
      onDeleteHours(hours);
    },
    [onDeleteHours, submitterId, dispatch]
  );

  return (
    <div className='flex flex-col gap-y-2'>
      <AccordionTrigger
        submitter={submitterTimeEntries.submitter}
        isExpanded={isExpanded}
        toggleIsExpanded={() => setIsExpanded(!isExpanded)}
        totalHours={submitterTimeEntries.totalHours}
      />
      <div
        id={`submitter-${submitterId}__accordion-content`}
        role='region'
        aria-labelledby={`submitter-${submitterId}__accordion-trigger`}
        className='flex flex-col gap-y-2 pl-12'
      >
        {isExpanded &&
          submitterTimeEntries.actualHours.map((timeEntry: Hours) => (
            <TimeEntry
              key={timeEntry.id}
              hours={timeEntry}
              onDeleteHours={handleDeletedHours}
              canDelete={isCurrentUserTheSubmitter || canDeleteHours}
            />
          ))}
      </div>
    </div>
  );
};

export default SubmitterAccordion;
