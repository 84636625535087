import { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { Status } from 'utils/customTypes';
import { SLICE_STATUS } from 'utils/constants';
import { CENTRALIZED_TASKS_TABLE_TABS } from 'utils/constants/centralizedTasks';
import {
  createDownloadLinkAndDownloadFile,
  extractFilenameFromContentDisposition,
} from 'utils/csvExports';
import { TaskListFilters } from 'types/store/tasksList';
import {
  fetchMyTasks,
  selectFetchMyTasksStatus,
  selectMyTasks,
  selectMyTasksFilters,
  setFilters,
  setSearchFilter,
  setSorting,
  selectMyTasksTableSorting,
  selectCanFetchMoreMyTasks,
  selectMyTasksTotalCount,
} from 'state/TasksList/myTasks/myTasksSlice';
import { selectUserId } from 'state/User/userSlice';
import { fetchFilterSettingByType } from 'state/Settings/Filters/FiltersSlice';
import {
  setShouldRefreshTasks,
  selectShouldRefreshTasks,
} from 'state/TasksList/allTasks/allTasksSlice';
import { exportTasksList } from 'api/tasksList';
import useSnackbarNotification from 'Hooks/useSnackbarNotification';
import TasksListTable from 'Pages/TasksListPage/components/v2/TasksListTable/TasksListTable';
import SkeletonLoader from 'Organisms/TableSkeletonLoader/PageTableLoader';

const MyTasks = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const shouldRefreshTasks = useSelector(selectShouldRefreshTasks);
  const myTasks = useSelector(selectMyTasks);
  const currentUserId = useSelector(selectUserId);
  const fetchMyTasksStatus = useSelector(selectFetchMyTasksStatus);
  const myTasksFilters = useSelector(selectMyTasksFilters);
  const myTasksSorting = useSelector(selectMyTasksTableSorting);
  const canFetchMoreTasks = useSelector(selectCanFetchMoreMyTasks);
  const totalCount = useSelector(selectMyTasksTotalCount);
  const prevFetchMyTasksStatus = useRef<Status>(fetchMyTasksStatus);

  const {
    SnackbarNotification,
    showNotification,
    isNotificationVisible,
    snackbarProps,
  } = useSnackbarNotification();

  const init = useCallback(async () => {
    await dispatch(
      fetchFilterSettingByType(CENTRALIZED_TASKS_TABLE_TABS.MY_TASKS)
    );
    dispatch(
      fetchMyTasks({
        userId: currentUserId!,
        fetchNext: false,
        includeTotalCount: true,
      })
    );
  }, [dispatch, currentUserId]);

  useEffect(() => {
    if (currentUserId) {
      init();
    }
  }, [init, currentUserId]);

  useEffect(() => {
    if (currentUserId && shouldRefreshTasks) {
      dispatch(setShouldRefreshTasks(false));
      init();
    }
  }, [currentUserId, shouldRefreshTasks, init, dispatch]);

  useEffect(() => {
    if (fetchMyTasksStatus !== prevFetchMyTasksStatus.current) {
      prevFetchMyTasksStatus.current = fetchMyTasksStatus;
      if (isLoading && fetchMyTasksStatus === SLICE_STATUS.IDLE) {
        setIsLoading(false);
      }
    }
  }, [fetchMyTasksStatus, isLoading]);

  const handleUpdateFilters = useCallback(
    (updatedFilters: TaskListFilters) => {
      dispatch(setFilters(updatedFilters));
      dispatch(
        fetchMyTasks({
          fetchNext: false,
          includeTotalCount: true,
          userId: currentUserId!,
        })
      );
    },
    [dispatch, currentUserId]
  );

  const handleSearchFilterChange = useCallback(
    (value: string) => {
      dispatch(setSearchFilter(value));
      dispatch(
        fetchMyTasks({
          fetchNext: false,
          includeTotalCount: true,
          userId: currentUserId!,
        })
      );
    },
    [dispatch, currentUserId]
  );

  const handleTableSort = useCallback(
    (sortBy: string, order: string) => {
      dispatch(setSorting({ sortBy, order }));
      dispatch(
        fetchMyTasks({
          fetchNext: false,
          includeTotalCount: false,
          userId: currentUserId!,
        })
      );
    },
    [dispatch, currentUserId]
  );

  const handleFetchMoreTasks = useCallback(() => {
    dispatch(
      fetchMyTasks({
        fetchNext: true,
        includeTotalCount: false,
        userId: currentUserId!,
      })
    );
  }, [dispatch, currentUserId]);

  const handleExportTasks = useCallback(async () => {
    try {
      const response = await exportTasksList({
        ...myTasksFilters,
        user: [currentUserId!],
      });
      const filename = extractFilenameFromContentDisposition(
        response.headers['content-disposition']
      );
      const blob = new Blob([response.data], { type: 'text/csv' });
      createDownloadLinkAndDownloadFile(blob, filename);
    } catch (error) {
      showNotification({
        notificationVariant: 'error',
        notificationTitle: intl.get('TASKS_LIST_PAGE.EXPORT_TASKS_ERROR.TITLE'),
        notificationMessage: intl.get(
          'TASKS_LIST_PAGE.EXPORT_TASKS_ERROR.MESSAGE'
        ),
      });
    }
  }, [myTasksFilters, showNotification, currentUserId]);

  if (isLoading) {
    return (
      <SkeletonLoader
        data-testid={`${CENTRALIZED_TASKS_TABLE_TABS.MY_TASKS}__skeleton-loader`}
      />
    );
  }

  return (
    <>
      <TasksListTable
        tableName={CENTRALIZED_TASKS_TABLE_TABS.MY_TASKS}
        tasks={myTasks}
        totalCount={totalCount}
        filters={myTasksFilters}
        onUpdateFilters={handleUpdateFilters}
        sortBy={myTasksSorting.sortBy}
        order={myTasksSorting.order}
        handleSort={handleTableSort}
        canFetchMoreTasks={canFetchMoreTasks}
        fetchMoreTasks={handleFetchMoreTasks}
        onExport={handleExportTasks}
        onSearchFilterChange={handleSearchFilterChange}
      />
      {isNotificationVisible && snackbarProps && (
        <SnackbarNotification {...snackbarProps} />
      )}
    </>
  );
};

export default MyTasks;
