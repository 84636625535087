import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from 'state/store';
import {
  TaskListElement,
  TaskListFilters,
  TasksListTableSorting,
  TaskListElementPermissions,
} from 'types/store/tasksList';
import { Status } from 'utils/customTypes';
import { SLICE_STATUS } from 'utils/constants';
import {
  CENTRALIZED_TASKS_TABLE_TABS,
  DEFAULT_TASK_ELEMENT_PERMISSIONS,
} from 'utils/constants/centralizedTasks';
import {
  setTasksAssignees,
  setTasksProjects,
  selectAssigneesListByIds,
  selectTaskProjectById,
} from '../allTasks/allTasksSlice';
import { fetchFilterSettingByType } from 'state/Settings/Filters/FiltersSlice';
import { fetchTasksList } from 'api/tasksList';
import {
  normalizeFiltersSettingsVersions,
  getFetchCentralizedTasksListQueryParams,
} from '../helpers';

interface TeamTasksSliceState {
  tasks: TaskListElement[];
  filters: TaskListFilters;
  sorting: TasksListTableSorting;
  status: Status;
  links: {
    next: string | null;
  };
  totalCount?: number;
  permissions?: Record<string, TaskListElementPermissions>;
}

const initialState: TeamTasksSliceState = {
  tasks: [],
  filters: {},
  sorting: {
    sortBy: 'name',
    order: 'desc',
  },
  status: SLICE_STATUS.IDLE,
  links: {
    next: null,
  },
};

interface FetchTasksListParams {
  fetchNext?: boolean;
  includeTotalCount?: boolean;
}

export const fetchTeamTasks = createAsyncThunk(
  'tasksList/teamTasks/fetchTeamTasks',
  async (
    { fetchNext = false, includeTotalCount = false }: FetchTasksListParams,
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      const state = getState() as RootState;
      let nextLink = null;
      if (fetchNext) {
        nextLink = state.tasksList.teamTasks.links.next;
      }
      const filters = state.tasksList.teamTasks.filters;
      const sorting = state.tasksList.teamTasks.sorting;
      const queryParams = getFetchCentralizedTasksListQueryParams(
        { nextLink, includeTotalCount },
        filters,
        sorting
      );
      const response = await fetchTasksList(queryParams);
      dispatch(setTasksAssignees(response.assignees));
      dispatch(setTasksProjects(response.projects));
      return {
        tasks: response.tasks,
        links: response.links,
        fetchNext,
        totalCount: response.totalCount,
        permissions: response.permissions,
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue('Error fetching team tasks');
    }
  }
);

const teamTasksSlice = createSlice({
  name: 'tasksList/teamTasks',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setSorting: (state, action) => {
      state.sorting = action.payload;
    },
    setSearchFilter: (state, action) => {
      state.filters.search = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeamTasks.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchTeamTasks.fulfilled, (state, action) => {
        state.status = SLICE_STATUS.IDLE;
        if (action.payload) {
          const fetchNext = action.payload.fetchNext;
          let updatedTasks = action.payload.tasks;
          let permissions = action.payload.permissions;
          if (fetchNext) {
            updatedTasks = state.tasks.concat(action.payload.tasks);
            permissions = {
              ...state.permissions,
              ...action.payload.permissions,
            };
          }
          state.tasks = updatedTasks;
          state.links = action.payload.links;
          state.permissions = permissions;
          if (action.payload.totalCount !== undefined) {
            state.totalCount = action.payload.totalCount;
          }
        }
      })
      .addCase(fetchTeamTasks.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchFilterSettingByType.fulfilled, (state, action) => {
        if (
          action.payload &&
          action.payload.filter_type === CENTRALIZED_TASKS_TABLE_TABS.TEAM_TASKS
        ) {
          state.filters = normalizeFiltersSettingsVersions(
            action.payload.filter_settings
          );
        }
      });
  },
});

const selectTasks = (state: RootState) => state.tasksList.teamTasks.tasks;
const selectTasksPermissions = (state: RootState) =>
  state.tasksList.teamTasks.permissions ?? {};
export const selectFetchTeamTasksStatus = (state: RootState) =>
  state.tasksList.teamTasks.status;

export const selectTeamTasks = createSelector(
  [(state: RootState) => state, selectTasks, selectTasksPermissions],
  (
    state: RootState,
    tasks: TeamTasksSliceState['tasks'],
    permissions: TeamTasksSliceState['permissions']
  ) => {
    return tasks.map((task) => {
      return {
        ...task,
        assignees: selectAssigneesListByIds(state, task.assignees),
        project: selectTaskProjectById(state, task.projectId),
        permissions: permissions
          ? permissions[task.id]
          : DEFAULT_TASK_ELEMENT_PERMISSIONS,
      };
    });
  }
);

export const selectTeamTasksTableFilters = (state: RootState) =>
  state.tasksList.teamTasks.filters;

export const selectTeamTasksSorting = (state: RootState) =>
  state.tasksList.teamTasks.sorting;

export const selectCanFetchMoreTeamTasks = (state: RootState) =>
  !!state.tasksList.teamTasks.links.next;

export const selectTeamTasksTotalCount = (state: RootState) =>
  state.tasksList.teamTasks.totalCount;

export const { setFilters, setSorting, setSearchFilter } =
  teamTasksSlice.actions;

export default teamTasksSlice.reducer;
