import {
  useCallback,
  useMemo,
  useRef,
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect,
} from 'react';
import ReactDOM from 'react-dom';
import { store } from 'state/store';
import { useSelector, useDispatch } from 'react-redux';
import {
  ScheduleComponent,
  Inject,
  ResourcesDirective,
  ResourceDirective,
  ViewsDirective,
  ViewDirective,
  TimelineViews,
  PopupCloseEventArgs,
  ActionEventArgs,
  CellClickEventArgs,
  PopupOpenEventArgs,
  EventRenderedArgs,
  ResourceDetails,
  RenderCellEventArgs,
  Month,
  TimelineMonth,
} from '@syncfusion/ej2-react-schedule';
import intl from 'react-intl-universal';
import isEmpty from 'lodash/isEmpty';
import { Typography, Tag } from '@getsynapse/design-system';
import {
  L10n,
  Internationalization,
  createElement,
} from '@syncfusion/ej2-base';
import { EventData, FetchedEvent } from 'utils/types/learningSchedule';
import {
  selectScheduleEvents,
  selectScheduleResources,
  createEvent,
  fetchFacilitators,
  editEvent,
  fetchEvents,
  selectEventsSearchTerm,
  deleteEvent,
  createException,
  fetchFacilitatorsEvents,
  selectFacilitatorsEvents,
  selectFacilitatorsResources,
  selectFacilitatorsViewSearchTerm,
  updateEventsCurrentView,
  updateFacilitatorsCurrentView,
  selectEventsCurrentView,
  selectFacilitatorsCurrentView,
} from 'state/Schedule/ScheduleSlice';
import {
  serializeCreateEvent,
  serializeUpdateEvent,
  serializeCreateException,
  getEventTypeIdFromCode,
  attachListenersToSchedule,
  getEventData,
  getEventTypeValueFromCode,
} from '../../helpers/helpers';
import DetailsSection from './EventModal/DetailsSection/DetailsSection';
import FacilitatorsSection from './EventModal/FacilitatorsSection/FacilitatorsSection';
import {
  headerTemplate,
  ContentTemplate,
} from '../../components/EventQuickView/EventQuickView';
import 'Pages/LearningSchedule/components/Events/styles.css';
import isEqual from 'lodash/isEqual';
import { Provider } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { showNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import {
  fetchFilterSettingByType,
  selectFiltersSettingsByType,
} from 'state/Settings/Filters/FiltersSlice';
import get from 'lodash/get';
import { SETTINGS_FILTERS, USER_STATUS } from 'utils/constants';
import {
  UNASSIGNED_FACILITATOR,
  SCHEDULE_TABS,
} from 'utils/constants/learningSchedule';
import { colors } from 'Pages/LearningSchedule/constants/constants';

export interface EventsScheduleRef {
  onAddingEvent: () => void;
}

const ScheduleCalendar = forwardRef(
  ({ pageToLoad }: { pageToLoad: string }, ref) => {
    L10n.load({
      'en-US': {
        schedule: {
          newEvent: intl.get('SCHEDULE.ADD_NEW_EVENT'),
        },
      },
    });
    const dispatch = useDispatch() as typeof store.dispatch;
    const isEventsPage = useMemo(
      () => pageToLoad === SCHEDULE_TABS.EVENTS,
      [pageToLoad]
    );
    const events = useSelector(selectScheduleEvents);
    const defaultResources = useSelector(selectScheduleResources);
    const facilitatorsResources = useSelector(selectFacilitatorsResources);
    const facilitatorsEvents = useSelector(selectFacilitatorsEvents);
    const filtersSettings = useSelector(
      selectFiltersSettingsByType(SETTINGS_FILTERS.SCHEDULE_EVENTS_VIEW)
    );
    const facilitatorsFilterSettings = useSelector(
      selectFiltersSettingsByType(SETTINGS_FILTERS.SCHEDULE_FACILITATORS_VIEW)
    );
    const saveButtonRef = useRef<HTMLButtonElement | null>(null);
    const scheduleObj = useRef<any>(null);
    const search = useSelector(selectEventsSearchTerm);
    const facilitattorsViewSearch = useSelector(
      selectFacilitatorsViewSearchTerm
    );
    const currentView = useSelector(
      isEventsPage ? selectEventsCurrentView : selectFacilitatorsCurrentView
    );

    const [projectIds, setProjectIds] = useState<string[]>([]);
    const [eventTitle, setEventTitle] = useState<string>('');
    const [eventType, setEventType] = useState<string>('');
    const [initialEvent, setInitialEvent] = useState<FetchedEvent | null>(null);
    const [updatedEvent, setUpdatedEvent] = useState<FetchedEvent | null>(null);
    const [isEditingSeriesWithExceptions, setIsEditingSeriesWithExceptions] =
      useState<boolean>(false);
    const [selectedFacilitatorsIds, setSelectedFacilitatorsIds] = useState<
      string[]
    >([]);
    const [eventsFiltersAreFetched, setEventsFiltersAreFetched] =
      useState<boolean>(false);
    const [facilitatorsFiltersAreFetched, setFacilitatorsFiltersAreFetched] =
      useState<boolean>(false);
    const [eventsColors, setEventsColors] = useState<{ [key: string]: string }>(
      {}
    );
    const [colorIndex, setColorIndex] = useState<number>(0);

    const deactivatedFacilitatorGroupIndexesSet = useMemo(() => {
      const deactivatedFacilitatorGroupIndexes = new Set<number>();
      facilitatorsResources.forEach((resource, index) => {
        if (
          resource.Status === USER_STATUS.REGISTERED_DISABLED ||
          resource.Status === USER_STATUS.INVITED_DISABLED
        ) {
          deactivatedFacilitatorGroupIndexes.add(index);
        }
      });
      return deactivatedFacilitatorGroupIndexes;
    }, [facilitatorsResources]);

    const eventsDataSource = useMemo(
      () => cloneDeep(isEventsPage ? events : facilitatorsEvents),
      [events, facilitatorsEvents, isEventsPage]
    );

    const groupSource = useMemo(
      () => cloneDeep(isEventsPage ? defaultResources : facilitatorsResources),
      [facilitatorsResources, defaultResources, isEventsPage]
    );
    useEffect(() => {
      const fetchEventsSettings = async () => {
        await dispatch(
          fetchFilterSettingByType(SETTINGS_FILTERS.SCHEDULE_EVENTS_VIEW)
        );
        setEventsFiltersAreFetched(true);
      };

      if (!eventsFiltersAreFetched && pageToLoad === SCHEDULE_TABS.EVENTS) {
        fetchEventsSettings();
      }
    }, [dispatch, eventsFiltersAreFetched, pageToLoad]);

    useEffect(() => {
      const fetchFacilitatorsSettings = async () => {
        await dispatch(
          fetchFilterSettingByType(SETTINGS_FILTERS.SCHEDULE_FACILITATORS_VIEW)
        );
        setFacilitatorsFiltersAreFetched(true);
      };

      if (
        !facilitatorsFiltersAreFetched &&
        pageToLoad === SCHEDULE_TABS.FACILITATORS
      ) {
        fetchFacilitatorsSettings();
      }
    }, [dispatch, facilitatorsFiltersAreFetched, pageToLoad]);

    const renderNoRecords = useCallback(() => {
      if (
        !scheduleObj.current?.element ||
        !scheduleObj.current?.element.childNodes ||
        !scheduleObj.current?.element.childNodes?.length
      ) {
        return;
      }
      const noRecordsDiv = document.createElement('div');
      noRecordsDiv.textContent = intl.get('NO_RECORDS');

      scheduleObj.current?.element.childNodes[2].replaceWith(noRecordsDiv);
      for (let i = 0; i <= 2; i++) {
        (
          scheduleObj.current?.element.childNodes[0].childNodes[0].childNodes[0]
            .childNodes[0].childNodes[i].childNodes[0] as HTMLButtonElement
        ).disabled = true;
      }

      if (noRecordsDiv.style) {
        noRecordsDiv.style.display = 'flex';
        noRecordsDiv.style.justifyContent = 'center';
        noRecordsDiv.style.alignItems = 'center';
        noRecordsDiv.style.height = '100%';
        noRecordsDiv.style.fontSize = '0.875rem';
      }
    }, [scheduleObj]);

    const filters = useMemo(() => {
      if (!eventsFiltersAreFetched || !filtersSettings?.settings) return null;

      const { facilitators = [], ...restSettings } = filtersSettings?.settings;

      let unassignedSchedules = false;
      let updatedFacilitators = facilitators;

      if (facilitators.includes(UNASSIGNED_FACILITATOR)) {
        updatedFacilitators = facilitators.filter(
          (facilitator: string) => facilitator !== UNASSIGNED_FACILITATOR
        );
        unassignedSchedules = true;
      }

      return JSON.stringify({
        ...restSettings,
        facilitators: updatedFacilitators,
        unassignedSchedules,
      });
    }, [eventsFiltersAreFetched, filtersSettings]);

    const facilitatorsFilters = useMemo(() => {
      if (!facilitatorsFiltersAreFetched || !facilitatorsFilterSettings)
        return null;

      return JSON.stringify(facilitatorsFilterSettings.settings);
    }, [facilitatorsFiltersAreFetched, facilitatorsFilterSettings]);

    useEffect(() => {
      async function fetchEventsTabData() {
        const data = await dispatch(
          fetchEvents({
            search,
            filters: filters ? JSON.parse(filters! as string) : null,
          })
        );
        const matchedProjects = get(
          data,
          'payload.projectsMatchingSearchParams'
        );
        if (isEmpty(matchedProjects)) {
          renderNoRecords();
        } else {
          scheduleObj.current?.refresh();
        }
      }
      if (pageToLoad === SCHEDULE_TABS.EVENTS && eventsFiltersAreFetched) {
        fetchEventsTabData();
      }
    }, [
      dispatch,
      search,
      filters,
      eventsFiltersAreFetched,
      pageToLoad,
      scheduleObj,
      renderNoRecords,
    ]);

    useEffect(() => {
      async function fetchFacilitatorsTabData() {
        const data = await dispatch(
          fetchFacilitatorsEvents({
            search: facilitattorsViewSearch,
            filters: facilitatorsFilters
              ? JSON.parse(facilitatorsFilters! as string)
              : null,
          })
        );
        const matchedFacilitators = get(
          data,
          'payload.facilitatorsMatchingSearchParams'
        );
        if (isEmpty(matchedFacilitators)) {
          renderNoRecords();
        } else {
          scheduleObj.current?.refresh();
        }
      }
      if (
        pageToLoad === SCHEDULE_TABS.FACILITATORS &&
        facilitatorsFiltersAreFetched
      ) {
        fetchFacilitatorsTabData();
      }
    }, [
      dispatch,
      pageToLoad,
      facilitattorsViewSearch,
      facilitatorsFiltersAreFetched,
      scheduleObj,
      renderNoRecords,
      facilitatorsFilters,
    ]);

    useEffect(() => {
      if (
        updatedEvent &&
        initialEvent &&
        saveButtonRef &&
        saveButtonRef.current
      ) {
        if (
          isEqual(updatedEvent, initialEvent) ||
          !updatedEvent.projectIds.length ||
          !updatedEvent.subject ||
          !updatedEvent.learningEventType
        ) {
          saveButtonRef.current.disabled = true;
        } else {
          saveButtonRef.current.disabled = false;
        }
      }
    }, [updatedEvent, initialEvent]);

    const onUpdateEventField = (key: string, value: any) => {
      setUpdatedEvent((prev) => {
        if (prev && prev.hasOwnProperty(key)) {
          return {
            ...prev,
            [key]: value,
          } as FetchedEvent;
        } else {
          return prev;
        }
      });
    };

    useEffect(() => {
      if (updatedEvent || initialEvent) {
        return;
      }
      if (projectIds.length && eventTitle && eventType) {
        if (saveButtonRef && saveButtonRef.current) {
          saveButtonRef.current.disabled = false;
        }
      } else {
        if (saveButtonRef && saveButtonRef.current) {
          saveButtonRef.current.disabled = true;
        }
      }
    }, [
      projectIds,
      eventTitle,
      eventType,
      saveButtonRef,
      updatedEvent,
      initialEvent,
    ]);

    const intlInstance = useMemo(() => {
      return new Internationalization();
    }, []);

    const closeQuickInfo = useCallback(() => {
      if (
        scheduleObj &&
        scheduleObj.current &&
        scheduleObj.current.closeQuickInfoPopup
      ) {
        scheduleObj.current.closeQuickInfoPopup();
      }
    }, [scheduleObj]);

    const formatHeader = useCallback(
      (args: { date: Date }) => {
        const formattedDate = intlInstance.formatDate(args?.date, {
          skeleton: 'Ed',
        });
        return <span>{formattedDate}</span>;
      },
      [intlInstance]
    );

    const onAddingEvent = (args?: CellClickEventArgs) => {
      let props;
      if (!args) {
        props = {
          startTime: new Date(),
          endTime: new Date(new Date().setHours(new Date().getHours() + 1)),
          isAllDay: true,
        };
      } else {
        props = args;
      }

      scheduleObj?.current?.openEditor(props, 'Add');
    };

    useImperativeHandle(ref, () => ({
      onAddingEvent,
    }));

    const oncancel = (args: PopupCloseEventArgs) => {
      if (args.type === 'Editor') {
        setUpdatedEvent(null);
        setInitialEvent(null);
        if (args.element.querySelector('.details-section')) {
          const detailsSection = args.element.querySelector('.details-section');
          detailsSection?.remove();
          const facilitatorsSection = args.element.querySelector(
            '.facilitators-section'
          );
          facilitatorsSection?.remove();
        }
      }
    };

    const resetState = () => {
      setProjectIds([]);
      setEventTitle('');
      setEventType('');
      setSelectedFacilitatorsIds([]);
      setIsEditingSeriesWithExceptions(false);
    };

    useEffect(() => {
      dispatch(fetchFacilitators());
    }, [dispatch]);

    const dispatchErrorNotification = (notificationTitle: string) => {
      dispatch(
        showNotification({
          notificationVariant: 'error',
          notificationTitle,
          notificationMessage: intl.get(
            'SCHEDULE.EVENTS.ERROR_NOTIFICATION_MESSAGE'
          ),
        })
      );
    };

    const actionBegin = async (args: ActionEventArgs) => {
      if (args.requestType === 'eventChange' && args?.changedRecords?.length) {
        try {
          if (args?.data?.hasOwnProperty('occurrence')) {
            let modifiedEventType: string = isNaN(Number(eventType))
              ? getEventTypeValueFromCode(eventType)
              : eventType;
            const data = args.changedRecords[0] as EventData;
            const parent = get(args, 'data.parent', null);

            if (parent) {
              let actionDispatched = false;
              const t = setInterval(async () => {
                if (parent.RecurrenceException) {
                  const exceptionEvent = getEventData({
                    projectIds,
                    eventTitle,
                    eventType: modifiedEventType,
                    data,
                    facilitators: selectedFacilitatorsIds,
                  });
                  const serializedException = serializeCreateException(
                    exceptionEvent,
                    parent.Id,
                    parent.RecurrenceException
                  );
                  try {
                    if (!actionDispatched) {
                      actionDispatched = true;
                      await dispatch(
                        createException({
                          scheduleId: parent.Id,
                          data: serializedException,
                        })
                      );
                    }
                  } catch {
                    dispatchErrorNotification(
                      intl.get('SCHEDULE.EVENTS.EDIT_ERROR_NOTIFICATION_TITLE')
                    );
                  }
                  clearInterval(t);
                }
              }, 10);
            }
          } else {
            const data = args.changedRecords[0] as EventData;
            let shouldResetExceptions: boolean = false;
            if (data) {
              let actionDispatched = false;
              const t = setInterval(async () => {
                if (
                  isEditingSeriesWithExceptions &&
                  !data.RecurrenceException
                ) {
                  shouldResetExceptions = true;
                }
                let modifiedEventType: string = isNaN(Number(eventType))
                  ? getEventTypeValueFromCode(eventType)
                  : eventType;
                const editedEvent = getEventData({
                  projectIds,
                  eventTitle,
                  eventType: modifiedEventType,
                  data,
                  facilitators: selectedFacilitatorsIds,
                });
                const editEventParams = serializeUpdateEvent(
                  editedEvent,
                  shouldResetExceptions
                );
                try {
                  if (!actionDispatched) {
                    actionDispatched = true;
                    await dispatch(editEvent(editEventParams));
                  }
                } catch {
                  dispatchErrorNotification(
                    intl.get('SCHEDULE.EVENTS.EDIT_ERROR_NOTIFICATION_TITLE')
                  );
                }
                clearInterval(t);
              }, 10);
            }
          }

          dispatch(
            showNotification({
              notificationVariant: 'success',
              notificationTitle: intl.get('SCHEDULE.EVENTS.EDIT_SUCCESS'),
              autoHide: false,
            })
          );
        } catch {
          dispatchErrorNotification(
            intl.get('SCHEDULE.EVENTS.EDIT_ERROR_NOTIFICATION_TITLE')
          );
        }
      } else if (args.requestType === 'eventCreate') {
        if (args.data && args.data.length) {
          try {
            const data = args.data as EventData[];
            const newEvent = {
              StartTime: data[0].StartTime,
              EndTime: data[0].EndTime,
              RecurrenceRule: data[0].RecurrenceRule,
              Id: data[0].Id,
              ProjectId: projectIds,
              FacilitatorId: selectedFacilitatorsIds,
              Subject: eventTitle,
              EventType: eventType,
              IsAllDay: data[0].IsAllDay,
            } as EventData;
            args.cancel = true;
            const createEventParams = serializeCreateEvent(newEvent);
            await dispatch(createEvent(createEventParams));
            dispatch(
              showNotification({
                notificationVariant: 'success',
                notificationTitle: intl.get('SCHEDULE.EVENTS.CREATE_SUCCESS'),
                autoHide: false,
              })
            );
          } catch {
            dispatchErrorNotification(
              intl.get('SCHEDULE.EVENTS.CREATE_ERROR_NOTIFICATION_TITLE')
            );
          }
        }
      } else if (args.requestType === 'eventRemove') {
        try {
          const data = args.data;
          const changedRecords = args.changedRecords;
          if (Array.isArray(data) && data.length > 0) {
            const parent = data[0].parent;
            const occurrence = data[0].occurrence;
            if (parent && occurrence && parent.Id === occurrence.Id) {
              let actionDispatched = false;
              const t = setInterval(async () => {
                if (parent.RecurrenceException) {
                  args.cancel = true;
                  const modifiedEventType: string = getEventTypeValueFromCode(
                    parent.EventType
                  );
                  const editEventParams = serializeUpdateEvent({
                    ...parent,
                    EventType: modifiedEventType,
                    Facilitators: [],
                  });
                  try {
                    if (!actionDispatched) {
                      actionDispatched = true;
                      await dispatch(editEvent(editEventParams));
                    }
                  } catch {
                    dispatchErrorNotification(
                      intl.get('SCHEDULE.EVENTS.EDIT_ERROR_NOTIFICATION_TITLE')
                    );
                  }
                  clearInterval(t);
                }
              }, 10);
            } else {
              if (changedRecords && changedRecords.length) {
                await dispatch(deleteEvent(changedRecords[0].Id));
              } else {
                await dispatch(deleteEvent(data[0].Id));
              }
            }
          }
          dispatch(
            showNotification({
              notificationVariant: 'success',
              notificationTitle: intl.get('SCHEDULE.EVENTS.DELETE_SUCCESS'),
              autoHide: false,
            })
          );
        } catch {
          dispatchErrorNotification(
            intl.get('SCHEDULE.EVENTS.DELETE_ERROR_NOTIFICATION_TITLE')
          );
        }
      }
    };

    const onPopupOpen = (args: PopupOpenEventArgs) => {
      let existingEventData: FetchedEvent | undefined;
      let initialProjects: string[] =
        args.data?.ProjectId && args.target ? [args.data?.ProjectId] : [];
      let projectsData = [];
      let initialFacilitators: { id: string; name: string }[] = [];
      if (args.type === 'RecurrenceValidationAlert') {
        setIsEditingSeriesWithExceptions(true);
      }
      if (args.type === 'Editor') {
        resetState();

        if (args.data?.FacilitatorId && args.target) {
          const facilitatorData = facilitatorsResources.find(
            (f) => f.FacilitatorId === args.data?.FacilitatorId
          );
          if (facilitatorData) {
            initialFacilitators = [
              { id: facilitatorData.FacilitatorId, name: facilitatorData.Name },
            ];
          }
        }
        if (args.data?.Id) {
          if (args.data?.Subject) {
            const modalTitle = args.element.querySelector('.e-title-text');
            if (modalTitle) {
              modalTitle.textContent = intl.get('SCHEDULE.EDIT_EVENT_TITLE', {
                eventTitle: args.data.Subject,
              });
            }
          }

          existingEventData = {
            id: args.data.Id,
            subject: args.data?.Subject || '',
            startTime: args.data?.StartTime,
            endTime: args.data?.EndTime,
            projectIds: args.data?.ProjectId,
            learningEventType: getEventTypeIdFromCode(
              args.data?.EventType ?? ''
            ),
            isAllDay: args.data?.IsAllDay,
            recurrenceRule: args.data?.RecurrenceRule,
            recurrenceException: args.data?.RecurrenceException,
            projects: args.data?.projects || [],
            facilitators: args.data?.facilitators || [],
            facilitatorIds: (args.data?.facilitators ?? [])
              .map(
                (facilitator: { id: string; name: string }) => facilitator.id
              )
              .sort(),
          };
          initialFacilitators = args.data?.facilitators || [];
          initialProjects = args.data?.ProjectId;
          setInitialEvent(existingEventData);
          setUpdatedEvent(existingEventData);
          setEventTitle(existingEventData?.subject || '');
          setEventType(existingEventData?.learningEventType || '');
          projectsData = args.data?.projects || [];
        }
        setProjectIds(initialProjects);
        attachListenersToSchedule(args, onUpdateEventField);

        if (!args.element.querySelector('.details-section')) {
          if (scheduleObj) {
            if (scheduleObj?.current?.eventWindow?.recurrenceEditor) {
              scheduleObj.current.eventWindow.recurrenceEditor.frequencies = [
                'none',
                'daily',
                'weekly',
                'monthly',
              ];
            }
          }
          const row = createElement('div', { className: 'details-section' });
          const fRow = createElement('div', {
            className: 'facilitators-section',
          });

          const saveButton = args.element.querySelector('.e-event-save');
          if (saveButton instanceof HTMLButtonElement) {
            saveButtonRef.current = saveButton;
          }

          const formElement = args.element.querySelector('.e-schedule-form');

          formElement?.firstChild?.insertBefore(
            row,
            formElement.firstChild.firstChild
          );

          formElement?.firstChild?.insertBefore(
            fRow,
            formElement.firstChild.lastChild
          );

          const detailsContainer = createElement('div', {
            className: 'custom-field-container',
          });
          const facilitatorsContainer = createElement('div', {
            className: 'custom-field-facilitators-container',
          });

          row.appendChild(detailsContainer);
          fRow.appendChild(facilitatorsContainer);

          ReactDOM.render(
            <Provider store={store}>
              <DetailsSection
                existingEventData={existingEventData}
                projects={projectsData}
                changeEventData={(value, name) => {
                  switch (name) {
                    case 'eventTitle':
                      setEventTitle(value);
                      onUpdateEventField('subject', value);
                      break;
                    case 'projectIds':
                      setProjectIds(value);
                      onUpdateEventField('projectIds', value);
                      break;
                    case 'learningEventTypeId':
                      setEventType(value);
                      onUpdateEventField('learningEventType', value);
                      break;
                    default:
                      break;
                  }
                }}
                projectIds={initialProjects}
              />
            </Provider>,
            detailsContainer
          );

          ReactDOM.render(
            <Provider store={store}>
              <Typography variant='body2' className='mt-4 mb-4'>
                {intl.get('SCHEDULE.EVENTS.FACILITATORS_SECTION.TITLE')}
              </Typography>
              <FacilitatorsSection
                existingFacilitators={initialFacilitators}
                updateFacilitatorsIds={(selectedIds) => {
                  setSelectedFacilitatorsIds(selectedIds);
                  onUpdateEventField('facilitatorIds', selectedIds.sort());
                }}
              />
            </Provider>,
            facilitatorsContainer
          );
        }
      } else if (args.type === 'RecurrenceAlert') {
        const dialogHeader = args.element.querySelector('#QuickDialog_title');
        const dialogContent = args.element.querySelector(
          '#QuickDialog_dialog-content'
        );
        const seriesButton = args.element.querySelector(
          'button.e-quick-dialog-series-event'
        );
        if (dialogContent && seriesButton) {
          if (dialogHeader?.textContent === 'Delete Event') {
            dialogContent.textContent = intl.get(
              'SCHEDULE.EVENTS.DELETE_RECURRING_EVENT_MESSAGE'
            );
            seriesButton.textContent = intl.get(
              'SCHEDULE.EVENTS.DELETE_SERIES_BUTTON'
            );
          } else if (dialogHeader?.textContent === 'Edit Event') {
            dialogContent.textContent = intl.get(
              'SCHEDULE.EVENTS.EDIT_RECURRING_EVENT_MESSAGE'
            );
            seriesButton.textContent = intl.get(
              'SCHEDULE.EVENTS.EDIT_SERIES_BUTTON'
            );
          }
        }
      }
    };

    const applyCategoryColor = (args: EventRenderedArgs) => {
      if (!args.element) return;

      const { Id } = args.data;

      if (eventsColors[Id]) {
        args.element.style.backgroundColor = eventsColors[Id];
        return;
      }

      const categoryColor = colors[colorIndex];
      args.element.style.backgroundColor = categoryColor;

      setEventsColors((prev) => {
        return {
          ...prev,
          [args.data.Id]: categoryColor,
        };
      });
      setColorIndex((prev) => (prev + 1) % colors.length);
    };

    const onCellClick = (args: CellClickEventArgs) => {
      args.cancel = true;
    };

    const onRenderCell = useCallback(
      (args: RenderCellEventArgs) => {
        if (
          args.groupIndex !== undefined &&
          !isEventsPage &&
          deactivatedFacilitatorGroupIndexesSet.has(args.groupIndex)
        ) {
          args.element.classList.add('e-disable');
        }
      },
      [deactivatedFacilitatorGroupIndexesSet, isEventsPage]
    );

    const resourceHeaderTemplate = useCallback(
      (props: ResourceDetails): JSX.Element => {
        if (isEventsPage) {
          return (
            <div className='text-sm truncate'>{props.resourceData.Title}</div>
          );
        } else {
          const status = props.resourceData?.Status;
          if (status && status !== USER_STATUS.REGISTERED) {
            const isPending = status === USER_STATUS.INVITED;
            return (
              <div className='flex flex-col py-1'>
                <div className='text-sm truncate'>
                  {props.resourceData.Name}
                </div>
                <Tag
                  label={intl.get(
                    `USERS_PAGE.TABLE.USER_STATUS.${status.toUpperCase()}`
                  )}
                  className={`border mt-0.5 border-transparent ${
                    isPending
                      ? 'bg-warning-lightest group-hover:border-warning-light'
                      : 'bg-neutral-lighter-two group-hover:border-neutral'
                  }`}
                  textClassName={
                    isPending ? 'text-warning-darker' : 'text-neutral-darker'
                  }
                />
              </div>
            );
          } else {
            return <div className='text-sm'>{props.resourceData.Name}</div>;
          }
        }
      },
      [isEventsPage]
    );

    const getCurrentView = () => {
      if (scheduleObj?.current?.currentView) {
        if (isEventsPage) {
          dispatch(
            updateEventsCurrentView({
              currentView: scheduleObj.current.currentView,
            })
          );
        } else {
          dispatch(
            updateFacilitatorsCurrentView({
              currentView: scheduleObj.current.currentView,
            })
          );
        }
      }
    };

    return (
      <div className='h-schedule overflow-y-auto' data-testid='schedule_div'>
        <ScheduleComponent
          height='100%'
          ref={scheduleObj}
          eventSettings={{
            dataSource: eventsDataSource,
          }}
          actionBegin={actionBegin}
          actionFailure={actionBegin}
          popupClose={oncancel}
          currentView={currentView}
          showTimeIndicator={false}
          cellClick={(args) => onCellClick(args)}
          rowAutoHeight={true}
          timeScale={{
            enable: false,
          }}
          renderCell={onRenderCell}
          dateHeaderTemplate={formatHeader}
          popupOpen={onPopupOpen}
          quickInfoTemplates={{
            header: headerTemplate(closeQuickInfo),
            content: ContentTemplate,
          }}
          eventRendered={applyCategoryColor}
          resourceHeaderTemplate={resourceHeaderTemplate}
          dataBound={getCurrentView}
        >
          <ViewsDirective>
            <ViewDirective
              option='TimelineWorkWeek'
              group={{
                resources: [isEventsPage ? 'projects' : 'facilitators'],
              }}
              displayName='Week'
            />
            <ViewDirective
              option={isEventsPage ? 'Month' : 'TimelineMonth'}
              showWeekend={false}
              {...(!isEventsPage && {
                group: {
                  resources: ['facilitators'],
                },
              })}
              displayName='Month'
            />
          </ViewsDirective>
          <Inject
            services={
              isEventsPage
                ? [TimelineViews, Month]
                : [TimelineViews, TimelineMonth]
            }
          />
          <ResourcesDirective>
            <ResourceDirective
              name={isEventsPage ? 'projects' : 'facilitators'}
              dataSource={groupSource}
              textField={isEventsPage ? 'Title' : 'Name'}
              field={isEventsPage ? 'ProjectId' : 'FacilitatorId'}
              idField={isEventsPage ? 'ProjectId' : 'FacilitatorId'}
            />
          </ResourcesDirective>
        </ScheduleComponent>
      </div>
    );
  }
);

export default ScheduleCalendar;
