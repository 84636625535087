import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  LabelList,
  Text,
  CartesianGrid,
} from 'recharts';
import intl from 'react-intl-universal';
import { RechartsCustomRendererTickProps } from 'Pages/InsightsPage/rechartTypeShim';

export interface HighestInstructorsChartData {
  name: string;
  score: string;
}

const CustomLeftTickLabel = (props: RechartsCustomRendererTickProps) => {
  const { x, y, payload } = props;
  return (
    <Text
      x={x}
      y={y}
      fontSize={12}
      fontWeight={600}
      textAnchor='end'
      dominantBaseline={'middle'}
    >
      {payload.value}
    </Text>
  );
};

const CustomRightTickLabel = (props: RechartsCustomRendererTickProps) => {
  const { x, y, payload } = props;
  return (
    <Text x={x + 64} y={y} fontSize={12} fontWeight={600} textAnchor='end'>
      {payload.value}
    </Text>
  );
};

export const HighestInstructorsChart = ({
  data,
}: {
  data: HighestInstructorsChartData[];
}) => {
  return (
    <ResponsiveContainer width='100%' height='80%'>
      <BarChart
        data={data}
        layout='vertical'
        margin={{
          left: 5,
        }}
      >
        <XAxis
          tickLine={false}
          type='number'
          hide
          padding={{ left: 20 }}
          dataKey='score'
          style={{
            fontSize: '0.75em',
            fontWeight: '400',
            fill: '#000',
            lineHeight: '1em',
          }}
        />
        <YAxis
          tickLine={false}
          yAxisId='left'
          tick={(props) => <CustomLeftTickLabel {...props} />}
          style={{
            fontSize: '0.75em',
            fontWeight: '400',
            fill: '#000',
            lineHeight: '1em',
          }}
          width={100}
          dataKey='name'
          type='category'
          orientation='left'
          axisLine={false}
        />

        <YAxis
          tickLine={false}
          yAxisId='right'
          tick={(props) => <CustomRightTickLabel {...props} />}
          width={84}
          style={{
            fontSize: '0.75em',
            fontWeight: '400',
            fill: '#000',
            lineHeight: '1em',
          }}
          dataKey='score'
          type='category'
          orientation='right'
          axisLine={false}
        />

        <CartesianGrid horizontal={false} />
        <Bar
          dataKey='score'
          name={intl.get('INSIGHTS_PAGE_TABS.NUMBER_OF_REQUESTS')}
          maxBarSize={36}
          yAxisId='left'
          fill='#8B2C56'
        />
        <LabelList dataKey='score' position='right' />
      </BarChart>
    </ResponsiveContainer>
  );
};
