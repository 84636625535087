import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import moment from 'moment';

import { Evaluation, EvaluationForTable } from 'utils/types/evaluations';
import { addInstructors, instructorsSelectors } from './instructorsSlice';
import evaluationsAPI from './evaluationsAPI';
import { RootState } from 'state/store';
import { Pagination, Status } from 'utils/customTypes';
import { DATE, SLICE_STATUS } from 'utils/constants';
import { DEFAULT_OFFSET, DEFAULT_PAGE_SIZE } from 'utils/constants/ui';

/* ============================== STATE SETUP =============================== */
interface EvaluationsState {
  status: Status;
  limit: number;
  offset: number;
  total: number;
}

const initialState: EvaluationsState = {
  status: SLICE_STATUS.IDLE,
  limit: DEFAULT_PAGE_SIZE,
  offset: DEFAULT_OFFSET,
  total: 0,
};

const evaluationsAdapter = createEntityAdapter<Evaluation>({
  sortComparer: (a, b) => {
    const dateA = new Date(a.date).getTime();
    const dateB = new Date(b.date).getTime();

    if (dateA < dateB) return -1;
    if (dateA > dateB) return 1;
    return 0;
  },
});

/* ============================== REDUX THUNK =============================== */
export const fetchEvaluations = createAsyncThunk(
  'evaluations/fetchEvaluations',
  async ({ limit, offset }: Pagination, { dispatch }) => {
    const { evaluations, total } = await evaluationsAPI.fetchEvaluations({
      limit,
      offset,
      afterDate: moment().startOf('year').format(DATE.DATE_STAMP),
      beforeDate: moment().endOf('year').add(1, 'd').format(DATE.DATE_STAMP),
    });

    const evaluationInstructors = evaluations.flatMap(
      (evaluation) => evaluation.instructors
    );
    dispatch(addInstructors(evaluationInstructors));

    const normalizedEvaluations = evaluations.map(
      (evaluation): Evaluation => ({
        ...evaluation,
        instructors: evaluation.instructors.map((instructor) => instructor.id),
      })
    );
    return { evaluations: normalizedEvaluations, total };
  },
  {
    condition: (_, { getState }) => {
      const { status } = (getState() as RootState).evaluations;

      return status !== SLICE_STATUS.LOADING;
    },
  }
);

export const getEvaluationRedirectionLink = createAsyncThunk(
  'evaluations/getEvaluationRedirectionLink',
  async (evaluationId?: string): Promise<string> => {
    const { redirectUrl } = await evaluationsAPI.getPerformitivRedirectionLink(
      evaluationId
    );
    return redirectUrl;
  }
);

/* ================================= REDUCER ================================ */
const evaluationsSlice = createSlice({
  name: 'evaluations',
  initialState: evaluationsAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEvaluations.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchEvaluations.fulfilled, (state, action) => {
        evaluationsAdapter.upsertMany(state, action.payload.evaluations);
        state.ids = action.payload.evaluations.map(
          (evaluation) => evaluation.id
        );
        state.total = action.payload.total;
        state.limit = action.meta.arg.limit;
        state.offset = action.meta.arg.offset;
        state.status = SLICE_STATUS.SUCCESS;
      })
      .addCase(fetchEvaluations.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      });
  },
});

/* =============================== SELECTORS ================================ */
const defaultSelectors = evaluationsAdapter.getSelectors<RootState>(
  (state) => state.evaluations
);

const selectStatus = (state: RootState) => state.evaluations.status;

const selectPage = (state: RootState) => state.evaluations.offset + 1;

const selectTotalCount = (state: RootState) => state.evaluations.total;

const selectEvaluationsForTable = createSelector(
  defaultSelectors.selectIds,
  defaultSelectors.selectEntities,
  instructorsSelectors.selectEntities,
  (ids, entities, instructors): EvaluationForTable[] =>
    ids.map((evaluationId): EvaluationForTable => {
      const evaluation = entities[evaluationId];
      if (!evaluation) {
        throw new Error('Evaluation not found in entities');
      }

      return {
        ...evaluation,
        date: moment(new Date(evaluation.date)).format(DATE.SHORT_FORMAT),
        score: evaluation.hasData ? evaluation.score : '-',
        instructors:
          evaluation.instructors
            .reduce((acc, id) => {
              const instructor = instructors[id];
              if (instructor) {
                acc.push(instructor.fullName);
              }
              return acc;
            }, [] as string[])
            .join(', ') || '-',
      };
    })
);

export const evaluationsSelectors = {
  ...defaultSelectors,
  selectStatus,
  selectPage,
  selectTotalCount,
  selectEvaluationsForTable,
};

export default evaluationsSlice.reducer;
