import { api } from './api';
import axios from 'axios';

export interface FetchProjectParticipantsListParams {
  projectId: string;
  name?: string;
}

export interface ProjectParticipantsListElement {
  id: string;
  name: string;
  email: string;
  avatarUrl: string;
  disabled?: boolean;
}

export interface FetchProjectParticipantsListResponse {
  participants: ProjectParticipantsListElement[];
}

export const fetchProjectParticipantsList = async (
  params: FetchProjectParticipantsListParams
) => {
  let url = `/v2/projects/${params.projectId}/participants`;
  if (params.name) {
    url = `${url}?name=${encodeURIComponent(params.name)}`;
  }
  const response = await api.get<FetchProjectParticipantsListResponse>(url);
  if (axios.isAxiosError(response)) {
    throw response;
  }
  return response.data;
};
