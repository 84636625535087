import { FlatfileResults, FlatfileSettings } from '@flatfile/react';
import intl from 'react-intl-universal';
import { TaskAssignee } from 'types/store/tasks';
import { TASK_FIELDS, TASK_STATUS } from 'utils/constants';
import { NewTask } from 'types/store/newTask';
import moment from 'moment';

export const getImportSettings: (
  assignees: TaskAssignee[],
  taskTypes: Record<string, string>,
  taskStatuses: Record<string, string>
) => FlatfileSettings = (assignees, taskTypes, taskStatuses) => ({
  type: 'Tasks',
  title: intl.get('IMPORT_FROM_FILE', {
    element: intl.get('UPDATE_PROJECT_PAGE_TABS.TASKS').toLowerCase(),
  }),
  fields: [
    {
      label: intl.get('TASKS.TABLE.HEAD.NAME'),
      key: TASK_FIELDS.NAME,
      validators: [{ validate: 'required' }],
    },
    {
      label: intl.get('TASKS.TABLE.HEAD.ASSIGNEE_UPDATE'),
      key: TASK_FIELDS.ASSIGNEE_ADD,
      type: 'select',
      options:
        assignees.length > 0
          ? assignees.map((assignee: TaskAssignee) => ({
              label: assignee.name,
              value: assignee.id,
            }))
          : [],
    },
    {
      label: intl.get('TASKS.TABLE.HEAD.START_DATE'),
      key: TASK_FIELDS.START_DATE,
    },
    {
      label: intl.get('TASKS.TABLE.HEAD.DUE_DATE'),
      key: TASK_FIELDS.DUE_DATE,
    },
    {
      label: intl.get('TASKS.TASK_DETAIL_PAGE.TASK_DESCRIPTION_LABEL'),
      key: TASK_FIELDS.DESCRIPTION,
    },
    {
      label: intl.get('TASKS.TABLE.HEAD.TASK_TYPE'),
      key: TASK_FIELDS.TASK_TYPE,
      type: 'select',
      options: Object.entries(taskTypes).map(([code, name]) => ({
        label: name,
        value: code,
      })),
    },
    {
      label: intl.get('TASKS.TABLE.HEAD.ESTIMATED_HOURS'),
      key: TASK_FIELDS.ESTIMATED_HOURS,
    },
    {
      label: intl.get('TASKS.TABLE.HEAD.STATUS'),
      key: TASK_FIELDS.STATUS,
      type: 'select',
      options: Object.entries(taskStatuses).map(([code, name]) => ({
        label: name,
        value: code,
      })),
    },
    {
      label: intl.get('TASKS.TABLE.HEAD.ACTUAL_HOURS'),
      key: TASK_FIELDS.ACTUAL_HOURS,
    },
    {
      label: intl.get('TASKS.TABLE.HEAD.ACTUAL_DATE'),
      key: TASK_FIELDS.COMPLETION_DATE,
    },
  ],
});

const validateDate = (value: string) => {
  if (!value) {
    return false;
  }
  return moment(new Date(value)).isValid();
};

export const serializeFlatfileResults: (
  projectId: string,
  results: FlatfileResults
) => NewTask[] = (projectId, results) => {
  return results.validData.map((task) => {
    const newTaskDto: NewTask = {
      name: task[TASK_FIELDS.NAME],
      projectId,
    };

    if (task[TASK_FIELDS.DESCRIPTION]) {
      newTaskDto.description = task[TASK_FIELDS.DESCRIPTION];
    }

    if (task[TASK_FIELDS.ASSIGNEE_ADD]) {
      newTaskDto.assignees = [task[TASK_FIELDS.ASSIGNEE_ADD]];
    }

    if (task[TASK_FIELDS.TASK_TYPE]) {
      newTaskDto.type = task[TASK_FIELDS.TASK_TYPE];
    }

    newTaskDto.status = task[TASK_FIELDS.STATUS] ?? TASK_STATUS.NEW;

    if (validateDate(task[TASK_FIELDS.START_DATE])) {
      newTaskDto.startDate = new Date(
        task[TASK_FIELDS.START_DATE]
      ).toLocaleDateString('en-US');
    }

    if (
      newTaskDto.startDate &&
      validateDate(task[TASK_FIELDS.DUE_DATE]) &&
      moment(new Date(task[TASK_FIELDS.DUE_DATE])).isSameOrAfter(
        newTaskDto.startDate
      )
    ) {
      newTaskDto.dueDate = new Date(
        task[TASK_FIELDS.DUE_DATE]
      ).toLocaleDateString('en-US');
    }

    if (task[TASK_FIELDS.ESTIMATED_HOURS]) {
      const estimateHours = parseFloat(task[TASK_FIELDS.ESTIMATED_HOURS]);
      if (!isNaN(estimateHours)) {
        newTaskDto.estimateHours = estimateHours;
      }
    }

    if (newTaskDto.status === TASK_STATUS.COMPLETED) {
      if (validateDate(task[TASK_FIELDS.COMPLETION_DATE])) {
        newTaskDto.actualDate = new Date(
          task[TASK_FIELDS.COMPLETION_DATE]
        ).toLocaleDateString('en-US');
      } else {
        newTaskDto.actualDate = new Date().toLocaleDateString('en-US');
      }

      if (task[TASK_FIELDS.ACTUAL_HOURS]) {
        const actualHours = parseFloat(task[TASK_FIELDS.ACTUAL_HOURS]);
        if (!isNaN(actualHours)) {
          newTaskDto.actualHours = actualHours;
        }
      }
    }

    if (
      newTaskDto.assignees &&
      newTaskDto.assignees.length > 0 &&
      !newTaskDto.startDate
    ) {
      newTaskDto.startDate = new Date().toLocaleDateString('en-US');
      newTaskDto.dueDate = new Date().toLocaleDateString('en-US');
    }

    if (newTaskDto.startDate && !newTaskDto.dueDate) {
      const dueDate = new Date(newTaskDto.startDate);
      dueDate.setDate(dueDate.getDate() + 30);
      newTaskDto.dueDate = new Date(dueDate).toLocaleDateString('en-US');
    }

    return newTaskDto;
  });
};
