import { FC, useCallback, useMemo } from 'react';
import intl from 'react-intl-universal';
import { Typography } from '@getsynapse/design-system';
import useModal from 'Hooks/useModal';

interface DeleteTaskConfirmationProps {
  shouldDisplayModal?: boolean;
  toogleModal: () => void;
  confirmDeleteTask: () => void;
}

const DeleteTaskConfirmation: FC<DeleteTaskConfirmationProps> = ({
  shouldDisplayModal = false,
  toogleModal,
  confirmDeleteTask,
}) => {
  const { Modal, modalProps, closeModal } = useModal();

  const handleCloseModal = useCallback(() => {
    toogleModal();
    closeModal();
  }, [closeModal, toogleModal]);

  const actionButtons = useMemo(
    () => [
      {
        children: intl.get('TASKS.TASK_DETAIL_PAGE.DELETE_TASK_MODAL.SUBMIT'),
        variant: 'primary',
        color: 'danger',
        onClick: confirmDeleteTask,
      },
      {
        children: intl.get('TASKS.TASK_DETAIL_PAGE.DELETE_TASK_MODAL.CANCEL'),
        variant: 'secondary',
        onClick: handleCloseModal,
      },
    ],
    [confirmDeleteTask, handleCloseModal]
  );

  return (
    <Modal
      {...modalProps}
      title={intl.get('TASKS.TASK_DETAIL_PAGE.DELETE_TASK_MODAL.TITLE')}
      aria-label='delete-task-confirmation-modal'
      size='medium'
      isOpen={shouldDisplayModal}
      onClose={handleCloseModal}
      actionButtons={actionButtons}
    >
      <Typography variant='body'>
        {intl.getHTML('TASKS.TASK_DETAIL_PAGE.DELETE_TASK_MODAL.BODY')}
      </Typography>
      <Typography variant='body' className='mt-6'>
        {intl.get('TASKS.TASK_DETAIL_PAGE.DELETE_TASK_MODAL.QUESTION')}
      </Typography>
    </Modal>
  );
};

export default DeleteTaskConfirmation;
