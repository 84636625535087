import intl from 'react-intl-universal';
import { FormItem, TextField, Toggle } from '@getsynapse/design-system';
import { PickerFileMetadata } from 'filestack-js';
import { OrganizationSettings, PortalBackground } from 'utils/customTypes';
import { ChangeEvent } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import AccountSettingsFooter from '../../../../AccountSettings/AccountSettingsFooter';
import {
  selectOrganizationSettings,
  selectOrganizationSliceStatus,
  updateOrganizationSettings,
} from 'state/Organization/organizationSlice';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import ShareableLink from './components/ShareableLink/ShareableLink';
import UploadLogo from './components/UploadLogo/UploadLogo';
import UploadBackground from './components/UploadeBackground/UploadBackground';
import PortalPreview from './components/PortalPreview/PortalPreview';
import { SLICE_STATUS } from 'utils/constants';
import UnsavedChangesPrompt from 'Organisms/UnsavedChangesPrompt/UnsavedChangesPrompt';

const General = () => {
  const dispatch = useDispatch();
  const sliceStatus = useSelector(selectOrganizationSliceStatus);
  const organizationSettings = useSelector(selectOrganizationSettings);
  const [currentSettings, setCurrentSettings] = useState<OrganizationSettings>(
    {}
  );

  useEffect(() => {
    setCurrentSettings(organizationSettings);
  }, [organizationSettings]);

  const isUpdating = useMemo<boolean>(
    () => sliceStatus === SLICE_STATUS.UPDATING,
    [sliceStatus]
  );

  const currentTeamRequestTab = get(currentSettings, 'teamRequestsTab', false);
  const currentPortalName = get(
    currentSettings,
    'intakePortalName',
    intl.get('REQUEST_PORTAL_DEFAULT_NAME')
  );

  const saveDisabled = useMemo<boolean>(
    () => isEqual(organizationSettings, currentSettings),
    [organizationSettings, currentSettings]
  );

  const onCancel = useCallback(() => {
    setCurrentSettings(organizationSettings);
  }, [organizationSettings]);

  const handleChange = (
    property: keyof OrganizationSettings,
    value: boolean | string | PickerFileMetadata | PortalBackground | undefined
  ) => {
    setCurrentSettings((prevSettings) => ({
      ...prevSettings,
      [property]: value,
    }));
  };

  const onSave = () => {
    dispatch(updateOrganizationSettings(currentSettings));
    dispatch(
      showNotificationBanner({
        notificationVariant: 'success',
        notificationText: intl.get('GENERAL_SETTINGS.SUCCESS_MESSAGE'),
      })
    );
  };

  return (
    <>
      <div className='flex flex-col mx-4 my-6 pb-10'>
        <div className='flex border-b w-full h-full border-primary-light pb-5'>
          <div className='w-1/2 pr-20'>
            <FormItem
              placeholder={intl.get('INTAKE_TAB.PORTAL_NAME_PLACEHOLDER')}
            >
              <TextField
                label={intl.get('INTAKE_TAB.PORTAL_NAME_LABEL')}
                labelProps={{
                  required: true,
                }}
                displayCharactersCount
                maxLength={35}
                value={currentPortalName}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleChange('intakePortalName', event.target.value)
                }
                data-cy='portal-name-input'
              />
            </FormItem>
            <ShareableLink />
            <UploadLogo
              onChange={handleChange}
              logoFile={get(currentSettings, 'portalLogo')}
            />
            <UploadBackground
              onChange={handleChange}
              portalImageSetting={get(currentSettings, 'portalBackground', {})}
            />
          </div>
          <PortalPreview
            logoUrl={get(currentSettings, 'portalLogo.url', '')}
            currentPortalName={currentPortalName}
            backgroundUrl={get(
              currentSettings,
              'portalBackground.image.url',
              ''
            )}
            useDefaultImage={get(
              currentSettings,
              'portalBackground.useDefault',
              false
            )}
          />
        </div>
        <Toggle
          isSmall
          id='team-requests-tab'
          className='mt-8'
          labelProps={{ className: 'mb-5' }}
          label={intl.get('INTAKE_TAB.TEAM_REQUEST_TOGGLE.LABEL')}
          checked={currentTeamRequestTab}
          onText={intl.get('INTAKE_TAB.TEAM_REQUEST_TOGGLE.ON_TEXT')}
          offText={intl.get('INTAKE_TAB.TEAM_REQUEST_TOGGLE.OFF_TEXT')}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            handleChange('teamRequestsTab', event.target.checked)
          }
        />
        <AccountSettingsFooter
          isLoading={isUpdating}
          onCancel={onCancel}
          onSave={onSave}
          saveDisabled={saveDisabled}
          dataCy='account-setting-save-button'
        />
      </div>
      <UnsavedChangesPrompt hasChanges={!saveDisabled} />
    </>
  );
};

export default General;
