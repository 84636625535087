import { FC, useRef, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { DebouncedFunc, debounce } from 'lodash';
import { FormItem, TextField } from '@getsynapse/design-system';
import { LONG_INPUTS_LENGTH } from 'utils/constants';

interface TaskNameProps {
  name?: string;
  onNameChange: (name: string) => void;
  isViewOnly?: boolean;
  isReadOnly?: boolean;
  hasError?: boolean;
}

const TaskName: FC<TaskNameProps> = ({
  name = '',
  onNameChange,
  isReadOnly = false,
  isViewOnly = false,
  hasError = false,
}) => {
  const [newNameVale, setNewNameVale] = useState<string>('');
  const debouncedOnChangeHandler =
    useRef<DebouncedFunc<(name: string) => void>>();

  useEffect(() => {
    debouncedOnChangeHandler.current = debounce(
      (name: string) => onNameChange(name),
      500
    );
  }, [onNameChange]);

  useEffect(() => {
    setNewNameVale(name);
  }, [name]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewNameVale(event.target.value);
    if (debouncedOnChangeHandler.current) {
      debouncedOnChangeHandler.current(event.target.value);
    }
  };

  return (
    <FormItem
      label={intl.get('TASKS.TASK_DETAIL_PAGE.TASK_TITLE_LABEL')}
      labelProps={{
        required: true,
        state: hasError ? 'error' : 'default',
      }}
      helpText={
        hasError && intl.get('TASKS.TASK_DETAIL_PAGE.MISSING_INFO_ERROR')
      }
      helpTextProps={{
        state: hasError ? 'error' : 'default',
      }}
    >
      <TextField
        value={newNameVale}
        variant='text'
        displayCharactersCount
        maxLength={LONG_INPUTS_LENGTH}
        placeholder={intl.get('TASKS.TASK_DETAIL_PAGE.TASK_TITLE_PLACEHOLDER')}
        disabled={isReadOnly || isViewOnly}
        onChange={onChangeHandler}
        aria-label='task-name-field'
      />
    </FormItem>
  );
};

export default TaskName;
