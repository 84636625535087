import intl from 'react-intl-universal';
import {
  Datepicker,
  FormItem,
  tailwindOverride,
} from '@getsynapse/design-system';
import { rangeDate } from 'utils/customTypes';
import { formatDate } from '../helpers/helpers';

interface TaskActualCompletionDateFieldProps {
  completionDate?: Date;
  minDate?: Date;
  onCompletionDateChange: (date: string | undefined) => void;
  hasError?: boolean;
  isOnSidepanel?: boolean;
  isViewOnly?: boolean;
  isReadOnly?: boolean;
}

const TaskActualCompletionDateField = ({
  completionDate,
  minDate,
  onCompletionDateChange,
  hasError = false,
  isOnSidepanel = false,
  isReadOnly = false,
  isViewOnly = false,
}: TaskActualCompletionDateFieldProps) => {
  const handleDatePick = (date: rangeDate) => {
    let startDate = undefined;
    if (date.startDate) {
      startDate = new Date(date.startDate);
      startDate = formatDate(startDate);
    }
    onCompletionDateChange(startDate);
  };

  return (
    <FormItem
      label={intl.get('TASKS.TASK_DETAIL_PAGE.ACTUAL_COMPLETION_DATE')}
      labelProps={{ required: true }}
      component='div'
      className={tailwindOverride({ 'mt-4': isOnSidepanel }, 'ml-4 w-full')}
    >
      <Datepicker
        disabled={isViewOnly || isReadOnly}
        className='w-full'
        startDate={completionDate}
        onPickDate={handleDatePick}
        minDate={minDate}
        inputProps={{
          'aria-label': 'completion-date-field',
        }}
        startDateLabelProps={{
          state: hasError ? 'error' : 'default',
        }}
        startDateError={
          hasError && intl.get('TASKS.TASK_DETAIL_PAGE.MISSING_INFO_ERROR')
        }
      />
    </FormItem>
  );
};

export default TaskActualCompletionDateField;
