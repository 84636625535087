import { FC } from 'react';
import { useSelector } from 'react-redux';
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd';
import intl from 'react-intl-universal';
import {
  tailwindOverride,
  Checkbox,
  Typography,
  Icon,
} from '@getsynapse/design-system';
import { TaskWithAssignees } from 'types/store/projectTasksList';
import { TASK_STATUS } from 'utils/constants';
import { RootState } from 'state/store';
import {
  getIsProjectClosed,
  getIsProjectCancelled,
} from 'state/Project/projectSlice';
import { selectTaskTypeByCode } from 'state/TasksList/allTasks/allTasksSlice';
import {
  getDueAndActualDateColumn,
  getStatusColumn,
} from 'Pages/ProjectsListPage/helpers/tableColumnsValues';
import AssigneesColumn from 'Pages/TasksListPage/components/v2/TasksListTable/TaskRow/AssigneesColumn/AssigneesColumn';
import DisableColumnIcon from '../DisableColumnIcon/DisableColumnIcon';
import ActionButtonsColumn from '../ActionButtonsColumn/ActionButtonsColumn';
import DragAnDropHandler from 'assets/icons/drag-and-drop-handler.svg';

interface TaskRowProps {
  index: number;
  task: TaskWithAssignees;
  openTakInSidePanel: (taskId: string) => void;
  handleClickTaskRow: (taskId: string, projectId: string) => void;
  handleDuplicateTask: (taskId: string) => void;
  bgColor?: string;
  showDisabledColumn?: boolean;
  canReorderTasks?: boolean;
  rowSelected?: boolean;
}

const dateFormatter = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
});

const TaskRow: FC<TaskRowProps> = ({
  index,
  task,
  handleClickTaskRow,
  openTakInSidePanel,
  handleDuplicateTask,
  bgColor = 'bg-neutral-white',
  showDisabledColumn = false,
  canReorderTasks = false,
  rowSelected = false,
}) => {
  const isProjectClosed = useSelector(getIsProjectClosed);
  const isProjectCancelled = useSelector(getIsProjectCancelled);
  const taskType = useSelector((state: RootState) =>
    selectTaskTypeByCode(state, task.type)
  );

  const columnStyles = tailwindOverride(
    'group-hover:border-secondary-darker shadow-table-column group-hover:shadow-none',
    'border-b border-t border-r-0 border-transparent',
    'px-4 py-3'
  );

  const stickyColumnStyles = tailwindOverride(
    'sticky z-1',
    columnStyles,
    bgColor
  );

  const formatDate = (dateString: string): string => {
    return dateFormatter.format(new Date(dateString));
  };

  const isTaskProjectDisabled = isProjectClosed || isProjectCancelled;
  const canUpdateTask = task.permissions?.canEdit && !task.disabled;
  const isViewOnly =
    task.status === TASK_STATUS.ON_HOLD ||
    isTaskProjectDisabled ||
    !canUpdateTask;

  return (
    <Draggable draggableId={task.id} index={index}>
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <tr
          ref={provided.innerRef}
          aria-label={`task-row-${task.id}`}
          className={tailwindOverride(
            'group cursor-pointer relative',
            'text-label text-primary group',
            'hover:shadow-lifted',
            bgColor
          )}
          onClick={() => handleClickTaskRow(task.id, task.projectId)}
          {...provided.draggableProps}
        >
          <td
            className={tailwindOverride(
              'left-0',
              'group-hover:border-secondary-darker shadow-table-column group-hover:shadow-none',
              'border-b border-t border-l border-r-0 border-transparent',
              'sticky z-1 pl-px pr-0.5',
              { 'border-secondary-darker': snapshot.isDragging }
            )}
          >
            <div className='w-5 h-5'>
              <Icon
                src={DragAnDropHandler}
                className={tailwindOverride('text-xl hidden', {
                  'group-hover:block': canReorderTasks,
                  block: snapshot.isDragging,
                })}
                {...provided.dragHandleProps}
              />
            </div>
          </td>
          <td
            className={tailwindOverride(
              'w-8 left-6',
              stickyColumnStyles,
              'pl-2 pr-4',
              { 'border-secondary-darker': snapshot.isDragging }
            )}
          >
            <div
              className='w-8'
              onClick={(event: React.MouseEvent<HTMLInputElement>) => {
                event.stopPropagation();
              }}
            >
              <Checkbox
                checked={rowSelected}
                onChange={() => {}}
                label=''
                aria-label={`task-row-${task.id}-checkbox`}
                inputProps={{
                  className: 'mr-0 mt-0',
                }}
              />
            </div>
          </td>
          <td
            className={tailwindOverride(
              'w-12 left-20',
              stickyColumnStyles,
              'p-0',
              {
                hidden: !showDisabledColumn,
                'border-secondary-darker': snapshot.isDragging,
              }
            )}
          >
            <div
              className={tailwindOverride(
                'w-12',
                'flex items-center justify-center'
              )}
            >
              <DisableColumnIcon
                taskId={task.id}
                isTaskDisabled={task.disabled || false}
                isTaskCompleted={task.status === TASK_STATUS.COMPLETED}
              />
            </div>
          </td>
          <td
            className={tailwindOverride(stickyColumnStyles, 'w-32', {
              'left-20': !showDisabledColumn,
              'left-32': showDisabledColumn,
              'border-secondary-darker': snapshot.isDragging,
            })}
          >
            <div className='w-32'>{task.displayId}</div>
          </td>
          <td
            className={tailwindOverride(stickyColumnStyles, 'w-1/3', {
              'left-60': !showDisabledColumn,
              'left-72': showDisabledColumn,
              'border-secondary-darker': snapshot.isDragging,
            })}
          >
            <div className='w-full truncate'>{task.name}</div>
          </td>
          <td
            className={tailwindOverride(columnStyles, 'w-20', {
              'border-secondary-darker': snapshot.isDragging,
            })}
          >
            <div className='w-20'>{getStatusColumn(task.status)}</div>
          </td>
          <td
            className={tailwindOverride('w-44', columnStyles, {
              'border-secondary-darker': snapshot.isDragging,
            })}
          >
            <div className='truncate w-44'>
              <AssigneesColumn assignees={task.assignees} />
            </div>
          </td>
          <td
            className={tailwindOverride('w-31', columnStyles, {
              'border-secondary-darker': snapshot.isDragging,
            })}
          >
            <div className='w-31 truncate' title={taskType}>
              {taskType ?? intl.get('TASKS.TABLE.NO_TASK_TYPE')}
            </div>
          </td>
          <td
            className={tailwindOverride('w-38', columnStyles, {
              'border-secondary-darker': snapshot.isDragging,
            })}
          >
            <div
              className={tailwindOverride('w-38', {
                'text-neutral-dark': !task.startDate,
              })}
            >
              {task.startDate
                ? formatDate(task.startDate)
                : intl.get('TASKS.TABLE.NO_DATE')}
            </div>
          </td>
          <td
            className={tailwindOverride('w-60', columnStyles, {
              'border-secondary-darker': snapshot.isDragging,
            })}
          >
            <div className='w-60'>
              {getDueAndActualDateColumn({
                status: task.status,
                due_date: task.endDate,
                completion_date: task.completedDate,
                id: task.id,
              })}
            </div>
          </td>
          <td
            className={tailwindOverride('w-34', columnStyles, {
              'border-secondary-darker': snapshot.isDragging,
            })}
          >
            <div className='w-34'>
              <Typography variant='label' className='text-primary'>
                {task.estimateHours
                  ? `${parseFloat(task.estimateHours)}h`
                  : '0h'}
                {task.actualHours ? ` / ${parseFloat(task.actualHours)}h` : ''}
              </Typography>
            </div>
          </td>
          <td
            className={tailwindOverride(
              'w-12 right-0 border-r',
              stickyColumnStyles,
              { 'border-secondary-darker': snapshot.isDragging }
            )}
          >
            <div
              className='w-12'
              onClick={(event: React.MouseEvent<HTMLInputElement>) => {
                event.stopPropagation();
              }}
            >
              <div className='hidden group-hover:flex'>
                <ActionButtonsColumn
                  taskId={task.id}
                  openTakInSidePanel={openTakInSidePanel}
                  displayDuplicateTaskModal={handleDuplicateTask}
                  canDuplicateTask={canUpdateTask}
                  canEditTask={!isViewOnly}
                />
              </div>
            </div>
          </td>
        </tr>
      )}
    </Draggable>
  );
};

export default TaskRow;
