import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { Card } from 'Pages/InsightsPage/Components/Card';

import { fetchEvaluationInsights } from 'state/Evaluations/evaluationInsightsSlice';
import HighestInstructors from './HighestInstructors/HighestInstructors';
import LearningDelivery from './LearningDelivery/LearningDelivery';
import LowestCourses from './LowestCourses/LowestCourses';

const Evaluations = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchEvaluationInsights());
  }, [dispatch]);

  return (
    <>
      <div className='flex h-full w-full relative space-x-2'>
        <div className='h-full min-h-120 mt-2 w-full flex gap-2 flex-wrap'>
          <Card className='w-120 pt-2.5 pl-3 pr-5 pb-5 h-80'>
            <HighestInstructors />
          </Card>

          <Card className='w-120 pt-2.5 pl-3 pr-5 pb-5 h-80'>
            <LearningDelivery />
          </Card>

          <Card className='w-120 pt-2.5 pl-3 pr-5 pb-5 h-80'>
            <LowestCourses />
          </Card>
        </div>
      </div>
    </>
  );
};

export default Evaluations;
