import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';
import {
  NewScheduleCreationParams,
  ScheduleUpdateParams,
  SearchFilterParams,
  ExceptionCreationParams,
  FetchedEventsWithProjects,
  FetchedEventsWithFacilitators,
  FacilitatorsSearchFilterParams,
} from 'utils/types/learningSchedule';

class ScheduleAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(`${config.get('backendURL')}v2/`);
  }

  createSchedule = async (newScheduleData: NewScheduleCreationParams) => {
    const res = await this.instance.post(
      'facilitation-management/event',
      newScheduleData
    );
    return res;
  };

  createException = async (params: ExceptionCreationParams) => {
    const res = await this.instance.post(
      'facilitation-management/event',
      params.data
    );
    return res;
  };

  updateSchedule = async (
    scheduleId: string,
    updateScheduleData: ScheduleUpdateParams
  ) => {
    const res = await this.instance.put(
      `facilitation-management/event/${scheduleId}`,
      updateScheduleData
    );
    return res;
  };

  fetchEvents = async (params: SearchFilterParams) => {
    let url = 'facilitation-management/event/get_events_by_projects';
    const queryParams = new URLSearchParams();

    if (params.search) {
      queryParams.append('search', params.search);
    }

    if (params.filters) {
      const {
        projects,
        facilitators,
        eventTypes,
        unassignedSchedules,
        programs,
        projectStatus,
      } = params.filters;

      if (projects?.length > 0) {
        queryParams.append('projects', projects.join(','));
      }
      if (facilitators?.length > 0) {
        queryParams.append('facilitators', facilitators.join(','));
      }
      if (eventTypes?.length > 0) {
        queryParams.append('event_types', eventTypes.join(','));
      }
      if (programs?.length > 0) {
        queryParams.append('programs', programs.join(','));
      }
      if (projectStatus?.length > 0) {
        queryParams.append('project_status', projectStatus.join(','));
      }
      if (unassignedSchedules) {
        queryParams.append(
          'unassigned_schedules',
          unassignedSchedules.toString()
        );
      }
    }
    if (queryParams.toString()) {
      url += `?${queryParams.toString()}`;
    }

    const res = await this.instance.get(url);
    return res.data as FetchedEventsWithProjects;
  };

  fetchMySchedule = async (search: string) => {
    let url = 'facilitation-management/event/my_schedule';
    if (search) {
      const queryParams = new URLSearchParams({ search }).toString();
      url += `?${queryParams}`;
    }
    const res = await this.instance.get(url);
    return res.data;
  };

  fetchFacilitatorsEvents = async (params: FacilitatorsSearchFilterParams) => {
    let url = 'facilitation-management/event/get_events_by_facilitator';
    const queryParams = new URLSearchParams();
    if (params.search) {
      queryParams.append('search', params.search);
    }
    if (params.filters) {
      const {
        facilitators,
        include_pending,
        teams,
        skills,
        languages,
        countries,
        states,
      } = params.filters;

      if (facilitators?.length > 0) {
        queryParams.append('facilitators', facilitators.join(','));
      }
      if (include_pending?.length > 0) {
        queryParams.append('include_pending_facilitators', 'true');
      }
      if (teams?.length > 0) {
        queryParams.append('teams', teams.join(','));
      }
      if (skills?.length > 0) {
        queryParams.append('skills', skills.join(','));
      }
      if (languages?.length > 0) {
        queryParams.append('languages', languages.join(','));
      }
      if (countries?.length > 0) {
        queryParams.append('country', countries.join(','));
      }
      if (states?.length > 0) {
        queryParams.append('province', states.join(','));
      }
    }
    if (queryParams.toString()) {
      url += `?${queryParams.toString()}`;
    }
    const res = await this.instance.get(url);
    return res.data as FetchedEventsWithFacilitators;
  };

  deleteSchedule = async (scheduleId: string) => {
    const res = await this.instance.delete(
      `facilitation-management/event/${scheduleId}`
    );
    return res;
  };

  fetchPrograms = async (name?: string) => {
    let url = `/available-programs/`;
    const urlParams = new URLSearchParams();
    if (name) {
      urlParams.append('name', name);
    }

    url = `${url}?${urlParams.toString()}`;
    const response = await this.instance.get(url);
    return response.data;
  };
}

export default new ScheduleAPI();
