import { useEffect, useMemo, useCallback, useState, ChangeEvent } from 'react';
import intl from 'react-intl-universal';
import { get, isEmpty, isEqual } from 'lodash';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  SLICE_STATUS,
  PATHS,
  SETTINGS_TABS,
  SETTINGS_SECTIONS,
  SETTINGS_ATTRIBUTES,
} from 'utils/constants';
import {
  getForm,
  selectForm,
  selectFormStatus,
  updateForm,
  selectFormQuestions,
  getFormQuestions,
  resetForm,
  deleteForm,
} from 'state/Forms/formSlice';
import {
  getLDUsers,
  selectLDUsersForDropdown,
} from 'state/UsersManagement/usersManagementSlice';
import {
  displayNotification,
  setNotificationText,
  setNotificationTimeout,
  setNotificationVariant,
} from 'state/InlineNotification/inlineNotificationSlice';
import {
  selectBusinessTeamsForDropdown,
  getBusinessTeams,
} from 'state/BusinessTeams/businessTeamsSlice';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import Loader from 'Molecules/Loader/Loader';
import FormDetailsTabs from './FormDetailsTabs';
import FormFooterButtons from './FormFooterButtons';
import FormTopBar from './FormTopBar';
import {
  RequestQuestion,
  dropdownOption,
  radioOption,
  checkboxOption,
} from 'utils/customTypes';
import DeleteFormModal from '../Forms/DeleteFormModal';
import EditFormModal from './EditFormModal';
import DetailsPage from 'Molecules/DetailsPage/DetailsPage';
import UnsavedChangesPrompt from 'Organisms/UnsavedChangesPrompt/UnsavedChangesPrompt';

const FormPage = () => {
  const { formId } = useParams<{ formId: string }>();
  const dispatch = useDispatch();
  const form = useSelector(selectForm);
  const questions = useSelector(selectFormQuestions);
  const formStatus = useSelector(selectFormStatus);
  const businessTeamsOptions = useSelector(selectBusinessTeamsForDropdown);
  const ldUsers = useSelector(selectLDUsersForDropdown);
  const [currentTab, setCurrentTab] = useState(0);
  const [editedForm, setEditedForm] = useState(form);
  const [formQuestions, setFormQuestions] = useState<RequestQuestion[]>([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [errors, setErrors] = useState({
    title: false,
    description: false,
    requestType: false,
    businessTeams: false,
    owners: false,
  });
  const [deleteModal, setDeleteModal] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (formId) {
      dispatch(getForm(formId));
      dispatch(getFormQuestions(formId));
    }
    return () => {
      dispatch(resetForm());
    };
  }, [formId, dispatch]);

  useEffect(() => {
    if (form) {
      setEditedForm(form);
    }
  }, [form]);

  useEffect(() => {
    if (questions) {
      setFormQuestions(questions);
    }
  }, [questions]);

  useEffect(() => {
    dispatch(getLDUsers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getBusinessTeams());
  }, [dispatch]);

  const questionLabel = useMemo(
    () =>
      formQuestions.some((question) => {
        if (question && question.data.label.length === 0) {
          return true;
        }
        return false;
      }),
    [formQuestions]
  );

  const selectQuestionsHasEmptyOptions = useMemo(
    () =>
      formQuestions?.some(
        (question) =>
          (question?.type === 'dropdown' &&
            question.data.value.items?.some(
              (item: dropdownOption) => !item.display
            )) ||
          (['dropdown', 'checkbox'].includes(question?.type) &&
            isEmpty(question.data.value.items)) ||
          (question?.type === 'checkbox' &&
            question.data.value.items?.some(
              (item: checkboxOption) => !item.item
            )) ||
          (question?.type === 'radio' &&
            question.data.value.options?.some(
              (item: radioOption) => !item.text
            )) ||
          (question?.type === 'radio' && isEmpty(question.data.value.options))
      ),
    [formQuestions]
  );

  const canSubmit = useMemo(
    () =>
      (!isEqual(form, editedForm) || !isEqual(questions, formQuestions)) &&
      !questionLabel &&
      !selectQuestionsHasEmptyOptions,
    [
      editedForm,
      form,
      questions,
      formQuestions,
      questionLabel,
      selectQuestionsHasEmptyOptions,
    ]
  );

  const disablePublishButton = useMemo(() => {
    if (
      get(form, 'title') &&
      get(form, 'request_type') &&
      get(form, 'form_description') &&
      get(form, 'businessTeams.length') &&
      get(form, 'owners.length')
    ) {
      return false;
    } else {
      return true;
    }
  }, [form]);

  const handleTabChange = useCallback((tab: number) => {
    setCurrentTab(tab);
  }, []);

  const handleCancel = useCallback(() => {
    history.push(
      `${PATHS.SETTINGS}/${SETTINGS_TABS.CONFIGURATIONS}?sections=${SETTINGS_SECTIONS.INTAKE}&attribute=${SETTINGS_ATTRIBUTES.REQUEST_FORM}`
    );
  }, [history]);

  const handleChangeField = useCallback(
    (value: string | string[] | boolean, path: string) => {
      setEditedForm({ ...editedForm, [path]: value });
    },
    [editedForm]
  );

  const handleShowModal = useCallback(() => setIsEditModalOpen(true), []);

  const handleCloseModal = useCallback(() => setIsEditModalOpen(false), []);

  const handleSave = useCallback(async () => {
    setErrors({
      title: !get(editedForm, 'title'),
      description: !get(editedForm, 'form_description'),
      requestType: !get(editedForm, 'request_type'),
      businessTeams: !get(editedForm, 'businessTeams.length') || false,
      owners: !get(editedForm, 'owners.length') || false,
    });

    if (
      get(editedForm, 'title') &&
      get(editedForm, 'form_description') &&
      get(editedForm, 'request_type') &&
      get(editedForm, 'owners.length') &&
      get(editedForm, 'businessTeams.length')
    ) {
      let updatedQuestions;
      if (!isEqual(formQuestions, questions)) {
        updatedQuestions = formQuestions;
      }
      await dispatch(
        updateForm({ ...editedForm, questions: updatedQuestions })
      );
      if (formStatus === SLICE_STATUS.IDLE) {
        dispatch(
          setNotificationText(intl.get('SETTINGS_PAGE.FORMS.UPDATED_SUCCESS'))
        );
        dispatch(setNotificationVariant('success'));
        dispatch(setNotificationTimeout(4000));
        dispatch(displayNotification());
      }
      history.push(
        `${PATHS.SETTINGS}/${SETTINGS_TABS.CONFIGURATIONS}?section=${SETTINGS_SECTIONS.INTAKE}&attribute=${SETTINGS_ATTRIBUTES.REQUEST_FORM}`
      );
    } else {
      handleCloseModal();
      setCurrentTab(0);
      dispatch(
        setNotificationText(intl.get('SETTINGS_PAGE.FORMS.NOT_UPDATED'))
      );
      dispatch(setNotificationVariant('error'));
      dispatch(setNotificationTimeout(4000));
      dispatch(displayNotification());
    }
  }, [
    editedForm,
    formQuestions,
    questions,
    dispatch,
    formStatus,
    history,
    handleCloseModal,
  ]);

  const handlePublishUnpublish = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const isPublished = e.target.checked;
      await dispatch(
        updateForm({
          ...form,
          data: { ...get(form, 'data'), published: isPublished },
        })
      );
      setEditedForm({
        ...editedForm,
        data: { ...get(editedForm, 'data'), published: isPublished },
      });
      if (formStatus === SLICE_STATUS.IDLE) {
        dispatch(
          setNotificationText(
            isPublished
              ? intl.get('SETTINGS_PAGE.FORMS.PUBLISH_SUCCESS')
              : intl.get('SETTINGS_PAGE.FORMS.UNPUBLISH_SUCCESS')
          )
        );
        dispatch(setNotificationVariant('success'));
        dispatch(setNotificationTimeout(4000));
        dispatch(displayNotification());
      }
    },
    [dispatch, editedForm, form, formStatus]
  );

  const onDelete = useCallback(async () => {
    if (formId) {
      await dispatch(deleteForm(formId));
      dispatch(
        setNotificationText(intl.get('SETTINGS_PAGE.FORMS.DELETE.SUCCESS'))
      );
      dispatch(setNotificationVariant('success'));
      dispatch(setNotificationTimeout(4000));
      dispatch(displayNotification());
      history.push(
        `${PATHS.SETTINGS}/${SETTINGS_TABS.CONFIGURATIONS}?section=${SETTINGS_SECTIONS.INTAKE}&attribute=${SETTINGS_ATTRIBUTES.REQUEST_FORM}`
      );
    }
  }, [dispatch, formId, history]);

  return (
    <div className='h-full flex flex-col'>
      <PageTitle
        titleComponent={`${intl.get('ENTITIES.REQUEST_FORM', {
          num: 2,
        })} / ${get(form, 'title', '')}`}
        className='sticky top-0 left-0 right-0'
      />
      <EditFormModal
        isOpen={isEditModalOpen}
        handleSave={handleSave}
        onClose={handleCloseModal}
        status={get(editedForm, 'data.published') ? 'published' : 'unpublished'}
      />
      <DeleteFormModal
        onClose={() => setDeleteModal(false)}
        isOpen={deleteModal}
        formId={formId}
        isPublished={get(editedForm, 'data.published')}
        onDelete={onDelete}
      />
      {formStatus !== SLICE_STATUS.LOADING && get(form, 'id', '') ? (
        <DetailsPage
          bodyClassName='overflow-y-hidden'
          topBar={
            <FormTopBar
              formObj={editedForm}
              handlePublishUnpublish={handlePublishUnpublish}
              onDelete={() => setDeleteModal(true)}
              disablePublishButton={disablePublishButton}
            />
          }
          content={
            <main className='w-full bg-neutral-white'>
              <FormDetailsTabs
                formObj={editedForm}
                handleChangeField={handleChangeField}
                ldUsers={ldUsers}
                onTabChange={handleTabChange}
                currentTab={currentTab}
                errors={errors}
                formQuestions={formQuestions}
                setFormQuestions={setFormQuestions}
                businessTeamsOptions={businessTeamsOptions}
              />
            </main>
          }
        />
      ) : (
        <Loader />
      )}
      <FormFooterButtons
        currentTab={currentTab}
        onTabChange={handleTabChange}
        onSave={handleShowModal}
        onCancel={handleCancel}
        canSubmit={canSubmit}
        saveButtonLabel={intl.get(
          'REQUEST_PAGE.REQUEST_DETAILS.BUTTON.UPDATE_EXIT'
        )}
        showTooltip={questionLabel || selectQuestionsHasEmptyOptions}
      />
      <UnsavedChangesPrompt
        hasChanges={
          !isEqual(form, editedForm) || !isEqual(questions, formQuestions)
        }
      />
    </div>
  );
};

export default FormPage;
