import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';
import { Pagination } from 'utils/customTypes';
import {
  DenormalizedEvaluation,
  EvaluationInsights,
} from 'utils/types/evaluations';

type FetchParams = {
  afterDate: string;
  beforeDate: string;
};

type FetchEvaluationsParams = {
  textSearch?: string;
} & FetchParams &
  Pagination;

type FetchEvaluationInsightsParams = {} & FetchParams;

interface FetchEvaluationsResponse {
  evaluations: DenormalizedEvaluation[];
  total: number;
}

class EvaluationsAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(`${config.get('backendURL')}v2/evaluations`);
  }

  fetchEvaluations = async (
    params: FetchEvaluationsParams
  ): Promise<FetchEvaluationsResponse> => {
    const { data } = await this.instance.get<FetchEvaluationsResponse>('', {
      params,
    });
    return data;
  };

  fetchEvaluationInsights = async (
    params: FetchEvaluationInsightsParams
  ): Promise<EvaluationInsights> => {
    const { data } = await this.instance.get('/insights', {
      params,
    });
    return data as EvaluationInsights;
  };

  getPerformitivRedirectionLink = async (
    evaluationId?: string
  ): Promise<{ redirectUrl: string }> => {
    const { data } = await this.instance.get<{ redirectUrl: string }>(
      `launch/${evaluationId ?? ''}`
    );
    return data;
  };
}

const evaluationsAPI = new EvaluationsAPI();

export default evaluationsAPI;
