import { useState } from 'react';
import classnames from 'classnames';
import { IconButton, Icon, Typography } from '@getsynapse/design-system';
import { getDateTime } from './helpers';
import intl from 'react-intl-universal';
import { ProjectResource } from 'utils/types/learningSchedule';
import { generateReadableRecurrancePattern } from '../../helpers/helpers';
import 'Pages/LearningSchedule/components/Events/styles.css';
import eventTypeIcon from 'assets/icons/Paste.svg';
import projectListIcon from 'assets/icons/project-list-quick-info.svg';
import teamIcon from 'assets/icons/Team.svg';
import dueDateIcon from 'assets/icons/due-date.svg';
import { renderProjectNameLink } from './helpers';
import { USER_STATUS } from 'utils/constants';

export const headerTemplate = (onClose: () => void) => {
  return () => (
    <div className='w-full h-8'>
      <IconButton
        name='close'
        onClick={onClose}
        className='float-right mt-2 mr-4 text-base text-neutral-dark'
      />
    </div>
  );
};

export const ContentTemplate = (props: any) => {
  const [isFacilitatorSectionOpen, setIsFacilitatorSectionOpen] =
    useState(false);
  const [isProjectsSectionOpen, setProjectsSectionOpen] = useState(false);

  const {
    Subject,
    IsAllDay,
    StartTime,
    EndTime,
    RecurrenceRule,
    EventType,
    projects = [],
    facilitators = [],
  } = props;

  const toggleFacilitatorSection = () => {
    setIsFacilitatorSectionOpen(!isFacilitatorSectionOpen);
  };

  const toggleProjectsSection = () => {
    setProjectsSectionOpen(!isProjectsSectionOpen);
  };

  const recurrenceLabel = generateReadableRecurrancePattern(RecurrenceRule);

  const generateFacilitatorElements = (
    facilitators: any,
    dataTestId: string
  ) => {
    return facilitators.map((facilitator: any, index: number) => {
      return (
        <Typography
          key={index}
          variant='body2'
          data-testid={`${dataTestId}-${index}`}
          className='flex justify-between w-full'
        >
          {facilitator.status === USER_STATUS.REGISTERED_DISABLED ||
          facilitator.status === USER_STATUS.INVITED_DISABLED ? (
            <>
              <span>{facilitator.name}</span>
              <span>
                ({intl.get('USERS_PAGE.TABLE.USER_STATUS.DEACTIVATED')})
              </span>
            </>
          ) : (
            facilitator.name
          )}
        </Typography>
      );
    });
  };

  const generateProjectElements = (
    projects: ProjectResource[],
    dataTestId: string
  ) => {
    return projects.map((project: ProjectResource, index: number) => {
      return renderProjectNameLink({
        projectElement: project,
        index,
        dataTestId,
      });
    });
  };

  const toggleSectionElement = (
    isToggleSectionOpen: boolean,
    onClickHandler: () => void,
    dataTestId: string
  ) => {
    return (
      <div className='ml-auto' data-testid='quick-info__toggle'>
        <div
          onClick={onClickHandler}
          data-testid={dataTestId}
          className='flex'
          style={{
            cursor: 'pointer',
            transform: `${
              isToggleSectionOpen ? 'rotate(180deg)' : 'rotate(0deg)'
            }`,
            transition: 'all 0.2s linear',
          }}
        >
          <Icon name='chevron-down' className='text-lg text-neutral-dark' />
        </div>
      </div>
    );
  };

  return (
    <div className={classnames('w-full px-1 flex flex-col')}>
      <div className='flex flex-row h-11 bg-quick-info border-l-6 border-quick-info-darker mb-4 text-lg font-semibold text-primary-darker'>
        <Typography variant='h6' className='content-center pl-4'>
          {Subject}
        </Typography>
      </div>
      <div className='flex flex-row pt-px'>
        <Icon
          src={dueDateIcon}
          className='w-6 h-6 pt-px pr-4 text-neutral-dark'
        />
        <div className='flex flex-col'>
          <Typography variant='body2'>
            {getDateTime(StartTime, EndTime, IsAllDay)}
          </Typography>
          <Typography variant='body2'>{recurrenceLabel}</Typography>
        </div>
      </div>
      {EventType && (
        <div className='flex flex-row pt-px'>
          <Icon src={eventTypeIcon} className='w-5 h-5 pt-px pr-4' />
          <Typography variant='body2'>
            {intl.get(
              `SCHEDULE.EVENTS.DETAILS_SECTION.EVENT_TYPES.${EventType?.toUpperCase()}`
            )}
          </Typography>
        </div>
      )}
      {facilitators && facilitators.length > 0 && (
        <div className='flex flex-row pt-px' data-testid='facilitators-list'>
          <Icon src={teamIcon} className='w-5 h-5 pt-px pr-4' />{' '}
          {facilitators.length > 4 ? (
            <div className='flex flex-col w-full'>
              <div className='flex'>
                <Typography variant='body2'>
                  {facilitators.length}{' '}
                  {intl.get('SCHEDULE.QUICK_VIEW.FACILITATORS')}
                </Typography>
                {toggleSectionElement(
                  isFacilitatorSectionOpen,
                  toggleFacilitatorSection,
                  'facilitators-toggle'
                )}
              </div>
              {isFacilitatorSectionOpen && (
                <div className='flex flex-col w-full'>
                  {generateFacilitatorElements(
                    facilitators,
                    'facilitators-list-elems'
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className='flex flex-col w-full'>
              {generateFacilitatorElements(facilitators, 'facilitators-list')}
            </div>
          )}
        </div>
      )}
      {projects.length > 0 && (
        <div className='flex flex-row pt-px' data-testid='projects-list'>
          <Icon src={projectListIcon} className='w-5 h-5 pt-px pr-4' />{' '}
          {projects.length > 4 ? (
            <div className='flex flex-col w-full'>
              <div className='flex'>
                <Typography variant='body2'>
                  {projects.length} {intl.get('SCHEDULE.QUICK_VIEW.PROJECTS')}
                </Typography>
                {toggleSectionElement(
                  isProjectsSectionOpen,
                  toggleProjectsSection,
                  'projects-toggle'
                )}
              </div>
              {isProjectsSectionOpen && (
                <div className='flex flex-col flex-1'>
                  {renderProjectNameLink({
                    projectElement: projects[0],
                    index: 0,
                    dataTestId: 'projects-list',
                  })}
                  {generateProjectElements(
                    projects.slice(1),
                    'projects-list-elems'
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className='flex flex-col flex-1' key={projects[0].id}>
              {generateProjectElements(projects, 'projects-list')}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ContentTemplate;
