import * as Sentry from '@sentry/react';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { store } from 'state/store';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';

const tracePropagationTargets: string[] = [
  'localhost',
  process.env.backendURL || '',
].filter((target) => target.length > 0);

const sentryEnabled = process.env.REACT_APP_SENTRY_ENABLED === 'true';

if (sentryEnabled) {
  Sentry.init({
    dsn: 'https://10d3169705f23cb802e821d7180f2104@o4507889185718272.ingest.us.sentry.io/4507890082381824',
    environment: process.env.REACT_APP_APP_ENV,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing capture rate
    tracesSampleRate: 0.8,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: tracePropagationTargets,
    // Sets the session replay sample rate at 0%, as for Sentry we are only interested in error and performance tracking
    replaysSessionSampleRate: 0.0,
    // Samples sessions where errors occur at 80% rate to save some quota
    replaysOnErrorSampleRate: 0.8, // 80% when sampling sessions where errors occur.
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
