import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import axios from 'axios';
import {
  LinkedTaskContentState,
  LinkedTaskContent,
} from 'types/store/linkedTaskContent';
import {
  fetchLinkedTaskContent,
  addLinkedTaskContent,
  UpdateLinkedTaskContentParams,
  removeLinkedTaskContent,
} from 'api/linkedTaskContent';
import { normalizeState } from 'state/utils/normalizeState';
import { RootState } from 'state/store';

const initialState: LinkedTaskContentState = {
  linkedContentIds: [],
  linkedContentById: {},
  uploadersById: {},
  uploadersIds: [],
  loading: false,
};

export const fetchTaskContent = createAsyncThunk(
  'linkedTaskContent/fetchTaskContent',
  async (taskId: string, { rejectWithValue }) => {
    try {
      const response = await fetchLinkedTaskContent({ taskId });
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue('Error fetching linked task content');
    }
  }
);

export const addContentToTask = createAsyncThunk(
  'linkedTaskContent/updateTaskContent',
  async (params: UpdateLinkedTaskContentParams, { rejectWithValue }) => {
    try {
      const response = await addLinkedTaskContent(params);
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue('Error updating linked task content');
    }
  }
);

export const removeContentFromTask = createAsyncThunk(
  'linkedTaskContent/removeContentFromTask',
  async (params: UpdateLinkedTaskContentParams, { rejectWithValue }) => {
    try {
      await removeLinkedTaskContent(params);
      return params.contentIds;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue('Error removing linked task content');
    }
  }
);

const linkedTaskContentSlice = createSlice({
  name: 'linkedTaskContent',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaskContent.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTaskContent.fulfilled, (state, action) => {
        if (action.payload) {
          const linkedContent = action.payload.linkedContent;
          const uploaders = action.payload.uploaders;
          const normalizedLinkedContentState =
            normalizeState<LinkedTaskContent>(linkedContent);
          state.linkedContentIds = normalizedLinkedContentState.ids;
          state.linkedContentById = normalizedLinkedContentState.entities;
          state.uploadersIds = Object.keys(uploaders);
          state.uploadersById = uploaders;
        }
        state.loading = false;
      })
      .addCase(fetchTaskContent.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addContentToTask.fulfilled, (state, action) => {
        if (action.payload) {
          const linkedContent = action.payload.linkedContent;
          const normalizedLinkedContentState =
            normalizeState<LinkedTaskContent>(linkedContent);
          state.linkedContentIds = state.linkedContentIds.concat(
            normalizedLinkedContentState.ids
          );
          state.linkedContentById = {
            ...state.linkedContentById,
            ...normalizedLinkedContentState.entities,
          };
        }
      })
      .addCase(addContentToTask.rejected, (state, action) => {
        throw new Error(action.payload as string);
      })
      .addCase(removeContentFromTask.fulfilled, (state, action) => {
        const contentIds = action.payload;
        const linkedContentIds = state.linkedContentIds.filter(
          (id) => !contentIds.includes(id)
        );
        const linkedContentById = { ...state.linkedContentById };
        contentIds.forEach((id) => {
          delete linkedContentById[id];
        });
        state.linkedContentIds = linkedContentIds;
        state.linkedContentById = linkedContentById;
      })
      .addCase(removeContentFromTask.rejected, (state, action) => {
        throw new Error(action.payload as string);
      });
  },
});

const selectLinkedTaskContentById = (state: RootState) =>
  state.linkedTaskContent.linkedContentById;
const selectLinkedTaskContentUploadersById = (state: RootState) =>
  state.linkedTaskContent.uploadersById;

export const selectLinkedTaskContentIds = (state: RootState) =>
  state.linkedTaskContent.linkedContentIds;

export const selectLinkedTaskContent = createSelector(
  [selectLinkedTaskContentById, selectLinkedTaskContentUploadersById],
  (
    linkedContentById: LinkedTaskContentState['linkedContentById'],
    uploadersById: LinkedTaskContentState['uploadersById']
  ) => {
    return Object.values(linkedContentById).map((linkedContent) => {
      const uploader = uploadersById[linkedContent.uploadedBy];
      return {
        ...linkedContent,
        uploader,
      };
    });
  }
);

export const selectIsFetchingLinkedTaskContent = (state: RootState) =>
  state.linkedTaskContent.loading;

export default linkedTaskContentSlice.reducer;
