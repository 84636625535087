import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import {
  InsightCourse,
  InsightInstructor,
  InsightLearningDelivery,
} from 'utils/types/evaluations';
import evaluationsAPI from './evaluationsAPI';
import { RootState } from 'state/store';
import { Status } from 'utils/customTypes';
import { DATE, SLICE_STATUS } from 'utils/constants';
import moment from 'moment';

/* ============================== STATE SETUP =============================== */
interface InsightsState {
  status: Status;
}

const initialState: InsightsState = {
  status: SLICE_STATUS.IDLE,
};

const selectId = (
  entity: InsightCourse | InsightInstructor | InsightLearningDelivery
) => entity.name;

const coursesAdapter = createEntityAdapter<InsightCourse>({ selectId });
const instructorsAdapter = createEntityAdapter<InsightInstructor>({ selectId });
const learningDeliveryAdapter = createEntityAdapter<InsightLearningDelivery>({
  selectId,
});

const calculateInsightsDateRange = (): {
  afterDate: string;
  beforeDate: string;
} => {
  /* Performitiv uses the last 6 fully elapsed months to calculate as a default,
  we're syncing our default until we have controls in the UI */
  const beforeDate = moment()
    .subtract(1, 'month')
    .endOf('month')
    .format(DATE.DATE_STAMP);

  const afterDate = moment()
    .subtract(6, 'months')
    .startOf('month')
    .format(DATE.DATE_STAMP);

  return { afterDate, beforeDate };
};

/* ============================== REDUX THUNK =============================== */
export const fetchEvaluationInsights = createAsyncThunk(
  'evaluation/fetchEvaluationInsights',
  async () => {
    const { afterDate, beforeDate } = calculateInsightsDateRange();

    const insights = await evaluationsAPI.fetchEvaluationInsights({
      afterDate,
      beforeDate,
    });
    return insights;
  },
  {
    condition: (_, { getState }) => {
      const { status } = (getState() as RootState).evaluations;

      return status !== SLICE_STATUS.LOADING;
    },
  }
);

/* ================================= REDUCER ================================ */
const evaluationInsightsSlice = createSlice({
  name: 'evaluationInsights',
  initialState: {
    ...initialState,
    insights: {
      courses: coursesAdapter.getInitialState(),
      instructors: instructorsAdapter.getInitialState(),
      learningDelivery: learningDeliveryAdapter.getInitialState(),
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEvaluationInsights.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchEvaluationInsights.fulfilled, (state, action) => {
        coursesAdapter.setAll(
          state.insights.courses,
          action.payload.insights.courses
        );
        instructorsAdapter.setAll(
          state.insights.instructors,
          action.payload.insights.instructors
        );
        learningDeliveryAdapter.setAll(
          state.insights.learningDelivery,
          action.payload.insights.learningDelivery
        );
        state.status = SLICE_STATUS.SUCCESS;
      })
      .addCase(fetchEvaluationInsights.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      });
  },
});

/* =============================== SELECTORS ================================ */
const defaultSelector = createSelector(
  (state: RootState) => state.evaluationInsights.insights,
  (state) => state
);

const selectStatus = (state: RootState) => state.evaluationInsights.status;

const selectCourseInsights = coursesAdapter.getSelectors<RootState>(
  (state) => state.evaluationInsights.insights.courses
);
const selectInstructorInsights = instructorsAdapter.getSelectors<RootState>(
  (state) => state.evaluationInsights.insights.instructors
);
const selectLearningDeliveryInsights =
  learningDeliveryAdapter.getSelectors<RootState>(
    (state) => state.evaluationInsights.insights.learningDelivery
  );

export const evaluationInsightsSelectors = {
  ...defaultSelector,
  selectStatus,
  selectCourseInsights,
  getCourseData: selectCourseInsights.selectAll,
  getInstructorData: selectInstructorInsights.selectAll,
  getLearningDeliveryData: selectLearningDeliveryInsights.selectAll,
  selectLearningDeliveryInsights,
};

export default evaluationInsightsSlice.reducer;
