export const EventTypesOptions = [
  {
    value: '1',
    code: 'ilt',
    label: 'Instructor Led Training',
  },
  {
    value: '2',
    code: 'vilt',
    label: 'Virtual Instructor Led Training',
  },
  {
    value: '3',
    code: 'hybrid',
    label: 'Blended / Hybrid',
  },
];

export const colors = [
  '#5957a9',
  '#367d2f',
  '#5e2863',
  '#dc822c',
  '#0073e6',
  '#8c6264',
  '#4f3489',
  '#0f574d',
  '#3e617d',
  '#aa4b72',
  '#a64817',
  '#2546f0',
  '#6f2419',
  '#32337f',
  '#687f1a',
  '#323d5f',
  '#b51963',
  '#2546f0',
  '#aa0606',
  '#166a60',
  '#246d8b',
  '#c44e52',
  '#596882',
];
