import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import intl from 'react-intl-universal';
import {
  fetchUserTasks,
  selectUserTasksLoading,
  selectUserTasks,
  selectCanFetchtMoreTasks,
  selectTotalCount,
  setFilters,
  setSearchFilter,
  selectUserTasksFilters,
} from 'state/ProjectTasksList/userTasks/userTasksSlice';
import {
  setShouldRefreshTasks,
  selectShouldRefreshTasks,
} from 'state/ProjectTasksList/actions/actionsSlice';
import { fetchProjectProgress } from 'state/Project/progress/progressSlice';
import { fetchTaskTypes } from 'state/TasksList/allTasks/allTasksSlice';
import { selectUserId } from 'state/User/userSlice';
import { exportTasksList } from 'api/projectTasks';
import { TASKS_TABLE_TABS, SLICE_STATUS } from 'utils/constants';
import {
  extractFilenameFromContentDisposition,
  createDownloadLinkAndDownloadFile,
} from 'utils/csvExports';
import useSnackbarNotification from 'Hooks/useSnackbarNotification';
import SkeletonLoader from 'Organisms/TableSkeletonLoader/PageTableLoader';
import TasksTable from '../TasksTable/TasksTable';

const UserTasks = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const fetchUserTasksStatus = useSelector(selectUserTasksLoading);
  const userTasks = useSelector(selectUserTasks);
  const userId = useSelector(selectUserId);
  const totalCount = useSelector(selectTotalCount);
  const canFetchMoreTasks = useSelector(selectCanFetchtMoreTasks);
  const shouldRefreshTasks = useSelector(selectShouldRefreshTasks);
  const filters = useSelector(selectUserTasksFilters);
  const { projectId } = useParams<{ projectId: string }>();

  const {
    SnackbarNotification,
    isNotificationVisible,
    snackbarProps,
    showNotification,
  } = useSnackbarNotification();

  const init = useCallback(
    (projectId: string, userId: string) => {
      dispatch(
        fetchUserTasks({
          projectId,
          userId,
          includeTotalCount: true,
        })
      );
      dispatch(fetchTaskTypes());
      dispatch(fetchProjectProgress({ projectId, userId }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (projectId && userId) {
      init(projectId, userId);
    }
  }, [userId, projectId, init]);

  useEffect(() => {
    if (shouldRefreshTasks) {
      dispatch(setShouldRefreshTasks(false));
      init(projectId, userId!);
    }
  }, [shouldRefreshTasks, init, dispatch, userId, projectId]);

  useEffect(() => {
    if (fetchUserTasksStatus) {
      if (fetchUserTasksStatus === SLICE_STATUS.IDLE && isLoading) {
        setIsLoading(false);
      }
    }
  }, [fetchUserTasksStatus, isLoading]);

  const handleFetchMoreTasks = useCallback(() => {
    dispatch(
      fetchUserTasks({
        projectId,
        userId: userId!,
        fetchNext: true,
      })
    );
  }, [dispatch, projectId, userId]);

  const handleUpdateFilters = useCallback(
    (updatedFilters) => {
      dispatch(setFilters(updatedFilters));
      dispatch(
        fetchUserTasks({
          projectId,
          userId: userId!,
          includeTotalCount: true,
        })
      );
    },
    [dispatch, projectId, userId]
  );

  const handleSearchFilterChange = useCallback(
    (value: string) => {
      dispatch(setSearchFilter(value));
      dispatch(
        fetchUserTasks({
          projectId,
          userId: userId!,
          includeTotalCount: true,
        })
      );
    },
    [dispatch, projectId, userId]
  );

  const handleExportTasks = useCallback(async () => {
    try {
      const response = await exportTasksList({
        ...filters,
        projectId,
        user: [userId!],
      });
      const filename = extractFilenameFromContentDisposition(
        response.headers['content-disposition']
      );
      createDownloadLinkAndDownloadFile(response.data, filename);
    } catch (error) {
      showNotification({
        notificationVariant: 'error',
        notificationTitle: intl.get('TASKS_LIST_PAGE.EXPORT_TASKS_ERROR.TITLE'),
        notificationMessage: intl.get(
          'TASKS_LIST_PAGE.EXPORT_TASKS_ERROR.MESSAGE'
        ),
      });
    }
  }, [projectId, userId, filters, showNotification]);

  if (isLoading) {
    return (
      <SkeletonLoader
        data-testid={`${TASKS_TABLE_TABS.MY_TASKS}__skeleton-loader`}
      />
    );
  }

  return (
    <>
      <TasksTable
        tableName={TASKS_TABLE_TABS.MY_TASKS}
        tasks={userTasks}
        canFetchMoreTasks={canFetchMoreTasks}
        fetchMoreTasks={handleFetchMoreTasks}
        totalCount={totalCount}
        filters={filters}
        onUpdateFilters={handleUpdateFilters}
        onSearchFilterChange={handleSearchFilterChange}
        onExport={handleExportTasks}
      />
      {isNotificationVisible && snackbarProps && (
        <SnackbarNotification {...snackbarProps} />
      )}
    </>
  );
};

export default UserTasks;
