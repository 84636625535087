import { api } from './api';
import axios from 'axios';
import {
  LinkedTaskContent,
  LinkedTaskContentUploader,
} from 'types/store/linkedTaskContent';

interface FetchLinkedTaskContentParams {
  taskId: string;
}

export interface FetchLinkedTaskContentResponse {
  linkedContent: LinkedTaskContent[];
  uploaders: Record<string, LinkedTaskContentUploader>;
}

export const fetchLinkedTaskContent = async ({
  taskId,
}: FetchLinkedTaskContentParams) => {
  const url = `/v2/tasks/${taskId}/linked-content`;
  const response = await api.get<FetchLinkedTaskContentResponse>(url);
  if (axios.isAxiosError(response)) {
    throw response;
  }
  return response.data;
};

export interface UpdateLinkedTaskContentParams {
  taskId: string;
  contentIds: string[];
}

interface AddLinkedTaskContentResponse {
  linkedContent: LinkedTaskContent[];
  uploader: LinkedTaskContentUploader;
}

export const addLinkedTaskContent = async ({
  taskId,
  contentIds,
}: UpdateLinkedTaskContentParams) => {
  const url = `/v2/tasks/${taskId}/linked-content`;
  const response = await api.post<AddLinkedTaskContentResponse>(url, {
    contentIds,
  });
  if (axios.isAxiosError(response)) {
    throw response;
  }
  return response.data;
};

export const removeLinkedTaskContent = async ({
  taskId,
  contentIds,
}: UpdateLinkedTaskContentParams) => {
  const queryParams = new URLSearchParams();
  contentIds.forEach((contentId) => {
    queryParams.append('contentIds[]', contentId);
  });
  const url = `/v2/tasks/${taskId}/linked-content?${queryParams.toString()}`;
  const response = await api.delete(url);
  if (axios.isAxiosError(response)) {
    throw response;
  }
  return response.data;
};
