import { useEffect, useMemo, useState } from 'react';
import intl from 'react-intl-universal';
import { useSelector } from 'react-redux';
import {
  Typography,
  FormItem,
  TextField,
  Dropdown,
  TextArea,
  Datepicker,
  UsersPicker,
  NumericInput,
  tailwindOverride,
  Tooltip,
  Icon,
  useLink,
} from '@getsynapse/design-system';
import { allUsers } from 'state/UsersManagement/usersManagementSlice';
import moment from 'moment';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getFormattedTaskAssignees } from '../helpers/helpers';
import { getTaskType } from 'utils/tasks';
import {
  TASK_FIELDS,
  TASK_STATUS,
  TASK_TYPES,
  LONG_INPUTS_LENGTH,
  PROJECT_STATUS,
  DATE,
  TASK_TYPE_CATEGORY,
  PATHS,
} from 'utils/constants';
import {
  objKeyAsString,
  Option,
  rangeDate,
  TaskActualHours,
  TaskDetailType,
  UserOption,
} from 'utils/customTypes';
import { getCurrentProjectData } from 'state/Project/projectSlice';
import { selectUser } from 'state/User/userSlice';
import {
  getSingleTaskData,
  getAvailableUsersForTaskAssignees,
} from 'state/SingleTask/singleTaskSlice';
import { selectAllUsersForDropdown } from 'state/UsersManagement/usersManagementSlice';
import TypePicker from 'Molecules/TypePicker/TypePicker';
import TaskActualHoursModal from './TaskActualHours/TaskActualHoursModal';
import TaskActualHoursLog from './TaskActualHours/TaskActualHoursLog';
import { Link, useHistory } from 'react-router-dom';

type TaskDetailsProps = {
  setData: (item: string, value: string | string[] | TaskActualHours[]) => void;
  data: TaskDetailType;
  requiredFieldsErrors?: objKeyAsString;
  isViewOnly?: boolean;
  canUpdateTask?: boolean;
  isOnSidepanel?: boolean;
  canDeleteActualHours?: boolean;
  setShowActualHoursLog?: React.Dispatch<React.SetStateAction<boolean>>;
  showActualHoursLog?: boolean;
};

const TaskDetails = ({
  setData,
  data,
  requiredFieldsErrors,
  isViewOnly = false,
  canUpdateTask = false,
  isOnSidepanel = false,
  canDeleteActualHours = false,
  setShowActualHoursLog,
  showActualHoursLog = false,
}: TaskDetailsProps) => {
  const history = useHistory();
  const { tasksAssignmentsBasedResourcesCapacity = false } = useFlags();
  const projectData = useSelector(getCurrentProjectData);
  const currentUser = useSelector(selectUser);
  const originalTaskData = useSelector(getSingleTaskData);
  const assignedUsers = data.assignedUsers.map((user) => {
    if (typeof user === 'string') {
      return user;
    } else {
      return get(user, 'id');
    }
  });
  const linkClassName = useLink();
  const hasAssignedUsers = assignedUsers.length > 0;

  const canAddActualHours = useMemo(
    () =>
      !(
        originalTaskData.status === TASK_STATUS.COMPLETED &&
        data.status === TASK_STATUS.COMPLETED
      ) &&
      data.status !== TASK_STATUS.ON_HOLD &&
      !data.disabled &&
      (assignedUsers as string[]).includes(currentUser.id!),
    [
      currentUser.id,
      assignedUsers,
      data.status,
      data.disabled,
      originalTaskData.status,
    ]
  );
  const actualHoursLastUpdate = useMemo(() => {
    const actualHoursOrderedByUpdateDate = orderBy(
      originalTaskData.taskActualHours,
      'updatedAt',
      'desc'
    );
    const lastUpdatedDate: TaskActualHours = actualHoursOrderedByUpdateDate[0];
    return moment(lastUpdatedDate?.updatedAt).format(DATE.SHORT_FORMAT);
  }, [originalTaskData]);

  const orgnizationUsers = useSelector(allUsers);
  const availableUsers = useSelector(
    tasksAssignmentsBasedResourcesCapacity
      ? selectAllUsersForDropdown
      : getAvailableUsersForTaskAssignees
  );
  const selectedUsers = getFormattedTaskAssignees(
    assignedUsers,
    orgnizationUsers
  );
  const [hasTaskStarted, setHasTaskStarted] = useState(
    moment(new Date(data.start_date!)).isBefore(moment())
  );
  const [isActualHoursModalOpen, setIsActualHoursModalOpen] =
    useState<boolean>(false);

  const statusOptions = useMemo(() => {
    let options: Option[] = [];
    Object.keys(TASK_STATUS).filter((key) => {
      if (!(!hasTaskStarted && key === TASK_STATUS.COMPLETED.toUpperCase())) {
        options.push({
          label: intl.get(`TASKS.TASK_DETAIL_PAGE.STATUS_OPTIONS.${key}`),
          value: TASK_STATUS[key],
        });
      }
      return options;
    });
    return options;
  }, [hasTaskStarted]);

  useEffect(() => {
    setHasTaskStarted(moment(new Date(data.start_date!)).isBefore(moment()));
  }, [data.start_date]);

  const updateOwners = (owners: UserOption[]) => {
    const ownersIds = owners.map((owner) => owner.value);
    setData(TASK_FIELDS.ASSIGNEE_UPDATE, ownersIds);
  };

  const getInitialValueForDropDown = (
    options: Option[],
    values: string[] | string | undefined
  ) => {
    if (!values) {
      return [];
    }
    return options.filter(
      (option) => values === option.value || values.includes(option.value)
    );
  };

  const isReadOnly =
    [PROJECT_STATUS.CLOSED, PROJECT_STATUS.CANCELED].includes(
      projectData.status
    ) || projectData.is_archived;

  const getProjectLink = () => (
    <FormItem
      label={intl.get('ENTITIES.PROJECT', { num: 1 })}
      className={tailwindOverride({ 'mt-2': isOnSidepanel })}
    >
      <Link
        to={{
          pathname: `${PATHS.PROJECT_PAGE}/${data.project_id}`,
          state: {
            from: history.location.pathname,
          },
        }}
        className={tailwindOverride(
          linkClassName,
          'flex items-center font-semibold'
        )}
      >
        {projectData.title}
        <Icon name='open-outline' className='ml-2 font-semibold' />
      </Link>
    </FormItem>
  );

  const getDescriptionField = () => (
    <FormItem
      label={intl.get('TASKS.TASK_DETAIL_PAGE.TASK_DESCRIPTION_LABEL')}
      className={tailwindOverride({ 'mt-2': isOnSidepanel }, 'row-span-2')}
    >
      <TextArea
        disabled={isViewOnly || isReadOnly}
        value={data.description}
        textAreaProps={{
          placeholder: intl.get(
            'TASKS.TASK_DETAIL_PAGE.TASK_DESCRIPTION_PLACEHOLDER'
          ),
          className: tailwindOverride('max-h-32', {}),
          'data-cy': 'task-description-input',
        }}
        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
          setData(TASK_FIELDS.DESCRIPTION, event.target.value)
        }
      />
    </FormItem>
  );

  const getAssigneeField = () => (
    <FormItem
      label={intl.get('TASKS.TASK_DETAIL_PAGE.TASK_ASSIGNEE')}
      disabled={isViewOnly || isReadOnly}
      className={tailwindOverride({ 'mt-4': isOnSidepanel })}
    >
      <UsersPicker
        disabled={isViewOnly || isReadOnly}
        triggerText={intl.get('TASKS.ADD_TASK_MODAL.TASK_ASSIGNEE_PLACEHOLDER')}
        deactivatedUserText={intl.get('DEACTIVATED')}
        usersList={availableUsers}
        selectedUsersList={selectedUsers}
        onChange={updateOwners}
        triggerProps={{
          'data-cy': 'task-users-picker',
        }}
      />
    </FormItem>
  );

  const getDatesField = () => {
    return (
      <FormItem className={tailwindOverride({ 'mt-4': isOnSidepanel })}>
        <Datepicker
          disabled={isViewOnly || isReadOnly}
          className='w-full'
          canSelectRange
          startDate={data.start_date && new Date(data.start_date)}
          startDateLabel={intl.get('TASKS.TASK_DETAIL_PAGE.START_DATE_LABEL')}
          startDateLabelProps={{
            required: hasAssignedUsers,
            state: requiredFieldsErrors?.start_date ? 'error' : 'default',
          }}
          startDateError={
            requiredFieldsErrors?.start_date
              ? intl.get('TASKS.TASK_DETAIL_PAGE.MISSING_INFO_ERROR')
              : ''
          }
          endDate={data.due_date && new Date(data.due_date)}
          endDateLabel={intl.get('TASKS.TASK_DETAIL_PAGE.DUE_DATE_LABEL')}
          endDateError={
            requiredFieldsErrors?.due_date
              ? intl.get('TASKS.TASK_DETAIL_PAGE.MISSING_INFO_ERROR')
              : ''
          }
          endDateLabelProps={{
            required: hasAssignedUsers,
            state: requiredFieldsErrors?.due_date ? 'error' : 'default',
          }}
          size='large'
          onPickDate={(date: rangeDate) => {
            setData(TASK_FIELDS.START_DATE, date.startDate as string);
            setData(TASK_FIELDS.DUE_DATE, date.endDate as string);
          }}
          data-cy='task-date-input'
        />
      </FormItem>
    );
  };

  const getTypeField = () => (
    <TypePicker
      formItemProps={{
        label: intl.get('TASKS.TASK_DETAIL_PAGE.TASK_TYPE'),
        className: tailwindOverride({ 'mt-4': isOnSidepanel }),
      }}
      dropdownProps={{
        disabled: isViewOnly || isReadOnly,
        onChange: (option: Option) =>
          setData(TASK_FIELDS.TASK_TYPE, option.label),
        triggerProps: {
          'data-cy': 'task-type',
          placeholder: intl.get('TASKS.ADD_TASK_MODAL.TASK_TYPE_PLACEHOLDER'),
        },
      }}
      currentValue={getTaskType(data.type || '', TASK_TYPE_CATEGORY.DETAIL)}
      options={Object.keys(TASK_TYPES)}
      labelRoute='TASKS.TASK_TYPES'
    />
  );

  const getEstimatedTimeField = () => (
    <FormItem
      component='div'
      label={intl.get('TASKS.TASK_DETAIL_PAGE.ESTIMATED_TIME_LABEL')}
      className={tailwindOverride({ 'mt-4': isOnSidepanel })}
    >
      <div className='flex items-center'>
        <NumericInput
          disabled={isViewOnly || isReadOnly}
          value={data.estimate_hours && parseFloat(data.estimate_hours)}
          placeholder={intl.get(
            'TASKS.TASK_DETAIL_PAGE.ESTIMATED_TIME_PLACEHOLDER'
          )}
          divProps={{ className: 'flex-1' }}
          containerClassName='w-full'
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setData(TASK_FIELDS.ESTIMATED_HOURS, event.target.value);
          }}
          data-cy='task-estimated-time-input'
        />
        <span className='pl-2'>
          {intl.get('TASKS.TASK_DETAIL_PAGE.HOURS_LABEL')}
        </span>
      </div>
    </FormItem>
  );

  const getTitleField = () => (
    <FormItem
      helpText={
        requiredFieldsErrors?.name &&
        intl.get('TASKS.TASK_DETAIL_PAGE.MISSING_INFO_ERROR')
      }
      helpTextProps={{
        state: requiredFieldsErrors?.name ? 'error' : 'default',
      }}
    >
      <TextField
        label={intl.get('TASKS.TASK_DETAIL_PAGE.TASK_TITLE_LABEL')}
        labelProps={{
          required: true,
          state: requiredFieldsErrors?.name ? 'error' : 'default',
        }}
        value={data.name}
        variant='text'
        displayCharactersCount
        maxLength={LONG_INPUTS_LENGTH}
        placeholder={intl.get('TASKS.TASK_DETAIL_PAGE.TASK_TITLE_PLACEHOLDER')}
        data-cy='task-title-input'
        disabled={isViewOnly || isReadOnly}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setData(TASK_FIELDS.NAME, event.target.value)
        }
      />
    </FormItem>
  );

  const getActualHoursField = () => {
    const actualHoursButton = (
      <button
        className={tailwindOverride(
          'text-purple-dark hover:text-purple-darker focus-visible:text-purple-darker active:text-purple-darker py-1 focus:outline-none text-sm leading-6 focus:ring-0',
          'disabled:text-primary-lighter',
          {
            'text-primary-lighter hover:text-primary-lighter focus-visible:text-primary-lighter active:text-primary-lighter':
              data.status === TASK_STATUS.NEW,
          }
        )}
        onClick={() => {
          if (data.status === TASK_STATUS.NEW) return;
          isOnSidepanel
            ? setShowActualHoursLog!(true)
            : setIsActualHoursModalOpen(true);
        }}
        data-testid='add-actual-hours-button'
        disabled={new Date(data.start_date!) > new Date()}
      >
        <div className='flex items-center'>
          <Icon
            name={canAddActualHours ? 'add-circle' : 'eye'}
            className='mr-2 text-xl'
          />
          {canAddActualHours
            ? intl.get('TASKS.TASK_DETAIL_PAGE.ADD_ACTUAL_HOURS')
            : intl.get('TASKS.TASK_DETAIL_PAGE.VIEW_ACTUAL_HOURS')}
        </div>
      </button>
    );

    return (
      <div>
        <FormItem
          component='div'
          label={
            <div className='flex'>
              <Typography className='text-label leading-4 font-semibold mr-1'>
                {intl.get('TASKS.TASK_DETAIL_PAGE.TOTAL_ACTUAL_HOURS')}
              </Typography>
              {!isEmpty(originalTaskData.taskActualHours) && (
                <Typography className='text-label leading-4 text-neutral-dark'>
                  {intl.get('TASKS.TASK_DETAIL_PAGE.ACTUAL_HOURS_LAST_UPDATE', {
                    date: actualHoursLastUpdate,
                  })}
                </Typography>
              )}
            </div>
          }
          labelProps={{ htmlFor: 'actual-hours-field' }}
          className={tailwindOverride({ 'mt-4': isOnSidepanel })}
        >
          <TextField
            id='actual-hours-field'
            value={`${
              (data.actual_hours && parseFloat(data.actual_hours)) || '00'
            }h`}
            data-cy='task-actual-hours'
            divProps={{ className: 'flex-1' }}
            readOnly
          />
        </FormItem>
        {data.status === TASK_STATUS.NEW ? (
          <Tooltip
            trigger={actualHoursButton}
            openMode='hover1'
            hasMaxWidth={false}
            timeout={0}
            contentProps={{
              'data-testid': 'actual-hours-tooltip',
            }}
          >
            {intl.get('TASKS.TASK_DETAIL_PAGE.ACTUAL_HOURS_TOOLTIP')}
          </Tooltip>
        ) : (
          actualHoursButton
        )}
      </div>
    );
  };

  const getActualCompletionDate = () =>
    data.status === TASK_STATUS.COMPLETED && (
      <FormItem
        label={intl.get('TASKS.TASK_DETAIL_PAGE.ACTUAL_COMPLETION_DATE')}
        labelProps={{ required: true }}
        component='div'
        className={tailwindOverride({ 'mt-4': isOnSidepanel }, 'ml-4 w-full')}
        helpText={
          requiredFieldsErrors?.completion_date &&
          intl.get('TASKS.TASK_DETAIL_PAGE.MISSING_INFO_ERROR')
        }
        helpTextProps={{
          state: requiredFieldsErrors?.completion_date ? 'error' : 'default',
        }}
      >
        <Datepicker
          disabled={isViewOnly || isReadOnly}
          className='w-full'
          startDate={data.completion_date && new Date(data.completion_date)}
          onPickDate={(date: { startDate: Date | string }) => {
            setData(TASK_FIELDS.COMPLETION_DATE, date.startDate as string);
          }}
          minDate={data.start_date && new Date(data.start_date)}
        />
      </FormItem>
    );

  const getStatusField = () => (
    <FormItem
      label={intl.get('TASKS.TASK_DETAIL_PAGE.STATUS')}
      component='div'
      className={tailwindOverride({ 'mt-4': isOnSidepanel }, 'w-full')}
    >
      <Dropdown
        placeholder={intl.get('TASKS.TASK_DETAIL_PAGE.STATUS_PLACEHOLDER')}
        options={statusOptions}
        disabled={
          data.status === TASK_STATUS.ON_HOLD
            ? !canUpdateTask
            : isViewOnly || isReadOnly
        }
        values={getInitialValueForDropDown(statusOptions, data['status'])}
        triggerProps={{
          'data-cy': 'task-status-input',
          'aria-label': intl.get('TASKS.TASK_DETAIL_PAGE.STATUS'),
        }}
        onChange={(option: Option) => setData(TASK_FIELDS.STATUS, option.value)}
        renderOption={(
          option: Option,
          isSelected: boolean,
          selectOption,
          { className, ...otherProps }
        ) => {
          const isOptionDisabled =
            option.value === TASK_STATUS.COMPLETED &&
            data.assignedUsers.length === 0;
          return (
            <li
              {...otherProps}
              onClick={!isOptionDisabled ? selectOption : () => {}}
              tabIndex={isSelected ? 0 : -1}
              className={tailwindOverride(
                'font-body text-base truncate cursor-pointer group px-4 py-2 text-neutral-black',
                'focus-visible:border-0 focus-visible:ring-0 focus-visible:outline-none',
                {
                  'hover:text-neutral-dark hover:shadow-list-item-hover hover:bg-neutral-lighter-two':
                    !isSelected && !isOptionDisabled,
                  'focus-visible:bg-neutral-lighter-two focus-visible:text-neutral-dark focus-visible:shadow-list-item-hover':
                    !isSelected && !isOptionDisabled,
                  'text-neutral-light cursor-not-allowed hover:text-neutral-light hover:shadow-none hover:bg-transparent':
                    isOptionDisabled,
                  'bg-primary focus-visible:bg-primary text-neutral-white':
                    isSelected,
                }
              )}
            >
              <div className='flex justify-between items-center'>
                <span>{option.label}</span>
                {isOptionDisabled && (
                  <Typography variant='caption' className='text-neutral'>
                    {intl.get('TASKS.TASK_DETAIL_PAGE.ASSIGNEES_REQUIRED')}
                  </Typography>
                )}
              </div>
            </li>
          );
        }}
      />
    </FormItem>
  );
  return isOnSidepanel && showActualHoursLog ? (
    <TaskActualHoursLog
      currentTaskStatus={data.status}
      originalTaskStatus={originalTaskData.status}
      isTaskDisabled={data.disabled}
      taskStartDate={data.start_date!}
      canAddActualHours={canAddActualHours}
      canDeleteActualHours={canDeleteActualHours}
      taskActualHoursTemp={data.taskActualHours!}
      setTaskActualHoursTemp={setData}
      isOnSidepanel
    />
  ) : isOnSidepanel && !showActualHoursLog ? (
    <div className='flex flex-col'>
      {getProjectLink()}
      {getAssigneeField()}
      {getDescriptionField()}
      {getDatesField()}
      {getTypeField()}
      <div className='flex'>
        {getStatusField()}
        {getActualCompletionDate()}
      </div>
      {getEstimatedTimeField()}
      {getActualHoursField()}
    </div>
  ) : (
    <div className='mt-4 border-b border-neutral-lighter pb-10'>
      {data.taskActualHours && (
        <TaskActualHoursModal
          setData={setData}
          data={data}
          originalTaskStatus={originalTaskData.status}
          canAddActualHours={canAddActualHours}
          isOpen={isActualHoursModalOpen}
          setIsOpen={setIsActualHoursModalOpen}
          canDeleteActualHours={canDeleteActualHours}
        />
      )}
      <Typography variant='h5'>
        {intl.get('TASKS.TASK_DETAIL_PAGE.TITLE')}
      </Typography>
      <div className='grid-cols-2 w-full grid gap-x-10% gap-y-4 px-px mt-8'>
        {getTitleField()}
        {getProjectLink()}
        {getDescriptionField()}
        {getAssigneeField()}
        {getDatesField()}
        {getTypeField()}
        <div className='flex'>
          {getStatusField()}
          {getActualCompletionDate()}
        </div>
        {getEstimatedTimeField()}
        {getActualHoursField()}
      </div>
    </div>
  );
};

export default TaskDetails;
