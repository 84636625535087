import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import {
  FormItem,
  tailwindOverride,
  TextField,
  Typography,
  Button,
} from '@getsynapse/design-system';
import { selectTaskActualHoursSum } from 'state/TaskDetail/taskSlice';
import TaskActualHoursModal from 'Pages/TaskDetailPage/ActualHoursModal/ActualHoursModal';

interface TaskActualHoursFieldProps {
  taskId: string;
  canAddActualHours?: boolean;
  isOnSidepanel?: boolean;
}

const TaskActualHoursField: FC<TaskActualHoursFieldProps> = ({
  taskId,
  canAddActualHours = true,
  isOnSidepanel = false,
}) => {
  const taskActualHoursSum = useSelector(selectTaskActualHoursSum);
  const [displayActualHoursModal, setDisplayActualHoursModal] =
    useState<boolean>(false);

  const toggleActualHoursModal = () => {
    setDisplayActualHoursModal(!displayActualHoursModal);
  };

  return (
    <>
      <TaskActualHoursModal
        taskId={taskId}
        canAddActualHours={canAddActualHours}
        shouldDisplayModal={displayActualHoursModal}
        toggleDisplayModal={toggleActualHoursModal}
      />
      <FormItem
        label={intl.get('TASKS.TASK_DETAIL_PAGE.ACTUAL_HOURS')}
        labelProps={{ htmlFor: 'actual-hours-field' }}
        className={tailwindOverride({ 'mt-4': isOnSidepanel })}
      >
        <div className='flex gap-x-2 items-center'>
          <TextField
            id='actual-hours-field'
            value={`${taskActualHoursSum}h`}
            aria-label='task-actual-hours-field'
            divProps={{ className: 'flex-1' }}
            readOnly
            className='flex-1'
          />
          <Typography variant='body' className='text-neutral-black'>
            hrs
          </Typography>
          <Button
            variant='secondary'
            className='py-3 ml-2'
            onClick={toggleActualHoursModal}
          >
            {intl.get('TASKS.TASK_DETAIL_PAGE.ADD_HOURS_BUTTON')}
          </Button>
        </div>
      </FormItem>
    </>
  );
};

export default TaskActualHoursField;
