import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from 'state/store';
import { TaskAssignee, TaskProject } from 'types/store/tasks';
import { NewTask } from 'types/store/newTask';
import { fetchTeamTasks } from '../teamTasks/teamTasksSlice';
import { fetchMyTasks } from '../myTasks/myTasksSlice';
import { fetchTaskStatusList } from 'api/taskStatusList';
import { fetchTaskTypeList } from 'api/taskTypeList';
import { createNewTask } from 'api/newTask';

export interface AllTasksSliceState {
  assignees: Record<string, TaskAssignee>;
  projects: Record<string, TaskProject>;
  taskTypesLoading: boolean;
  taskTypes: Record<string, string>;
  taskStatuesLoading: boolean;
  taskStatuses: Record<string, string>;
  shouldRefreshTasks?: boolean;
}

interface CreateNewTaskParams {
  newTasks: NewTask[];
  userId: string;
  sendNotifications?: boolean;
}

const initialState: AllTasksSliceState = {
  assignees: {},
  projects: {},
  taskTypesLoading: false,
  taskTypes: {},
  taskStatuesLoading: false,
  taskStatuses: {},
  shouldRefreshTasks: false,
};

export const fetchTaskTypes = createAsyncThunk(
  'taskDetail/fetchTaskTypes',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchTaskTypeList();
      return response.taskTypes;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue('Error fetching team tasks');
    }
  }
);

export const fetchTaskStatuses = createAsyncThunk(
  'taskDetail/fetchTaskStatues',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchTaskStatusList();
      return response.taskStatuses;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue('Error fetching team tasks');
    }
  }
);

export const createTask = createAsyncThunk(
  'tasksList/createTask',
  async (
    { newTasks, userId, sendNotifications = true }: CreateNewTaskParams,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await createNewTask({
        newTasks,
        sendNotifications,
      });
      dispatch(fetchTeamTasks({}));
      dispatch(fetchMyTasks({ userId }));
      return response.successes[0];
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue(error);
    }
  }
);

const allTasksSlice = createSlice({
  name: 'allTasks',
  initialState,
  reducers: {
    setTasksAssignees: (state, action) => {
      state.assignees = {
        ...state.assignees,
        ...action.payload,
      };
    },
    setTasksProjects: (state, action) => {
      state.projects = {
        ...state.projects,
        ...action.payload,
      };
    },
    setShouldRefreshTasks: (state, action) => {
      state.shouldRefreshTasks = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTaskTypes.pending, (state) => {
        state.taskTypesLoading = true;
      })
      .addCase(fetchTaskTypes.fulfilled, (state, action) => {
        state.taskTypesLoading = false;

        state.taskTypes = action.payload.reduce<Record<string, string>>(
          (p, type) => {
            p[type.code] = type.name;

            return p;
          },
          {}
        );
      })
      .addCase(fetchTaskTypes.rejected, (state) => {
        state.taskTypesLoading = false;
      })
      .addCase(fetchTaskStatuses.pending, (state) => {
        state.taskStatuesLoading = true;
      })
      .addCase(fetchTaskStatuses.fulfilled, (state, action) => {
        state.taskStatuesLoading = false;

        state.taskStatuses = action.payload.reduce<Record<string, string>>(
          (p, type) => {
            p[type.code] = type.name;

            return p;
          },
          {}
        );
      })
      .addCase(fetchTaskStatuses.rejected, (state) => {
        state.taskStatuesLoading = false;
      })
      .addCase(createTask.rejected, (state, action) => {
        throw new Error(action.payload as string);
      });
  },
});

export const { setTasksAssignees, setTasksProjects, setShouldRefreshTasks } =
  allTasksSlice.actions;

export const selectTaskAssignees = (state: RootState) =>
  state.tasksList.allTasks.assignees;
export const selectTaskProjects = (state: RootState) =>
  state.tasksList.allTasks.projects;

export const selectTaskTypes = (state: RootState) =>
  state.tasksList.allTasks.taskTypes;

export const selectTaskTypesLoadingStatus = (state: RootState) =>
  state.tasksList.allTasks.taskTypesLoading;

export const selectShouldRefreshTasks = (state: RootState) =>
  state.tasksList.allTasks.shouldRefreshTasks;

export const selectCanFetchTaskTypes = createSelector(
  [selectTaskTypes, selectTaskTypesLoadingStatus],
  (taskTypes: Record<string, string>, areTaskTypesLoading: boolean) => {
    return Object.keys(taskTypes).length === 0 && areTaskTypesLoading === false;
  }
);

export const selectTaskTypeByCode = createSelector(
  [selectTaskTypes, (_: RootState, taskTypeCode: string) => taskTypeCode],
  (taskTypes: Record<string, string>, taskTypeCode: string) =>
    taskTypes[taskTypeCode] ?? undefined
);

export const selectTaskStatusesLoadingStatus = (state: RootState) =>
  state.tasksList.allTasks.taskStatuesLoading;

export const selectTaskStatuses = (state: RootState) =>
  state.tasksList.allTasks.taskStatuses;

export const selectCanFetchTaskStatues = createSelector(
  [selectTaskStatuses, selectTaskStatusesLoadingStatus],
  (taskStatues: Record<string, string>, areTaskStatusesLoading: boolean) => {
    return (
      Object.keys(taskStatues).length === 0 && areTaskStatusesLoading === false
    );
  }
);

export const selectAssigneesListByIds = createSelector(
  [selectTaskAssignees, (_: RootState, assigneesIds: string[]) => assigneesIds],
  (taskAssignees: AllTasksSliceState['assignees'], assigneesIds: string[]) => {
    const assignees: TaskAssignee[] = [];
    assigneesIds.forEach((id) => {
      if (id in taskAssignees) {
        assignees.push(taskAssignees[id]);
      }
    });
    return assignees;
  }
);

export const selectTaskProjectById = createSelector(
  [selectTaskProjects, (_: RootState, projectId: string) => projectId],
  (taskProjects: AllTasksSliceState['projects'], projectId: string) =>
    taskProjects[projectId] ?? ({} as TaskProject)
);

export default allTasksSlice.reducer;
