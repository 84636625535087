import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import { useHistory } from 'react-router';
import { getAllUsers } from 'state/UsersManagement/usersManagementSlice';
import { addProjectTemplate } from 'state/ProjectTemplates/projectTemplatesSlice';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import {
  PATHS,
  SETTINGS_TABS,
  SETTINGS_SECTIONS,
  SETTINGS_ATTRIBUTES,
} from 'utils/constants';
import { ProjectTemplateStatus } from 'utils/types/templates';
import useTemplateForm from '../../hooks/useTemplateForm';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import DetailsPage from 'Molecules/DetailsPage/DetailsPage';
import TemplateContentTabs from '../TemplatePage/Form/TemplateContentTabs';
import TopBar from '../TemplatePage/Form/TopBar';
import PageFooter from '../TemplatePage/Form/PageFooter';
import UnsavedChangesPrompt from 'Organisms/UnsavedChangesPrompt/UnsavedChangesPrompt';

const NewTemplatePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { templateData, handleFieldChange, canSubmitForm, hasChanges } =
    useTemplateForm();

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  const returnToTablePage = () =>
    history.push(
      `${PATHS.SETTINGS}/${SETTINGS_TABS.CONFIGURATIONS}?section=${SETTINGS_SECTIONS.PROJECTS}&attribute=${SETTINGS_ATTRIBUTES.TEMPLATES}`
    );

  const handleSave = async () => {
    setIsSubmitting(true);
    await dispatch(addProjectTemplate({ template: templateData }));
    dispatch(
      showNotificationBanner({
        notificationVariant: 'success',
        notificationText: intl.get(
          'SETTINGS_PAGE.TEMPLATE_PAGE.SUCCESS_MESSAGE',
          { action: intl.get('SETTINGS_PAGE.TEMPLATE_PAGE.ACTIONS.ADD') }
        ),
      })
    );
    returnToTablePage();
  };

  return (
    <div className='h-full flex flex-col shadow-skim'>
      <PageTitle
        titleComponent={intl.get('SETTINGS_PAGE.TEMPLATE_PAGE.ADD_TEMPLATE')}
      />
      <DetailsPage
        bodyClassName='overflow-y-hidden'
        topBar={
          <TopBar
            status={templateData.status}
            onUpdateStatus={(status: ProjectTemplateStatus) =>
              handleFieldChange('status', status)
            }
          />
        }
        content={
          <TemplateContentTabs
            templateData={templateData}
            updateTemplateData={handleFieldChange}
          />
        }
      />
      <PageFooter
        canSave={canSubmitForm}
        onCancel={returnToTablePage}
        onSave={handleSave}
        isLoading={isSubmitting}
      />
      <UnsavedChangesPrompt hasChanges={hasChanges} />
    </div>
  );
};

export default NewTemplatePage;
