import { FC, Dispatch, SetStateAction } from 'react';
import intl from 'react-intl-universal';
import MyWorspaceModal from '../MyWorkspaceModal';
import TasksTable from './TasksTable';
import dueTasksIcon from 'assets/icons/due-tasks-icon.svg';

interface TasksModalProps {
  isOpen?: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const TasksModal: FC<TasksModalProps> = ({ isOpen = false, setIsOpen }) => {
  return (
    <MyWorspaceModal
      title={intl.get('MY_WORKSPACE.DUE_TASKS.MODAL_TITLE')}
      icon={dueTasksIcon}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      content={<TasksTable cardTable={false} />}
      dataCy='due-tasks-modal'
    />
  );
};

export default TasksModal;
