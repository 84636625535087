import { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { Status } from 'utils/customTypes';
import { SLICE_STATUS } from 'utils/constants';
import { CENTRALIZED_TASKS_TABLE_TABS } from 'utils/constants/centralizedTasks';
import {
  extractFilenameFromContentDisposition,
  createDownloadLinkAndDownloadFile,
} from 'utils/csvExports';
import { TaskListFilters } from 'types/store/tasksList';
import {
  fetchTeamTasks,
  selectFetchTeamTasksStatus,
  selectTeamTasks,
  setFilters,
  setSearchFilter,
  selectTeamTasksTableFilters,
  selectTeamTasksSorting,
  setSorting,
  selectCanFetchMoreTeamTasks,
  selectTeamTasksTotalCount,
} from 'state/TasksList/teamTasks/teamTasksSlice';
import {
  selectShouldRefreshTasks,
  setShouldRefreshTasks,
} from 'state/TasksList/allTasks/allTasksSlice';
import { fetchFilterSettingByType } from 'state/Settings/Filters/FiltersSlice';
import { exportTasksList } from 'api/tasksList';
import useSnackbarNotification from 'Hooks/useSnackbarNotification';
import TasksListTable from 'Pages/TasksListPage/components/v2/TasksListTable/TasksListTable';
import SkeletonLoader from 'Organisms/TableSkeletonLoader/PageTableLoader';

const TeamTasks = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const shouldRefreshTasks = useSelector(selectShouldRefreshTasks);
  const fetchTeamTasksStatus = useSelector(selectFetchTeamTasksStatus);
  const teamTasks = useSelector(selectTeamTasks);
  const teamTasksSorting = useSelector(selectTeamTasksSorting);
  const teamTasksTableFilters = useSelector(selectTeamTasksTableFilters);
  const canFetchMoreTasks = useSelector(selectCanFetchMoreTeamTasks);
  const totalCount = useSelector(selectTeamTasksTotalCount);
  const prevFetchTeamTasksStatus = useRef<Status>(fetchTeamTasksStatus);

  const {
    SnackbarNotification,
    showNotification,
    isNotificationVisible,
    snackbarProps,
  } = useSnackbarNotification();

  const init = useCallback(async () => {
    await dispatch(
      fetchFilterSettingByType(CENTRALIZED_TASKS_TABLE_TABS.TEAM_TASKS)
    );
    dispatch(
      fetchTeamTasks({
        fetchNext: false,
        includeTotalCount: true,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    if (shouldRefreshTasks) {
      dispatch(setShouldRefreshTasks(false));
      init();
    }
  }, [shouldRefreshTasks, dispatch, init]);

  useEffect(() => {
    if (fetchTeamTasksStatus !== prevFetchTeamTasksStatus.current) {
      prevFetchTeamTasksStatus.current = fetchTeamTasksStatus;
      if (isLoading && fetchTeamTasksStatus === SLICE_STATUS.IDLE) {
        setIsLoading(false);
      }
    }
  }, [fetchTeamTasksStatus, isLoading]);

  const handleUpdateFilters = useCallback(
    (updatedFilters: TaskListFilters) => {
      dispatch(setFilters(updatedFilters));
      dispatch(
        fetchTeamTasks({
          fetchNext: false,
          includeTotalCount: true,
        })
      );
    },
    [dispatch]
  );

  const handleSearchFilterChange = useCallback(
    (value: string) => {
      dispatch(setSearchFilter(value));
      dispatch(
        fetchTeamTasks({
          fetchNext: false,
          includeTotalCount: true,
        })
      );
    },
    [dispatch]
  );

  const handleTableSort = useCallback(
    (sortBy: string, order: string) => {
      dispatch(setSorting({ sortBy, order }));
      dispatch(
        fetchTeamTasks({
          fetchNext: false,
          includeTotalCount: false,
        })
      );
    },
    [dispatch]
  );

  const handleFetchMoreTasks = useCallback(() => {
    dispatch(
      fetchTeamTasks({
        fetchNext: true,
        includeTotalCount: false,
      })
    );
  }, [dispatch]);

  const handleExportTasks = useCallback(async () => {
    try {
      const response = await exportTasksList({ ...teamTasksTableFilters });
      const filename = extractFilenameFromContentDisposition(
        response.headers['content-disposition']
      );
      const blob = new Blob([response.data], { type: 'text/csv' });
      createDownloadLinkAndDownloadFile(blob, filename);
    } catch (error) {
      showNotification({
        notificationVariant: 'error',
        notificationTitle: intl.get('TASKS_LIST_PAGE.EXPORT_TASKS_ERROR.TITLE'),
        notificationMessage: intl.get(
          'TASKS_LIST_PAGE.EXPORT_TASKS_ERROR.MESSAGE'
        ),
      });
    }
  }, [teamTasksTableFilters, showNotification]);

  if (isLoading) {
    return (
      <SkeletonLoader
        data-testid={`${CENTRALIZED_TASKS_TABLE_TABS.TEAM_TASKS}__skeleton-loader`}
      />
    );
  }

  return (
    <>
      <TasksListTable
        tableName={CENTRALIZED_TASKS_TABLE_TABS.TEAM_TASKS}
        tasks={teamTasks}
        filters={teamTasksTableFilters}
        totalCount={totalCount}
        onUpdateFilters={handleUpdateFilters}
        sortBy={teamTasksSorting.sortBy}
        order={teamTasksSorting.order}
        handleSort={handleTableSort}
        canFetchMoreTasks={canFetchMoreTasks}
        fetchMoreTasks={handleFetchMoreTasks}
        onExport={handleExportTasks}
        onSearchFilterChange={handleSearchFilterChange}
      />
      {isNotificationVisible && snackbarProps && (
        <SnackbarNotification {...snackbarProps} />
      )}
    </>
  );
};

export default TeamTasks;
