import { Typography } from '@getsynapse/design-system';
import { useSelector } from 'react-redux';
import { evaluationInsightsSelectors } from 'state/Evaluations/evaluationInsightsSlice';
import { SLICE_STATUS } from 'utils/constants';
import intl from 'react-intl-universal';
import { HighestInstructorsChart } from './HighestInstructorsChart';
import { useMemo } from 'react';
import HorizontalBarChartLoader from 'Pages/InsightsPage/Components/SkeletonLoaders/HorizontalBarChartLoader';
import ErrorScreen from 'Pages/InsightsPage/Components/ErrorScreen';
import { PerformitivLink } from 'Pages/InsightsPage/Components/PerformitivLink';

const HighestInstructors = () => {
  const chartData = useSelector(evaluationInsightsSelectors.getInstructorData);
  const status = useSelector(evaluationInsightsSelectors.selectStatus);

  const formattedChartData = useMemo(
    () =>
      chartData?.map((data) => ({
        ...data,
        score: data.score.toFixed(data.scorePrecision),
      })),
    [chartData]
  );

  return (
    <>
      {status === SLICE_STATUS.LOADING && <HorizontalBarChartLoader />}
      {status === SLICE_STATUS.FAILED && <ErrorScreen />}
      {status === SLICE_STATUS.SUCCESS && (
        <div className='flex flex-col w-full h-full gap-8'>
          <div className='flex flex-row w-full justify-between align-middle'>
            <Typography
              variant='label'
              className='text-primary font-semibold leading-1'
            >
              {intl.get(
                'INSIGHTS_PAGE_TABS.INSIGHTS_EVALUATIONS.CHARTS.HIGHEST_INSTRUCTORS.TITLE',
                { count: chartData?.length || 0 }
              )}
            </Typography>
            <PerformitivLink />
          </div>

          <HighestInstructorsChart data={formattedChartData} />
        </div>
      )}
    </>
  );
};

export default HighestInstructors;
