import { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { TableOperationHeader } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import {
  updateMyScheduleSearchTerm,
  selectMyScheduleSearchTerm,
} from 'state/Schedule/ScheduleSlice';

const MyScheduleHeader = () => {
  const dispatch = useDispatch();
  const search = useSelector(selectMyScheduleSearchTerm);
  const [searchTerm, setSearchTerm] = useState(search);

  const debouncedHandleSearch = useMemo(() => {
    return debounce((value: string) => {
      dispatch(updateMyScheduleSearchTerm({ search: value }));
    }, 500);
  }, [dispatch]);

  return (
    <TableOperationHeader
      className='border-b-0'
      inputValue={searchTerm}
      setInputValue={(value: string) => {
        setSearchTerm(value);
        debouncedHandleSearch(value);
      }}
      searchProps={{
        'data-testid': 'my-schedule-search_input',
        placeholder: intl.get('SCHEDULE.SEARCH_BY_EVENT'),
      }}
    />
  );
};

export default MyScheduleHeader;
