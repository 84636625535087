import { api } from './api';
import axios from 'axios';

export interface Project {
  id: string;
  title: string;
  isArchived: boolean;
  status: string;
}

export interface ProjectsListResponse {
  projects: Project[];
}

export const fetchProjectsList = async (projectName?: string) => {
  let url = `/v2/projects`;
  if (projectName) {
    const queryParams = new URLSearchParams({ projectName }).toString();
    url += `?${queryParams}`;
  }
  const response = await api.get<ProjectsListResponse>(url);
  if (axios.isAxiosError(response)) {
    throw response;
  }
  return response.data;
};
