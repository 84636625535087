import intl from 'react-intl-universal';
import { NewTask } from 'types/store/newTask';
import { TaskState } from 'types/store/taskDetail';

export const serializeTaskDetailToDuplicate = (
  taskDetail: TaskState
): NewTask => {
  const taskData: NewTask = {
    name: `${intl.get('TASKS.TABLE.COPY_OF_PLACEHOLDER')} ${taskDetail.name}`,
    projectId: taskDetail.project.id,
  };
  if (taskDetail.description) {
    taskData.description = taskDetail.description;
  }
  if (taskDetail.type) {
    taskData.type = taskDetail.type;
  }
  if (taskDetail.startDate) {
    taskData.startDate = taskDetail.startDate;
  }
  if (taskDetail.endDate) {
    taskData.dueDate = taskDetail.endDate;
  }
  if (taskDetail.estimateHours) {
    taskData.estimateHours = parseFloat(taskDetail.estimateHours);
  }
  if (taskDetail.assignees && taskDetail.assignees.length > 0) {
    taskData.assignees = taskDetail.assignees.map((assignee) => assignee.id);
  }
  return taskData;
};
