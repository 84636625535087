import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TabPanels, TabPanel, Tabs } from '@reach/tabs';
import { Tab, TabList } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import { INSIGHTS_TABS } from 'utils/constants';
import Intake from './Intake/Intake';
import Projects from './Projects/Projects';
import Capacity from './Capacity/Capacity';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Evaluations from './Evaluations/Evaluations';

const InsightsPage = () => {
  const { insightsCapacityTab = false, performativIntegration = false } =
    useFlags();
  const location = useLocation();
  const history = useHistory();
  const [activeIndex, setActiveIndex] = useState(0);
  const queryParams = new URLSearchParams(location.search);
  const defaultTabString = queryParams.get('tab');

  const handleTabChange = (index: number) => {
    setActiveIndex(index);
    const tabToString: any = {
      0: INSIGHTS_TABS.INTAKE,
      1: INSIGHTS_TABS.PROJECTS,
      2: INSIGHTS_TABS.CAPACITY,
      3: INSIGHTS_TABS.EVALUATIONS,
    };
    const tabKey = tabToString[index];
    history.push(`${location.pathname}?tab=${tabKey}`);
  };

  useEffect(() => {
    if (defaultTabString) {
      const tabMapping = {
        [INSIGHTS_TABS.INTAKE]: 0,
        [INSIGHTS_TABS.PROJECTS]: 1,
        [INSIGHTS_TABS.CAPACITY]: 2,
        [INSIGHTS_TABS.EVALUATIONS]: 3,
      };
      setActiveIndex(tabMapping[defaultTabString]);
    }
  }, [defaultTabString]);

  return (
    <Tabs
      index={activeIndex}
      className='flex flex-col'
      onChange={handleTabChange}
    >
      <PageTitle
        dataCy='insights-page-title'
        titleComponent={intl.get('INSIGHTS_PAGE.TITLE')}
        headerChildren={
          <div className='flex'>
            <TabList type='subHeader'>
              <Tab index={0} type='subHeader'>
                {intl.get('INSIGHTS_PAGE_TABS.INTAKE')}
              </Tab>
              <Tab index={1} type='subHeader'>
                {intl.get('INSIGHTS_PAGE_TABS.PROJECTS')}
              </Tab>
              {insightsCapacityTab && (
                <Tab index={2} type='subHeader'>
                  {intl.get('INSIGHTS_PAGE_TABS.CAPACITY')}
                </Tab>
              )}
              {performativIntegration && (
                <Tab index={3} type='subHeader'>
                  {intl.get('INSIGHTS_PAGE_TABS.EVALUATIONS')}
                </Tab>
              )}
            </TabList>
          </div>
        }
      />

      <TabPanels className='px-6 relative'>
        <TabPanel key={0} className='overflow-y-hidden'>
          <Intake />
        </TabPanel>

        <TabPanel key={1}>
          <Projects />
        </TabPanel>

        {insightsCapacityTab && (
          <TabPanel key={2}>
            <Capacity />
          </TabPanel>
        )}

        {performativIntegration && (
          <TabPanel key={3}>
            <Evaluations />
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  );
};

export default InsightsPage;
