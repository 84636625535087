import {
  createAsyncThunk,
  createSelector,
  createSlice,
  createAction,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from 'state/store';
import {
  createActualHours,
  removeActualHours,
} from 'state/TaskDetail/taskSlice';
import { ActualHoursState, Hours, Submitter } from 'types/store/actualHours';
import { TaskActualHourState } from 'types/store/taskDetail';
import {
  fetchTaskActualHours,
  FetchTaskActualHoursParams,
  createTaskActualHours,
  CreateTaskActualHoursParams,
  deleteTaskActualHours,
} from 'api/taskActualHours';

const normalizeActualHoursState = (
  hours: Hours[]
): ActualHoursState['actualHours'] => {
  return hours.reduce((acc: ActualHoursState['actualHours'], hour: Hours) => {
    if (!acc[hour.submitterId]) {
      acc[hour.submitterId] = [hour];
    } else {
      acc[hour.submitterId].push(hour);
    }
    return acc;
  }, {});
};

const getActualHoursTotal = (actualHours: Hours[]) =>
  actualHours.reduce((acc, hour) => acc + hour.hours, 0);

interface DeleteActualHoursParams {
  taskId: string;
  hours: Hours[];
}

export const initialState: ActualHoursState = {
  actualHours: {},
  loading: false,
  submitters: {},
};

export const fetchActualHours = createAsyncThunk(
  'tasksDetail/fetchActualHours',
  async (params: FetchTaskActualHoursParams, { rejectWithValue }) => {
    try {
      const response = await fetchTaskActualHours(params);
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue('Error fetching team tasks');
    }
  }
);

export const addActualHours = createAsyncThunk(
  'tasksDetail/addActualHours',
  async (
    params: CreateTaskActualHoursParams,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await createTaskActualHours(params);
      const totalHours = getActualHoursTotal(response);
      dispatch(createActualHours(totalHours));
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return (error as Error).message;
    }
  }
);

export const deleteActualHours = createAsyncThunk(
  'tasksDetail/deleteActualHours',
  async (params: DeleteActualHoursParams, { rejectWithValue, dispatch }) => {
    try {
      const ids = params.hours.map((hour) => hour.id);
      await deleteTaskActualHours({
        taskId: params.taskId,
        ids,
      });
      const totalHours = getActualHoursTotal(params.hours);
      dispatch(removeActualHours(totalHours));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue('Error deleting hours');
    }
  }
);

export const removeSubmitterHours = createAction<{
  submitterId: string;
  hoursId: string;
}>('actualHours/removeSubmitterHours');

const selectSubmitters = (state: RootState) => state.actualHours.submitters;
const selectActualHours = (state: RootState) => state.actualHours.actualHours;

export const selectActualHoursAreLoading = (state: RootState) =>
  state.actualHours.loading;

export const selectCanFetchActualHours = createSelector(
  [
    selectActualHoursAreLoading,
    (state: RootState) => state.taskDetail.taskActualHours,
  ],
  (areActualHoursLoading, actualHours?: TaskActualHourState) => {
    return !actualHours && areActualHoursLoading === false;
  }
);

export const selectSubmittersIds = createSelector(
  [(state: RootState) => state.actualHours.submitters],
  (submitters: ActualHoursState['submitters']) => Object.keys(submitters)
);

const selectSubmmiterById = createSelector(
  [selectSubmitters, (_: RootState, submitterId: string) => submitterId],
  (submitters, submitterId) => submitters[submitterId]
);

const selectActualHoursBySubmitterId = createSelector(
  [selectActualHours, (_: RootState, submitterId: string) => submitterId],
  (actualHours, submitterId) => actualHours[submitterId] || []
);

export const selectSubmitterTimeEntries = createSelector(
  [selectSubmmiterById, selectActualHoursBySubmitterId],
  (submitter: Submitter, actualHours: Hours[]) => {
    const totalHours = actualHours.reduce((acc, hour) => acc + hour.hours, 0);
    return {
      submitter,
      totalHours,
      actualHours,
    };
  }
);

const actualHoursSlice = createSlice({
  name: 'actualHours',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchActualHours.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchActualHours.fulfilled, (state, action) => {
        if (action.payload) {
          const actualHours = normalizeActualHoursState(action.payload.hours);
          state.submitters = action.payload.submitters;
          state.actualHours = actualHours;
        }
        state.loading = false;
      })
      .addCase(fetchActualHours.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addActualHours.fulfilled, (state, action) => {
        if (action.payload) {
          const newHours = normalizeActualHoursState(action.payload as Hours[]);
          state.actualHours = {
            ...state.actualHours,
            ...newHours,
          };
        }
      })
      .addCase(addActualHours.rejected, (state, action) => {
        throw new Error(action.payload as string);
      })
      .addCase(removeSubmitterHours, (state, action) => {
        const submittersHours = { ...state.actualHours };
        const submitterHours = [...submittersHours[action.payload.submitterId]];
        const newSubmitterHours = submitterHours.filter(
          (hour) => hour.id !== action.payload.hoursId
        );
        if (newSubmitterHours.length === 0) {
          delete submittersHours[action.payload.submitterId];
        } else {
          submittersHours[action.payload.submitterId] = newSubmitterHours;
        }
        state.actualHours = submittersHours;
      });
  },
});

export default actualHoursSlice.reducer;
