import { api } from './api';
import axios from 'axios';

import { NewTask, NewTaskDetail } from 'types/store/newTask';

export interface CreateNewTaskParams {
  newTasks: NewTask[];
  sendNotifications?: boolean;
}

export interface CreateNewTaskResponse {
  successes: NewTaskDetail[];
  failures: {
    task: NewTaskDetail;
    error: string;
  }[];
}

export const createNewTask = async ({
  newTasks,
  sendNotifications = true,
}: CreateNewTaskParams) => {
  const url = `/v2/tasks`;
  const config = {
    headers: {
      'send-notifications': sendNotifications ? 'true' : 'false',
    },
  };
  const response = await api.post<CreateNewTaskResponse>(
    url,
    {
      newTasks,
    },
    config
  );
  if (axios.isAxiosError(response)) {
    throw response;
  }
  if (response.data.failures.length > 0) {
    throw new Error(response.data.failures[0].error);
  }
  return response.data;
};
