import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { Option } from 'utils/customTypes';
import MultiSelectDropdown from 'Organisms/MultiSelectDropdow/MultiSelectDropdown';
import {
  fetchAvailableProjects,
  selectSearchProjects,
} from 'state/TasksList/Search/searchSlice';
import { isUserOrganizationAdmin as selectIsUserOrganizationAdmin } from 'state/User/userSlice';
import { debounce } from 'lodash';

interface ProjectFilterFieldProps {
  selectedPrograms: string[];
  project: string[];
  onChange: (project: string[]) => void;
  isFiltering?: boolean;
}

const ProjectFilterField: FC<ProjectFilterFieldProps> = ({
  project,
  onChange,
  isFiltering = true,
}) => {
  const projectsList = useSelector(selectSearchProjects);
  const isUserOrganizationAdmin = useSelector(selectIsUserOrganizationAdmin);
  const participatingProjectsOnly = !isUserOrganizationAdmin && !isFiltering;

  const onFilterChange = debounce(
    (name: string) =>
      dispatch(fetchAvailableProjects({ name, participatingProjectsOnly })),
    500
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAvailableProjects({ participatingProjectsOnly }));
  }, [dispatch, participatingProjectsOnly]);

  const projectOptions = projectsList.map((project) => {
    return {
      value: project.id,
      label: `${project.displayId} - ${project.name}`,
    };
  });

  const selectProjectOptions = projectOptions.filter((option: Option) =>
    project.includes(option.value)
  );

  return (
    <MultiSelectDropdown
      options={projectOptions}
      filterable
      values={selectProjectOptions}
      onChange={(options: Option[]) => {
        const project = options.map((option: Option) => option.value);
        onChange(project);
      }}
      onFilterChange={onFilterChange}
      placeholder={intl.get('FILTER_GENERAL.PLACEHOLDER')}
      triggerProps={{
        'aria-label': intl.get('ENTITIES.PROJECT', { num: 1 }),
      }}
    />
  );
};

export default ProjectFilterField;
