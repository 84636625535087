import intl from 'react-intl-universal';
import { Modal } from '@getsynapse/design-system';
import {
  TaskActualHours,
  TaskDetailType,
  Task_Status,
} from 'utils/customTypes';
import TaskActualHoursLog from './TaskActualHoursLog';
import { useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import { calculateActualHoursTotal } from '../../helpers/helpers';
import UnsavedChangesModal from 'Organisms/UnsavedChangesModal/UnsavedChangesModal';

type TaskActualHoursModalProps = {
  setData: (item: string, value: string | string[] | TaskActualHours[]) => void;
  data: TaskDetailType;
  originalTaskStatus: Task_Status;
  canAddActualHours: boolean;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  canDeleteActualHours: boolean;
};

const TaskActualHoursModal = ({
  setData,
  data,
  originalTaskStatus,
  canAddActualHours,
  isOpen,
  setIsOpen,
  canDeleteActualHours,
}: TaskActualHoursModalProps) => {
  const [taskActualHoursTemp, setTaskActualHoursTemp] = useState(
    data.taskActualHours || []
  );

  const hasChanges = useMemo(
    () => !isEqual(data.taskActualHours, taskActualHoursTemp),
    [data.taskActualHours, taskActualHoursTemp]
  );

  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);

  const saveActualHours = () => {
    const newActualHoursTotal = calculateActualHoursTotal(taskActualHoursTemp);
    setData('taskActualHours', taskActualHoursTemp);
    setData('actual_hours', newActualHoursTotal.toString());
    setIsOpen(false);
  };

  const cancelChange = () => {
    setTaskActualHoursTemp(data.taskActualHours!);
    setIsOpen(false);
  };

  const actionButtons =
    canDeleteActualHours || canAddActualHours
      ? [
          {
            children: intl.get('SAVE'),
            variant: 'primary',
            onClick: saveActualHours,
            disabled: !hasChanges,
            'data-testid': 'save-actuals-button',
          },
          {
            children: intl.get('CANCEL'),
            variant: 'tertiary',
            onClick: cancelChange,
            'data-testid': 'cancel-actuals-button',
          },
        ]
      : [
          {
            children: intl.get('CLOSE'),
            variant: 'secondary',
            onClick: () => setIsOpen(false),
          },
        ];

  return (
    <Modal
      aria-label={intl.get('TASK_ACTUAL_HOURS.TITLE')}
      closeModal={() => {
        if (hasChanges) {
          setShowUnsavedChangesModal(true);
        } else {
          setShowUnsavedChangesModal(false);
          cancelChange();
        }
      }}
      title={intl.get('TASK_ACTUAL_HOURS.TITLE')}
      size='large'
      isOpen={isOpen}
      actionButtons={actionButtons}
      data-testid='actual-hours-modal'
    >
      <TaskActualHoursLog
        currentTaskStatus={data.status}
        originalTaskStatus={originalTaskStatus}
        isTaskDisabled={data.disabled}
        taskStartDate={data.start_date!}
        canAddActualHours={canAddActualHours}
        canDeleteActualHours={canDeleteActualHours}
        taskActualHoursTemp={taskActualHoursTemp}
        setTaskActualHoursTemp={setTaskActualHoursTemp}
      />
      <UnsavedChangesModal
        isOpen={showUnsavedChangesModal}
        setIsOpen={setShowUnsavedChangesModal}
        onConfirm={() => cancelChange()}
      />
    </Modal>
  );
};

export default TaskActualHoursModal;
