import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FACILITATORS_TABLE_FILTERS } from 'utils/constants/learningSchedule';
import { Option } from 'utils/customTypes';
import { State } from 'country-state-city';
import { EventFiltersKey } from 'utils/types/filters';
import { selectFacilitatorsForDropdown } from 'state/Schedule/ScheduleSlice';
import { selectLearningTeamsForDropdown } from 'state/LearningTeams/learningTeamsSlice';
import { USER_STATUS, SKILLS, LANGUAGES, COUNTRIES } from 'utils/constants';
import { naturalSort } from 'utils/naturalSorting';
import intl from 'react-intl-universal';

const useFacilitatorsFilters = (countrySelection?: string) => {
  const facilitatorsSelector = useSelector(selectFacilitatorsForDropdown);
  const learningTeamsOptions = useSelector(selectLearningTeamsForDropdown);

  const facilitatorOptions = useMemo(() => {
    const facilitators = facilitatorsSelector.map((facilitator) => {
      const facilitatorStatus =
        facilitator.status === USER_STATUS.REGISTERED_DISABLED ||
        facilitator.status === USER_STATUS.INVITED_DISABLED;

      const facilitatorName = facilitatorStatus
        ? `${facilitator.name} (${intl.get(
            'USERS_PAGE.TABLE.USER_STATUS.DEACTIVATED'
          )})`
        : facilitator.name;
      return {
        label: facilitatorName,
        value: facilitator.id,
        avatar: {
          imageSrc: facilitator.avatarUrl,
          initial: facilitator.initials,
          name: facilitator.name,
          className: '',
        },
      };
    });
    const sortedFacilitators = naturalSort(facilitators, 'label', 'asc');
    return sortedFacilitators;
  }, [facilitatorsSelector]);

  const skillsOptions: Option[] = useMemo(() => {
    return SKILLS.map((skill) => ({
      value: skill,
      label: intl.get(`TEAMS.SKILLS.${skill}`),
    }));
  }, []);

  const languageOptions: Option[] = useMemo(() => {
    return LANGUAGES.map((language) => ({
      value: language,
      label: intl.get(`LANGUAGES.${language}`),
    }));
  }, []);

  const countryOptions: Option[] = useMemo(
    () =>
      COUNTRIES.map((country) => ({
        value: country,
        label: intl.get(`COUNTRIES.${country}`),
      })),
    []
  );

  const provinceOptions = useMemo(() => {
    const provinces = State.getStatesOfCountry(countrySelection ?? '');
    return provinces.map((province) => ({
      value: province.name,
      label: province.name,
    }));
  }, [countrySelection]);

  const getOptionsbyKey: (key: EventFiltersKey) => Option[] = (key) => {
    switch (key) {
      case FACILITATORS_TABLE_FILTERS.FACILITATORS:
        return facilitatorOptions;
      case FACILITATORS_TABLE_FILTERS.TEAMS:
        return learningTeamsOptions;
      case FACILITATORS_TABLE_FILTERS.SKILLS:
        return skillsOptions;
      case FACILITATORS_TABLE_FILTERS.LANGUAGES:
        return languageOptions;
      case FACILITATORS_TABLE_FILTERS.COUNTRIES:
        return countryOptions;
      case FACILITATORS_TABLE_FILTERS.STATES:
        return provinceOptions;
      case FACILITATORS_TABLE_FILTERS.INCLUDE_PENDING:
        return [
          {
            label: intl.get('SCHEDULE.EVENTS.FILTERS.INCLUDE_PENDING'),
            value: FACILITATORS_TABLE_FILTERS.INCLUDE_PENDING,
          },
        ];
      default:
        return [];
    }
  };

  return {
    learningTeamsOptions,
    facilitatorOptions,
    skillsOptions,
    languageOptions,
    countryOptions,
    provinceOptions,
    getOptionsbyKey,
  };
};

export default useFacilitatorsFilters;
