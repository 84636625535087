import { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  ScheduleComponent,
  Inject,
  CellClickEventArgs,
  EventRenderedArgs,
  WorkWeek,
  ViewsDirective,
  ViewDirective,
  Month,
} from '@syncfusion/ej2-react-schedule';
import { Internationalization } from '@syncfusion/ej2-base';
import get from 'lodash/get';
import intl from 'react-intl-universal';
import {
  fetchMySchedule,
  selectMyScheduleCurrentView,
  updateMyScheduleCurrentView,
  selectMyScheduleEvents,
  selectMyScheduleSearchTerm,
} from 'state/Schedule/ScheduleSlice';
import { SCHEDULE_TABS } from 'utils/constants/learningSchedule';
import {
  headerTemplate,
  ContentTemplate,
} from 'Pages/LearningSchedule/components/EventQuickView/EventQuickView';
import { colors } from 'Pages/LearningSchedule/constants/constants';
import '../../styles.css';
import { isEmpty } from 'lodash';

const MyScheduleCalendar = ({ pageToLoad }: { pageToLoad: string }) => {
  const dispatch = useDispatch();

  const events = useSelector(selectMyScheduleEvents);
  const search = useSelector(selectMyScheduleSearchTerm);
  const currentView = useSelector(selectMyScheduleCurrentView);

  const scheduleObj = useRef<ScheduleComponent>(null);

  const [eventsColors, setEventsColors] = useState<{ [key: string]: string }>(
    {}
  );
  const [colorIndex, setColorIndex] = useState<number>(0);

  useEffect(() => {
    async function fetchData(search: string) {
      const data = await dispatch(fetchMySchedule(search));
      const formattedSchedules = get(data, 'payload.formattedSchedules');
      if (isEmpty(formattedSchedules) && search) {
        const noRecordsDiv = document.createElement('div');
        noRecordsDiv.textContent = intl.get('NO_RECORDS');
        scheduleObj.current?.element.childNodes[2].replaceWith(noRecordsDiv);
        for (let i = 0; i <= 2; i++) {
          (
            scheduleObj.current?.element.childNodes[0].childNodes[0]
              .childNodes[0].childNodes[0].childNodes[i]
              .childNodes[0] as HTMLButtonElement
          ).disabled = true;
        }

        if (noRecordsDiv.style) {
          noRecordsDiv.style.display = 'flex';
          noRecordsDiv.style.justifyContent = 'center';
          noRecordsDiv.style.alignItems = 'center';
          noRecordsDiv.style.height = '100%';
          noRecordsDiv.style.fontSize = '0.875rem';
        }
      } else {
        scheduleObj.current?.refresh();
      }
    }

    if (pageToLoad === SCHEDULE_TABS.MY_SCHEDULE) {
      fetchData(search);
    }
  }, [pageToLoad, dispatch, search]);

  const intlInstance = useMemo(() => {
    return new Internationalization();
  }, []);

  const closeQuickInfo = useCallback(() => {
    if (
      scheduleObj &&
      scheduleObj.current &&
      scheduleObj.current.closeQuickInfoPopup
    ) {
      scheduleObj.current.closeQuickInfoPopup();
    }
  }, [scheduleObj]);

  const formatHeader = useCallback(
    (args: { date: Date }) => {
      const formattedDate = intlInstance.formatDate(args?.date, {
        skeleton: 'Ed',
      });
      return <span>{formattedDate}</span>;
    },
    [intlInstance]
  );

  const onCellClick = (args: CellClickEventArgs) => {
    args.cancel = true;
  };

  const applyCategoryColor = (args: EventRenderedArgs) => {
    if (!args.element) return;

    const { Id } = args.data;

    if (eventsColors[Id]) {
      args.element.style.backgroundColor = eventsColors[Id];
      return;
    }

    const categoryColor = colors[colorIndex];
    args.element.style.backgroundColor = categoryColor;

    setEventsColors((prev) => {
      return {
        ...prev,
        [args.data.Id]: categoryColor,
      };
    });
    setColorIndex((prev) => (prev + 1) % colors.length);
  };

  const getCurrentView = () => {
    if (scheduleObj?.current?.currentView) {
      dispatch(
        updateMyScheduleCurrentView({
          currentView: scheduleObj?.current?.currentView,
        })
      );
    }
  };

  return (
    <div
      className='h-my-schedule overflow-y-auto'
      data-testid='my-schedule_container'
      id='my-schedule'
    >
      <ScheduleComponent
        height='100%'
        ref={scheduleObj}
        eventSettings={{
          dataSource: events,
          allowEditing: false,
          allowAdding: false,
        }}
        currentView={currentView}
        showTimeIndicator={false}
        rowAutoHeight={true}
        dateHeaderTemplate={formatHeader}
        cellClick={(args) => onCellClick(args)}
        eventDoubleClick={(args) => (args.cancel = true)}
        quickInfoTemplates={{
          header: headerTemplate(closeQuickInfo),
          content: ContentTemplate,
          footer: () => <></>,
        }}
        eventRendered={applyCategoryColor}
        dataBound={getCurrentView}
      >
        <ViewsDirective>
          <ViewDirective option='WorkWeek' displayName='Week' />
          <ViewDirective option='Month' showWeekend={false} />
        </ViewsDirective>
        <Inject services={[WorkWeek, Month]} />
      </ScheduleComponent>
    </div>
  );
};

export default MyScheduleCalendar;
