import { FC } from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import {
  Typography,
  tailwindOverride,
  Tooltip,
} from '@getsynapse/design-system';
import { selectLinkedTaskContent } from 'state/LinkedTaskContent/linkedTaskContentSlice';
import LinkedContentCard from './LinkedContentCard/LinkedContentCard';
import ContentDescription from './LinkedContentCard/ContentDescription';

interface LinkedContentCardsListProps {
  unlinkContent: (contentId: string) => void;
}

const LinkedContentCardsList: FC<LinkedContentCardsListProps> = ({
  unlinkContent,
}) => {
  const linkedTaskContent = useSelector(selectLinkedTaskContent);

  if (linkedTaskContent.length === 0) {
    return (
      <div className='mt-4'>
        <Typography variant='label' className='text-neutral-black'>
          {intl.get('TASKS.TASK_DETAIL_PAGE.CONTENT.NO_CONTENT')}
        </Typography>
      </div>
    );
  }

  return (
    <div className={tailwindOverride('w-full flex gap-x-2 flex-wrap')}>
      {linkedTaskContent.map((content) => (
        <Tooltip
          key={content.id}
          trigger={
            <div>
              <LinkedContentCard
                key={content.id}
                unlinkContent={unlinkContent}
                {...content}
              />
            </div>
          }
          openMode='hover2'
          timeout={0}
          ariaId={content.id}
          position='topRight'
          showPopper
          contentProps={{
            className: tailwindOverride(
              'bg-neutral-darker shadow-tooltip',
              'rounded-lg p-4',
              'w-max absolute',
              'text-body',
              'text-neutral-white'
            ),
          }}
        >
          <ContentDescription
            displayUploader
            truncateName={false}
            contentType={content.contentType}
            contentData={content.contentData}
            uploader={content.uploader}
            createdAt={content.createdAt}
            className='text-neutral-white'
          />
        </Tooltip>
      ))}
    </div>
  );
};

export default LinkedContentCardsList;
