import moment from 'moment';
import {
  CapacityEntry,
  UserCapacity,
  UserColumnType,
  SectionUserType,
  ResourceAllocationSectionsType,
  TeamMemberProject,
} from 'utils/customTypes';
import has from 'lodash/has';

const generateUserColumns = (user: UserCapacity) => {
  const cache: Record<string, string> = {};
  const roles: string[] = [];
  user.projects.forEach((project) =>
    project.currentParticipantRoles.forEach(({ role }) => {
      if (!cache[role]) {
        roles.push(role);
      }
      cache[role] = role;
    })
  );

  const resp: UserColumnType = {
    user: {
      avatar_url: user.data.avatar_url || '',
      data: user.data.data,
      roles: roles.join(', '),
      isTeamManager: has(user, 'data.learning_team_managers'),
    },
    projects: user.projects.map((project) => {
      const rolesAllocations: TeamMemberProject['currentParticipantRoles'] = [
        ...project.currentParticipantRoles,
      ];
      project.currentParticipantRoles.forEach(({ role }, index) => {
        rolesAllocations[index] = { ...rolesAllocations[index], weeks: [] };
        user.allocations.forEach(
          (allocation: UserCapacity['allocations'][0]) => {
            if (
              has(allocation, ['weeklyAllocationsByProjectAndRole', project.id])
            ) {
              if (
                allocation.weeklyAllocationsByProjectAndRole[project.id][
                  role
                ] >= 0
              ) {
                rolesAllocations[index].weeks?.push({
                  startDate: allocation.weekStart,
                  endDate: allocation.weekEnd,
                  hours:
                    allocation.weeklyAllocationsByProjectAndRole[project.id][
                      role
                    ],
                });
              }
            }
          }
        );
      });
      return {
        id: project.id,
        title: project.title,
        priority: project.priority,
        health: project.health,
        startDate: project.startDate,
        targetCompletionDate: project.targetCompletionDate,
        roles:
          project.currentParticipantRoles.length === 0
            ? {}
            : project.currentParticipantRoles.reduce((prev, current) => {
                const prevStartDate = new Date(
                  new Date(prev.startDate).toLocaleDateString('en-US')
                );
                const currentStartDate = new Date(
                  new Date(current.startDate).toLocaleDateString('en-US')
                );

                const prevEndDate = new Date(
                  new Date(prev.endDate).toLocaleDateString('en-US')
                );
                const currentEndDate = new Date(
                  new Date(current.endDate).toLocaleDateString('en-US')
                );
                return {
                  role: `${prev.role}, ${current.role}`,
                  startDate:
                    prevStartDate < currentStartDate
                      ? prevStartDate.toLocaleDateString('en-US')
                      : currentStartDate.toLocaleDateString('en-US'),
                  endDate:
                    prevEndDate > currentEndDate
                      ? prevEndDate.toLocaleDateString('en-US')
                      : currentEndDate.toLocaleDateString('en-US'),
                };
              }),
        status: project.status,
        hours: project.totalAllocationForAllRoles,
        currentParticipantRoles: rolesAllocations,
      };
    }),
  };
  return resp;
};

const generateUserAllocations = (
  allocations: UserCapacity['allocations'],
  defaultCapacity: number
) => {
  return allocations.map((allocation) => ({
    weekStart: new Date(allocation.weekStart),
    weekEnd: new Date(allocation.weekEnd),
    allocation: allocation.timeOff.allocationTotal,
    capacity:
      defaultCapacity -
      (allocation.timeOff.weeklyTimeOffForUser +
        allocation.holidays.length * 8),
    defaultCapacity,
  }));
};

const generateUserTimeOffsList = (allocations: UserCapacity['allocations']) => {
  const timeOffs = allocations.filter((allocation) => {
    return (
      allocation.timeOff.weeklyTimeOffForUser > 0 ||
      allocation.holidays.length > 0
    );
  });
  return timeOffs.map((allocation) => ({
    weekStart: new Date(allocation.weekStart),
    weekEnd: new Date(allocation.weekEnd),
    capacity:
      allocation.timeOff.weeklyTimeOffForUser + allocation.holidays.length * 8,
  }));
};

const formatSectionUsers = (users: Record<string, UserCapacity>) => {
  const resp: SectionUserType[] = [];
  const usersIds = Object.keys(users);
  let startDate = new Date();
  let endDate = new Date();
  for (const userId of usersIds) {
    const allocations = generateUserAllocations(
      users[userId].allocations,
      users[userId].data.default_capacity
    );
    const start = allocations[0].weekStart;
    const end = allocations[allocations.length - 1].weekEnd;
    startDate = moment(start).isBefore(startDate) ? start : startDate;
    endDate = moment(end).isAfter(endDate) ? end : endDate;
    resp.push({
      id: userId,
      ...generateUserColumns(users[userId]),
      allocations,
      timeOffs: generateUserTimeOffsList(users[userId].allocations),
    });
  }
  return { users: resp, start: startDate, end: endDate };
};

export const formatResourceAllocationSections = (
  rawSections: Record<string, CapacityEntry>
) => {
  const resp: ResourceAllocationSectionsType[] = [];
  const sectionKeys = Object.keys(rawSections);
  let startDate = new Date();
  let endDate = new Date();
  for (const sectionKey of sectionKeys) {
    const section = rawSections[sectionKey];
    const { users, start, end } = formatSectionUsers(section.users);
    startDate = moment(start).isBefore(startDate) ? start : startDate;
    endDate = moment(end).isAfter(endDate) ? end : endDate;
    resp.push({
      id: sectionKey,
      label: section.team.name,
      users,
    });
  }
  return { allocationData: resp, startDate, endDate };
};
