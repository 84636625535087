import { ReactNode } from 'react';
import intl from 'react-intl-universal';

import loadingIcon from 'assets/icons/loading.svg';

import { Icon, tailwindOverride } from '@getsynapse/design-system';
import usePerformitivRedirection from './userPerformitivRedirection';

export interface Props {
  children: ReactNode;
  evaluationId?: string;
}

const PerformitivRedirect = ({ children, evaluationId }: Props) => {
  const { loading, redirect } = usePerformitivRedirection(evaluationId);

  return (
    <span
      role='button'
      tabIndex={0}
      aria-disabled={loading}
      aria-busy={loading}
      className={tailwindOverride(
        'flex cursor-pointer focus:underline hover:underline',
        {
          'cursor-not-allowed': loading,
        }
      )}
      onClick={redirect}
    >
      {loading && (
        <Icon
          className={tailwindOverride(
            'fill-current text-neutral-black mr-2 text-xl',
            {
              'animate-spin': loading,
            }
          )}
          src={loadingIcon}
          aria-hidden={!loading}
          aria-label={intl.get('LOADING')}
        />
      )}
      {children}
    </span>
  );
};

export default PerformitivRedirect;
