import { useState, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import intl from 'react-intl-universal';
import get from 'lodash/get';
import { Button, tailwindOverride, useLink } from '@getsynapse/design-system';
import { NewTask, NewTaskDetail } from 'types/store/newTask';
import { PATHS } from 'utils/constants';
import { selectUserId } from 'state/User/userSlice';
import { createTask } from 'state/TasksList/allTasks/allTasksSlice';
import { createProjectTask } from 'state/ProjectTasksList/actions/actionsSlice';
import useSnackbarNotification from 'Hooks/useSnackbarNotification';
import TaskModalForm from '../TaskModalForm/TaskModalForm';
import ButtonWithTooltip from './ButtonWithTooltip';

interface CreateTaskModalProps {
  isCentralizedPage?: boolean;
  projectId?: string;
  isUserFacilitator?: boolean;
  canUserAddTask?: boolean;
}

const CreateTaskModal: FC<CreateTaskModalProps> = ({
  isCentralizedPage = true,
  isUserFacilitator = false,
  canUserAddTask = true,
  projectId,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUserId = useSelector(selectUserId);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [notificationLink, setNotificationLink] = useState<string>('');
  const {
    isNotificationVisible,
    snackbarProps,
    showNotification,
    SnackbarNotification,
  } = useSnackbarNotification();
  const linkClassName = useLink();

  const toggleModal = () => {
    setOpenModal((isOpen) => !isOpen);
  };

  const handleAddOrganizationTask = async (newTask: NewTask) => {
    try {
      const resultAction = await dispatch(
        createTask({
          newTasks: [newTask],
          userId: currentUserId!,
        })
      );
      const newTaskDetail = get(resultAction, 'payload', {}) as NewTaskDetail;
      const taskRoute = `${PATHS.PROJECT_PAGE}/${newTaskDetail.project.id}/tasks/${newTaskDetail.id}`;
      setNotificationLink(taskRoute);
    } catch (error) {
      throw error;
    }
  };

  const handleAddProjectTask = async (newTask: NewTask) => {
    return dispatch(
      createProjectTask({
        newTasks: [newTask],
        userId: currentUserId!,
      })
    );
  };

  const handleCreateTask = async (newTask: NewTask) => {
    try {
      let notificationMessage = intl.get(
        'TASKS.NOTIFICATIONS.CENTRALIZED.MESSAGE'
      );
      if (isCentralizedPage) {
        await handleAddOrganizationTask(newTask);
      } else {
        await handleAddProjectTask(newTask);
        notificationMessage = intl.get(
          'TASKS.NOTIFICATIONS.TASK_CREATION_SUCCESS'
        );
      }
      showNotification({
        notificationTitle: intl.get('SUCCESS_SNACKBAR_TITLE'),
        notificationMessage,
        hasActionButton: true,
      });
    } catch (error) {
      showNotification({
        notificationVariant: 'error',
        notificationTitle: intl.get(
          'TASKS.NOTIFICATIONS.TASK_CREATION_ERROR_TITLE'
        ),
        notificationMessage: intl.get(
          'TASKS.NOTIFICATIONS.TASK_CREATION_ERROR_MESSAGE'
        ),
      });
    }
  };

  if (isCentralizedPage && isUserFacilitator) {
    return null;
  }

  return (
    <>
      {isUserFacilitator && !canUserAddTask && <ButtonWithTooltip />}
      {(!isUserFacilitator || (isUserFacilitator && canUserAddTask)) && (
        <Button
          variant='primary'
          size='large'
          onClick={toggleModal}
          className='px-4 py-2'
        >
          {intl.get('TASKS.ADD_TASK_BUTTON_TITLE')}
        </Button>
      )}
      <TaskModalForm
        title={intl.get('TASK_MODAL.TITLE', {
          action: intl.get('ADD'),
        })}
        shouldOpenModal={openModal}
        onCloseModal={toggleModal}
        saveButtonLabel={intl.get('SAVE')}
        formPrompt={intl.get('TASK_MODAL.ADD_PROMPT', {
          container: intl.get('ENTITIES.PROJECT', { num: 1 }).toLowerCase(),
        })}
        displayProjectPicker={isCentralizedPage}
        onSaveTask={handleCreateTask}
        orginalTaskData={
          !isCentralizedPage ? ({ projectId } as NewTask) : ({} as NewTask)
        }
      />
      {isNotificationVisible && snackbarProps && (
        <SnackbarNotification
          {...snackbarProps}
          cta={
            isCentralizedPage && (
              <a
                href={notificationLink}
                className={tailwindOverride(linkClassName, 'ml-9')}
                onClick={(e) => {
                  e.preventDefault();
                  history.push(notificationLink, {
                    from: PATHS.TASKS_LIST_PAGE,
                  });
                }}
              >
                {intl.get('TASKS.NOTIFICATIONS.CENTRALIZED.CALLBACK')}
              </a>
            )
          }
        />
      )}
    </>
  );
};

export default CreateTaskModal;
