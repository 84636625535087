import intl from 'react-intl-universal';
import { useEffect, useMemo, useState } from 'react';
import {
  SidePanelFilter,
  FormItem,
  UsersPicker,
  Checkbox,
  Dropdown,
} from '@getsynapse/design-system';
import { useDispatch } from 'react-redux';
import { getLearningTeams } from 'state/LearningTeams/learningTeamsSlice';

import useFacilitatorsFilters from 'Pages/LearningSchedule/hooks/useFacilitatorsFilters';
import useSidePanelFilters from 'Hooks/useSidePanelFilters';
import { EventFilters, EventFiltersKey } from 'utils/types/filters';
import { Option, UserOption } from 'utils/customTypes';
import { FACILITATORS_TABLE_FILTERS } from 'utils/constants/learningSchedule';
import get from 'lodash/get';
import {
  getInitialValueForDropDown,
  getInitialUsersPickerValue,
} from 'utils/functions';

type EventsFiltersSidePanelProps = {
  open: boolean;
  onUpdateFilters: (filters: EventFilters) => void;
  onClose: () => void;
  savedFilters: EventFilters;
};

const FacilitatorsEventsFiltersSidePanel = ({
  open,
  onUpdateFilters,
  onClose,
  savedFilters,
}: EventsFiltersSidePanelProps) => {
  const [currentCountrySelection, setCurrentCountrySelection] =
    useState<string>('');
  const dispatch = useDispatch();
  const [provinceResetkey, setProvinceResetkey] = useState<number>(1);
  const [countryResetkey, setCountryResetkey] = useState<number>(1);

  useEffect(() => {
    dispatch(getLearningTeams());
  }, [dispatch]);

  const {
    appliedFilters,
    canApplyFilters,
    handleUpdateFilters,
    handleApplyFilters,
    handleClearAllFilters,
    handleClosePanel,
  } = useSidePanelFilters<EventFilters>(savedFilters, onUpdateFilters, onClose);

  const {
    facilitatorOptions,
    learningTeamsOptions,
    skillsOptions,
    languageOptions,
    countryOptions,
    provinceOptions,
  } = useFacilitatorsFilters(currentCountrySelection);

  const updateFilters = (key: EventFiltersKey, value: Option[]) => {
    const updatedFilters = { ...appliedFilters };
    if (value.length === 0) {
      delete updatedFilters[key];
    } else {
      updatedFilters[key] = value.map((option) => option.value);
    }
    if (key === FACILITATORS_TABLE_FILTERS.COUNTRIES) {
      delete updatedFilters[FACILITATORS_TABLE_FILTERS.STATES];
    }

    handleUpdateFilters(updatedFilters);
  };

  const currentCountry = useMemo(() => {
    const selections = get(
      appliedFilters,
      FACILITATORS_TABLE_FILTERS.COUNTRIES,
      []
    );
    if (!selections.length) {
      return [];
    }
    const selectedCountry = selections?.[0];
    return countryOptions.filter(
      (country) => country.value === selectedCountry
    );
  }, [countryOptions, appliedFilters]);

  useEffect(() => {
    const countrySelections = get(
      appliedFilters,
      FACILITATORS_TABLE_FILTERS.COUNTRIES,
      []
    );
    const savedCurrentCountry = countrySelections?.[0] ?? '';

    if (savedCurrentCountry !== currentCountrySelection) {
      setCurrentCountrySelection(savedCurrentCountry);
    }
  }, [appliedFilters, currentCountrySelection]);

  const currentProvince = useMemo(() => {
    const selections = get(
      appliedFilters,
      FACILITATORS_TABLE_FILTERS.STATES,
      []
    );
    if (!selections.length) {
      return [];
    }
    const selectedProvince = selections?.[0];
    return provinceOptions.filter((state) => state.value === selectedProvince);
  }, [provinceOptions, appliedFilters]);

  useEffect(() => {
    if (!currentProvince || currentProvince.length === 0) {
      setProvinceResetkey((prev) => prev + 1);
    }
  }, [currentProvince]);

  useEffect(() => {
    if (!currentCountry || currentCountry.length === 0) {
      setCountryResetkey((prev) => prev + 1);
    }
  }, [currentCountry]);

  return (
    <SidePanelFilter
      open={open}
      onClose={handleClosePanel}
      appliedFiltersCount={Object.keys(appliedFilters).length}
      canApplyFilters={canApplyFilters}
      onClearAllFilters={handleClearAllFilters}
      onApplyFilters={handleApplyFilters}
    >
      <Checkbox
        className='mt-6'
        label={intl.get('SCHEDULE.FACILITATORS.FILTERS.INCLUDE_PENDING')}
        onChange={(event) =>
          updateFilters(
            FACILITATORS_TABLE_FILTERS.INCLUDE_PENDING,
            event.target.checked
              ? [
                  {
                    value: FACILITATORS_TABLE_FILTERS.INCLUDE_PENDING,
                    label: FACILITATORS_TABLE_FILTERS.INCLUDE_PENDING,
                  },
                ]
              : []
          )
        }
        checked={
          get(appliedFilters, FACILITATORS_TABLE_FILTERS.INCLUDE_PENDING, false)
            ? true
            : false
        }
      />
      <FormItem
        label={intl.get('SCHEDULE.FACILITATORS.FILTERS.FACILITATORS.LABEL')}
        className='mt-6 mb-2'
      >
        <UsersPicker
          triggerText={intl.get(
            'SCHEDULE.FACILITATORS.FILTERS.FACILITATORS.TRIGGER_TEXT'
          )}
          usersList={facilitatorOptions}
          selectedUsersList={getInitialUsersPickerValue(
            facilitatorOptions,
            (appliedFilters as EventFilters)[
              FACILITATORS_TABLE_FILTERS.FACILITATORS
            ] as string[]
          )}
          onChange={(selectedUsers: UserOption[]) =>
            updateFilters(
              FACILITATORS_TABLE_FILTERS.FACILITATORS,
              selectedUsers
            )
          }
          noSelectedUsersText={intl.get(
            'SCHEDULE.FACILITATORS.FILTERS.FACILITATORS.NO_FACILITATORS_SELECTED'
          )}
          selectedUsersListTitle={intl.get(
            'SCHEDULE.FACILITATORS.FILTERS.FACILITATORS.SELECTED_FACILITATORS'
          )}
          emptyUsersListText={intl.get(
            'SCHEDULE.FACILITATORS.FILTERS.FACILITATORS.NO_AVAILABLE_FACILITATORS'
          )}
          triggerProps={{ 'data-testid': 'facilitators_trigger' }}
          popperProps={{ 'data-testid': 'facilitators_popper' }}
        />
      </FormItem>
      <FormItem label={intl.get('SCHEDULE.FACILITATORS.FILTERS.TEAMS')}>
        <Dropdown
          options={learningTeamsOptions}
          multiple
          filterable
          values={getInitialValueForDropDown(
            learningTeamsOptions,
            (appliedFilters as EventFilters)[
              FACILITATORS_TABLE_FILTERS.TEAMS
            ] as string[]
          )}
          onChange={(options: Option[]) =>
            updateFilters(FACILITATORS_TABLE_FILTERS.TEAMS, options)
          }
          placeholder={intl.get(
            'SCHEDULE.FACILITATORS.FILTERS.TEAMS_PLACEHOLDER'
          )}
        />
      </FormItem>
      <FormItem
        label={intl.get('SCHEDULE.FACILITATORS.FILTERS.SKILLS')}
        className='mt-6'
      >
        <Dropdown
          multiple
          options={skillsOptions}
          values={getInitialValueForDropDown(
            skillsOptions,
            (appliedFilters as EventFilters)[
              FACILITATORS_TABLE_FILTERS.SKILLS
            ] as string[]
          )}
          onChange={(options: Option[]) =>
            updateFilters(FACILITATORS_TABLE_FILTERS.SKILLS, options)
          }
          placeholder={intl.get(
            'SCHEDULE.FACILITATORS.FILTERS.SKILLS_PLACEHOLDER'
          )}
        />
      </FormItem>
      <FormItem
        label={intl.get('SCHEDULE.FACILITATORS.FILTERS.LANGUAGES')}
        className='mt-6'
      >
        <Dropdown
          multiple
          options={languageOptions}
          values={getInitialValueForDropDown(
            languageOptions,
            (appliedFilters as EventFilters)[
              FACILITATORS_TABLE_FILTERS.LANGUAGES
            ] as string[]
          )}
          onChange={(options: Option[]) =>
            updateFilters(FACILITATORS_TABLE_FILTERS.LANGUAGES, options)
          }
          placeholder={intl.get(
            'SCHEDULE.FACILITATORS.FILTERS.LANGUAGES_PLACEHOLDER'
          )}
          triggerProps={{ 'data-testid': 'languages-dropdown_trigger' }}
          listProps={{
            'data-testid': 'languages-dropdown_list',
          }}
        />
      </FormItem>
      <FormItem
        label={intl.get('SCHEDULE.FACILITATORS.FILTERS.COUNTRY')}
        className='mt-6'
      >
        <Dropdown
          key={countryResetkey}
          options={countryOptions}
          filterable
          values={currentCountry}
          onChange={(option: Option) => {
            setCurrentCountrySelection(option.value);
            updateFilters(FACILITATORS_TABLE_FILTERS.COUNTRIES, [option]);
          }}
          placeholder={intl.get(
            'SCHEDULE.FACILITATORS.FILTERS.SELECT_AN_OPTION'
          )}
        />
      </FormItem>
      <FormItem
        label={intl.get('SCHEDULE.FACILITATORS.FILTERS.PROVINCE')}
        className='mt-6'
      >
        <Dropdown
          key={provinceResetkey}
          options={provinceOptions}
          filterable
          values={currentProvince}
          onChange={(option: Option) => {
            updateFilters(FACILITATORS_TABLE_FILTERS.STATES, [option]);
          }}
          placeholder={intl.get(
            'SCHEDULE.FACILITATORS.FILTERS.SELECT_AN_OPTION'
          )}
        />
      </FormItem>
    </SidePanelFilter>
  );
};

export default FacilitatorsEventsFiltersSidePanel;
