import { useEffect, useMemo, useCallback, useState } from 'react';
import intl from 'react-intl-universal';
import { get, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import FormDetailsTabs from './FormDetailsTabs';
import FormFooterButtons from './FormFooterButtons';
import {
  PATHS,
  SLICE_STATUS,
  SETTINGS_TABS,
  SETTINGS_SECTIONS,
  SETTINGS_ATTRIBUTES,
} from 'utils/constants';
import {
  getLDUsers,
  selectLDUsersForDropdown,
} from 'state/UsersManagement/usersManagementSlice';
import { createForm, selectFormStatus } from 'state/Forms/formSlice';
import { selectOrganizationId, selectUserId } from 'state/User/userSlice';
import {
  selectBusinessTeamsForDropdown,
  getBusinessTeams,
} from 'state/BusinessTeams/businessTeamsSlice';
import {
  displayNotification,
  setNotificationText,
  setNotificationTimeout,
  setNotificationVariant,
} from 'state/InlineNotification/inlineNotificationSlice';
import {
  RequestQuestion,
  dropdownOption,
  radioOption,
  checkboxOption,
} from 'utils/customTypes';
import DetailsPage from 'Molecules/DetailsPage/DetailsPage';
import UnsavedChangesPrompt from 'Organisms/UnsavedChangesPrompt/UnsavedChangesPrompt';

const NewForm = () => {
  const dispatch = useDispatch();
  const ldUsers = useSelector(selectLDUsersForDropdown);
  const organizationId = useSelector(selectOrganizationId);
  const businessTeamsOptions = useSelector(selectBusinessTeamsForDropdown);
  const userId = useSelector(selectUserId);
  const formStatus = useSelector(selectFormStatus);
  const [currentTab, setCurrentTab] = useState(0);
  const [newForm, setNewForm] = useState({});
  const history = useHistory();
  const [formQuestions, setFormQuestions] = useState<RequestQuestion[]>([]);
  const [wasSaved, setWasSaved] = useState(false);

  useEffect(() => {
    dispatch(getLDUsers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getBusinessTeams());
  }, [dispatch]);

  useEffect(() => {
    if (userId) {
      setNewForm({ owners: [userId] });
    }
  }, [userId]);

  const questionLabel = useMemo(
    () =>
      formQuestions.some((question) => {
        if (question && question.data.label.length === 0) {
          return true;
        }
        return false;
      }),
    [formQuestions]
  );

  const selectQuestionsHasEmptyOptions = useMemo(
    () =>
      formQuestions?.some(
        (question) =>
          (question?.type === 'dropdown' &&
            question.data.value.items?.some(
              (item: dropdownOption) => !item.display
            )) ||
          (['dropdown', 'checkbox'].includes(question?.type) &&
            isEmpty(question.data.value.items)) ||
          (question?.type === 'checkbox' &&
            question.data.value.items?.some(
              (item: checkboxOption) => !item.item
            )) ||
          (question?.type === 'radio' &&
            question.data.value.options?.some(
              (item: radioOption) => !item.text
            )) ||
          (question?.type === 'radio' && isEmpty(question.data.value.options))
      ),
    [formQuestions]
  );

  const canSubmit = useMemo(() => {
    if (
      get(newForm, 'title') &&
      get(newForm, 'request_type') &&
      get(newForm, 'form_description') &&
      get(newForm, 'businessTeams.length') &&
      get(newForm, 'owners.length') &&
      !questionLabel &&
      !selectQuestionsHasEmptyOptions
    ) {
      return true;
    } else {
      return false;
    }
  }, [newForm, questionLabel, selectQuestionsHasEmptyOptions]);

  const hasChanges = useMemo(() => {
    if (
      get(newForm, 'title') ||
      get(newForm, 'request_type') ||
      get(newForm, 'form_description') ||
      get(newForm, 'businessTeams.length') ||
      get(newForm, 'owners.length') ||
      !questionLabel ||
      !selectQuestionsHasEmptyOptions
    ) {
      return true;
    } else {
      return false;
    }
  }, [newForm, questionLabel, selectQuestionsHasEmptyOptions]);

  const handleTabChange = useCallback((tab: number) => {
    setCurrentTab(tab);
  }, []);

  const handleChangeField = useCallback(
    (value: string | string[] | boolean, path: string) => {
      setNewForm((prev) => ({ ...prev, [path]: value }));
      setWasSaved(false);
    },
    []
  );

  const handleSave = useCallback(async () => {
    if (userId && organizationId) {
      await dispatch(
        createForm({
          ...newForm,
          form_creator_id: userId,
          organizationId,
          data: { published: false },
          questions: formQuestions,
        })
      );
    }
    if (formStatus === SLICE_STATUS.IDLE) {
      setWasSaved(true);
      dispatch(
        setNotificationText(
          intl.get('SETTINGS_PAGE.FORMS.FORM_CREATED_SUCCESS')
        )
      );
      dispatch(setNotificationVariant('success'));
      dispatch(setNotificationTimeout(4000));
      dispatch(displayNotification());
    }
    history.push(
      `${PATHS.SETTINGS}/${SETTINGS_TABS.CONFIGURATIONS}?section=${SETTINGS_SECTIONS.INTAKE}&attribute=${SETTINGS_ATTRIBUTES.REQUEST_FORM}`
    );
  }, [
    userId,
    organizationId,
    formStatus,
    history,
    dispatch,
    newForm,
    formQuestions,
  ]);

  const handleCancel = useCallback(() => {
    history.push(
      `${PATHS.SETTINGS}/${SETTINGS_TABS.CONFIGURATIONS}?section=${SETTINGS_SECTIONS.INTAKE}&attribute=${SETTINGS_ATTRIBUTES.REQUEST_FORM}`
    );
  }, [history]);

  return (
    <div className='h-full flex flex-col'>
      <PageTitle
        titleComponent={`${intl.get('ENTITIES.REQUEST_FORM', {
          num: 2,
        })} / ${intl.get('SETTINGS_PAGE.FORMS.ADD_FORM')}`}
        className='sticky top-0 left-0 right-0 bg-neutral-lightest z-10'
      />
      <DetailsPage
        bodyClassName='overflow-y-hidden'
        content={
          <main className='flex-grow flex space-x-4 bg-neutral-white'>
            <div className='flex-grow h-full overflow-y-auto'>
              <FormDetailsTabs
                formObj={newForm}
                handleChangeField={handleChangeField}
                ldUsers={ldUsers}
                onTabChange={handleTabChange}
                currentTab={currentTab}
                formQuestions={formQuestions}
                setFormQuestions={setFormQuestions}
                businessTeamsOptions={businessTeamsOptions}
              />
            </div>
          </main>
        }
      />
      <FormFooterButtons
        currentTab={currentTab}
        onTabChange={handleTabChange}
        onSave={handleSave}
        onCancel={handleCancel}
        canSubmit={canSubmit}
        isNew
        showTooltip={questionLabel || selectQuestionsHasEmptyOptions}
      />
      <UnsavedChangesPrompt hasChanges={hasChanges && !wasSaved} />
    </div>
  );
};

export default NewForm;
