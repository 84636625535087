import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Text,
  LabelList,
  Customized,
  LabelProps,
} from 'recharts';
import intl from 'react-intl-universal';
import { RechartsCustomRendererTickProps } from 'Pages/InsightsPage/rechartTypeShim';
import { isNumber } from 'lodash';

interface Benchmark {
  benchmark: number;
  score: number;
  scorePrecision: number;
}

export interface LearningDeliveryChartData {
  name: string;
  benchmarking: Benchmark;
  distributionHigh: number;
  distributionLow: number;
}

interface CustomTitleProps {
  x: number;
  y: number;
  value: string;
}

const CustomTitle = (props: CustomTitleProps) => {
  const { x, y, value } = props;
  return (
    <Text
      x={x}
      y={y}
      fontSize={12}
      fontWeight={600}
      textAnchor='start'
      dominantBaseline={'middle'}
    >
      {value}
    </Text>
  );
};

const CustomLeftTickLabel = (props: RechartsCustomRendererTickProps) => {
  const { x, y, payload } = props;
  return (
    <Text
      x={x - 100}
      y={y}
      fontSize={12}
      fontWeight={600}
      textAnchor='start'
      dominantBaseline={'middle'}
    >
      {payload.value}
    </Text>
  );
};

const CustomBarLabel = (props: LabelProps) => {
  const { x, y, height, width, value, fill } = props;

  const midY = isNumber(height) ? height / 2 : 0;
  const midX = isNumber(width) ? width / 2 : 0;

  const posX = (isNumber(x) ? x : 0) + midX - 6;
  const posY = (isNumber(y) ? y : 0) + midY;

  return (
    <Text
      x={posX}
      y={posY}
      fontSize={12}
      fontWeight={600}
      fill={fill}
      dominantBaseline={'middle'}
      overflow={'hidden'}
    >
      {isNumber(value) ? value.toFixed(0) : value}
    </Text>
  );
};

const CustomBenchmarkLabel = (
  // Recharts typing is inconsistent. The value is not always a number or string. In this case, it's an object.
  props: Omit<LabelProps, 'value'> & { value: unknown }
) => {
  const { x, y } = props;
  const value = props.value as Benchmark;

  const benchmark = value.benchmark.toFixed(value.scorePrecision);
  const average = value.score.toFixed(value.scorePrecision);

  const posY = (isNumber(y) ? y : 0) + 15;
  const valX = isNumber(x) ? x : 0;

  return (
    <>
      <Text
        x={valX - 140}
        y={posY}
        fontSize={12}
        fontWeight={600}
        fill='#0E1212'
        dominantBaseline={'middle'}
        overflow={'hidden'}
      >
        {`${average} / `}
      </Text>
      <Text
        x={valX - 107}
        y={posY}
        fontSize={12}
        fontWeight={400}
        fill='#0E1212'
        dominantBaseline={'middle'}
        overflow={'hidden'}
      >
        {benchmark}
      </Text>
    </>
  );
};

export const LearningDeliveryChart = ({
  data,
}: {
  data: LearningDeliveryChartData[];
}) => {
  return (
    <ResponsiveContainer width='100%' height='80%'>
      <BarChart
        data={data}
        layout='vertical'
        margin={{
          left: 5,
          top: 5,
        }}
      >
        <XAxis
          tickLine={false}
          type='number'
          hide
          padding={{ left: 200 }}
          orientation='top'
          style={{
            fontSize: '0.75em',
            fontWeight: '400',
            fill: '#000',
            lineHeight: '1em',
          }}
        ></XAxis>

        <YAxis
          tickLine={false}
          axisLine={false}
          yAxisId='left'
          tick={(props) => <CustomLeftTickLabel {...props} />}
          style={{
            fontSize: '0.75em',
            fontWeight: '400',
            fill: '#000',
            lineHeight: '1em',
          }}
          width={120}
          dataKey='name'
          type='category'
          orientation='left'
        />

        <Bar
          dataKey='distributionHigh'
          name={intl.get('INSIGHTS_PAGE_TABS.NUMBER_OF_REQUESTS')}
          maxBarSize={28}
          stackId={'distribution'}
          yAxisId='left'
          fill='#327569'
        >
          <LabelList
            dataKey={'benchmarking'}
            content={(props: LabelProps) => {
              const { value, ...rest } = props;
              return (
                <CustomBenchmarkLabel {...rest} value={value as unknown} />
              );
            }}
            width={120}
          />
          <LabelList
            dataKey={'distributionHigh'}
            content={<CustomBarLabel fill='#FFFFFF' />}
          />
        </Bar>
        <Bar
          dataKey='distributionLow'
          name={intl.get('INSIGHTS_PAGE_TABS.NUMBER_OF_REQUESTS')}
          maxBarSize={28}
          stackId={'distribution'}
          yAxisId='left'
          fill='#F6BD30'
        >
          <LabelList
            dataKey={'distributionLow'}
            content={<CustomBarLabel fill='#0E1212' />}
          />
        </Bar>

        <Customized
          component={
            <CustomTitle
              x={165}
              y={5}
              value={intl.get(
                'INSIGHTS_PAGE_TABS.INSIGHTS_EVALUATIONS.CHARTS.LEARNING_DELIVERY.AVERAGE_BENCHMARK'
              )}
            />
          }
        />
        <Customized
          component={
            <CustomTitle
              x={325}
              y={5}
              value={intl.get(
                'INSIGHTS_PAGE_TABS.INSIGHTS_EVALUATIONS.CHARTS.LEARNING_DELIVERY.RATINGS_DISTRIBUTION'
              )}
            />
          }
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
