import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import intl from 'react-intl-universal';
import get from 'lodash/get';
import set from 'lodash/set';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import {
  getSelectedUser,
  selectSelectedUser,
  selectUserStatus,
  updateUser,
} from 'state/UsersManagement/usersManagementSlice';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import { selectUserId } from 'state/User/userSlice';
import { AllUsersType, objKeyAsString } from 'utils/customTypes';
import { PATHS, SLICE_STATUS } from 'utils/constants';
import Loader from 'Molecules/Loader/Loader';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import UserDetailsSection from '../SettingsPage/UsersListPage/UserPage/components/UserDetailsSection';
import UserPageFooter from '../SettingsPage/UsersListPage/UserPage/UserPageFooter';
import { validateRequiredFields } from '../SettingsPage/UsersListPage/UserPage/helpers/validationHelper';
import DetailsPage from 'Molecules/DetailsPage/DetailsPage';
import UnsavedChangesPrompt from 'Organisms/UnsavedChangesPrompt/UnsavedChangesPrompt';

const EditProfilePage = () => {
  const history = useHistory();

  const dispatch = useDispatch();
  const userId = useSelector(selectUserId);
  const user = useSelector(selectSelectedUser);
  const userStatus = useSelector(selectUserStatus);
  const [errors, setErrors] = useState<objKeyAsString>({});
  const [userUpdatedFields, setUserUpdatedFields] = useState<
    Partial<AllUsersType>
  >({});
  const [disableSave, setDisableSave] = useState<boolean>(true);
  const [wasSaved, setWasSaved] = useState<boolean>(false);
  const changesDetected = useMemo(() => {
    return Object.keys(userUpdatedFields).length > 0 || !wasSaved;
  }, [userUpdatedFields, wasSaved]);

  useEffect(() => {
    if (userId && get(user, 'id') !== userId) {
      dispatch(getSelectedUser(userId));
    }
  }, [dispatch, user, userId]);

  useEffect(() => {
    const currentUserData = {
      ...user,
      ...userUpdatedFields,
      data: { ...user.data, ...userUpdatedFields.data },
    };

    if (!isEqual(user, currentUserData) && disableSave) {
      setDisableSave(false);
    } else if (isEqual(user, currentUserData) && !disableSave) {
      setDisableSave(true);
    }
  }, [user, userUpdatedFields, disableSave]);

  const handleChangeField = (
    inputValue: string | string[] | boolean | number,
    targetPath: string
  ) => {
    let newData: Partial<AllUsersType> = {};
    newData = set(newData, targetPath, inputValue);
    setWasSaved(false);
    setUserUpdatedFields((prevState) => {
      if (newData.data) {
        newData = {
          data: { ...user.data, ...prevState.data, ...newData.data },
        };
      }
      if (Object.keys(errors).length) {
        setErrors(
          validateRequiredFields({ ...user, ...prevState, ...newData })
        );
      }
      return { ...prevState, ...newData };
    });
  };

  const onUpdate = async () => {
    let currentErrors = { ...errors };
    if (isEmpty(errors)) {
      currentErrors = validateRequiredFields({
        ...user,
        ...userUpdatedFields,
        data: { ...user.data, ...userUpdatedFields.data },
      });
      setErrors(currentErrors);
    }
    if (Object.keys(currentErrors).length) {
      dispatch(
        showNotificationBanner({
          notificationVariant: 'error',
          notificationText: intl.get(
            'SETTINGS_PAGE.USER_PAGE.ERRORS.INLINE_NOTFICATION'
          ),
        })
      );
      return;
    }

    if (Object.keys(userUpdatedFields).length) {
      if (userId) {
        await dispatch(updateUser({ userId, updateFields: userUpdatedFields }));
        setWasSaved(true);
      }
    }

    showSuccessBanner();
  };

  const showSuccessBanner = useCallback(() => {
    history.push(PATHS.ROOT);
    dispatch(
      showNotificationBanner({
        notificationVariant: 'success',
        notificationText: intl.get('PROFILE.SUCCESS'),
      })
    );
  }, [dispatch, history]);

  return (
    <div className='h-full flex flex-col'>
      <PageTitle
        titleComponent={intl.get('PROFILE.TITLE')}
        className='sticky top-0 left-0 right-0'
      />
      {userStatus !== SLICE_STATUS.LOADING && get(user, 'id') ? (
        <Fragment>
          <DetailsPage
            content={
              <UserDetailsSection
                userObj={user}
                handleChangeField={handleChangeField}
                errors={errors}
                isProfileEdit
              />
            }
          />
          <UserPageFooter
            disableSave={disableSave}
            onUpdate={onUpdate}
            newUser={false}
          />
        </Fragment>
      ) : (
        <Loader />
      )}
      <UnsavedChangesPrompt hasChanges={changesDetected && !wasSaved} />
    </div>
  );
};

export default EditProfilePage;
