import {
  createSlice,
  createAsyncThunk,
  createSelector,
  createAction,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { fetchTasksList } from 'api/projectTasks';
import { RootState } from 'state/store';
import {
  selectAssigneesListByIds,
  setTasksAssignees,
} from 'state/TasksList/allTasks/allTasksSlice';
import { fetchFilterSettingByType } from 'state/Settings/Filters/FiltersSlice';
import { SLICE_STATUS, TASKS_TABLE_TABS } from 'utils/constants';
import { DEFAULT_TASK_ELEMENT_PERMISSIONS } from 'utils/constants/centralizedTasks';
import { ProjectTasksSliceState } from 'types/store/projectTasksList';
import { getFetchProjectsTasksListQueryParams } from '../helpers';
import { normalizeFiltersSettingsVersions } from 'state/TasksList/helpers';

const initialState: ProjectTasksSliceState = {
  tasks: [],
  links: {
    next: null,
  },
};

interface FetchTasksListParams {
  projectId: string;
  fetchNext?: boolean;
  includeTotalCount?: boolean;
}

export const fetchTeamTasks = createAsyncThunk(
  'projectTasksList/teamTasks/fetchTeamTasks',
  async (
    params: FetchTasksListParams,
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const state = getState() as RootState;
      const teamTasksState = state.projectTasksList.teamTasks;
      const queryParams = getFetchProjectsTasksListQueryParams(
        params,
        teamTasksState
      );
      const response = await fetchTasksList(queryParams);
      dispatch(setTasksAssignees(response.assignees));
      return {
        tasks: response.tasks,
        links: response.links,
        fetchNext: params.fetchNext,
        totalCount: response.totalCount,
        permissions: response.permissions,
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue('Error fetching project team tasks');
    }
  }
);

export const reorderTask = createAction<{
  taskId: string;
  startIndex: number;
  endIndex: number;
}>('projectTasksList/teamTasks/reorderTask');

const teamTasksSlice = createSlice({
  name: 'projectTasksList/teamTasks',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setSearchFilter: (state, action) => {
      state.filters = {
        ...state.filters,
        search: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeamTasks.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchTeamTasks.fulfilled, (state, action) => {
        let updatedTasks = action.payload.tasks;
        let permissions = action.payload.permissions;
        if (action.payload.fetchNext) {
          updatedTasks = state.tasks.concat(action.payload.tasks);
          permissions = {
            ...state.permissions,
            ...action.payload.permissions,
          };
        }
        state.tasks = updatedTasks;
        state.permissions = permissions;
        state.links = action.payload.links;
        if (action.payload.totalCount !== undefined) {
          state.totalCount = action.payload.totalCount;
        }
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(fetchTeamTasks.rejected, (state, action) => {
        state.status = SLICE_STATUS.FAILED;
        throw new Error(action.payload as string);
      })
      .addCase(reorderTask, (state, action) => {
        if (action.payload) {
          const currentTasks = state.tasks;
          const { taskId, startIndex, endIndex } = action.payload;
          if (startIndex < 0 || endIndex > state.tasks.length) {
            return;
          }
          const taskToReorder = { ...state.tasks[startIndex] };
          if (taskToReorder.id !== taskId) {
            return;
          }
          currentTasks.splice(startIndex, 1);
          currentTasks.splice(endIndex, 0, taskToReorder);
          state.tasks = currentTasks;
        }
      })
      .addCase(fetchFilterSettingByType.fulfilled, (state, action) => {
        if (
          action.payload &&
          action.payload.filter_type === TASKS_TABLE_TABS.TEAM_TASKS
        ) {
          const filterSettings = normalizeFiltersSettingsVersions(
            action.payload.filter_settings
          );
          state.filters = {
            ...state.filters,
            ...filterSettings,
          };
        }
      });
  },
});

const selectTasks = (state: RootState) =>
  state.projectTasksList.teamTasks.tasks;
const selectTasksPermissions = (state: RootState) =>
  state.projectTasksList.teamTasks.permissions;
export const selectTeamTasksLoading = (state: RootState) =>
  state.projectTasksList.teamTasks.status;
export const selectTeamTasksTableFilters = (state: RootState) =>
  state.projectTasksList.teamTasks.filters ?? {};

export const selectTeamTasks = createSelector(
  [(state: RootState) => state, selectTasks, selectTasksPermissions],
  (
    state: RootState,
    tasks: ProjectTasksSliceState['tasks'],
    permissions: ProjectTasksSliceState['permissions']
  ) => {
    return tasks.map((task) => {
      return {
        ...task,
        assignees: selectAssigneesListByIds(state, task.assignees),
        permissions: permissions
          ? permissions[task.id]
          : DEFAULT_TASK_ELEMENT_PERMISSIONS,
      };
    });
  }
);

export const selectTotalCount = (state: RootState) =>
  state.projectTasksList.teamTasks.totalCount;

export const selectCanFetchtMoreTasks = (state: RootState) =>
  state.projectTasksList.teamTasks.links.next !== null;

export const { setFilters, setSearchFilter } = teamTasksSlice.actions;

export default teamTasksSlice.reducer;
