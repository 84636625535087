import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import {
  deleteProjectTask,
  setSnackBarNotification,
} from 'state/ProjectTasksList/actions/actionsSlice';

export interface UseDeleteTaskParams {
  taskId: string;
  showNotification: (params: any) => void;
  onSuccessCallback?: () => void;
}

interface UseDeleteTaskReturn {
  deleteTask: () => void;
}

const useDeleteTask: (params: UseDeleteTaskParams) => UseDeleteTaskReturn = ({
  taskId,
  showNotification,
  onSuccessCallback = () => {},
}) => {
  const dispatch = useDispatch();

  const deleteTask = useCallback(async () => {
    try {
      await dispatch(deleteProjectTask(taskId));
      dispatch(
        setSnackBarNotification({
          notificationMessage: intl.get(
            'TASKS.TASK_DETAIL_PAGE.DELETE_SUCCESS'
          ),
        })
      );
      onSuccessCallback();
    } catch (error) {
      showNotification({
        notificationVariant: 'error',
        notificationTitle: intl.get(
          'TASKS.TASK_DETAIL_PAGE.DELETE_ERROR.TITLE'
        ),
        notificationMessage: intl.get(
          'TASKS.TASK_DETAIL_PAGE.DELETE_ERROR.MESSAGE'
        ),
      });
    }
  }, [dispatch, showNotification, taskId, onSuccessCallback]);

  return useMemo(() => ({ deleteTask }), [deleteTask]);
};

export default useDeleteTask;
