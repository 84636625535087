import { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { useHistory, useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import {
  Typography,
  Icon,
  Button,
  useElevation,
  Tabs,
} from '@getsynapse/design-system';
import { getAllRequests, getUserRequests } from 'state/Requests/requestSlice';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import {
  createNewProject,
  selectProjectStatus,
} from 'state/Projects/projectsSlice';
import { getLearningTeams } from 'state/LearningTeams/learningTeamsSlice';
import projectTemplateAPI from 'state/ProjectTemplate/ProjectTemplateAPI';
import {
  PATHS,
  NEW_PROJECT_FORM_FIELDS,
  PROJECT_STATUS,
  SLICE_STATUS,
} from 'utils/constants';
import { NewProject, objKeyAsString } from 'utils/customTypes';
import { ProjectTemplateField } from 'utils/types/templates';
import { validateRequiredFields } from 'Pages/ProjectPage/helpers/formValidation';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import useModal from 'Hooks/useModal';
import BasicDetails from './components/BasicDetails';
import {
  defaultNewProjectData,
  requiredFieldsErrorsMap,
} from './helpers/types';
import DetailsPage from 'Molecules/DetailsPage/DetailsPage';
import ResourcesDetails from './components/ResourcesDetails';
import { attachTaskBundleToProject } from 'state/TasksBundle/tasksBundleSlice';
import moment from 'moment';
import UnsavedChangesPrompt from 'Organisms/UnsavedChangesPrompt/UnsavedChangesPrompt';
import { selectUser } from 'state/User/userSlice';

const NewProjectPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { templateId } = useParams<{ templateId: string }>();
  const projectSliceStatus = useSelector(selectProjectStatus);
  const [currentTab, setCurrentTab] = useState(0);
  const [projectTemplateFields, setProjectTemplateFields] = useState<
    ProjectTemplateField[]
  >([]);
  const [requiredFieldsErrors, setRequiredFieldsErrors] =
    useState<objKeyAsString>(requiredFieldsErrorsMap);
  const { Modal, modalProps, openModal, closeModal } = useModal();
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [isCanceled, setIsCanceled] = useState<boolean>(false);
  const currentUser = useSelector(selectUser);

  const initialProjectData = useMemo(() => {
    return {
      ...defaultNewProjectData,
      project_template_id: templateId,
    };
  }, [templateId]);

  const [newProjectData, setNewProjectData] = useState<
    NewProject & {
      task_bundle_id?: string;
    }
  >(initialProjectData);
  const footerElevation = useElevation(1);

  useEffect(() => {
    const { owners, ...rest } = newProjectData;
    const { owners: initialOwners, ...initialRest } = initialProjectData;
    if (!isEqual(rest, initialRest) || !isEqual(owners, [currentUser.id])) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  }, [newProjectData, initialProjectData, currentUser]);

  const fetchProjectTemplateFields = useCallback(async () => {
    const projectTemplate = await projectTemplateAPI.fetchProjectTemplateById(
      templateId
    );
    const projectTemplateFields = get(
      projectTemplate,
      'project_template_fields',
      []
    );
    const defaultCustomFieldsValues = projectTemplateFields.reduce(
      (defaultValues: objKeyAsString, templateField: ProjectTemplateField) => {
        if (templateField.field_template.default_value) {
          defaultValues[templateField.field_template.name] =
            templateField.field_template.default_value;
        }
        return defaultValues;
      },
      {}
    );
    setProjectTemplateFields(projectTemplateFields);
    if (!isEmpty(defaultCustomFieldsValues)) {
      setNewProjectData((prevData) => ({
        ...prevData,
        ...defaultCustomFieldsValues,
      }));
    }
  }, [templateId]);

  const requiredCustomFieldsErrorsMap = useMemo<{ [key: string]: boolean }>(
    () =>
      projectTemplateFields.reduce(
        (
          errorsMap: { [key: string]: boolean },
          currentField: ProjectTemplateField
        ) => {
          if (currentField.required) {
            errorsMap[currentField.field_template.name] = false;
          }
          return errorsMap;
        },
        {}
      ),
    [projectTemplateFields]
  );

  useEffect(() => {
    const init = async () => {
      await Promise.all([
        dispatch(getAllRequests()),
        dispatch(getUserRequests()),
        dispatch(getLearningTeams()),
        fetchProjectTemplateFields(),
      ]);
    };
    init();
  }, [dispatch, fetchProjectTemplateFields]);

  useEffect(() => {
    if (isCanceled) {
      closeModal();
      history.push(PATHS.PROJECTS_LIST_PAGE);
    }
  }, [isCanceled, closeModal, history]);

  const handleCancel = () => {
    openModal({
      title: intl.get('NEW_PROJECT_PAGE.CANCEL_PROJECT_CREATION_MODAL.TITLE'),
      size: 'medium',
      children: (
        <Typography variant='h6'>
          {intl.get('NEW_PROJECT_PAGE.CANCEL_PROJECT_CREATION_MODAL.BODY', {
            projectName: newProjectData.title,
          })}
        </Typography>
      ),
      actionButtons: [
        {
          children: intl.get(
            'NEW_PROJECT_PAGE.CANCEL_PROJECT_CREATION_MODAL.SUBMIT'
          ),
          color: 'danger',
          onClick: () => {
            dispatch(
              showNotificationBanner({
                notificationVariant: 'success',
                notificationText: intl.get(
                  'NEW_PROJECT_PAGE.CANCEL_PROJECT_CREATION_SUCCESS',
                  {
                    projectName: newProjectData.title,
                  }
                ),
              })
            );
            setIsCanceled(true);
          },
        },
        {
          children: intl.get(
            'NEW_PROJECT_PAGE.CANCEL_PROJECT_CREATION_MODAL.CANCEL'
          ),
          variant: 'secondary',
          onClick: () => closeModal(),
        },
      ],
    });
  };
  const handleTabsNavigation = (value: number) => {
    setCurrentTab(value);
  };

  const handleSaveProject = async () => {
    const { task_bundle_id, ...projectDataToSend } = newProjectData;

    const data = await dispatch(
      createNewProject({
        ...{
          ...projectDataToSend,
          startDate: moment(
            projectDataToSend.startDate,
            'YYYY-MM-DD'
          ).toString(),
          targetCompletionDate: moment(
            projectDataToSend.targetCompletionDate,
            'YYYY-MM-DD'
          ).toString(),
        },
        [NEW_PROJECT_FORM_FIELDS.STATUS]: PROJECT_STATUS.NEW,
      })
    );

    const createdProject = get(data, 'payload.data', null);

    if (createdProject && task_bundle_id) {
      await dispatch(
        attachTaskBundleToProject({
          projectId: createdProject.id,
          taskBundleId: task_bundle_id,
        })
      );
    }

    dispatch(
      showNotificationBanner({
        notificationVariant: 'success',
        notificationText: intl.get(
          'REQUEST_PAGE.TOP_BAR.CREATE_PROJECT_MODAL.SUCCESS_MESSAGE',
          {
            projectTitle: newProjectData.title,
          }
        ),
      })
    );
    setHasChanges(false);

    if (createdProject?.id) {
      history.push(`${PATHS.PROJECT_PAGE}/${createdProject.id}`);
    } else {
      history.push(PATHS.PROJECTS_LIST_PAGE);
    }
  };

  const handleSave = () => {
    const { canSave, errorsMap } = validateRequiredFields(
      newProjectData,
      false,
      requiredCustomFieldsErrorsMap
    );
    setRequiredFieldsErrors(errorsMap);
    if (!canSave) {
      dispatch(
        showNotificationBanner({
          notificationVariant: 'error',
          notificationText: intl.get('NEW_PROJECT_PAGE.SAVE_ERROR'),
        })
      );
      setCurrentTab(0);
    } else {
      handleSaveProject();
    }
  };

  return (
    <div className='flex flex-col h-full min-h-project'>
      <PageTitle titleComponent={intl.get('NEW_PROJECT_PAGE.TITLE')} />
      <Modal {...modalProps} aria-label='New project form' />
      <DetailsPage
        content={
          <div
            className='bg-neutral-white flex-grow overflow-y-auto'
            data-cy='project-form-body'
          >
            <Tabs
              index={currentTab}
              onChange={handleTabsNavigation}
              data={[
                {
                  label: intl.get('NEW_PROJECT_PAGE.BASIC_DETAILS'),
                  content: (
                    <BasicDetails
                      data={newProjectData}
                      setData={setNewProjectData}
                      requiredFieldsErrors={requiredFieldsErrors}
                      projectTemplateFields={projectTemplateFields}
                    />
                  ),
                },
                {
                  label: intl.get('NEW_PROJECT_PAGE.RESOURCES_DETAILS'),
                  content: (
                    <ResourcesDetails
                      data={newProjectData}
                      setData={setNewProjectData}
                    />
                  ),
                },
              ]}
            />
          </div>
        }
      />
      <div
        className={`w-full bg-neutral-white py-2 flex justify-between z-5 ${footerElevation}`}
      >
        <div className='ml-6'>
          {currentTab > 0 && (
            <Button
              variant='secondary'
              onClick={() => handleTabsNavigation(currentTab - 1)}
            >
              <Icon name='caret-back-outline' className='mr-4' />
              {intl.get('NEW_PROJECT_PAGE.BACK_BUTTON')}
            </Button>
          )}
        </div>
        <div className='flex space-x-4 mr-12'>
          <Button variant='secondary' onClick={handleCancel}>
            {intl.get('NEW_PROJECT_PAGE.CANCEL_BUTTON')}
          </Button>
          {currentTab < 1 ? (
            <Button
              onClick={() => handleTabsNavigation(currentTab + 1)}
              data-testid='next-button'
            >
              {intl.get('NEW_PROJECT_PAGE.NEXT_BUTTON')}
              <Icon name='caret-forward-outline' className='ml-3.5' />
            </Button>
          ) : (
            <Button
              onClick={handleSave}
              data-testid='save-button'
              loading={projectSliceStatus === SLICE_STATUS.LOADING}
            >
              {intl.get('NEW_PROJECT_PAGE.SAVE_BUTTON')}
            </Button>
          )}
        </div>
      </div>
      <UnsavedChangesPrompt hasChanges={hasChanges && !isCanceled} />
    </div>
  );
};

export default NewProjectPage;
