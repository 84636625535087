import {
  Datepicker,
  FormItem,
  tailwindOverride,
} from '@getsynapse/design-system';
import { rangeDate } from 'utils/customTypes';
import intl from 'react-intl-universal';

interface TaskDatesFieldProps {
  isOnSidepanel?: boolean;
  isViewOnly?: boolean;
  isReadOnly?: boolean;
  startDate?: Date;
  dueDate?: Date;
  hasAssignedUsers: boolean;
  onDateChange: (date: rangeDate) => void;
  hasError?: boolean;
}
const TaskDatesField = ({
  startDate,
  dueDate,
  onDateChange,
  hasError = false,
  hasAssignedUsers = false,
  isOnSidepanel = false,
  isViewOnly = false,
  isReadOnly = false,
}: TaskDatesFieldProps) => {
  return (
    <FormItem className={tailwindOverride({ 'mt-4': isOnSidepanel })}>
      <Datepicker
        disabled={isViewOnly || isReadOnly}
        className='w-full'
        canSelectRange
        startDate={startDate}
        startDateLabel={intl.get('TASKS.TASK_DETAIL_PAGE.START_DATE_LABEL')}
        startDateLabelProps={{
          required: hasAssignedUsers,
          state: hasError ? 'error' : 'default',
        }}
        startDateError={
          hasError && intl.get('TASKS.TASK_DETAIL_PAGE.MISSING_INFO_ERROR')
        }
        endDate={dueDate}
        endDateLabel={intl.get('TASKS.TASK_DETAIL_PAGE.DUE_DATE_LABEL')}
        endDateLabelProps={{
          required: hasAssignedUsers,
          state: hasError ? 'error' : 'default',
        }}
        endDateError={
          hasError && intl.get('TASKS.TASK_DETAIL_PAGE.MISSING_INFO_ERROR')
        }
        size='large'
        onPickDate={onDateChange}
      />
    </FormItem>
  );
};

export default TaskDatesField;
