import { L10n } from '@syncfusion/ej2-base';
import { generateSummary } from '@syncfusion/ej2-schedule';
import {
  NewScheduleCreationParams,
  EventData,
  ScheduleUpdateParams,
  ExceptionData,
  ExceptionCreationData,
} from 'utils/types/learningSchedule';
import { PopupOpenEventArgs } from '@syncfusion/ej2-react-schedule';
import { EventTypesOptions } from 'Pages/LearningSchedule/constants/constants';

export const generateReadableRecurrancePattern = (
  recurrancePattern?: string
): string => {
  if (!recurrancePattern) {
    return '';
  }
  const l10n = new L10n('recurrenceeditor', {
    none: 'None',
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
    month: 'Month',
    yearly: 'Yearly',
    never: 'Never',
    until: 'Until',
    count: 'Count',
    first: 'First',
    second: 'Second',
    third: 'Third',
    fourth: 'Fourth',
    last: 'Last',
    repeat: 'Repeat',
    repeatEvery: 'Repeat every',
    on: 'Repeat On',
    end: 'End',
    onDay: 'Day',
    days: 'Day(s)',
    weeks: 'Week(s)',
    months: 'Month(s)',
    years: 'Year(s)',
    every: 'every',
    summaryTimes: 'time(s)',
    summaryOn: 'on',
    summaryUntil: 'until',
    summaryRepeat: 'Repeats',
    summaryDay: 'day(s)',
    summaryWeek: 'week(s)',
    summaryMonth: 'month(s)',
    summaryYear: 'year(s)',
    monthWeek: 'Month Week',
    monthPosition: 'Month Position',
    monthExpander: 'Month Expander',
    yearExpander: 'Year Expander',
    repeatInterval: 'Repeat Interval',
  });

  return generateSummary(recurrancePattern, l10n, 'en');
};

export const serializeCreateEvent = (
  data: EventData
): NewScheduleCreationParams => {
  return {
    learningEvent: {
      learningEventTypeId: data.EventType,
      title: data.Subject,
    },
    schedule: {
      scheduleFacilitatorIds: data.FacilitatorId || [],
      scheduleProjectIds: data.ProjectId,
      startDateTime: data.StartTime,
      endDateTime: data.EndTime,
      isAllDay: data.IsAllDay,
      recurrencePattern: {
        recurrenceRule: data.RecurrenceRule || '',
      },
    },
  };
};

export const serializeUpdateEvent = (
  data: EventData,
  shouldResetExceptions = false
): ScheduleUpdateParams => {
  return {
    scheduleId: data.Id,
    learningEvent: {
      learningEventTypeId: data.EventType,
      title: data.Subject,
    },
    schedule: {
      scheduleFacilitatorIds: data.FacilitatorId,
      scheduleProjectIds: data.ProjectId,
      startDateTime: data.StartTime,
      endDateTime: data.EndTime,
      isAllDay: data.IsAllDay,
      recurrencePattern: {
        recurrenceRule: data.RecurrenceRule || '',
      },
      recurrenceException: data.RecurrenceException || '',
    },
    resetExceptions: shouldResetExceptions,
  };
};

export const getEventTypeIdFromCode = (code: string) => {
  const eventType = EventTypesOptions.find((option) => option.code === code);
  return eventType?.value || '';
};

export const attachListenersToSchedule = (
  args: PopupOpenEventArgs,
  onUpdateEventField: (key: string, value: any) => void
) => {
  const fieldsToListen = [
    {
      key: 'startTime',
      element: '#StartTime',
      value: 'value',
    },
    {
      key: 'endTime',
      element: '#EndTime',
      value: 'value',
    },
    {
      key: 'isAllDay',
      element: '#IsAllDay',
      value: 'checked',
    },
    {
      key: 'recurrenceRule',
      element: '.e-recurrenceeditor',
      value: 'value',
    },
  ];

  for (const field of fieldsToListen) {
    const element = args.element.querySelector(field.element) as any;
    if (element && element.ej2_instances) {
      const originalChange = element.ej2_instances[0].change;
      element.ej2_instances[0].change = (e: any) => {
        onUpdateEventField(field.key, e[field.value]);
        if (originalChange) originalChange.call(element.ej2_instances[0], e);
      };
    }
  }
};

export const serializeCreateException = (
  data: ExceptionData,
  parentId: string,
  recurrenceException: string
): ExceptionCreationData => {
  return {
    learningEvent: {
      learningEventTypeId: data.EventType,
      title: data.Subject,
    },
    schedule: {
      scheduleFacilitatorIds: data.FacilitatorId,
      scheduleProjectIds: data.ProjectId,
      startDateTime: data.StartTime,
      endDateTime: data.EndTime,
      isAllDay: data.IsAllDay,
      parentId: parentId,
    },
    recurrenceException: recurrenceException,
  };
};

export const getEventTypeValueFromCode = (code: string) => {
  const eventType = EventTypesOptions.find((option) => option.code === code);
  return eventType?.value.toString() || '';
};

export const getEventData = ({
  projectIds,
  eventTitle,
  eventType,
  data,
  facilitators,
}: {
  projectIds: string[];
  eventTitle: string;
  eventType: string;
  data: EventData;
  facilitators: any;
}) => {
  return {
    StartTime: data.StartTime,
    EndTime: data.EndTime,
    RecurrenceRule: data.RecurrenceRule,
    Id: data.Id,
    ProjectId: projectIds,
    Subject: eventTitle,
    EventType: eventType,
    IsAllDay: data.IsAllDay,
    FacilitatorId: facilitators,
    RecurrenceException: data.RecurrenceException,
  };
};
