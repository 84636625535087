import { FC, useEffect, useCallback, useMemo, useState } from 'react';
import intl from 'react-intl-universal';
import { Typography } from '@getsynapse/design-system';
import { NewTask } from 'types/store/newTask';
import useModal from 'Hooks/useModal';
import useTaskForm from './hooks/useTaskForm';
import TaskFormFields from './TaskFormFields/TaskFormFields';
import UnsavedChangesModal from 'Organisms/UnsavedChangesModal/UnsavedChangesModal';

interface TaskModalFormProps {
  shouldOpenModal: boolean;
  onCloseModal: () => void;
  title: string;
  saveButtonLabel: string;
  formPrompt: string;
  onSaveTask: (newTask: NewTask) => void;
  displayProjectPicker?: boolean;
  orginalTaskData?: NewTask;
  isDuplicate?: boolean;
}

const TaskModalForm: FC<TaskModalFormProps> = ({
  shouldOpenModal,
  onCloseModal,
  title,
  saveButtonLabel,
  formPrompt,
  onSaveTask = () => {},
  displayProjectPicker = true,
  isDuplicate = false,
  orginalTaskData = {} as NewTask,
}) => {
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] =
    useState<boolean>(false);
  const { Modal, modalProps, openModal, closeModal } = useModal();
  const {
    taskData,
    updateTaskData,
    canSaveTask,
    setCanSaveTask,
    hasUpdatedValues,
    resetTaskForm,
  } = useTaskForm({
    orginalTaskData,
    isDuplicate,
  });

  useEffect(() => {
    if (shouldOpenModal && !modalProps.isOpen) {
      openModal({ ...modalProps });
    }
  }, [shouldOpenModal, modalProps, openModal]);

  useEffect(() => {
    if (isDuplicate && modalProps.isOpen) {
      setCanSaveTask(true);
    }
  }, [isDuplicate, modalProps.isOpen, setCanSaveTask]);

  const handleCloseModal = useCallback(() => {
    onCloseModal();
    closeModal();
  }, [closeModal, onCloseModal]);

  const validateIfCanCloseModal = useCallback(() => {
    if (hasUpdatedValues) {
      setShowUnsavedChangesModal(true);
    } else {
      handleCloseModal();
    }
  }, [hasUpdatedValues, handleCloseModal]);

  const handleSaveTask = useCallback(() => {
    onSaveTask(taskData);
    resetTaskForm();
    handleCloseModal();
  }, [taskData, handleCloseModal, onSaveTask, resetTaskForm]);

  const actionButtons = useMemo(
    () => [
      {
        children: saveButtonLabel,
        variant: 'primary',
        'data-cy': 'confirm-button',
        'data-testid': 'confirm-button',
        disabled: !canSaveTask,
        onClick: handleSaveTask,
      },
      {
        children: intl.get('CANCEL'),
        variant: 'tertiary',
        onClick: handleCloseModal,
        'data-cy': 'cancel-button',
        'data-testid': 'cancel-button',
      },
    ],
    [handleCloseModal, saveButtonLabel, handleSaveTask, canSaveTask]
  );

  return (
    <>
      <Modal
        {...modalProps}
        aria-label={title}
        closeModal={validateIfCanCloseModal}
        title={title}
        size='large'
        className='h-full'
        actionButtons={actionButtons}
      >
        <div className='flex flex-col'>
          <Typography>{formPrompt}</Typography>
          <TaskFormFields
            taskData={taskData}
            updateTaskData={updateTaskData}
            displayProjectPicker={displayProjectPicker}
          />
        </div>
      </Modal>
      <UnsavedChangesModal
        isOpen={showUnsavedChangesModal}
        setIsOpen={setShowUnsavedChangesModal}
        onConfirm={() => handleCloseModal()}
      />
    </>
  );
};

export default TaskModalForm;
