import TimeEntriesSection from './TimeEntriesSection';
import TimeEntrySkeletonLoader from './TimeEntrySkeletonLoader';

const TimeEntriesSkeletonLoader = () => {
  return (
    <TimeEntriesSection>
      <div aria-label='time-entries-list-skeleton-loader'>
        <TimeEntrySkeletonLoader />
        <TimeEntrySkeletonLoader />
      </div>
    </TimeEntriesSection>
  );
};

export default TimeEntriesSkeletonLoader;
