import { FC, useState, useEffect } from 'react';
import { SidePanelFilter } from '@getsynapse/design-system';
import { TaskListFilters } from 'types/store/tasksList';
import FiltersForm from './FiltersForm/FiltersForm';

interface TasksFiltersSidePanelProps {
  open: boolean;
  onClose: () => void;
  filters: TaskListFilters;
  onUpdateFilters: (filters: TaskListFilters) => void;
  hideAssigneeFilter?: boolean;
  isCentralizedTasksPage?: boolean;
}

const TasksFiltersSidePanel: FC<TasksFiltersSidePanelProps> = ({
  open,
  onClose,
  filters,
  onUpdateFilters,
  hideAssigneeFilter = false,
  isCentralizedTasksPage = true,
}) => {
  const [canApplyFilters, setCanApplyFilters] = useState<boolean>(false);
  const [appliedFilters, setAppliedFilters] = useState<TaskListFilters>({});
  const appliedFiltersCount = Object.keys(appliedFilters).length || 0;

  useEffect(() => {
    if (filters) {
      setAppliedFilters(filters);
    }
  }, [filters]);

  const handleClearAllFilters = () => {
    onUpdateFilters({});
    setAppliedFilters({});
    setCanApplyFilters(false);
  };

  const handleUpdateFilters = (updatedFilters: TaskListFilters) => {
    setAppliedFilters(updatedFilters);
    setCanApplyFilters(true);
  };

  const handleApplyFilters = () => {
    onUpdateFilters(appliedFilters);
    setCanApplyFilters(false);
  };

  return (
    <SidePanelFilter
      open={open}
      onClose={onClose}
      appliedFiltersCount={appliedFiltersCount}
      canApplyFilters={canApplyFilters}
      onClearAllFilters={handleClearAllFilters}
      onApplyFilters={handleApplyFilters}
    >
      <FiltersForm
        filters={appliedFilters}
        onUpdateFilters={handleUpdateFilters}
        hideAssigneeFilter={hideAssigneeFilter}
        isCentralizedTasksPage={isCentralizedTasksPage}
      />
    </SidePanelFilter>
  );
};

export default TasksFiltersSidePanel;
