import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Text,
  LabelList,
  CartesianGrid,
  LabelProps,
} from 'recharts';
import intl from 'react-intl-universal';
import { isNumber } from 'lodash';
import { RechartsCustomRendererTickProps } from 'Pages/InsightsPage/rechartTypeShim';

export interface LowestCoursesChartData {
  name: string;
  score: string;
}

const CustomBarLabel = (props: LabelProps) => {
  const { x, y, height, value } = props;
  const midY = (isNumber(height) ? height : 0) / 2;
  const posX = (isNumber(x) ? x : 0) + 24;
  const posY = (isNumber(y) ? y : 0) + midY;

  return (
    <Text
      x={posX}
      y={posY}
      fontSize={12}
      fontWeight={600}
      fill='#0E1212'
      dominantBaseline={'middle'}
      overflow={'hidden'}
    >
      {value}
    </Text>
  );
};

const CustomRightTickLabel = (props: RechartsCustomRendererTickProps) => {
  const { x, y, payload } = props;
  return (
    <Text x={x + 20} y={y} fontSize={12} fontWeight={600} textAnchor='end'>
      {payload.value}
    </Text>
  );
};

export const LowestCoursesChart = ({
  data,
}: {
  data: LowestCoursesChartData[];
}) => {
  return (
    <ResponsiveContainer width='100%' height='80%'>
      <BarChart
        data={data}
        layout='vertical'
        margin={{
          left: 5,
        }}
      >
        <XAxis
          tickLine={false}
          type='number'
          hide
          padding={{ left: 20 }}
          dataKey='score'
          style={{
            fontSize: '0.75em',
            fontWeight: '400',
            fill: '#000',
            lineHeight: '1em',
          }}
        />

        <YAxis
          tickLine={false}
          axisLine={false}
          tick={(props) => <CustomRightTickLabel {...props} />}
          width={30}
          style={{
            fontSize: '0.75em',
            fontWeight: '400',
            fill: '#000',
            lineHeight: '1em',
          }}
          dataKey='score'
          type='category'
          orientation='right'
        />
        <CartesianGrid
          horizontal={false}
          verticalPoints={[100, 185, 265, 345]}
        />
        <Bar
          dataKey='score'
          name={intl.get('INSIGHTS_PAGE_TABS.NUMBER_OF_REQUESTS')}
          maxBarSize={36}
          fill='#55AC9B'
        >
          <LabelList
            dataKey={'name'}
            content={(props: LabelProps) => <CustomBarLabel {...props} />}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};
