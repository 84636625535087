import React from 'react';
import intl from 'react-intl-universal';
import moment from 'moment';
import classnames from 'classnames';
import { Button, GlobalBanner, Typography } from '@getsynapse/design-system';
import { DATE } from 'utils/constants';

export const OnHoldStatusBanner: React.FC<{
  message: string;
  handleOnClick?: () => void;
  iconProps?: { 'data-testid'?: string; name?: string; src?: string };
  sidePanel?: boolean;
}> = ({ message, handleOnClick, iconProps, sidePanel = false }) => (
  <GlobalBanner
    variant='warning'
    hasCloseIcon
    className={classnames(`h-16 items-center ${sidePanel ? 'mb-4' : 'mt-4'}`)}
    data-cy='on-hold-inline-notification'
    aria-label='on-hold-inline-notification'
    iconProps={iconProps}
  >
    {() => (
      <div className='flex justify-between items-center'>
        <span>{message}</span>
        {handleOnClick && (
          <Button
            color='warning'
            onClick={handleOnClick}
            data-cy='view-hold-reason-button'
          >
            {intl.get('PROJECT_DETAIL.PUT_PROJECT_ON_HOLD_MODAL.VIEW_DETAILS')}
          </Button>
        )}
      </div>
    )}
  </GlobalBanner>
);

export const ClosedStatusBanner: React.FC<{
  handleOnClick?: () => void;
  closedDate?: string | null;
  sidePanel?: boolean;
}> = ({ handleOnClick, closedDate, sidePanel = false }) => (
  <GlobalBanner
    variant='information'
    hasCloseIcon
    className='mt-4 items-center'
    data-cy='closed-inline-notification'
  >
    {() => (
      <div className='flex justify-between items-center'>
        <Typography variant='body' className='text-primary'>
          {intl.get('PROJECT_DETAIL.CLOSE_PROJECT.CLOSED_PROJECT_BANNER')}
        </Typography>
        <div className='flex items-center'>
          <Typography
            variant='body'
            className={classnames(
              `text-primary ${sidePanel ? 'mb-4' : 'mr-4'}`
            )}
            data-cy='closed-notification-date'
          >
            {closedDate &&
              moment(closedDate).format(DATE.FULL_MONTH_YEAR_FORMAT)}
          </Typography>
          {handleOnClick && (
            <Button onClick={handleOnClick} data-cy='view-closed-details'>
              {intl.get('PROJECT_DETAIL.CLOSE_PROJECT.VIEW_DETAILS_BUTTON')}
            </Button>
          )}
        </div>
      </div>
    )}
  </GlobalBanner>
);
