import { FC } from 'react';
import intl from 'react-intl-universal';
import { Datepicker } from '@getsynapse/design-system';
import { rangeDateType } from 'utils/customTypes';

interface DateRangeFilterFieldProps {
  fromDateValue: Date | null;
  toDateValue: Date | null;
  onFromDateChange: (date: string) => void;
  onToDateChange: (date: string) => void;
  label: string;
}

const DateRangeFilterField: FC<DateRangeFilterFieldProps> = ({
  fromDateValue,
  onFromDateChange,
  toDateValue,
  onToDateChange,
  label,
}) => {
  const getFormattedDate = (date: rangeDateType) => {
    if (!date.startDate) {
      return '';
    }
    const newDate = new Date(date.startDate);
    return newDate.toLocaleDateString('en-US');
  };

  return (
    <div className='w-full flex space-x-4'>
      <Datepicker
        className='w-full'
        startPlaceHolder={intl.get('FILTER_GENERAL.FROM')}
        startDate={fromDateValue}
        onPickDate={(date: rangeDateType) => {
          const updatedFromDate = getFormattedDate(date);
          onFromDateChange(updatedFromDate);
        }}
        inputProps={{
          'aria-label': `${label}__from-range`,
        }}
      />
      <Datepicker
        className='w-full'
        startPlaceHolder={intl.get('FILTER_GENERAL.TO')}
        startDate={toDateValue}
        onPickDate={(date: rangeDateType) => {
          const updateToDate = getFormattedDate(date);
          onToDateChange(updateToDate);
        }}
        inputProps={{
          'aria-label': `${label}__to-range`,
        }}
      />
    </div>
  );
};

export default DateRangeFilterField;
