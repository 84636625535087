import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import TableView from './views/TableView';
import V2TableView from './views/v2/TableView';
import CreateCentralizedTaskModal from './components/CreateCentralizedTaskModal';
import CreateTaskModal from 'Pages/TasksListPage/components/v2/CreateTaskModal/CreateTaskModal';
import { selectUserType } from 'state/User/userSlice';
import { USER_TYPES } from 'utils/constants';
import { useFlags } from 'launchdarkly-react-client-sdk';

const TasksListPage = () => {
  const { newTaskListViews = false } = useFlags();
  const userType = useSelector(selectUserType);

  const canAddTasks = useMemo<boolean>(
    () =>
      userType !== USER_TYPES.BUSINESS &&
      userType !== USER_TYPES.EXTERNAL &&
      userType !== USER_TYPES.FACILITATOR,
    [userType]
  );

  let NewTaskFormModal = CreateCentralizedTaskModal;
  if (newTaskListViews) {
    NewTaskFormModal = CreateTaskModal;
  }

  return (
    <div className='flex flex-col h-full'>
      <PageTitle
        titleComponent={intl.get('TASKS_LIST_PAGE.TITLE')}
        headerChildren={canAddTasks && <NewTaskFormModal />}
      />
      {!newTaskListViews && <TableView />}
      {newTaskListViews && (
        <V2TableView isLDTypeUser={userType === USER_TYPES.L_D} />
      )}
    </div>
  );
};

export default TasksListPage;
