import { FC } from 'react';
import { Table, tailwindOverride } from '@getsynapse/design-system';
import {
  TaskWithAssigneesAndProject,
  TaskListFilters,
} from 'types/store/tasksList';
import { CentralizedTasksTableTab } from 'utils/types/centralizedTasks';
import TableHeader from './TableHeader/TableHeader';
import TableBody from './TableBody/TableBody';
import TableFooter from './TableFooter/TableFooter';
import ActionsHeader from './ActionsHeader/ActionsHeader';

interface TasksListTableProps {
  tableName: CentralizedTasksTableTab;
  filters: TaskListFilters;
  tasks: TaskWithAssigneesAndProject[];
  totalCount?: number;
  onUpdateFilters: (filters: TaskListFilters) => void;
  onSearchFilterChange: (value: string) => void;
  sortBy: string;
  order: string;
  handleSort: (sortBy: string, order: string) => void;
  canFetchMoreTasks: boolean;
  fetchMoreTasks: () => void;
  onExport: () => void;
}

const TasksListTable: FC<TasksListTableProps> = ({
  tableName,
  tasks,
  totalCount,
  filters,
  onUpdateFilters,
  onSearchFilterChange,
  sortBy,
  order,
  handleSort,
  canFetchMoreTasks,
  fetchMoreTasks,
  onExport,
}) => {
  const isFiltersEmpty = Object.keys(filters).length === 0;
  return (
    <>
      <ActionsHeader
        tableName={tableName}
        filters={filters}
        onUpdateFilters={onUpdateFilters}
        onExport={onExport}
        onSearchFilterChange={onSearchFilterChange}
      />
      <div
        className={tailwindOverride(
          'w-full',
          'rounded-b border-neutral-lighter-two border border-t-0',
          'overflow-auto',
          {
            'max-h-centralized-tasks-table': isFiltersEmpty,
            'max-h-filtered-centralized-tasks-table': !isFiltersEmpty,
          }
        )}
      >
        <Table
          canSelectRows={false}
          className='w-full relative border-0'
          aria-label={tableName}
          data={{
            headData: {
              headCells: [],
            },
            rows: [],
          }}
        >
          <TableHeader sortBy={sortBy} order={order} handleSort={handleSort} />
          <TableBody
            tasks={tasks}
            canFetchMoreTasks={canFetchMoreTasks}
            tableName={tableName}
            fetchMoreTasks={fetchMoreTasks}
          />
        </Table>
      </div>
      <TableFooter total={totalCount} />
    </>
  );
};

export default TasksListTable;
