import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import {
  Dropdown,
  FormItem,
  tailwindOverride,
} from '@getsynapse/design-system';
import {
  fetchTaskTypes,
  selectCanFetchTaskTypes,
  selectTaskTypes,
} from 'state/TasksList/allTasks/allTasksSlice';
import { Option } from 'utils/customTypes';

interface TaskTypeFieldProp {
  type?: string;
  onChangeType: (type: string) => void;
  isOnSidepanel?: boolean;
  isViewOnly?: boolean;
  isReadOnly?: boolean;
}

const TaskTypeField = ({
  type = '',
  onChangeType,
  isOnSidepanel = false,
  isViewOnly = false,
  isReadOnly = false,
}: TaskTypeFieldProp) => {
  const handleOnChange = (option: Option) => {
    onChangeType(option.value);
  };
  const taskTypes = useSelector(selectTaskTypes);
  const typeName = taskTypes[type];

  const dispatch = useDispatch();
  const canFetchTaskTypes = useSelector(selectCanFetchTaskTypes);
  useEffect(() => {
    if (canFetchTaskTypes) {
      dispatch(fetchTaskTypes());
    }
  }, [canFetchTaskTypes, dispatch]);

  return (
    <FormItem
      className={tailwindOverride({ 'mt-4': isOnSidepanel })}
      label={intl.get('TASKS.TASK_DETAIL_PAGE.TASK_TYPE')}
    >
      <Dropdown
        placeholder={intl.get('TASKS.ADD_TASK_MODAL.TASK_TYPE_PLACEHOLDER')}
        disabled={isViewOnly || isReadOnly}
        onChange={handleOnChange}
        options={Object.entries(taskTypes).map((entry): Option => {
          return {
            label: entry[1],
            value: entry[0],
          };
        })}
        values={[
          {
            label: typeName,
            value: type,
          },
        ]}
        triggerProps={{
          'data-cy': 'task-type',
          'aria-label': intl.get('TASKS.TASK_DETAIL_PAGE.TASK_TYPE'),
        }}
      />
    </FormItem>
  );
};

export default TaskTypeField;
