import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { PATHS, UPDATE_PROJECT_TABS } from 'utils/constants';

interface LocationState {
  from: string;
}

interface UseNavigateToTasksListReturn {
  navigateToTasksList: () => void;
}

const useNavigateToTasksList: (
  projectId: string
) => UseNavigateToTasksListReturn = (projectId) => {
  const location = useLocation<LocationState>();
  const history = useHistory();

  const navigateToProjectTasksList = useCallback(() => {
    history.replace(
      `${PATHS.PROJECT_PAGE}/${projectId}?tab=${UPDATE_PROJECT_TABS.TASKS}`
    );
  }, [history, projectId]);

  const navigateToCentralizedTasksList = useCallback(() => {
    history.push(PATHS.TASKS_LIST_PAGE);
  }, [history]);

  const navigateToTasksList = useCallback(() => {
    const state = location.state;
    const fromPage = state?.from;
    if (fromPage === PATHS.TASKS_LIST_PAGE) {
      navigateToCentralizedTasksList();
    } else {
      navigateToProjectTasksList();
    }
  }, [
    location.state,
    navigateToProjectTasksList,
    navigateToCentralizedTasksList,
  ]);

  return {
    navigateToTasksList,
  };
};

export default useNavigateToTasksList;
