import { useState, useEffect, useCallback, useMemo } from 'react';
import intl from 'react-intl-universal';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Tabs, ProgressBar, Icon, Typography } from '@getsynapse/design-system';
import {
  fetchTeamTasks,
  fetchUserTasks,
  setSearchParam,
  getTeamTasksCompletionProgress,
  getUserTasksCompletionProgress,
  getTeamTasksEstimatedAndActualHours,
  getUserTasksEstimatedAndActualHours,
  resetTasksTable,
  updatePagination,
  exportTasks,
} from 'state/ProjectTasks/projectTaskSlice';
import { isCurrentUserAssignedToTask } from 'state/SingleTask/singleTaskSlice';
import { Pagination, ProjectTasksTableTab } from 'utils/customTypes';
import {
  TASKS_TABLE_TABS,
  PROJECT_USER_ACTIONS,
  PROJECT_STATUS,
  SETTINGS_FILTERS,
  PROJECT_PERMISSIONS_BY_LEVEL,
  USER_TYPES,
} from 'utils/constants';
import { fetchFilterSettingByType } from 'state/Settings/Filters/FiltersSlice';
import { selectUserId, selectUserType } from 'state/User/userSlice';
import { getOriginalProjectData } from 'state/Project/projectSlice';
import estimatedHoursIcon from 'assets/icons/estimated-hours.svg';
import actualHoursIcon from 'assets/icons/actual-hours.svg';
import { useUserPermissionsContext } from 'Pages/ProjectPage/context/UserPermissionsContext';
import useHasUserAccess from 'Pages/ProjectPage/hooks/useHasUserAccess';
import TaskSidePanel from 'Organisms/TaskSidePanel/TaskSidePanel';
import CreateTaskModal from './CreateTaskModal';
import TeamTasks from './TasksListPage/components/TeamTasks';
import MyTasks from './TasksListPage/components/MyTasks';

const Tasks = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const subTab = queryParams.get('subTab');
  const tabMapping: { [key: string]: number } = {
    [TASKS_TABLE_TABS.TEAM_TASKS]: 0,
    [TASKS_TABLE_TABS.MY_TASKS]: 1,
  } as { [key: string]: number };
  const dispatch = useDispatch();
  const { canUser } = useUserPermissionsContext();
  const { projectId } = useParams<{ projectId: string }>();
  const currentUserId = useSelector(selectUserId);
  const isUserAssignedToTask = useSelector(isCurrentUserAssignedToTask);
  const addTaskButtonText = intl.get('TASKS.ADD_TASK_BUTTON_TITLE');
  const [currentTabIndex, setCurrentTabIndex] = useState(
    subTab ? tabMapping[subTab] : 0
  );
  const [activeTask, setActiveTask] = useState<string>('');
  const { permissionsLevel } = useHasUserAccess();
  const availableUserActions = useMemo(
    () => PROJECT_PERMISSIONS_BY_LEVEL[permissionsLevel],
    [permissionsLevel]
  );
  const userType = useSelector(selectUserType);
  const isFacilitator = userType === USER_TYPES.FACILITATOR;

  const init = useCallback(async () => {
    await Promise.all([
      dispatch(fetchUserTasks(projectId)),
      dispatch(fetchTeamTasks(projectId)),
      dispatch(
        fetchFilterSettingByType(SETTINGS_FILTERS.PROJECT_TEAM_TASKS_TABLE)
      ),
      dispatch(
        fetchFilterSettingByType(SETTINGS_FILTERS.MY_PROJECT_TASKS_TABLE)
      ),
    ]);
  }, [dispatch, projectId]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    return () => {
      dispatch(setSearchParam('', TASKS_TABLE_TABS.MY_TASKS));
      dispatch(setSearchParam('', TASKS_TABLE_TABS.TEAM_TASKS));
      dispatch(resetTasksTable());
    };
  }, [dispatch]);

  const teamTasksCompletionProgress = useSelector(
    getTeamTasksCompletionProgress
  );
  const userTasksCompletionProgress = useSelector(
    getUserTasksCompletionProgress
  );

  const teamTasksEstimatedAndActualHours = useSelector(
    getTeamTasksEstimatedAndActualHours
  );
  const userTasksEstimatedAndActualHours = useSelector(
    getUserTasksEstimatedAndActualHours
  );

  const handleExportTasksToCsv = async (
    taskIds: string[],
    callback: () => void
  ) => {
    await dispatch(exportTasks(taskIds));
    callback();
  };

  const handleUpdatePagination = useCallback(
    (pagination: Pagination, table: ProjectTasksTableTab) => {
      dispatch(updatePagination(pagination, table));
    },
    [dispatch]
  );

  const projectData = useSelector(getOriginalProjectData);

  const shouldDisplayAddTaskButton =
    ![PROJECT_STATUS.CLOSED, PROJECT_STATUS.CANCELED].includes(
      projectData.status
    ) &&
    (canUser(PROJECT_USER_ACTIONS.ADD_TASK) || isFacilitator) &&
    !projectData.is_archived;

  const onSaveTask = async () => {
    if (projectData.id) {
      await Promise.all([
        dispatch(fetchUserTasks(projectData.id)),
        dispatch(fetchTeamTasks(projectData.id)),
      ]);
    }
  };

  const changeCurrenTabIndex = (index: number) => {
    setCurrentTabIndex(index);
    let url = `${window.location.pathname}?subTab=${
      Object.keys(tabMapping)[index]
    }`;
    const parentTab = queryParams.get('tab');
    if (parentTab) {
      url = `${url}&tab=${parentTab}`;
    }
    window.history.replaceState({}, '', url);
  };

  return (
    <div className='w-full h-full relative'>
      <TaskSidePanel
        taskId={activeTask}
        onClosePanel={() => setActiveTask('')}
        currentUserId={currentUserId}
        permissionsLevel={permissionsLevel}
        availableUserActions={availableUserActions}
        isUserAssignedToTask={isUserAssignedToTask}
        onSave={onSaveTask}
      />
      <div className='absolute left-64 top-0 ml-4 h-8 flex items-center'>
        <ProgressBar
          maxValue={
            currentTabIndex === 0
              ? teamTasksCompletionProgress.available
              : userTasksCompletionProgress.available
          }
          currentValue={
            currentTabIndex === 0
              ? teamTasksCompletionProgress.completed
              : userTasksCompletionProgress.completed
          }
          label={intl.get('TASKS.COMPLETED_PROGRESS_BAR_LABEL')}
          barClassName='max-w-35 shadow-inner'
          containerClassName='w-80'
          progressClassName='bg-success-darker'
        />
        <div className='flex ml-6 items-center'>
          <Icon src={estimatedHoursIcon} className='text-xl' />
          <Typography
            variant='body'
            className='ml-1.5 text-neutral-darker font-semibold'
          >{`${
            currentTabIndex === 0
              ? teamTasksEstimatedAndActualHours.estimatedHours
              : userTasksEstimatedAndActualHours.estimatedHours
          } ${intl
            .get('TASKS.TABLE.HEAD.ESTIMATED_HOURS')
            .toLowerCase()}`}</Typography>
        </div>
        <div className='flex ml-6 items-center'>
          <Icon src={actualHoursIcon} className='text-xl' />
          <Typography
            variant='body'
            className='ml-1.5 text-neutral-darker font-semibold'
          >{`${
            currentTabIndex === 0
              ? teamTasksEstimatedAndActualHours.actualHours
              : userTasksEstimatedAndActualHours.actualHours
          } ${intl
            .get('TASKS.TABLE.HEAD.ACTUAL_HOURS')
            .toLowerCase()}`}</Typography>
        </div>
      </div>
      {shouldDisplayAddTaskButton && (
        <div className='absolute top-0 right-0 w-28'>
          <CreateTaskModal
            renderText={addTaskButtonText}
            componentType='header'
          />
        </div>
      )}
      <div className='px-6 flex-grow'>
        <Tabs
          index={currentTabIndex}
          onChange={changeCurrenTabIndex}
          tabListProps={{
            className: 'mb-4 w-58',
          }}
          type='tab'
          data={[
            {
              label: intl.get('TASKS.TEAM_TASKS'),
              content: currentTabIndex === 0 && (
                <TeamTasks
                  projectId={projectId}
                  onExportTasksToCsv={handleExportTasksToCsv}
                  onUpdatePagination={handleUpdatePagination}
                  openTaskPanel={setActiveTask}
                  hasActiveTask={!isEmpty(activeTask)}
                />
              ),
            },
            {
              label: intl.get('TASKS.MY_TASKS'),
              content: currentTabIndex === 1 && (
                <MyTasks
                  projectId={projectId}
                  onExportTasksToCsv={handleExportTasksToCsv}
                  onUpdatePagination={handleUpdatePagination}
                  openTaskPanel={setActiveTask}
                  hasActiveTask={!isEmpty(activeTask)}
                />
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default Tasks;
