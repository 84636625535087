import { forwardRef } from 'react';
import intl from 'react-intl-universal';
import { useSelector } from 'react-redux';

import { GlobalBanner, Typography } from '@getsynapse/design-system';
import FacilitatorsHeader from './components/FacilitatorsHeader';
import { selectFacilitatorsForDropdown } from 'state/Schedule/ScheduleSlice';
import ScheduleCalendar from '../ScheduleCalendar/ScheduleCalendar';

const Facilitators = forwardRef(
  ({ pageToLoad }: { pageToLoad: string }, ref) => {
    const facilitators = useSelector(selectFacilitatorsForDropdown);

    return (
      <div className='overflow-hidden'>
        {facilitators.length === 0 && (
          <GlobalBanner
            variant='warning'
            className='h-12 mb-4 bg-teal-lightest'
            iconProps={{
              className: 'text-teal',
            }}
          >
            {() => (
              <Typography>
                {intl.get('SCHEDULE.FACILITATORS.BANNER')}
              </Typography>
            )}
          </GlobalBanner>
        )}
        <FacilitatorsHeader />
        <ScheduleCalendar pageToLoad={pageToLoad} ref={ref} />
      </div>
    );
  }
);

export default Facilitators;
