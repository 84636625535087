import { useCallback, useMemo } from 'react';
import { TaskListFilters } from 'types/store/tasksList';

type TaskListFiltersKey = keyof TaskListFilters;

const useTaskFilters = (callback: (filters: TaskListFilters) => void) => {
  const updateArrayFilters = useCallback(
    (filters: TaskListFilters, key: TaskListFiltersKey, value: string[]) => {
      let filtersCopy = { ...filters };
      if (value.length === 0) {
        delete filtersCopy[key];
      } else {
        filtersCopy = { ...filtersCopy, [key]: value };
      }
      callback(filtersCopy);
    },
    [callback]
  );

  const updateStringFilters = useCallback(
    (filters: TaskListFilters, key: TaskListFiltersKey, value: string) => {
      let filtersCopy = { ...filters };
      if (value === '') {
        delete filtersCopy[key];
      } else {
        filtersCopy = { ...filtersCopy, [key]: value };
      }
      callback(filtersCopy);
    },
    [callback]
  );

  const updateBooleanFilters = useCallback(
    (filters: TaskListFilters, key: TaskListFiltersKey, value: boolean) => {
      let filtersCopy = { ...filters };
      if (!value) {
        delete filtersCopy[key];
      } else {
        filtersCopy = { ...filtersCopy, [key]: value };
      }
      callback(filtersCopy);
    },
    [callback]
  );

  return useMemo(
    () => ({
      updateArrayFilters,
      updateStringFilters,
      updateBooleanFilters,
    }),
    [updateArrayFilters, updateStringFilters, updateBooleanFilters]
  );
};

export default useTaskFilters;
