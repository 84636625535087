import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { selectFetchMyTasksStatus } from 'state/TasksList/myTasks/myTasksSlice';
import { SLICE_STATUS } from 'utils/constants';
import Card from '../Card';
import TasksTableLoader from '../DueTasks/TasksTableLoader';
import TasksTable from './TasksTable';
import TasksModal from './TasksModal';
import useDueTasks from './useDueTasks';
import emptyDueTasksImg from 'assets/images/empty-due-tasks.svg';
import dueTasksIcon from 'assets/icons/due-tasks-icon.svg';

const TasksCard = () => {
  const tasksStatus = useSelector(selectFetchMyTasksStatus);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { init, reset, hasTasks } = useDueTasks();

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  useEffect(() => {
    init();
    return () => {
      reset();
    };
  }, [init, reset]);

  return (
    <>
      <TasksModal isOpen={isModalOpen} setIsOpen={toggleModal} />
      <Card
        title={intl.get('MY_WORKSPACE.DUE_TASKS.TITLE')}
        iconSrc={dueTasksIcon}
        emptyStateImg={emptyDueTasksImg}
        emptyStateText={intl.get('MY_WORKSPACE.DUE_TASKS.EMPTY_STATE_TEXT')}
        content={
          tasksStatus === SLICE_STATUS.LOADING ? (
            <TasksTableLoader />
          ) : hasTasks ? (
            <TasksTable limit={6} />
          ) : null
        }
        showViewAllButton={hasTasks}
        viewAllTooltipText={intl.get('MY_WORKSPACE.DUE_TASKS.TOOLTIP_TEXT')}
        viewAllOnClick={toggleModal}
        dataCy='due-tasks'
      />
    </>
  );
};

export default TasksCard;
