import { Fragment, useMemo, useState } from 'react';
import intl from 'react-intl-universal';
import { Task } from 'utils/customTypes';
import { useParams } from 'react-router-dom';
import useModal from 'Hooks/useModal';
import TaskForm from './TaskForm';
import { taskModalFields } from 'utils/constants/task';
import { TaskFormFields } from 'utils/types/task';
import UnsavedChangesModal from 'Organisms/UnsavedChangesModal/UnsavedChangesModal';

interface TaskModalProps {
  isTaskTemplate?: boolean;
  isCentralizedTask?: boolean;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  taskData: Task;
  onSaveTask?: (newTaskData: TaskFormFields) => Promise<void> | void;
}

const TaskModal: React.FC<TaskModalProps> = ({
  isTaskTemplate = false,
  isCentralizedTask = false,
  isOpen,
  setIsOpen,
  taskData,
  onSaveTask = () => {},
}) => {
  const { Modal, modalProps } = useModal();
  const { projectId } = useParams<{ projectId: string }>();
  const [canCreateTask, setCanCreateTask] = useState(false);
  const [taskFields, setTaskFields] = useState<TaskFormFields>(taskModalFields);
  const modalLabels = useMemo<{ title: string; saveButton: string }>(() => {
    const isUpdating = isTaskTemplate && taskData.id;
    return {
      title: intl.get('TASK_MODAL.TITLE', {
        action: isUpdating ? intl.get('UPDATE') : intl.get('ADD'),
      }),
      saveButton: isUpdating ? intl.get('SAVE') : intl.get('ADD'),
    };
  }, [isTaskTemplate, taskData.id]);
  const [formHasChanges, setFormHasChanges] = useState(false);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);

  const addTask = async () => {
    onSaveTask(taskFields);
    setIsOpen(false);
  };

  return (
    <Fragment>
      <Modal
        {...modalProps}
        aria-label={modalLabels.title}
        closeModal={() => {
          if (formHasChanges) {
            setShowUnsavedChangesModal(true);
          } else {
            setShowUnsavedChangesModal(false);
            setIsOpen(false);
          }
        }}
        data-cy='add-tasks-modal'
        title={modalLabels.title}
        size='large'
        className='h-full'
        isOpen={isOpen}
        actionButtons={[
          {
            children: modalLabels.saveButton,
            variant: 'primary',
            'data-cy': 'confirm-button',
            'data-testid': 'confirm-button',
            disabled: !canCreateTask,
            onClick: addTask,
          },
          {
            children: intl.get('CANCEL'),
            variant: 'tertiary',
            onClick: () => setIsOpen(false),
            'data-cy': 'cancel-button',
            'data-testid': 'cancel-button',
          },
        ]}
      >
        <TaskForm
          taskData={taskData}
          canCreateTask={canCreateTask}
          isTaskTemplate={isTaskTemplate}
          isCentralizedTask={isCentralizedTask}
          projectId={projectId}
          setCanCreateTask={setCanCreateTask}
          setTaskFields={setTaskFields}
          onChangeValues={(value) => setFormHasChanges(value)}
        />
      </Modal>
      <UnsavedChangesModal
        isOpen={showUnsavedChangesModal}
        setIsOpen={setShowUnsavedChangesModal}
        onConfirm={() => setIsOpen(false)}
      />
    </Fragment>
  );
};

export default TaskModal;
