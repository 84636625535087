import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { selectUserType } from 'state/User/userSlice';
import { USER_TYPES } from 'utils/constants';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import MyWorkspace from './MyWorkspace/MyWorkspace';
import ExternalUsersLandingPage from './ExternalUsersLandingPage';

const LandingPage = () => {
  const userType = useSelector(selectUserType);

  return (
    <div className='flex flex-col h-full'>
      {userType === USER_TYPES.L_D || userType === USER_TYPES.FACILITATOR ? (
        <MyWorkspace />
      ) : (
        <>
          <PageTitle titleComponent={intl.get('LANDING.WELCOME')} />
          <ExternalUsersLandingPage />
        </>
      )}
    </div>
  );
};

export default LandingPage;
