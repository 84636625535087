import { FC } from 'react';
import intl from 'react-intl-universal';
import { OnHoldStatusBanner } from 'Pages/ProjectPage/components/Banners/Banners';
import pastDueDateIcon from 'assets/icons/past-due.svg';

interface StatusBannerProps {
  isTaskDisabled?: boolean;
  isTaskOnHold?: boolean;
  isTaskDueDatePast?: boolean;
  isOnSidepanel?: boolean;
}

const StatusBanner: FC<StatusBannerProps> = ({
  isTaskDisabled = false,
  isTaskOnHold = false,
  isTaskDueDatePast = false,
  isOnSidepanel = false,
}) => {
  if (!isTaskDisabled && !isTaskOnHold && !isTaskDueDatePast) {
    return null;
  }

  let message = '';

  if (isTaskDisabled) {
    message = intl.get('TASKS.TASK_DETAIL_PAGE.TASK_DISABLED_WARNING');
  }

  if (isTaskOnHold) {
    message = intl.get('TASKS.TASK_DETAIL_PAGE.TASK_ON_HOLD_WARNING');
  }

  if (isTaskDueDatePast) {
    message = intl.get('TASKS.TASK_DETAIL_PAGE.TASK_PAST_DUE_WARNING');
  }

  return (
    <OnHoldStatusBanner
      message={message}
      sidePanel={isOnSidepanel}
      iconProps={isTaskDueDatePast ? { src: pastDueDateIcon } : undefined}
    />
  );
};

export default StatusBanner;
