import MyScheduleHeader from './components/MyScheduleHeader/MyScheduleHeader';
import MyScheduleCalendar from './components/MyScheduleCalendar/MyScheduleCalendar';

const MySchedule = ({ pageToLoad }: { pageToLoad: string }) => (
  <div className='overflow-hidden'>
    <MyScheduleHeader />
    <MyScheduleCalendar pageToLoad={pageToLoad} />
  </div>
);

export default MySchedule;
