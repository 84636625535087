import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Button } from '@getsynapse/design-system';
import { ProjectContent } from 'utils/customTypes';
import {
  PROJECT_USER_ACTIONS,
  SLICE_STATUS,
  PROJECT_CONTENT_TYPE,
  USER_TYPES,
} from 'utils/constants';
import { getProjectFiles } from 'state/Project/projectSlice';
import { fetchTeamTasks } from 'state/ProjectTasks/projectTaskSlice';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import {
  addProjectFiles,
  fetchProjectFiles,
  getContentPageStatus,
  deleteProjectFiles,
} from 'state/Project/projectSlice';
import { useUserPermissionsContext } from 'Pages/ProjectPage/context/UserPermissionsContext';
import ContentTable from './components/ContentTable/ContentTable';
import AddContentModal from './components/AddContentModal/AddContentModal';
import { selectUserType } from 'state/User/userSlice';

const Content: React.FC<{ projectId: string; isReadOnly: boolean }> = ({
  projectId,
  isReadOnly,
}) => {
  const dispatch = useDispatch();
  const pageStatus = useSelector(getContentPageStatus);
  const projectFiles = useSelector(getProjectFiles);
  const { canUser } = useUserPermissionsContext();
  const [uploadedFiles, setUploadedFiles] = useState<ProjectContent[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const userType = useSelector(selectUserType);
  const isFacilitator = userType === USER_TYPES.FACILITATOR;

  const handleUpdateProjectFiles = async (
    files: ProjectContent[],
    successMessage: string
  ) => {
    await dispatch(
      addProjectFiles({
        projectId,
        data: {
          projectFiles: files,
        },
      })
    );
    dispatch(
      showNotificationBanner({
        notificationVariant: 'success',
        notificationText: successMessage,
      })
    );
  };

  const handleDeleteProjectFiles = async (file: ProjectContent) => {
    let successMessage = intl.get(
      'PROJECT_DETAIL.FILES_TAB.FILE_REMOVE_SUCCESS'
    );
    if (file.content_type === PROJECT_CONTENT_TYPE.WEB_LINK) {
      successMessage = intl.get(
        'PROJECT_DETAIL.FILES_TAB.WEBLINK_REMOVE_SUCCESS'
      );
    }
    if (file.content_type === PROJECT_CONTENT_TYPE.DESIGN) {
      successMessage = intl.get(
        'PROJECT_DETAIL.FILES_TAB.DESIGN_REMOVE_SUCCESS'
      );
    }
    await dispatch(
      deleteProjectFiles({
        projectId,
        data: { fileId: file.id! },
      })
    );
    dispatch(
      showNotificationBanner({
        notificationVariant: 'success',
        notificationText: successMessage,
      })
    );
  };

  const handleUploadOrLinkFiles = async (
    files: ProjectContent[],
    successMessage: string
  ) => {
    setUploadedFiles((prevFiles: ProjectContent[]) => {
      const updatedFilesArray = prevFiles.concat(files);
      return updatedFilesArray;
    });
    handleUpdateProjectFiles(files, successMessage);
  };

  useEffect(() => {
    dispatch(fetchProjectFiles(projectId));
    dispatch(fetchTeamTasks(projectId));
  }, [dispatch, projectId]);

  useEffect(() => {
    if (pageStatus === SLICE_STATUS.IDLE) {
      setUploadedFiles(projectFiles);
    }
  }, [setUploadedFiles, projectFiles, pageStatus]);

  return (
    <div className='w-full h-full relative'>
      <AddContentModal
        isOpen={modalOpen}
        setModalOpen={setModalOpen}
        handleUploadOrLinkFiles={handleUploadOrLinkFiles}
      />
      <div className='flex justify-between items-center px-6'>
        <Typography
          variant='body'
          weight='medium'
          className='text-neutral-dark'
        >
          {intl.get('PROJECT_DETAIL.FILES_TAB.TITLE')}
        </Typography>
        {canUser(PROJECT_USER_ACTIONS.ADD_FILE) && (
          <Button
            onClick={() => setModalOpen(true)}
            data-cy='project-add-content-button'
            disabled={isReadOnly}
          >
            {intl.get('PROJECT_DETAIL.FILES_TAB.ADD_CONTENT')}
          </Button>
        )}
      </div>
      <ContentTable
        projectId={projectId}
        files={uploadedFiles}
        onRemoveFile={handleDeleteProjectFiles}
        onAddContent={() => setModalOpen(true)}
        canRemoveFile={
          canUser(PROJECT_USER_ACTIONS.DELETE_FILE) && !isFacilitator
        }
      />
    </div>
  );
};

export default Content;
