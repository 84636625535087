import { useCallback, useState, FC, useRef } from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { MentionItem } from 'react-mentions';
import { CommentsPanel, Comment } from '@getsynapse/design-system';
import debounce from 'lodash/debounce';
import {
  createTaskComment,
  deleteTaskComment,
  getSingleTaskId,
  getTaskComments,
  updateTaskComment,
} from 'state/SingleTask/singleTaskSlice';
import { useAppThunkDispatch } from 'state/store';
import { selectUser } from 'state/User/userSlice';
import { showNotification as showSnackbarNotification } from 'state/SnackbarNotification/SnackbarNotificationSlice';
import {
  fetchProjectParticipantsList,
  ProjectParticipantsListElement,
} from 'api/projectParticipantsList';
import { Comment as CommentType } from 'utils/types/comments';
import { DeleteCommentConfirmationModal } from 'Organisms/Comments/DeleteCommentConfirmationModal/DeleteCommentConfirmationModal';
import { UserDataSuggestion } from 'Organisms/Comments/Mentions/CustomSuggestion';
import MentionableCommentForm from 'Organisms/Comments/Mentions/MentionableCommentForm';

interface TaskCommentsPanelProps {
  projectId: string;
}

const serializeProjectParticipantsToUserSuggestion = (
  participants: ProjectParticipantsListElement[]
): UserDataSuggestion[] => {
  return participants.map((participant: ProjectParticipantsListElement) => {
    const initial = participant.name
      .split(' ')
      .map((name) => name[0])
      .join('');
    return {
      id: participant.id,
      display: participant.name,
      avatar: {
        initial,
        imageSrc: participant.avatarUrl,
        name: participant.name,
      },
      disabled: participant.disabled,
    };
  });
};

const TaskCommentsPanel: FC<TaskCommentsPanelProps> = ({ projectId }) => {
  const taskId = useSelector(getSingleTaskId);
  const taskComments = useSelector(getTaskComments);
  const user = useSelector(selectUser);
  const dispatch = useAppThunkDispatch();
  const debouncedSearchUser = useRef(
    debounce(
      (query: string, callback) =>
        fetchProjectParticipantsList({ projectId, name: query })
          .then((response) => {
            const suggestions = serializeProjectParticipantsToUserSuggestion(
              response.participants
            );
            if (suggestions.length === 0) {
              callback([{ id: 'no-results' }]);
            } else {
              callback(suggestions);
            }
          })
          .catch(() => callback([{ id: 'no-results' }])),
      500
    )
  );

  const [isCommentsExpanded, setIsCommentsExpanded] = useState<boolean>(false);
  const [commentToDelete, setCommentToDelete] = useState<CommentType | null>(
    null
  );
  const [commentToEdit, setCommentToEdit] = useState<CommentType | null>(null);

  const notifyResult = useCallback(
    (result, successMessage: string) => {
      if (result.meta.requestStatus === 'rejected') {
        dispatch(
          showSnackbarNotification({
            notificationVariant: 'error',
            notificationTitle: intl.get('ERROR_SNACKBAR_TITLE'),
            notificationMessage: intl.get('COMMENT.COMMENT_ERROR'),
          })
        );
      } else {
        dispatch(
          showSnackbarNotification({
            notificationVariant: 'success',
            notificationTitle: intl.get('SUCCESS_SNACKBAR_TITLE'),
            notificationMessage: intl.get(successMessage),
          })
        );
      }
    },
    [dispatch]
  );

  const handleAddComment = async (content: string, mentions: MentionItem[]) => {
    if (!taskId) {
      return;
    }
    const result = await dispatch(
      createTaskComment({
        taskId,
        content,
        mentions: mentions.map((mention) => mention.id),
      })
    );
    notifyResult(result, 'COMMENT.COMMENT_CREATED');
  };

  const handleEditComment = async (
    content: string,
    mentions: MentionItem[]
  ) => {
    if (commentToEdit && taskId) {
      const result = await dispatch(
        updateTaskComment({
          commentId: commentToEdit.id,
          taskId,
          content,
          mentions: mentions.map((mention) => mention.id || String(mention)),
        })
      );
      notifyResult(result, 'COMMENT.COMMENT_UPDATED');
      setCommentToEdit(null);
    }
  };

  const handleDeleteComment = async () => {
    if (commentToDelete) {
      const result = await dispatch(
        deleteTaskComment({ commentId: commentToDelete.id })
      );
      notifyResult(result, 'COMMENT.COMMENT_DELETED');
      setCommentToDelete(null);
    }
  };

  const handleClickDeleteComment = async (comment: CommentType) => {
    setCommentToDelete(comment);
  };

  const handleClickEditComment = async (comment: CommentType) => {
    setCommentToEdit(comment);
  };

  if (!taskId) {
    return <></>;
  }

  return (
    <>
      <CommentsPanel
        commentsList={taskComments}
        isExpanded={isCommentsExpanded}
        onToggle={() => setIsCommentsExpanded(!isCommentsExpanded)}
        canAddComment
        addCommentControl={
          <MentionableCommentForm
            placeholder={intl.get(
              'TASKS.TASK_DETAIL_PAGE.COMMENTS.ADD_COMMENT'
            )}
            onSubmitComment={handleAddComment}
            customFilterSuggestionsCallback={debouncedSearchUser.current}
          />
        }
        renderComment={(comment: CommentType) => (
          <Comment
            comment={comment}
            isMutable={user.id === comment.author.id}
            onClickEdit={handleClickEditComment}
            onClickDelete={handleClickDeleteComment}
            isEditing={commentToEdit?.id === comment.id}
            editControl={
              <MentionableCommentForm
                placeholder={intl.get(
                  'TASKS.TASK_DETAIL_PAGE.COMMENTS.ADD_COMMENT'
                )}
                onSubmitComment={handleEditComment}
                onCancel={() => setCommentToEdit(null)}
                defaultContent={comment.content}
                defaultMentions={comment.mentions}
                customFilterSuggestionsCallback={debouncedSearchUser.current}
              />
            }
          />
        )}
        labels={{
          emptyState: intl.get('TASKS.TASK_DETAIL_PAGE.COMMENTS.NO_COMMENTS'),
          title: intl.get('TASKS.TASK_DETAIL_PAGE.COMMENTS.TITLE', {
            num: taskComments.length,
          }),
          viewTooltip: intl.get(
            'TASKS.TASK_DETAIL_PAGE.COMMENTS.VIEW_COMMENTS'
          ),
        }}
      />
      <DeleteCommentConfirmationModal
        isOpen={commentToDelete !== null}
        onCancel={() => setCommentToDelete(null)}
        onConfirm={() => handleDeleteComment()}
      />
    </>
  );
};

export default TaskCommentsPanel;
