import { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import get from 'lodash/get';
import {
  fetchProject,
  resetProject,
  getCurrentProjectData,
  getCurrentUserParticipantType,
} from 'state/Project/projectSlice';
import {
  getAllUsers,
  getLDUsers,
} from 'state/UsersManagement/usersManagementSlice';
import { ProjectTab } from 'utils/customTypes';
import {
  UPDATE_PROJECT_TABS,
  PROJECT_PARTICIPANT_TYPE,
  SLICE_STATUS,
  PROJECT_STATUS,
  USER_TYPES,
} from 'utils/constants';
import {
  getLearningTeams,
  selectLearningTeamStatus,
  selectLearningTeamIsFetched,
} from 'state/LearningTeams/learningTeamsSlice';
import useGetProjectReadOnly from 'Pages/ProjectPage/hooks/useGetProjectReadOnly';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import TabLink from './components/TabLink/TabLink';
import Overview from './tabs/Overview/Overview';
import Content from './tabs/Content/Content';
import Tasks from './tabs/Tasks/Tasks';
import ProjectTasksList from 'Pages/ProjectTasksList/ProjectTasksList';
import Budget from './tabs/Budget/Budget';
import People from './tabs/People/People';
import { resetSidePanelUpdatedData } from 'state/SidePanel/sidePanelSlice';
import { selectUserType } from 'state/User/userSlice';

const ProjectPage = () => {
  const dispatch = useDispatch();
  const { newTaskListViews = false } = useFlags();
  const { projectId } = useParams<{ projectId: string }>();
  const projectData = useSelector(getCurrentProjectData);
  const fromSidePanel = new URLSearchParams(window.location.search).get(
    'fromSidePanel'
  )
    ? true
    : false;
  const participantType = useSelector(getCurrentUserParticipantType);
  const ldTeamsFetched = useSelector(selectLearningTeamIsFetched);
  const ldTeamStatus = useSelector(selectLearningTeamStatus);
  const isUserCollaborator =
    participantType === PROJECT_PARTICIPANT_TYPE.COLLABORATOR;
  const [currentTab, setCurrentTab] = useState<ProjectTab>(
    UPDATE_PROJECT_TABS.OVERVIEW
  );
  const userType = useSelector(selectUserType);
  const isFacilitator = userType === USER_TYPES.FACILITATOR;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const defaultTabString = queryParams.get('tab');
  const isProjectClosed = projectData.status === PROJECT_STATUS.CLOSED;

  const isReadOnly =
    useGetProjectReadOnly(projectData.status, projectData.is_archived) ||
    isFacilitator;

  const init = useCallback(async () => {
    await Promise.all([
      dispatch(fetchProject(projectId)),
      dispatch(getAllUsers()),
      dispatch(getLDUsers()),
    ]);
  }, [dispatch, projectId]);

  useEffect(() => {
    if (projectId) {
      init();
    }
    return () => {
      dispatch(resetProject());
      if (fromSidePanel) {
        dispatch(resetSidePanelUpdatedData);
      }
    };
  }, [fromSidePanel, projectId, init, dispatch]);

  useEffect(() => {
    if (projectId && !ldTeamsFetched && ldTeamStatus !== SLICE_STATUS.LOADING) {
      dispatch(getLearningTeams());
    }
  }, [ldTeamStatus, projectId, ldTeamsFetched, dispatch]);

  const notAvailableTabsForCollaborators = useMemo(
    () => [UPDATE_PROJECT_TABS.PEOPLE, UPDATE_PROJECT_TABS.BUDGET],
    []
  );
  const availableTabs = useMemo(() => {
    let tabs: string[] = [];
    for (let value of Object.values(UPDATE_PROJECT_TABS)) {
      if (
        isUserCollaborator &&
        notAvailableTabsForCollaborators.includes(value) &&
        !isFacilitator
      ) {
        continue;
      }
      tabs.push(value);
    }
    return tabs;
  }, [notAvailableTabsForCollaborators, isUserCollaborator, isFacilitator]);

  useEffect(() => {
    if (defaultTabString && availableTabs.includes(defaultTabString)) {
      setCurrentTab(defaultTabString);
    } else {
      setCurrentTab(UPDATE_PROJECT_TABS.OVERVIEW);
    }
  }, [defaultTabString, availableTabs]);

  let projectTitle = get(projectData, 'title');
  if (projectTitle && projectTitle.length > 80) {
    projectTitle = `${projectTitle.substring(0, 80)}...`;
  }

  return (
    <div className='h-full flex flex-col'>
      <PageTitle
        titleComponent={projectTitle}
        headerChildren={
          <div className='flex'>
            {availableTabs.map((tab) => {
              return (
                <TabLink
                  key={tab}
                  tabKey={tab}
                  isActive={currentTab === tab}
                  setCurrentTab={setCurrentTab}
                  fromSidePanel={fromSidePanel}
                />
              );
            })}
          </div>
        }
      />
      {currentTab === UPDATE_PROJECT_TABS.OVERVIEW && (
        <Overview
          projectId={projectId}
          fromSidePanel={fromSidePanel}
          isReadOnly={isReadOnly}
        />
      )}
      {currentTab === UPDATE_PROJECT_TABS.TASKS &&
        (newTaskListViews ? (
          <ProjectTasksList
            projectId={projectId}
            projectStatus={projectData.status || PROJECT_STATUS.NEW}
            isProjectArchived={projectData.is_archived}
            isUserFacilitator={isFacilitator}
          />
        ) : (
          <Tasks />
        ))}
      {currentTab === UPDATE_PROJECT_TABS.BUDGET && (
        <Budget
          fromSidePanel={fromSidePanel}
          templateId={projectData.project_template_id}
          isReadOnly={isReadOnly}
        />
      )}
      {currentTab === UPDATE_PROJECT_TABS.PEOPLE && (
        <People
          projectId={projectId}
          fromSidePanel={fromSidePanel}
          isReadOnly={
            isReadOnly || projectData.status === PROJECT_STATUS.CANCELED
          }
        />
      )}
      {currentTab === UPDATE_PROJECT_TABS.CONTENT && (
        <Content
          projectId={projectId}
          isReadOnly={
            isFacilitator || isProjectClosed || projectData.is_archived || false
          }
        />
      )}
    </div>
  );
};

export default ProjectPage;
