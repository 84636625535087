import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  EVENTS_TABLE_FILTERS,
  UNASSIGNED_FACILITATOR,
} from 'utils/constants/learningSchedule';
import { Option } from 'utils/customTypes';
import { EventFiltersKey } from 'utils/types/filters';
import {
  selectFacilitatorsForDropdown,
  selectAllProjects,
  selectProgramsForDropdown,
  fetchProgramsForDropdown,
} from 'state/Schedule/ScheduleSlice';
import { EventTypesOptions } from '../constants/constants';
import userAvatar from 'assets/icons/user-avatar.svg';
import { USER_STATUS } from 'utils/constants';
import { naturalSort } from 'utils/naturalSorting';
import intl from 'react-intl-universal';
import useProjectFiltersOptions from 'Pages/ProjectsListPage/components/ProjectsFiltersSidePanel/hooks/useProjectFiltersOptions';
import { PROJECTS_TABLE_FILTERS } from 'utils/constants';

const useEventsFilters = () => {
  const dispatch = useDispatch();
  const projectsSelector = useSelector(selectAllProjects);
  const facilitatorsSelector = useSelector(selectFacilitatorsForDropdown);
  const programsSelector = useSelector(selectProgramsForDropdown);
  const { getFilterOptionsByKey } = useProjectFiltersOptions();

  const projectStatusOptions = useMemo(
    () =>
      naturalSort(
        getFilterOptionsByKey(PROJECTS_TABLE_FILTERS.STATUS),
        'label',
        'asc'
      ),
    [getFilterOptionsByKey]
  );

  const projectOptions = useMemo(() => {
    const data = projectsSelector.map((project) => ({
      label: project.title,
      value: project.id,
    }));
    return naturalSort(data, 'label', 'asc');
  }, [projectsSelector]);

  const facilitatorOptions = useMemo(() => {
    const facilitators = facilitatorsSelector.map((facilitator) => {
      const facilitatorStatus =
        facilitator.status === USER_STATUS.REGISTERED_DISABLED ||
        facilitator.status === USER_STATUS.INVITED_DISABLED;

      const facilitatorName = facilitatorStatus
        ? `${facilitator.name} (${intl.get(
            'USERS_PAGE.TABLE.USER_STATUS.DEACTIVATED'
          )})`
        : facilitator.name;
      return {
        label: facilitatorName,
        value: facilitator.id,
        avatar: {
          imageSrc: facilitator.avatarUrl,
          initial: facilitator.initials,
          name: facilitator.name,
          className: '',
        },
      };
    });
    const sortedFacilitators = naturalSort(facilitators, 'label', 'asc');
    sortedFacilitators.unshift({
      label: intl.get('SCHEDULE.EVENTS.FILTERS.FACILITATORS.UNASSIGNED'),
      value: UNASSIGNED_FACILITATOR,
      avatar: {
        imageSrc: userAvatar,
        initial: '',
        name: intl.get('SCHEDULE.EVENTS.FILTERS.FACILITATORS.UNASSIGNED'),
        className: 'border-neutral bg-neutral',
      },
    });
    return sortedFacilitators;
  }, [facilitatorsSelector]);

  const programOptions = useMemo(() => {
    const data = programsSelector.map((program) => ({
      label: program.name,
      value: program.id,
    }));
    return naturalSort(data, 'label', 'asc');
  }, [programsSelector]);

  const eventTypesOptions = useMemo(() => {
    return EventTypesOptions.map((eventType) => ({
      label: eventType.label,
      value: eventType.code,
    }));
  }, []);

  useEffect(() => {
    dispatch(fetchProgramsForDropdown());
  }, [dispatch]);

  const getOptionsbyKey: (key: EventFiltersKey) => Option[] = (key) => {
    switch (key) {
      case EVENTS_TABLE_FILTERS.FACILITATORS:
        return facilitatorOptions;
      case EVENTS_TABLE_FILTERS.EVENT_TYPE:
        return eventTypesOptions;
      case EVENTS_TABLE_FILTERS.PROJECTS:
        return projectOptions;
      case EVENTS_TABLE_FILTERS.PROGRAMS:
        return programOptions;
      case EVENTS_TABLE_FILTERS.PROJECT_STATUS:
        return projectStatusOptions;
      default:
        return [];
    }
  };

  return {
    projectOptions,
    facilitatorOptions,
    eventTypesOptions,
    programOptions,
    projectStatusOptions,
    getOptionsbyKey,
  };
};

export default useEventsFilters;
