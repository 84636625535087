import { Icon, Typography } from '@getsynapse/design-system';
import PerformitivRedirect from 'Atoms/PerformitivRedirect/PerformitivRedirect';
import intl from 'react-intl-universal';
import openIcon from 'assets/icons/open.svg';

export const PerformitivLink = () => {
  return (
    <PerformitivRedirect>
      <div className='flex flex-row align-middle justify-between gap-1 mt-1/2'>
        <Icon src={openIcon} className='text-xl' />
        <Typography>{intl.get('VIEW')}</Typography>
      </div>
    </PerformitivRedirect>
  );
};
