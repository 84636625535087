import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import { Instructor } from 'utils/types/evaluations';

/* ============================== STATE SETUP =============================== */
export const instructorsAdapter = createEntityAdapter<Instructor>({
  sortComparer: (a, b) => a.fullName.localeCompare(b.fullName),
});

/* ================================= REDUCER ================================ */
const instructorsSlice = createSlice({
  name: 'instructors',
  initialState: instructorsAdapter.getInitialState(),
  reducers: {
    addInstructors: (state, action: PayloadAction<Instructor[]>) => {
      return instructorsAdapter.upsertMany(state, action.payload);
    },
  },
});

export const { addInstructors } = instructorsSlice.actions;

/* =============================== SELECTORS ================================ */
export const instructorsSelectors = instructorsAdapter.getSelectors<RootState>(
  (state) => state.instructors
);

export default instructorsSlice.reducer;
