import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import set from 'lodash/set';
import get from 'lodash/get';
import { Button, tailwindOverride, useLink } from '@getsynapse/design-system';
import TaskModal from 'Organisms/TaskModal/TaskModal';
import { taskModalDefaultValues } from 'Pages/ProjectPage/tabs/Tasks/helpers/constants';
import { TaskFormFields } from 'utils/types/task';
import {
  createTask,
  selectTasksTablePagination,
  updateMyTasksPagination,
  updateTeamTasksPagination,
} from 'state/Tasks/taskSlice';
import { selectOrganizationId, selectUserType } from 'state/User/userSlice';
import { useHistory } from 'react-router-dom';
import { getTaskRoute } from 'utils/dynamicRoutes';
import { PATHS, USER_TYPES } from 'utils/constants';
import useSnackbarNotification from 'Hooks/useSnackbarNotification';
import { CENTRALIZED_TASKS_TABLE_TABS } from 'utils/constants/centralizedTasks';

const CreateCentralizedTaskModal: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const {
    isNotificationVisible,
    snackbarProps,
    showNotification,
    SnackbarNotification,
  } = useSnackbarNotification();
  const linkClassName = useLink();
  const [notificationLink, setNotificationLink] = useState<string>('');
  const organizationId = useSelector(selectOrganizationId);
  const teamTasksTablePagination = useSelector(
    selectTasksTablePagination(CENTRALIZED_TASKS_TABLE_TABS.TEAM_TASKS)
  );
  const myTasksTablePagination = useSelector(
    selectTasksTablePagination(CENTRALIZED_TASKS_TABLE_TABS.MY_TASKS)
  );
  const userType = useSelector(selectUserType);
  const isFacilitator = userType === USER_TYPES.FACILITATOR;

  const onCreateTask = async (newTaskData: TaskFormFields) => {
    const newTask: TaskFormFields = {
      ...newTaskData,
    };

    if (!newTaskData.start_date) {
      set(newTask, 'start_date', null);
    }
    if (!newTaskData.due_date) {
      set(newTask, 'due_date', null);
    }
    newTask.organization_id = organizationId;

    const taskAction = await dispatch(createTask(newTask));
    setNotificationLink(getTaskRoute(get(taskAction, 'payload', {})));
    showNotification({
      notificationTitle: intl.get('SUCCESS_SNACKBAR_TITLE'),
      notificationMessage: intl.get('TASKS.NOTIFICATIONS.CENTRALIZED.MESSAGE'),
      hasActionButton: true,
    });

    dispatch(
      updateTeamTasksPagination({
        limit: teamTasksTablePagination.limit,
        offset: teamTasksTablePagination.offset,
      })
    );
    dispatch(
      updateMyTasksPagination({
        limit: myTasksTablePagination.limit,
        offset: myTasksTablePagination.offset,
      })
    );
  };

  return (
    <>
      {!isFacilitator && (
        <Button
          onClick={() => setIsModalOpen(true)}
          className='px-4 py-2'
          data-cy='centralized-add-task-button'
          size='small'
        >
          {intl.get('TASKS.ADD_TASK_BUTTON_TITLE')}
        </Button>
      )}

      <TaskModal
        isCentralizedTask
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        taskData={taskModalDefaultValues}
        onSaveTask={onCreateTask}
      />

      {isNotificationVisible && snackbarProps && (
        <SnackbarNotification
          {...snackbarProps}
          cta={
            <a
              href={notificationLink}
              className={tailwindOverride(linkClassName, 'ml-9')}
              onClick={(e) => {
                e.preventDefault();
                history.push(notificationLink, {
                  from: PATHS.TASKS_LIST_PAGE,
                });
              }}
            >
              {intl.get('TASKS.NOTIFICATIONS.CENTRALIZED.CALLBACK')}
            </a>
          }
        />
      )}
    </>
  );
};

export default CreateCentralizedTaskModal;
