import { useState, FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import useImportProjectTasks from '../../hooks/useImportProjectTasks';
import MoreActionsPopup, {
  OptionProps,
} from 'Organisms/TableHeaderActionButtons/MoreActionsPopup';
import {
  setShouldRefreshTasks,
  setSnackBarNotification,
} from 'state/ProjectTasksList/actions/actionsSlice';
import ImportTasksModal from 'Pages/ProjectPage/tabs/Tasks/TasksListPage/components/ImportTasksModal/ImportTasksModal';
import ImportIcon from 'assets/icons/import.svg';
import ExportIcon from 'assets/icons/export.svg';

interface ImportExportActionsProps {
  onExport: (callback: () => void) => void;
  exportEnabled?: Boolean;
}

const ImportExportActions: FC<ImportExportActionsProps> = ({
  exportEnabled = false,
  onExport,
}) => {
  const dispatch = useDispatch();
  const [displayImportTaskModal, setDisplayImportTaskModal] =
    useState<boolean>(false);

  const {
    disabled: isImportButtonDisable,
    settings,
    currentUser,
    onData,
  } = useImportProjectTasks();

  const toggleImportTaskModal = () =>
    setDisplayImportTaskModal(!displayImportTaskModal);

  const onImportFromTaskBundle = useCallback(() => {
    dispatch(setShouldRefreshTasks(true));
    dispatch(
      setSnackBarNotification({
        notificationMessage: intl.get('TASKS.IMPORT_SUCCESS'),
      })
    );
  }, [dispatch]);

  if (!settings) {
    return null;
  }

  const tableActionsList: Partial<OptionProps>[] = [
    { label: intl.get('IMPORT'), isSectionTitle: true },
    {
      label: intl.get('TASKS.FROM_BUNDLE'),
      selectOption: () => setDisplayImportTaskModal(true),
      iconSrc: ImportIcon,
      disabled: !currentUser || !settings || isImportButtonDisable,
      dataCy: 'import-tasks-from-bundle_button',
    },
    {
      label: intl.get('TASKS.FROM_FILE'),
      isImportButton: true,
      disabled: !currentUser || !settings || isImportButtonDisable,
      importButtonProps: {
        currentUser,
        settings,
        onData: onData,
      },
    },
    { label: intl.get('EXPORT'), isSectionTitle: true },
    {
      label: intl.get('TASKS.TABLE.EXPORT'),
      selectOption: onExport,
      disabled: !exportEnabled,
      tooltip: {
        text: !exportEnabled ? intl.get('TASKS.TABLE.EXPORT_TOOLTIP') : '',
      },
      iconSrc: ExportIcon,
    },
  ];

  return (
    <>
      <ImportTasksModal
        showModal={displayImportTaskModal}
        onClose={toggleImportTaskModal}
        onImportCallback={onImportFromTaskBundle}
      />
      <MoreActionsPopup options={tableActionsList} />
    </>
  );
};

export default ImportExportActions;
