import { FC, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import { updateTaskDetail } from 'state/TaskDetail/taskSlice';
import { Toggle } from '@getsynapse/design-system';

interface DisableTaskToogleProps {
  taskId: string;
  isTaskDisabled?: boolean;
  disabled?: boolean;
}

const DisableTaskToogle: FC<DisableTaskToogleProps> = ({
  taskId,
  isTaskDisabled = false,
  disabled = false,
}) => {
  const dispatch = useDispatch();

  const handleToggleChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateTaskDetail({
        task: {
          id: taskId,
          disabled: !event.target.checked,
        },
      })
    );
  };

  return (
    <Toggle
      offText={intl.get('TASKS.TASK_DETAIL_PAGE.ENABLE_TASK')}
      onChange={handleToggleChange}
      onText={intl.get('TASKS.TASK_DETAIL_PAGE.ENABLE_TASK')}
      className='mr-1 my-auto'
      checked={!isTaskDisabled}
      toggleTextPosition='left'
      inputProps={{
        'aria-label': 'task-disable-toggle',
      }}
      disabled={disabled}
    />
  );
};

export default DisableTaskToogle;
