import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { TableOperationHeader } from '@getsynapse/design-system';
import {
  updateFacilitatorsSearchTerm,
  selectFacilitatorsViewSearchTerm,
} from 'state/Schedule/ScheduleSlice';
import {
  selectFiltersSettingsByType,
  updateFilterSetting,
  createFilterSetting,
} from 'state/Settings/Filters/FiltersSlice';
import AppliedFiltersTags from 'Organisms/AppliedFiltersTags/AppliedFiltersTags';
import { SETTINGS_FILTERS } from 'utils/constants';
import { FACILITATORS_TABLE_FILTERS } from 'utils/constants/learningSchedule';
import get from 'lodash/get';
import { setFacilitatorsFilters } from 'state/Schedule/ScheduleSlice';
import { EventFilters } from 'utils/types/filters';
import intl from 'react-intl-universal';
import debounce from 'lodash/debounce';
import useFacilitatorsFilters from 'Pages/LearningSchedule/hooks/useFacilitatorsFilters';

import FilterButton from 'Organisms/TableHeaderActionButtons/FilterButton';
import FacilitatorsEventsFiltersSidePanel from './FacilitatorsEventsFiltersSidePanel/FacilitatorsEventsFiltersSidePanel';

const FacilitatorsHeader = () => {
  const dispatch = useDispatch();
  const search = useSelector(selectFacilitatorsViewSearchTerm);
  const [searchTerm, setSearchTerm] = useState(search);

  const debouncedHandleSearch = useMemo(() => {
    return debounce((value: string) => {
      dispatch(updateFacilitatorsSearchTerm({ search: value }));
    }, 500);
  }, [dispatch]);

  const filtersSettings = useSelector(
    selectFiltersSettingsByType(SETTINGS_FILTERS.SCHEDULE_FACILITATORS_VIEW)
  );

  const savedFilters = useMemo(
    () => (filtersSettings ? filtersSettings.settings : {}),
    [filtersSettings]
  );

  const currentCountry = useMemo(() => {
    const selections = get(
      savedFilters,
      FACILITATORS_TABLE_FILTERS.COUNTRIES,
      []
    );
    if (!selections.length) {
      return [];
    }
    return selections?.[0] ?? '';
  }, [savedFilters]);

  const { getOptionsbyKey } = useFacilitatorsFilters(currentCountry);

  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);

  const toggleFilterPanel = () => {
    setIsFilterPanelOpen((prevState) => !prevState);
  };

  const saveFilters = (updatedFilters: EventFilters) => {
    if (filtersSettings && filtersSettings.id) {
      dispatch(
        updateFilterSetting({
          id: filtersSettings.id,
          updateFields: { filter_settings: updatedFilters },
        })
      );
    } else {
      dispatch(
        createFilterSetting({
          filter_type: SETTINGS_FILTERS.SCHEDULE_FACILITATORS_VIEW,
          filter_settings: updatedFilters,
        })
      );
    }
  };

  const handleUpdateFilters = (updatedFilters: EventFilters) => {
    dispatch(setFacilitatorsFilters(updatedFilters));
    saveFilters(updatedFilters);
  };

  return (
    <>
      <FacilitatorsEventsFiltersSidePanel
        savedFilters={savedFilters}
        onClose={() => setIsFilterPanelOpen(false)}
        open={isFilterPanelOpen}
        onUpdateFilters={handleUpdateFilters}
      />
      <TableOperationHeader
        className='border-b-0'
        inputValue={searchTerm}
        actionButtons={
          <FilterButton
            data-testid='facilitators-filter_button'
            onClick={() => setIsFilterPanelOpen(true)}
          />
        }
        searchProps={{
          placeholder: intl.get('SCHEDULE.SEARCH_BY_EVENT'),
        }}
        setInputValue={(value: string) => {
          setSearchTerm(value);
          debouncedHandleSearch(value);
        }}
      />
      <AppliedFiltersTags<EventFilters>
        savedFilters={savedFilters}
        onUpdateFilters={handleUpdateFilters}
        onDisplayAllFilters={toggleFilterPanel}
        getOptionsbyKey={getOptionsbyKey}
        dataTestid='events-applied-filters'
      />
    </>
  );
};

export default FacilitatorsHeader;
