import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { useHistory } from 'react-router-dom';
import { Tabs } from '@getsynapse/design-system';
import { TABLE_TABS_MAPPING, PATHS } from 'utils/constants';
import { isUserTypeFacilitator } from 'state/User/userSlice';
import TeamTasks from './TeamTasks/TeamTasks';
import MyTasks from './MyTasks/MyTasks';
import TaskDetailSidePanel from 'Pages/ProjectTasksList/TaskDetailSidePanel/TaskDetailSidePanel';
import ActionsNotifications from 'Pages/ProjectTasksList/ActionsNotifications/ActionsNotifications';

interface TableViewProps {
  isLDTypeUser: boolean;
}

const TableView: FC<TableViewProps> = ({ isLDTypeUser }) => {
  const history = useHistory();
  const isUserFacilitator = useSelector(isUserTypeFacilitator);
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);

  useEffect(() => {
    if (isLDTypeUser) {
      const search = history.location.search;
      const queryParams = new URLSearchParams(search);
      const activeTab = queryParams.get('activeTab');
      const actibeTabIndex = activeTab ? TABLE_TABS_MAPPING[activeTab] : 0;
      if (currentTabIndex !== actibeTabIndex) {
        setCurrentTabIndex(actibeTabIndex);
      }
    }
  }, [currentTabIndex, isLDTypeUser, history]);

  const handleChangeTab = (index: number) => {
    setCurrentTabIndex(index);
    const activeTab = Object.keys(TABLE_TABS_MAPPING)[index];
    history.replace(`${PATHS.TASKS_LIST_PAGE}?activeTab=${activeTab}`);
  };

  return (
    <div className='relative w-full h-full'>
      <TaskDetailSidePanel isCentralizedTask />
      <div className='pb-4 px-6'>
        {(isLDTypeUser || isUserFacilitator) && (
          <Tabs
            index={currentTabIndex}
            onChange={handleChangeTab}
            tabListProps={{ className: 'w-58 mb-4' }}
            tabPanelsProps={{ className: 'max-h-table overflow-y-auto' }}
            type='tab'
            data={[
              {
                label: intl.get('TASKS_LIST_PAGE.TEAM_TASKS'),
                content: currentTabIndex === 0 && <TeamTasks />,
              },
              {
                label: intl.get('TASKS_LIST_PAGE.MY_TASKS'),
                content: currentTabIndex === 1 && <MyTasks />,
              },
            ]}
          />
        )}
        {!isLDTypeUser && (
          <div>
            <span className='text-neutral-dark font-semibold text-base'>
              {intl.get('TASKS_LIST_PAGE.MY_TASKS')}
            </span>
            <MyTasks />
          </div>
        )}
      </div>
      <ActionsNotifications />
    </div>
  );
};

export default TableView;
