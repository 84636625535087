export const extractFilenameFromContentDisposition: (
  contentDisposition: string
) => string = (contentDisposition) => {
  let filename = 'filename.csv';
  if (contentDisposition && contentDisposition.includes('attachment')) {
    const filenameMatch = contentDisposition.match(
      /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    );
    if (filenameMatch != null && filenameMatch[1]) {
      filename = filenameMatch[1].replace(/['"]/g, '');
    }
  }
  return filename;
};

export const createDownloadLinkAndDownloadFile: (
  blob: Blob,
  filename: string
) => void = (blob, filename = 'filename.csv') => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  a.remove();
  window.URL.revokeObjectURL(url);
};
