import { useCallback, useMemo, useState, Dispatch } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import orderBy from 'lodash/orderBy';
import {
  fetchMyTasks,
  selectMyTasks,
  setSorting,
  setFilters,
} from 'state/TasksList/myTasks/myTasksSlice';
import { selectUserId } from 'state/User/userSlice';
import { TASK_STATUS } from 'utils/constants';
import { TaskWithAssigneesAndProject } from 'types/store/tasksList';

interface TableSorting {
  sortBy: string;
  order: 'asc' | 'desc';
}

interface UseDueTasksReturn {
  init: () => void;
  reset: () => void;
  sortedTasks: TaskWithAssigneesAndProject[];
  setTableSorting: Dispatch<TableSorting>;
  hasTasks: boolean;
}

const useDueTasks: (limit?: number) => UseDueTasksReturn = (limit = 30) => {
  const dispatch = useDispatch();
  const currentUserId = useSelector(selectUserId);
  const tasks = useSelector(selectMyTasks);
  const [tableSorting, setTableSorting] = useState<TableSorting>({
    sortBy: 'endDate',
    order: 'asc',
  });

  const slicedTasks = tasks.slice(0, limit);

  const init = useCallback(() => {
    dispatch(
      setFilters({
        status: [TASK_STATUS.NEW, TASK_STATUS.IN_PROGRESS],
      })
    );
    dispatch(
      setSorting({
        sortBy: 'dueDate',
        order: 'asc',
      })
    );
    dispatch(
      fetchMyTasks({
        userId: currentUserId!,
        fetchNext: false,
        includeTotalCount: false,
      })
    );
  }, [dispatch, currentUserId]);

  const reset = useCallback(() => {
    dispatch(setFilters({}));
    dispatch(setSorting({}));
  }, [dispatch]);

  const sortedTasks = useMemo(() => {
    if (!tableSorting) {
      return slicedTasks;
    }
    return orderBy(slicedTasks, [tableSorting.sortBy], [tableSorting.order]);
  }, [slicedTasks, tableSorting]);

  return useMemo(
    () => ({
      init,
      reset,
      sortedTasks,
      setTableSorting,
      hasTasks: !!tasks.length,
    }),
    [init, reset, sortedTasks, setTableSorting, tasks.length]
  );
};

export default useDueTasks;
