import {
  createSlice,
  createAsyncThunk,
  createAction,
  createSelector,
} from '@reduxjs/toolkit';
import { Status } from 'utils/customTypes';
import { SLICE_STATUS } from 'utils/constants';
import { fetchProjectsList, Project } from 'api/projectsList';
import { fetchFacilitatorsList } from 'api/facilitatorsList';
import {
  EventData,
  ScheduleProjectResource,
  NewScheduleCreationParams,
  ScheduleProjectOption,
  Facilitator,
  ScheduleUpdateParams,
  ProjectResource,
  ExceptionCreationParams,
  SearchFilterParams,
  ScheduleFacilitatorResource,
  FacilitatorResource,
  FacilitatorsSearchFilterParams,
} from 'utils/types/learningSchedule';
import {
  serializeEvents,
  serializeCreatedEvent,
  serializeExceptionEvent,
} from './helpers';
import { RootState } from 'state/store';
import ScheduleAPI from './ScheduleAPI';
import { EventFilters } from 'utils/types/filters';
import { View } from '@syncfusion/ej2-react-schedule';

export interface AvailableProgram {
  id: string;
  name: string;
  displayId: string;
}

interface ScheduleState {
  status: Status;
  value: EventsValue;
  projects: ProjectResource[];
  facilitators: FacilitatorResource[];
  facilitatorsForDropdown: Facilitator[];
  projectsForDropdown: Project[];
  programsForDropdown: AvailableProgram[];
  search: {
    events: string;
    facilitators: string;
    mySchedule: string;
  };
  currentView: {
    events: View;
    facilitators: View;
    mySchedule: View;
  };
  filters: {
    events: EventFilters;
    facilitators: EventFilters;
  };
}

type EventsValue = {
  events: EventData[];
  mySchedule: EventData[];
  facilitatorEvents: EventData[];
};

/* ============================= INITIAL STATE ============================== */

const initialState: ScheduleState = {
  value: {
    events: [],
    mySchedule: [],
    facilitatorEvents: [],
  },
  status: SLICE_STATUS.IDLE,
  projects: [],
  facilitators: [],
  projectsForDropdown: [],
  programsForDropdown: [],
  search: {
    events: '',
    facilitators: '',
    mySchedule: '',
  },
  currentView: {
    events: 'TimelineWorkWeek',
    facilitators: 'TimelineWorkWeek',
    mySchedule: 'WorkWeek',
  },
  facilitatorsForDropdown: [],
  filters: {
    events: {},
    facilitators: {},
  },
};

/* ============================= ACTIONS ============================== */
export const updateEventsSearchTerm = createAction<{
  search: string;
}>('SCHEDULE/UPDATE_EVENTS_SEARCH_TERM');

export const updateMyScheduleSearchTerm = createAction<{
  search: string;
}>('SCHEDULE/UPDATE_MY_SCHEDULE_SEARCH_TERM');

export const updateFacilitatorsSearchTerm = createAction<{
  search: string;
}>('SCHEDULE/UPDATE_FACILITATORS_SEARCH_TERM');

export const updateEventsCurrentView = createAction<{
  currentView: View;
}>('SCHEDULE/UPDATE_EVENTS_SELECTED_VIEW');

export const updateFacilitatorsCurrentView = createAction<{
  currentView: View;
}>('SCHEDULE/UPDATE_FACILITATORS_SELECTED_VIEW');

export const updateMyScheduleCurrentView = createAction<{
  currentView: View;
}>('SCHEDULE/UPDATE_MY_SCHEDULE_SELECTED_VIEW');

export const setFilters = createAction<EventFilters>('SCHEDULE/SET_FILTERS');
export const setEventsFilters = createAction<EventFilters>(
  'SCHEDULE/SET_EVENTS_FILTERS'
);

export const setFacilitatorsFilters = createAction<EventFilters>(
  'SCHEDULE/SET_FACILITATORS_FILTERS'
);

/* ============================== REDUX THUNK =============================== */
export const createEvent = createAsyncThunk(
  'SCHEDULE/CREATE_EVENT',
  async (params: NewScheduleCreationParams) => {
    const response = await ScheduleAPI.createSchedule(params);
    return response.data;
  }
);

export const fetchProjects = createAsyncThunk(
  'SCHEDULE/FETCH_PROJECTS',
  async (projectName?: string) => {
    const response = await fetchProjectsList(projectName);
    return response;
  }
);

export const fetchProgramsForDropdown = createAsyncThunk(
  'SCHEDULE/FETCH_PROGRAMS',
  async (programName?: string) => {
    const response = await ScheduleAPI.fetchPrograms(programName);
    return response.availablePrograms;
  }
);

export const fetchFacilitatorsEvents = createAsyncThunk(
  'SCHEDULE/FETCH_FACILITATORS_EVENTS',
  async (params: FacilitatorsSearchFilterParams) => {
    const response = await ScheduleAPI.fetchFacilitatorsEvents(params);
    return response;
  }
);

export const editEvent = createAsyncThunk(
  'SCHEDULE/EDIT_EVENT',
  async (params: ScheduleUpdateParams) => {
    const scheduleId = params.scheduleId || '';
    const updateParams = {
      learningEvent: params.learningEvent,
      schedule: params.schedule,
      resetExceptions: params.resetExceptions,
    };
    const response = await ScheduleAPI.updateSchedule(scheduleId, updateParams);
    return {
      data: response.data,
      resetExceptions: params.resetExceptions,
    };
  }
);

export const createException = createAsyncThunk(
  'SCHEDULE/CREATE_EXCEPTION',
  async (params: ExceptionCreationParams) => {
    const response = await ScheduleAPI.createException(params);
    return response.data;
  }
);

export const fetchEvents = createAsyncThunk(
  'SCHEDULE/FETCH_EVENTS',
  async (params: SearchFilterParams) => {
    const response = await ScheduleAPI.fetchEvents(params); // fetchEvents
    return response;
  }
);

export const fetchMySchedule = createAsyncThunk(
  'SCHEDULE/FETCH_MY_SCHEDULE',
  async (search: string) => {
    const response = await ScheduleAPI.fetchMySchedule(search);
    return response.serializedResponse;
  }
);

export const deleteEvent = createAsyncThunk(
  'SCHEDULE/DELETE_EVENT',
  async (scheduleId: string) => {
    const response = await ScheduleAPI.deleteSchedule(scheduleId);
    return response.data;
  }
);

export const fetchFacilitators = createAsyncThunk(
  'SCHEDULE/FETCH_FACILITATORS',
  async (params?: {
    search?: string;
    filters?: {
      languages: string[];
      skills: string[];
      countries: string[];
    };
  }) => {
    const response = await fetchFacilitatorsList(
      params?.search,
      params?.filters
    );
    return response;
  }
);

/* ================================= REDUCER ================================ */
const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createEvent.fulfilled, (state, action) => {
        if (action.payload) {
          const serializedEvent = serializeCreatedEvent(action.payload);
          state.value.events.push(serializedEvent);
          state.value.facilitatorEvents.push(serializedEvent);
          state.status = SLICE_STATUS.IDLE;
        }
      })
      .addCase(createEvent.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(createEvent.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
        throw new Error();
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.projectsForDropdown = action.payload.projects;
      })
      .addCase(fetchFacilitators.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchFacilitators.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchFacilitators.fulfilled, (state, action) => {
        state.facilitatorsForDropdown = action.payload;
      })
      .addCase(editEvent.fulfilled, (state, action) => {
        if (action.payload) {
          const serializedEvent = serializeCreatedEvent(action.payload.data);
          const index = state.value.events.findIndex(
            (event) => event.Id === serializedEvent.Id
          );
          const facilitatorEventIndex = state.value.facilitatorEvents.findIndex(
            (event) => event.Id === serializedEvent.Id
          );

          if (serializedEvent.RecurrenceID) {
            const parentEvent = state.value.events.find(
              (event) => event.Id === serializedEvent.RecurrenceID
            );
            if (parentEvent) {
              serializedEvent.RecurrenceRule = parentEvent.RecurrenceRule;
            }
          }
          if (index !== -1) {
            state.value.events[index] = serializedEvent;
          }
          if (facilitatorEventIndex !== -1) {
            state.value.facilitatorEvents[facilitatorEventIndex] =
              serializedEvent;
          }
          if (action.payload.resetExceptions) {
            state.value.events = state.value.events.filter(
              (event) => event.RecurrenceID !== serializedEvent.Id
            );
            state.value.facilitatorEvents =
              state.value.facilitatorEvents.filter(
                (event) => event.RecurrenceID !== serializedEvent.Id
              );
          }

          state.status = SLICE_STATUS.IDLE;
        }
      })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.value.events = serializeEvents(action.payload.formattedSchedules);
        state.projects = action.payload.projectsMatchingSearchParams;
      })
      .addCase(fetchMySchedule.fulfilled, (state, action) => {
        state.value.mySchedule = serializeEvents(
          action.payload.formattedSchedules
        );
      })
      .addCase(updateEventsSearchTerm, (state, action) => {
        state.search.events = action.payload.search;
      })
      .addCase(updateMyScheduleSearchTerm, (state, action) => {
        state.search.mySchedule = action.payload.search;
      })
      .addCase(updateFacilitatorsSearchTerm, (state, action) => {
        state.search.facilitators = action.payload.search;
      })
      .addCase(updateEventsCurrentView, (state, action) => {
        state.currentView.events = action.payload.currentView;
      })
      .addCase(updateMyScheduleCurrentView, (state, action) => {
        state.currentView.mySchedule = action.payload.currentView;
      })
      .addCase(updateFacilitatorsCurrentView, (state, action) => {
        state.currentView.facilitators = action.payload.currentView;
      })
      .addCase(editEvent.rejected, () => {
        throw new Error();
      })
      .addCase(deleteEvent.fulfilled, (state, action) => {
        state.value.events = state.value.events.filter((event) => {
          return (
            event.Id !== action.payload.deletedEventId &&
            event.RecurrenceID !== action.payload.deletedEventId
          );
        });
        state.value.facilitatorEvents = state.value.facilitatorEvents.filter(
          (event) => {
            return (
              event.Id !== action.payload.deletedEventId &&
              event.RecurrenceID !== action.payload.deletedEventId
            );
          }
        );
      })
      .addCase(deleteEvent.rejected, () => {
        throw new Error();
      })
      .addCase(setEventsFilters, (state, action) => {
        state.filters.events = action.payload || {};
      })
      .addCase(setFacilitatorsFilters, (state, action) => {
        state.filters.facilitators = action.payload || {};
      })
      .addCase(createException.fulfilled, (state, action) => {
        if (action.payload) {
          const serializedException = serializeExceptionEvent(action.payload);
          const parentId = action.payload.parent.id;
          const parentException = action.payload.parent.recurrenceException;
          const parentIndex = state.value.events.findIndex(
            (event) => event.Id === parentId
          );
          const facilitatorEventIndex = state.value.facilitatorEvents.findIndex(
            (event) => event.Id === parentId
          );
          const parentEvent = state.value.events[parentIndex];
          if (parentEvent) {
            serializedException.RecurrenceRule = parentEvent.RecurrenceRule;
          }
          if (parentIndex !== -1) {
            state.value.events[parentIndex].RecurrenceException =
              parentException;
            state.value.events.push(serializedException);
          }
          if (facilitatorEventIndex !== -1) {
            state.value.facilitatorEvents[
              facilitatorEventIndex
            ].RecurrenceException = parentException;
            state.value.facilitatorEvents.push(serializedException);
          }
        }
      })
      .addCase(createException.rejected, () => {
        throw new Error();
      })
      .addCase(fetchFacilitatorsEvents.fulfilled, (state, action) => {
        state.value.facilitatorEvents = serializeEvents(
          action.payload.formattedSchedules
        );
        state.facilitators = action.payload.facilitatorsMatchingSearchParams;
      })
      .addCase(fetchProgramsForDropdown.fulfilled, (state, action) => {
        state.programsForDropdown = action.payload;
      });
  },
});

/* =============================== SELECTORS ================================ */

export const selectScheduleEvents = (state: RootState) =>
  state.schedule.value.events;

export const selectFacilitatorsEvents = (state: RootState) =>
  state.schedule.value.facilitatorEvents;

export const selectMyScheduleEvents = (state: RootState) =>
  state.schedule.value.mySchedule;

export const selectEventsSearchTerm = (state: RootState) =>
  state.schedule.search.events;

export const selectMyScheduleSearchTerm = (state: RootState) =>
  state.schedule.search.mySchedule;

export const selectFacilitatorsViewSearchTerm = (state: RootState) =>
  state.schedule.search.facilitators;

export const selectEventsCurrentView = (state: RootState) =>
  state.schedule.currentView.events;

export const selectMyScheduleCurrentView = (state: RootState) =>
  state.schedule.currentView.mySchedule;

export const selectFacilitatorsCurrentView = (state: RootState) =>
  state.schedule.currentView.facilitators;

export const selectScheduleResources = (
  state: RootState
): ScheduleProjectResource[] =>
  state.schedule.projects.map((project) => ({
    ProjectId: project.id,
    Title: project.title,
    Status: project.status,
  }));

export const selectFacilitatorsResources = (
  state: RootState
): ScheduleFacilitatorResource[] =>
  state.schedule.facilitators.map((facilitator) => ({
    FacilitatorId: facilitator.id,
    Name: facilitator.name,
    Status: facilitator.status,
  }));

export const selectProjectsForDropdown = (
  state: RootState
): ScheduleProjectOption[] =>
  state.schedule.projectsForDropdown.map((project) => ({
    id: project.id,
    title: project.title,
    status: project.status,
  }));

export const selectAllProjects = createSelector(
  [selectScheduleResources, selectProjectsForDropdown],
  (scheduleProjectsResources, dropdownProjects) => {
    const extraProjects = scheduleProjectsResources
      .filter(
        (project) =>
          !dropdownProjects.find(
            (dropdownProject) => dropdownProject.id === project.ProjectId
          )
      )
      .map((project) => ({
        id: project.ProjectId,
        title: project.Title,
        status: project.Status,
      }));

    const allProjects = [...dropdownProjects, ...extraProjects];

    return allProjects.map(({ id, title, status }) => ({
      id,
      title,
      status,
    }));
  }
);

export const selectFacilitatorsForDropdown = (
  state: RootState
): Facilitator[] => state.schedule.facilitatorsForDropdown;

export const selectProgramsForDropdown = (state: RootState) =>
  state.schedule.programsForDropdown;

export default scheduleSlice.reducer;
