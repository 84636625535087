import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSnackBarNotification,
  setSnackBarNotification,
} from 'state/ProjectTasksList/actions/actionsSlice';
import useSnackbarNotification from 'Hooks/useSnackbarNotification';

const ActionsNotifications = () => {
  const dispatch = useDispatch();
  const snackBarNotificationData = useSelector(selectSnackBarNotification);

  const {
    SnackbarNotification,
    snackbarProps,
    showNotification,
    isNotificationVisible,
  } = useSnackbarNotification();

  useEffect(() => {
    if (snackBarNotificationData) {
      if (!isNotificationVisible) {
        showNotification(snackBarNotificationData);
      } else {
        dispatch(setSnackBarNotification(null));
      }
    }
  }, [
    snackBarNotificationData,
    isNotificationVisible,
    showNotification,
    dispatch,
  ]);

  if (!isNotificationVisible) {
    return null;
  }

  return <SnackbarNotification title='Project actions' {...snackbarProps} />;
};

export default ActionsNotifications;
