import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import get from 'lodash/get';
import { Typography } from '@getsynapse/design-system';
import AccountSettingsFooter from './AccountSettingsFooter';
import {
  selectOrganizationSettings,
  selectOrganizationSliceStatus,
  updateOrganizationSettings,
} from 'state/Organization/organizationSlice';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import { OrganizationSettings, Option } from 'utils/customTypes';
import { SLICE_STATUS, CURRENCIES } from 'utils/constants';
import { FormItem, Dropdown } from '@getsynapse/design-system';
import UnsavedChangesPrompt from 'Organisms/UnsavedChangesPrompt/UnsavedChangesPrompt';

const AccountSettings = () => {
  const dispatch = useDispatch();
  const sliceStatus = useSelector(selectOrganizationSliceStatus);
  const organizationSettings = useSelector(selectOrganizationSettings);
  const [currentSettings, setCurrentSettings] = useState<OrganizationSettings>(
    {}
  );

  useEffect(() => {
    setCurrentSettings(organizationSettings);
  }, [organizationSettings]);

  const isUpdating = useMemo<boolean>(
    () => sliceStatus === SLICE_STATUS.UPDATING,
    [sliceStatus]
  );

  const currentCurrency = get(
    currentSettings,
    'platformCurrency',
    intl.get('CURRENCY')
  );

  const currencyOptions = useMemo(
    () =>
      CURRENCIES.map((cur) => ({
        value: cur,
        label: intl.get(`CURRENCY_OPTIONS.${cur}`),
      })),
    []
  );

  const saveDisabled = useMemo<boolean>(
    () =>
      Boolean(
        get(organizationSettings, 'platformCurrency', intl.get('CURRENCY')) ===
          currentCurrency
      ),
    [organizationSettings, currentCurrency]
  );

  const onCancel = useCallback(() => {
    setCurrentSettings(organizationSettings);
  }, [organizationSettings]);

  const handleChange = (
    property: keyof OrganizationSettings,
    value: boolean | string
  ) => {
    setCurrentSettings((prevSettings) => ({
      ...prevSettings,
      [property]: value,
    }));
  };

  const onSave = () => {
    dispatch(updateOrganizationSettings(currentSettings));
    dispatch(
      showNotificationBanner({
        notificationVariant: 'success',
        notificationText: intl.get('GENERAL_SETTINGS.SUCCESS_MESSAGE'),
      })
    );
  };

  return (
    <div className='flex flex-col h-full'>
      <Typography className='text-neutral-dark mb-5' weight='medium'>
        {intl.get('GENERAL_SETTINGS.TITLE')}
      </Typography>
      <div className='bg-neutral-white flex-grow rounded px-4 pt-6'>
        <Typography variant='h4'>{intl.get('GENERAL')}</Typography>

        <Typography variant='caption' className='text-neutral'>
          {intl.get('SETTINGS_PAGE.GENERAL_PAGE_CAPTION')}
        </Typography>
        <div className='grid gap-x-20 gap-y-6 grid-cols-2 mt-6'>
          <FormItem
            label={intl.get('DISPLAY_TAB.CURRENCY_LABEL')}
            labelProps={{
              required: true,
            }}
          >
            <Dropdown
              options={currencyOptions}
              values={[
                {
                  label: intl.get(`CURRENCY_OPTIONS.${currentCurrency}`),
                  value: currentCurrency,
                },
              ]}
              onChange={(option: Option) => {
                handleChange('platformCurrency', option.value);
              }}
            />
          </FormItem>
        </div>
      </div>
      <AccountSettingsFooter
        isLoading={isUpdating}
        onCancel={onCancel}
        onSave={onSave}
        saveDisabled={saveDisabled}
      />
      <UnsavedChangesPrompt hasChanges={!saveDisabled} />
    </div>
  );
};

export default AccountSettings;
