import { FC } from 'react';
import { PROJECT_STATUS, PROJECT_USER_ACTIONS } from 'utils/constants';
import { useUserPermissionsContext } from 'Pages/ProjectPage/context/UserPermissionsContext';
import TableTabs from './TableTabs/TableTabs';
import ProjectProgress from './ProjectProgress/ProjectProgress';
import TaskDetailSidePanel from './TaskDetailSidePanel/TaskDetailSidePanel';
import DuplicateTaskModal from './DuplicateTaskModal/DuplicateTaskModal';
import CreateTaskModal from 'Pages/TasksListPage/components/v2/CreateTaskModal/CreateTaskModal';

interface ProjectTasksListProps {
  projectId: string;
  projectStatus: string;
  isProjectArchived?: boolean;
  isUserFacilitator?: boolean;
}

const ProjectTasksList: FC<ProjectTasksListProps> = ({
  projectId,
  projectStatus,
  isProjectArchived = false,
  isUserFacilitator = false,
}) => {
  const { canUser } = useUserPermissionsContext();

  const shouldDisplayAddTaskButton =
    ![PROJECT_STATUS.CLOSED, PROJECT_STATUS.CANCELED].includes(projectStatus) &&
    !isProjectArchived &&
    (canUser(PROJECT_USER_ACTIONS.ADD_TASK) || isUserFacilitator);

  return (
    <div className='w-full h-full relative'>
      <TaskDetailSidePanel />
      <DuplicateTaskModal />
      <div className='absolute left-64 top-0 ml-4 h-8'>
        <ProjectProgress />
      </div>
      {shouldDisplayAddTaskButton && (
        <div className='absolute top-0 right-0 w-28'>
          <CreateTaskModal
            isCentralizedPage={false}
            projectId={projectId}
            isUserFacilitator={isUserFacilitator}
            canUserAddTask={canUser(PROJECT_USER_ACTIONS.ADD_TASK)}
          />
        </div>
      )}
      <TableTabs />
    </div>
  );
};

export default ProjectTasksList;
