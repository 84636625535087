import { FC, useMemo, useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import useModal from 'Hooks/useModal';
import { ProjectContent } from 'utils/customTypes';
import { selectLinkedTaskContentIds } from 'state/LinkedTaskContent/linkedTaskContentSlice';
import { getProjectFiles, fetchProjectFiles } from 'state/Project/projectSlice';
import ProjectContentPicker from 'Pages/ProjectPage/tabs/Tasks/components/LinkContentModal/ProjectContentPicker';

interface LinkProjectContentModalProps {
  projectId: string;
  isOpen: boolean;
  onClose: () => void;
  linkContentFiles: (contentIds: string[]) => void;
  unLinkContentFiles: (contentIds: string[]) => void;
}
const LinkProjectContentModal: FC<LinkProjectContentModalProps> = ({
  projectId,
  isOpen,
  onClose,
  linkContentFiles,
  unLinkContentFiles,
}) => {
  const dispatch = useDispatch();
  const [linkedContentFiles, setLinkedContentFiles] = useState<
    ProjectContent[]
  >([]);
  const projectContentFiles = useSelector(getProjectFiles);
  const originalLinkedTaskContentIds = useSelector(selectLinkedTaskContentIds);
  const contentIdsToLink = useMemo(
    () =>
      linkedContentFiles
        .filter(
          (contentFile: ProjectContent) =>
            !originalLinkedTaskContentIds?.includes(contentFile.id!)
        )
        .map((contentFile: ProjectContent) => contentFile.id!),
    [linkedContentFiles, originalLinkedTaskContentIds]
  );
  const contentIdsToUnlink = useMemo(
    () =>
      originalLinkedTaskContentIds?.filter(
        (contentId) =>
          !linkedContentFiles.find(
            (contentFile: ProjectContent) => contentFile.id === contentId
          )
      ),
    [originalLinkedTaskContentIds, linkedContentFiles]
  );
  const canSubmit =
    contentIdsToLink.length > 0 || contentIdsToUnlink.length > 0;

  const { Modal, modalProps } = useModal();

  const onConfirm = useCallback(() => {
    if (contentIdsToLink.length > 0) {
      linkContentFiles(contentIdsToLink);
    }

    if (contentIdsToUnlink.length > 0) {
      unLinkContentFiles(contentIdsToUnlink);
    }
    onClose();
  }, [
    linkContentFiles,
    unLinkContentFiles,
    contentIdsToLink,
    contentIdsToUnlink,
    onClose,
  ]);

  useEffect(() => {
    dispatch(fetchProjectFiles(projectId));
  }, [dispatch, projectId]);

  useEffect(() => {
    setLinkedContentFiles(
      projectContentFiles.filter((contentFile: ProjectContent) =>
        originalLinkedTaskContentIds?.includes(contentFile.id!)
      )
    );
  }, [projectContentFiles, originalLinkedTaskContentIds]);

  const actionButtons = useMemo(
    () => [
      {
        children: intl.get('SAVE'),
        variant: 'primary',
        onClick: onConfirm,
        'data-cy': 'confirm-button',
        disabled: !canSubmit,
      },
      {
        children: intl.get('CANCEL'),
        variant: 'tertiary',
        onClick: onClose,
      },
    ],
    [onConfirm, onClose, canSubmit]
  );

  return (
    <Modal
      {...modalProps}
      isOpen={isOpen}
      title={intl.get(
        'TASKS.TASK_DETAIL_PAGE.CONTENT.LINK_CONTENT_MODAL_TITLE'
      )}
      aria-label={intl.get(
        'TASKS.TASK_DETAIL_PAGE.CONTENT.LINK_CONTENT_MODAL_TITLE'
      )}
      size='large'
      actionButtons={actionButtons}
      closeModal={onClose}
    >
      <ProjectContentPicker
        projectContentFiles={projectContentFiles}
        setLinkedContentFiles={setLinkedContentFiles}
        linkedContentFiles={linkedContentFiles}
      />
    </Modal>
  );
};

export default LinkProjectContentModal;
