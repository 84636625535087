import { api } from './api';
import axios from 'axios';

import {
  TaskListElement,
  TaskListElementPermissions,
} from 'types/store/tasksList';
import { TaskAssignee, TaskProject } from './taskTypes';

export interface FetchTasksListApiParams {
  after?: string;
  sortBy?: string;
  order?: string;
  program?: string[];
  projectId?: string;
  user?: string[];
  status?: string[];
  type?: string[];
  disabled?: boolean;
  startDateFrom?: string;
  startDateTo?: string;
  dueDateFrom?: string;
  dueDateTo?: string;
  completionDateFrom?: string;
  completionDateTo?: string;
  estimateHoursFrom?: string;
  estimateHoursTo?: string;
  actualHoursFrom?: string;
  actualHoursTo?: string;
  search?: string;
  includeTotalCount?: boolean;
}

export interface FetchTasksListResponse {
  tasks: TaskListElement[];
  assignees: Record<string, TaskAssignee>;
  projects: Record<string, TaskProject>;
  links: {
    next: string | null;
  };
  totalCount?: number;
  permissions?: Record<string, TaskListElementPermissions>;
}

const getFetchTasksListUrlSearchParams = (params: FetchTasksListApiParams) => {
  const urlSearchParams = new URLSearchParams();

  if (params.program) {
    params.program.forEach((id) => {
      urlSearchParams.append(`program[]`, id);
    });
  }

  if (params.user) {
    params.user.forEach((id) => {
      urlSearchParams.append(`user[]`, id);
    });
  }

  if (params.status && params.status.length > 0) {
    params.status.forEach((status) => {
      urlSearchParams.append(`status[]`, status);
    });
  }

  if (params.type && params.type.length > 0) {
    params.type.forEach((type) => {
      urlSearchParams.append(`type[]`, type);
    });
  }

  if (params.disabled) {
    urlSearchParams.append('disabled', 'true');
  }

  if (params.startDateFrom) {
    urlSearchParams.append('startDateFrom', params.startDateFrom);
  }

  if (params.startDateTo) {
    urlSearchParams.append('startDateTo', params.startDateTo);
  }

  if (params.dueDateFrom) {
    urlSearchParams.append('dueDateFrom', params.dueDateFrom);
  }

  if (params.dueDateTo) {
    urlSearchParams.append('dueDateTo', params.dueDateTo);
  }

  if (params.completionDateFrom) {
    urlSearchParams.append('completionDateFrom', params.completionDateFrom);
  }

  if (params.completionDateTo) {
    urlSearchParams.append('completionDateTo', params.completionDateTo);
  }

  if (params.estimateHoursFrom) {
    urlSearchParams.append(
      'estimateHoursFrom',
      params.estimateHoursFrom.toString()
    );
  }

  if (params.estimateHoursTo) {
    urlSearchParams.append(
      'estimateHoursTo',
      params.estimateHoursTo.toString()
    );
  }

  if (params.actualHoursFrom) {
    urlSearchParams.append(
      'actualHoursFrom',
      params.actualHoursFrom.toString()
    );
  }

  if (params.actualHoursTo) {
    urlSearchParams.append('actualHoursTo', params.actualHoursTo.toString());
  }

  if (params.search) {
    urlSearchParams.append('search', params.search);
  }

  if (params.sortBy) {
    urlSearchParams.append('sortBy', params.sortBy);
  }

  if (params.order) {
    urlSearchParams.append('order', params.order);
  }

  if (params.after) {
    urlSearchParams.append('after', params.after);
  }

  urlSearchParams.append('limit', '30');

  return urlSearchParams.toString();
};

export const fetchTasksList = async (params: FetchTasksListApiParams) => {
  const searchParams = getFetchTasksListUrlSearchParams(params);
  const url = `/v2/projects/${params.projectId}/tasks?${searchParams}`;
  const config = {
    headers: {
      'include-total-count': params.includeTotalCount ? 'true' : 'false',
    },
  };

  const response = await api.get<FetchTasksListResponse>(url, config);
  if (axios.isAxiosError(response)) {
    throw response;
  }
  return response.data;
};

export const exportTasksList = async (params: FetchTasksListApiParams) => {
  const searchParams = getFetchTasksListUrlSearchParams(params);
  const url = `/v2/projects/${params.projectId}/tasks/exports/csv?${searchParams}`;
  const response = await api.get(url, { responseType: 'blob' });
  if (axios.isAxiosError(response)) {
    throw response;
  }
  return response;
};
