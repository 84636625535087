import { useState, Fragment } from 'react';
import intl from 'react-intl-universal';
import set from 'lodash/set';
import { Button, Tooltip } from '@getsynapse/design-system';
import TaskModal from 'Organisms/TaskModal/TaskModal';
import { taskModalDefaultValues } from './helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createNewTask } from 'state/ProjectTasks/projectTaskSlice';
import { showNotificationBanner } from 'state/InlineNotification/inlineNotificationSlice';
import { useUserPermissionsContext } from 'Pages/ProjectPage/context/UserPermissionsContext';
import { TaskFormFields } from 'utils/types/task';
import { selectUserType } from 'state/User/userSlice';
import { PROJECT_USER_ACTIONS, USER_TYPES } from 'utils/constants';

const CreateTaskModal: React.FC<{
  renderText: string;
  componentType?: string;
}> = ({ renderText, componentType }) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { canUser } = useUserPermissionsContext();
  const userType = useSelector(selectUserType);
  const isFacilitator = userType === USER_TYPES.FACILITATOR;

  const onCreateTask = async (newTaskData: TaskFormFields) => {
    if (!newTaskData.start_date) {
      set(newTaskData, 'start_date', null);
    }
    if (!newTaskData.due_date) {
      set(newTaskData, 'due_date', null);
    }
    await dispatch(createNewTask(newTaskData));
    await dispatch(
      showNotificationBanner({
        notificationVariant: 'success',
        notificationText: intl.get('TASKS.NOTIFICATIONS.TASK_CREATION_SUCCESS'),
      })
    );
  };

  return (
    <Fragment>
      {isFacilitator && !canUser(PROJECT_USER_ACTIONS.ADD_TASK) && (
        <Tooltip
          openMode='hover1'
          position='topLeft'
          timeout={0}
          showPopper
          contentProps={{
            className: 'rounded text-sm w-60 whitespace-normal z-50',
          }}
          trigger={
            <Button
              className='px-4 py-2 cursor-not-allowed'
              data-cy={`${componentType}-add-task-button`}
              size='small'
              disabled
            >
              {renderText}
            </Button>
          }
        >
          {intl.get('TASKS.ADD_TASK_BUTTON_TOOLTIP_FOR_FACILITATORS')}
        </Tooltip>
      )}

      {(!isFacilitator ||
        (isFacilitator && canUser(PROJECT_USER_ACTIONS.ADD_TASK))) && (
        <Button
          onClick={() => setIsModalOpen(true)}
          className='px-4 py-2'
          data-cy={`${componentType}-add-task-button`}
          size='small'
        >
          {renderText}
        </Button>
      )}

      <TaskModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        taskData={taskModalDefaultValues}
        onSaveTask={onCreateTask}
      />
    </Fragment>
  );
};

export default CreateTaskModal;
