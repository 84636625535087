import intl from 'react-intl-universal';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Option, UserOption, Task } from 'utils/customTypes';
import {
  TASK_FIELDS,
  TASK_TYPES,
  LONG_INPUTS_LENGTH,
  TASK_TYPE_CATEGORY,
} from 'utils/constants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  Datepicker,
  FormItem,
  NumericInput,
  TextArea,
  TextField,
  Typography,
  UsersPicker,
} from '@getsynapse/design-system';
import { selectAllUsersForDropdown } from 'state/UsersManagement/usersManagementSlice';
import { getAvailableUsersForTaskAssignees } from 'state/SingleTask/singleTaskSlice';
import TypePicker from 'Molecules/TypePicker/TypePicker';
import { TaskFormFields } from 'utils/types/task';
import { difference } from 'utils/functions';
import { getTaskType } from 'utils/tasks';
import ProjectPicker from './ProjectPicker';

interface TaskFormProps {
  canCreateTask: boolean;
  isTaskTemplate: boolean;
  isCentralizedTask: boolean;
  taskData: Task;
  projectId: string;
  setTaskFields: React.Dispatch<React.SetStateAction<TaskFormFields>>;
  setCanCreateTask: React.Dispatch<React.SetStateAction<boolean>>;
  onChangeValues?: (hasChanges: boolean) => void;
}

const TaskForm: React.FC<TaskFormProps> = ({
  canCreateTask,
  isTaskTemplate,
  isCentralizedTask,
  taskData,
  projectId,
  setCanCreateTask,
  setTaskFields = () => {},
  onChangeValues = () => {},
}) => {
  const { tasksAssignmentsBasedResourcesCapacity = false } = useFlags();
  const availableUsers = useSelector(
    tasksAssignmentsBasedResourcesCapacity
      ? selectAllUsersForDropdown
      : getAvailableUsersForTaskAssignees
  );

  const [fieldsValues, setFieldsValues] = useState<TaskFormFields>({
    name: taskData?.name,
    type: taskData?.type,
    description: taskData?.description,
    start_date: '',
    due_date: '',
    estimate_hours: !!taskData?.estimate_hours ? taskData.estimate_hours : '',
    project_id: projectId,
    assignedUserIds: [],
  });

  useEffect(() => {
    setFieldsValues((prevData) => ({
      ...prevData,
      name: taskData?.name,
      type: taskData.type,
      description: taskData.description,
      estimate_hours: !!taskData?.estimate_hours ? taskData.estimate_hours : '',
    }));
  }, [setFieldsValues, taskData]);

  const updateValue = (newValue: any) => {
    setFieldsValues((prevData) => ({ ...prevData, ...newValue }));
  };

  const hasAssingees = fieldsValues.assignedUserIds?.length > 0;

  const handlePickOwners = (owners: UserOption[]) => {
    const ownersIds = owners.map((owner) => owner.value);
    updateValue({ [TASK_FIELDS.ASSIGNEE_ADD]: ownersIds });
  };

  const hasAddedRequiredValues = useMemo<boolean>(() => {
    return Boolean(
      ((hasAssingees && fieldsValues.start_date && fieldsValues.due_date) ||
        !hasAssingees) &&
        fieldsValues.name &&
        (isTaskTemplate || fieldsValues.project_id)
    );
  }, [
    fieldsValues.due_date,
    fieldsValues.name,
    fieldsValues.project_id,
    fieldsValues.start_date,
    hasAssingees,
    isTaskTemplate,
  ]);

  const hasUpdatedValues = useMemo<boolean>(() => {
    const assigneesDiff: string[] = difference(
      taskData.assignedUsers?.map((user) => user.id) || [],
      fieldsValues.assignedUserIds
    );

    return Boolean(
      fieldsValues.name !== taskData.name ||
        fieldsValues.type !== taskData.type ||
        fieldsValues.description !== taskData.description ||
        fieldsValues.start_date !== taskData.start_date ||
        fieldsValues.due_date !== taskData.due_date ||
        fieldsValues.estimate_hours !== taskData.estimate_hours ||
        assigneesDiff.length > 0
    );
  }, [
    fieldsValues.assignedUserIds,
    fieldsValues.description,
    fieldsValues.due_date,
    fieldsValues.estimate_hours,
    fieldsValues.name,
    fieldsValues.start_date,
    fieldsValues.type,
    taskData.assignedUsers,
    taskData.description,
    taskData.due_date,
    taskData.estimate_hours,
    taskData.name,
    taskData.start_date,
    taskData.type,
  ]);

  useEffect(() => {
    if (hasAddedRequiredValues && hasUpdatedValues) {
      !canCreateTask && setCanCreateTask(true);
    } else {
      canCreateTask && setCanCreateTask(false);
    }
  }, [
    canCreateTask,
    hasAddedRequiredValues,
    hasUpdatedValues,
    setCanCreateTask,
  ]);

  useEffect(() => {
    onChangeValues(hasUpdatedValues || hasAddedRequiredValues);
  }, [hasUpdatedValues, hasAddedRequiredValues, onChangeValues]);

  useEffect(() => {
    setTaskFields(fieldsValues);
  }, [setTaskFields, fieldsValues]);

  const formPrompt = useMemo<string>(() => {
    const container: string = isTaskTemplate
      ? intl.get('ENTITIES.TASK_BUNDLE', { num: 1 }).toLowerCase()
      : intl.get('ENTITIES.PROJECT', { num: 1 }).toLowerCase();

    return isTaskTemplate && taskData.id
      ? intl.get('TASK_MODAL.UPDATE_PROMPT', { container })
      : intl.get('TASK_MODAL.ADD_PROMPT', { container });
  }, [isTaskTemplate, taskData.id]);

  return (
    <div className='flex flex-col'>
      <Typography>{formPrompt}</Typography>

      <div className='mt-4 flex w-full gap-x-10 px-px'>
        <div className='grid gap-y-4 w-2/4'>
          <FormItem>
            <TextField
              labelProps={{ required: true }}
              label={intl.get('TASKS.ADD_TASK_MODAL.TASK_TITLE')}
              displayCharactersCount
              maxLength={LONG_INPUTS_LENGTH}
              defaultValue={fieldsValues.name}
              onChange={(e: any) =>
                updateValue({ [TASK_FIELDS.NAME]: e.target.value })
              }
              placeholder={intl.get(
                'TASKS.ADD_TASK_MODAL.TASK_TITLE_PLACEHOLDER'
              )}
              className='w-full'
              data-cy='task_title-name'
              data-testid='task_title-name'
            />
          </FormItem>

          <TypePicker
            formItemProps={{
              label: intl.get('TASKS.ADD_TASK_MODAL.TASK_TYPE'),
            }}
            dropdownProps={{
              onChange: (option: Option) =>
                updateValue({ [TASK_FIELDS.TASK_TYPE]: option.label }),
              triggerProps: {
                'data-cy': 'task-type',
                'data-testid': 'task-type',
                placeholder: intl.get(
                  'TASKS.ADD_TASK_MODAL.TASK_TYPE_PLACEHOLDER'
                ),
              },
            }}
            currentValue={getTaskType(
              fieldsValues.type || taskData.type || '',
              TASK_TYPE_CATEGORY.DETAIL
            )}
            options={Object.keys(TASK_TYPES)}
            labelRoute='TASKS.TASK_TYPES'
          />

          {!isTaskTemplate && (
            <FormItem>
              <Datepicker
                className='flex justify-evenly w-full'
                canSelectRange={true}
                startDateLabel={intl.get('TASKS.ADD_TASK_MODAL.START_DATE')}
                size='large'
                onPickDate={(dates: any) =>
                  updateValue({
                    [TASK_FIELDS.START_DATE]: dates.startDate || '',
                    [TASK_FIELDS.DUE_DATE]: dates.endDate || '',
                  })
                }
                endDateLabel={intl.get('TASKS.ADD_TASK_MODAL.END_DATE')}
                data-cy='task-input_date'
                startDateLabelProps={{
                  required: hasAssingees,
                }}
                endDateLabelProps={{
                  required: hasAssingees,
                }}
              />
            </FormItem>
          )}

          <FormItem
            component='div'
            label={intl.get('TASK.ESTIMATED_HOURS')}
            className='pb-px'
          >
            <div className='flex items-center'>
              <NumericInput
                data-cy='estimated_time'
                data-testid='estimated_time'
                placeholder={intl.get(
                  'TASKS.ADD_TASK_MODAL.ESTIMATED_TIME_PLACEHOLDER'
                )}
                containerClassName='w-11/12'
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  updateValue({
                    [TASK_FIELDS.ESTIMATED_HOURS]: event.target.value,
                  });
                }}
                defaultValue={parseFloat(fieldsValues.estimate_hours)}
              />
              <span className='pl-4'>hrs</span>
            </div>
          </FormItem>
        </div>

        <div className='grid gap-y-4 w-2/4'>
          {isCentralizedTask && (
            <ProjectPicker
              dropdownProps={{
                onChange: (option: Option) =>
                  updateValue({ project_id: option.value }),
              }}
            />
          )}

          <FormItem label={intl.get('TASKS.ADD_TASK_MODAL.TASK_DESCRIPTION')}>
            <TextArea
              textAreaProps={{
                className: 'max-h-30',
                placeholder: intl.get(
                  'TASKS.ADD_TASK_MODAL.TASK_DESCRIPTION_PLACEHOLDER'
                ),
                'data-cy': 'task_description',
                'data-testid': 'task_description',
                defaultValue: fieldsValues.description,
              }}
              onChange={(e) =>
                updateValue({ [TASK_FIELDS.DESCRIPTION]: e.target.value })
              }
            />
          </FormItem>

          {!isTaskTemplate && (
            <FormItem
              label={intl.get('TASKS.ADD_TASK_MODAL.TASK_ASSIGNEE')}
              className='text-primary-light font-medium'
            >
              <UsersPicker
                triggerText={intl.get(
                  'TASKS.ADD_TASK_MODAL.TASK_ASSIGNEE_PLACEHOLDER'
                )}
                usersList={availableUsers}
                triggerProps={{
                  'data-cy': 'user_picker',
                }}
                onChange={handlePickOwners}
              />
            </FormItem>
          )}
        </div>
      </div>
    </div>
  );
};

export default TaskForm;
