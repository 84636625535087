import intl from 'react-intl-universal';
import { Typography, Icon } from '@getsynapse/design-system';
import emptyActualHours from 'assets/icons/empty-actual-hours.svg';

const EmptyList = () => {
  return (
    <div className='flex gap-x-4 items-center'>
      <Icon
        aria-label='empty-actual-hours-icon'
        src={emptyActualHours}
        className='w-20 h-20'
      />
      <Typography variant='body'>
        {intl.get('TASK_ACTUAL_HOURS.EMPTY_LIST')}
      </Typography>
    </div>
  );
};

export default EmptyList;
